import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";

import { getUserDetails } from "../actions/userActions";
import { serverIp } from "../config/conf";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { checkCoupon } from "../actions/couponActions";
import { getPricingEntries } from "../actions/bookingItemActions";
import {
  ADD_BOOKING_RESET,
  GET_BOOKING_RESET,
} from "../constants/bookingConstants";
import { CHECK_COUPON_RESET } from "../constants/couponConstants";
import { addBooking } from "../actions/bookingActions";
import {
  BOOKING_ITEM_RESET,
  RESET_BOOKING_DATE_OPTIONS,
} from "../constants/bookingItemConstants";

const BookingInfoScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const booking = useSelector((state) => state.booking);
  const { bookingMenuItems } = booking;

  const pricing = useSelector((state) => state.pricing);
  const { pricingEntries } = pricing;

  const bookingOption = useSelector((state) => state.bookingOption);
  const { date, options } = bookingOption;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingUser, error: errorUSer, user } = userDetails;

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  const bookingCreate = useSelector((state) => state.bookingCreate);
  const { booking: createdBooking, success, error } = bookingCreate;

  const [code, setCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("paypal");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [perNightRent, setPerNightRent] = useState(0);

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getPricingEntries());
    dispatch({ type: GET_BOOKING_RESET });
    dispatch({ type: CHECK_COUPON_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      booking.couponCode = "";
      navigate(`/booking/${createdBooking._id}`);
      dispatch({ type: ADD_BOOKING_RESET });
      dispatch({ type: BOOKING_ITEM_RESET });
      dispatch({ type: RESET_BOOKING_DATE_OPTIONS });
    }
  }, [success, navigate, createdBooking]);

  useEffect(() => {
    calculateTotalPrice(bookingMenuItems, pricingEntries, date, options);
  }, [dispatch, bookingMenuItems, pricingEntries, date, options]);

  useEffect(() => {
    if (isValid) {
      calculateDiscountedPrice(discount);
    }
  }, [dispatch, isValid, discount]);

  const calculateDiscountedPrice = (discount) => {
    const discountPrice = perNightRent - perNightRent * (discount / 100);
    booking.discountedPrice = discountPrice;
    booking.discount = discountPrice;
  };

  booking.discount = perNightRent * (discount / 100);
  booking.totalPrice = perNightRent - booking.discount;

  useEffect(() => {
    if (!userInfo) {
      // navigate('/signup');
      setName("");
      setEmail("");
      setPhone("");
    } else {
      if (!user.name) {
        dispatch(getUserDetails());
      } else {
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
      }
    }
  }, [userInfo, user]);

  const calculateTotalPrice = (
    bookingMenuItems,
    pricingEntries,
    date,
    options
  ) => {
    // Calculate number of nights
    const start = new Date(date[0].startDate);
    const end = new Date(date[0].endDate);
    const adult = options.adult;
    const child = options.children;
    const numberOfNights = Math.floor((end - start) / (1000 * 60 * 60 * 24));

    // Get the per day price from pricingEntries
    const perDayPrice =
      pricingEntries.length > 0 ? pricingEntries[0].perDayPrice : 0;
    const campingTax =
      pricingEntries.length > 0 ? pricingEntries[0].campingTax : 0;

    // Calculate the base price for the booking
    const basePrice = perDayPrice * numberOfNights;
    setPerNightRent(basePrice);
    booking.perNightRent = basePrice;
    booking.discountedPrice = basePrice;
    booking.campingTax = campingTax;

    // Calculate total adult price
    const totalAdultPrice = bookingMenuItems.reduce((total, item) => {
      const itemPrice = item.priceAdult * adult;
      return total + itemPrice;
    }, 0);

    // Calculate total child price
    const totalChildPrice = bookingMenuItems.reduce((total, item) => {
      const itemPrice = item.priceChild * child;
      return total + itemPrice;
    }, 0);

    // Calculate total group price
    const totalGroupPrice = bookingMenuItems.reduce((total, item) => {
      const itemPrice = item.priceGroup * 1;
      return total + itemPrice;
    }, 0);
    const menuTotal = totalAdultPrice + totalChildPrice + totalGroupPrice;
    booking.menuTotal = menuTotal;

    // Calculate overall total price
    const totalPrice =
      basePrice + totalAdultPrice + totalChildPrice + totalGroupPrice;
    booking.totalPrice = totalPrice;

    return totalPrice;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    dispatch(
      addBooking({
        user: userInfo?._id,
        userName: name,
        userEmail: email,
        userContact: phone,
        startDate: date[0].startDate,
        endDate: date[0].endDate,
        adults: options.adult,
        children: options.children,
        menuItems: booking.bookingMenuItems,
        paymentMethod: selectedOption,
        totalBookingPrice: booking.perNightRent,
        totalMenuPrice: booking.menuTotal,
        totalPrice: booking.totalPrice,
        couponCode: booking.couponCode,
        discount: discount,
        discountedPrice: booking.discountedPrice,
      })
    );
  };

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code, "booking"));
    booking.couponCode = code;
  };

  return (
    <div className="flex flex-col p-4 md:p-6">
      <nav className="flex justify-center pb-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              Cart
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Information
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                Payment
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col md:flex-row max-w-7xl mx-auto gap-4 justify-between">
        <div className="flex h-full max-h-[135vh] w-full md:w-1/2 flex-col shadow-md">
          <h2 className="text-2xl p-4 font-semibold leading-7 text-gray-900">
            Kontaktdaten
          </h2>
          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
            <form id="form1" onSubmit={submitHandler}>
              <div className="space-y-8">
                <div className="">
                  <div className="grid grid-cols-1 md:grid-cols-8 gap-x-6 gap-y-4">
                    <div className="md:col-span-8">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="name"
                          required
                          placeholder="Name*"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          id="Email"
                          required
                          placeholder="Email*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Telefonnummer
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          id="Mobile"
                          required
                          placeholder="Telefonnummer*"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="flex flex-col space-y-2 p-6">
            <p className="text-2xl font-semibold leading-7 text-gray-900">
              Weiter mit den folgenden Zahlungsoptionen:
            </p>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="paypal"
                value="paypal"
                checked={selectedOption === "paypal"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="paypal">PayPal</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="Kreditkarte"
                value="Kreditkarte"
                checked={selectedOption === "Kreditkarte"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="Kreditkarte">Kreditkarte</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="Debitkarte"
                value="Debitkarte"
                checked={selectedOption === "Debitkarte"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="Debitkarte">Debitkarte</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="GiroPay"
                value="GiroPay"
                checked={selectedOption === "GiroPay"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="GiroPay">GiroPay</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="Sofortüberweisung"
                value="Sofortüberweisung"
                checked={selectedOption === "Sofortüberweisung"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="Sofortüberweisung">Sofortüberweisung</label>
            </div>
          </div>
        </div>

        <div className="flex h-full max-h-[135vh] w-full md:w-1/2 flex-col shadow-md">
          <h2 className="text-2xl font-semibold leading-7 p-4 text-gray-900">
            Ihre Bestelldaten
          </h2>
          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
            <div className="">
              <div className="flow-root">
                <ul role="list" className="">
                  {bookingMenuItems.length !== 0 &&
                    bookingMenuItems.map((item) => (
                      <div className="mb-2 rounded-lg bg-white p-4 md:p-6 shadow-md flex justify-between gap-2">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-[90px] h-[90px] object-cover rounded-lg"
                        />
                        <div className="flex w-4/5 sm:justify-between">
                          <div className="flex flex-col gap-2 sm:mt-0 w-full">
                            <h2 className="text-sm md:text-lg font-bold text-gray-900">
                              {item.name}
                            </h2>
                            <h2 className="hidden md:flex text-md font-semibold text-gray-900 overflow-hidden">
                              {item.description}
                            </h2>
                          </div>
                          {/* <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6"> */}
                          <div className="flex flex-col justify-between w-1/3">
                            <p className="self-end text-lg md:text-2xl font-bold text-gray-700">
                              <span>
                                {(
                                  +options.adult * item.priceAdult +
                                  +options.children * item.priceChild +
                                  1 * item.priceGroup
                                )
                                  ?.toFixed(2)
                                  .replace(".", ",")}
                                €
                              </span>
                            </p>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    ))}
                  {booking?.menuTotal && (
                    <Flex
                      borderBottom="1px"
                      py="2"
                      bg="white"
                      rounded="lg"
                      p="6"
                      mb="2"
                      borderColor="gray.200"
                      alignitems="center"
                      justifyContent="space-between"
                    >
                      <Flex direction="column" gap={1} w="80%">
                        <Text fontWeight="bold" fontSize="xl">
                          Gesamtpreis Ihrer Wunschliste
                        </Text>
                        <Text fontSize="sm" color="red">
                          (* Bitte beachten Sie: Dies ist eine Wunschliste und
                          noch nicht bestätigt, daher zahlen Sie heute nur den
                          Stellplatz und Ihre Wünsche dann bei uns vor Ort.)
                        </Text>
                        <Text fontSize="sm" color="red">{`*
                                        Wichtiger Hinweis zu Stornierungen von Buchungen für Camping und unseren Events: Bitte beachten Sie, dass wir für Sie stets einen perfekten Aufenthalt planen und das natürlich auch mit Planung und Bereitstellung von Ressourcen verbunden ist. Daher gelten für Stornierungen die folgenden Bedingungen und wir bitten um Ihr Verständnis.`}</Text>

                        <Text fontSize="sm" color="red">{`*
                                        Terminverschiebungen sind stets für Sie völlig kostenfrei. Für Stornierungen gelten die folgenden Bedingungen. - A) Bis 14 Tage vor dem Anreisetermin ist die Stornierung kostenfrei möglich. - B) Bis 7 Tage vor dem Anreisetermin erstatten wir Ihnen 50% der gezahlten Buchungskosten. - Bis 3 Tage vor dem Anreisetermin erstatten wir 20% der gezahlten Buchungskosten.
                                    `}</Text>
                      </Flex>
                      <Text fontWeight="bold" fontSize="xl">
                        {booking.menuTotal?.toFixed(2).replace(".", ",")}€
                      </Text>
                    </Flex>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="px-4 py-6 sm:px-6">
            <div className="my-2">
              <form id="form2" onSubmit={checkCouponHandler}>
                <p className="text-lg">Gutscheine</p>
                <div className="flex gap-2 w-full">
                  <input
                    type="text"
                    id="code"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="submit"
                    value="form2"
                    className="w-1/4 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                  >
                    Apply
                  </button>
                </div>
              </form>
              {loading && <Loader />}
              {errorCoupon && <Message type="error">{errorCoupon}</Message>}
            </div>

            <div className="flex justify-between py-2">
              <p className="text-gray-700">Ihre Reisedaten</p>
              <p className="text-gray-700">
                Von {new Date(date[0].startDate).toISOString().split("T")[0]}{" "}
                Bis {new Date(date[0].endDate).toISOString().split("T")[0]}
              </p>
            </div>

            <div className="flex justify-between py-2">
              <p className="text-gray-700">Erwachsene</p>
              <p className="text-gray-700">{options.adult}</p>
            </div>

            <div className="flex justify-between py-2">
              <p className="text-gray-700">Kinder</p>
              <p className="text-gray-700">{options.children}</p>
            </div>

            <div className="flex justify-between py-2">
              <p className="text-gray-700">
                Stellplatz Übernachtung x (
                {Math.floor(
                  (new Date(date[0].endDate) - new Date(date[0].startDate)) /
                    (1000 * 60 * 60 * 24)
                )}
                )
              </p>
              <p className="text-gray-700">
                {booking.perNightRent?.toFixed(2).replace(".", ",")}€
              </p>
            </div>

            {discount !== 0 && (
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Discount({discount}%)</p>
                <p className="text-gray-700">
                  {booking.discount?.toFixed(2).replace(".", ",")}€
                </p>
              </div>
            )}

            {isValid && (
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Gutscheine</p>
                <p className="text-gray-700">{booking.couponCode}</p>
              </div>
            )}

            <hr className="my-4" />
            <div className="flex justify-between">
              <p className="text-xl font-bold">Gesamter Betrag</p>
              <div className="flex flex-col items-end">
                <p className="mb-1 text-xl font-bold">
                  {booking.totalPrice?.toFixed(2).replace(".", ",")}€
                </p>
                <p className="text-sm text-gray-700">
                  Inklusive MwSt(
                  {(perNightRent * (booking.campingTax / 100))
                    ?.toFixed(2)
                    .replace(".", ",")}
                  €)
                </p>
              </div>
            </div>

            {error && <Message type="error">{error}</Message>}

            <div className="flex w-full justify-center">
              <button
                type="submit"
                value="form1"
                form="form1"
                className="rounded-md w-1/2 bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Jetzt bestellen & zahlen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingInfoScreen;
