import React from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	Heading,
	Image,
	Link,
	Text,
} from '@chakra-ui/react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';

import {
	ORDER_DELIVER_RESET,
	ORDER_PAY_RESET,
} from '../constants/orderConstants';
import {
	getOrderDetails,
	payOrder,
} from '../actions/orderActions';

class OrderPrint extends React.Component {
	render() {
		const { order } = this.props;
		return (
			<div className='p-12'>
				<Box borderBottom='1px' borderColor='gray.300'>
					<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
						Gutschein
					</Heading>
					<Text>
						Name: <strong>{order.shippingAddress.name}</strong>
					</Text>
					<Text>
						Email:{' '}
						<strong>
							<a href={`mailto:${order.shippingAddress.email}`}>{order.shippingAddress.email}</a>
						</strong>
					</Text>
					<Text>
						Phone:{' '}
						<strong>{order.shippingAddress.phone}</strong>
					</Text>
					<Text>
						Gutschein Code:{' '}
						<strong>{order._id}</strong>
					</Text>
					<Text fontSize='lg' fontWeight='semibold'>
						Erwachsene {order.adults}
					</Text>
					<Text fontSize='lg' fontWeight='semibold'>
						Kinder {order.children}
					</Text>
				</Box>
				<Box borderBottom='1px' py='6' borderColor='gray.300'>
					<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
						Zahlungsabwicklung
					</Heading>
					<Text>
						<strong>Ihre Zahlungsoptionen: </strong>
						{order.paymentMethod.toUpperCase()}
					</Text>
					<Text mt='4'>
						{order.isPaid ? (
							<>
								Paid on {order.paidAt.substring(0, 10)}
							</>
						) : (
							<>Not Paid</>
						)}
					</Text>
				</Box>

				<Box borderBottom='1px' py='6' borderColor='gray.300'>
					<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
						Gutschein For
					</Heading>
					<Box>
						{order.orderItems.length === 0 ? (
							<div>No Order Info</div>
						) : (
							<Box py='2'>
								{order.orderItems.map((item, idx) => (
									<Flex
										key={idx}
										justifyContent='space-between'
										flexDirection='column'
									>
										<Flex py='2' alignItems='center'>
											<Image
												src={item.image}
												alt={item.name}
												w='12'
												h='12'
												objectFit='cover'
												mr='6'
											/>
											<Link
												fontWeight='semibold'
												as={RouterLink}
												to={`/product/${item.product}`}>
												{item.name}
											</Link>
										</Flex>
									</Flex>
								))}
							</Box>
						)}
					</Box>
				</Box>
				<Box px={{ sm: '12', md: '0' }}>
					<Heading mb='6' as='h2' fontSize='3xl' fontWeight='bold'>
						Gutschein Summary
					</Heading>

					{/* Total Price */}
					<Flex
						borderBottom='1px'
						py='2'
						borderColor='gray.200'
						alignitems='center'
						gap='4'
					>
						<Text fontSize='xl'>Summe</Text>
						<Text fontWeight='bold' fontSize='xl'>
							{(order.totalPrice)?.toFixed(2).replace('.', ',')}€
						</Text>
					</Flex>
					<Text>Inklusive USt</Text>
				</Box>
			</div>
		);
	}
};

const CouponScreen = () => {
	const dispatch = useDispatch();
	const { id: orderId } = useParams();
	const componentRef = useRef();

	const orderDetails = useSelector((state) => state.orderDetails);
	const { order, loading, error } = orderDetails;

	const orderPay = useSelector((state) => state.orderPay);
	const { loading: loadingPay, success: successPay } = orderPay;

	const orderDeliver = useSelector((state) => state.orderDeliver);
	const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	if (!loading) {
		order.itemsPrice = order.orderItems.reduce(
			(acc, currVal) => acc + currVal.price * currVal.qty,
			0
		);
	}

	const successPaymentHandler = (paymentResult) => {
		dispatch(payOrder(orderId, paymentResult));
	};

	useEffect(() => {
		dispatch({ type: ORDER_PAY_RESET });
		dispatch({ type: ORDER_DELIVER_RESET });

		if (!order || successPay) {
			dispatch({ type: ORDER_PAY_RESET });
			dispatch({ type: ORDER_DELIVER_RESET });
			dispatch(getOrderDetails(orderId));
		}
	}, [dispatch, orderId, successPay, order, successDeliver]);

	const printHandler = () => {
		if (componentRef && componentRef.current) {
			componentRef.current.print();
		}
	};

	return loading ? (
		<div>Loading</div>
		// <Loader />
	) : error ? (
		<div type='error'>{error}</div>
	) : (
		<Flex>
			{/* <Header /> */}
			<Flex w='full' py='5' direction='column'>
				<Grid templateColumns={{ sm: '3fr fr', md: '3fr 2fr' }} gap='5'>
					{/* Column 1 */}
					<Flex direction='column' shadow='md' p='4'>
						{/* Shipping */}
						<Box borderBottom='1px' borderColor='gray.300'>
							<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
								Gutschein
							</Heading>
							<Text>
								Name: <strong>{order.shippingAddress.name}</strong>
							</Text>
							<Text>
								Email:{' '}
								<strong>
									<a href={`mailto:${order.shippingAddress.email}`}>{order.shippingAddress.email}</a>
								</strong>
							</Text>
							<Text>
								Phone:{' '}
								<strong>{order.shippingAddress.phone}</strong>
							</Text>
							<Text>
								Gutschein Code:{' '}
								<strong>{order._id}</strong>
							</Text>
							<Text fontSize='lg' fontWeight='semibold'>
								Erwachsene {order.adults}
							</Text>
							<Text fontSize='lg' fontWeight='semibold'>
								Kinder {order.children}
							</Text>
						</Box>

						{/* Payment Method */}
						<Box borderBottom='1px' py='6' borderColor='gray.300'>
							<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
								Zahlungsabwicklung
							</Heading>
							<Text>
								<strong>Ihre Zahlungsoptionen: </strong>
								{order.paymentMethod.toUpperCase()}
							</Text>
							<Text mt='4'>
								{order.isPaid ? (
									<>
										Paid on {order.paidAt.substring(0, 10)}
									</>
								) : (
									<>Not Paid</>
								)}
							</Text>
						</Box>

						{/* Order Items */}
						<Box borderBottom='1px' py='6' borderColor='gray.300'>
							<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
								Gutschein For
							</Heading>
							<Box>
								{order.orderItems.length === 0 ? (
									<div>No Order Info</div>
								) : (
									<Box py='2'>
										{order.orderItems.map((item, idx) => (
											<Flex
												key={idx}
												justifyContent='space-between'
												flexDirection='column'
											>
												<Flex py='2' alignItems='center'>
													<Image
														src={item.image}
														alt={item.name}
														w='12'
														h='12'
														objectFit='cover'
														mr='6'
													/>
													<Link
														fontWeight='semibold'
														as={RouterLink}
														to={`/product/${item.product}`}>
														{item.name}
													</Link>
												</Flex>


											</Flex>
										))}
									</Box>
								)}
							</Box>
						</Box>
					</Flex>

					{/* Column 2 */}
					<Flex
						mx={{ sm: '0', md: '4' }}
						direction='column'
						bgColor='white'
						justifyContent={{ sm: 'start', md: 'space-between' }}
						p={{ sm: '2', md: '8' }}
						shadow='md'
						w='full'
						gap='4'
						rounded='lg'
						borderColor='gray.300'>
						<Box px={{ sm: '12', md: '0' }}>
							<Heading mb='6' as='h2' fontSize='3xl' fontWeight='bold'>
								Gutschein Summary
							</Heading>

							{/* Total Price */}
							<Flex
								borderBottom='1px'
								py='2'
								borderColor='gray.200'
								alignitems='center'
								gap='4'
							>
								<Text fontSize='xl'>Total</Text>
								<Text fontWeight='bold' fontSize='xl'>
									{(order.totalPrice)?.toFixed(2).replace('.', ',')}€
								</Text>
							</Flex>
							<Text>Inklusive USt</Text>
						</Box>

						{/* PAYMENT BUTTON */}
						{!order.isPaid && (
							<Box>
								{loadingPay ? (
									<div>Loading</div>
									// <Loader />
								) : (
									<PayPalScriptProvider
										options={{
											'client-id':
												'AdOtJGzC_gvmx7PD2HQ3qw8s5B1E8FqLQMlMwyDrixthJdW7FX0WdmzbqaumH-954dXb8lOMivXFb66Z',
											components: 'buttons',
											currency: 'EUR',
											locale: 'de_DE',
										}}>
										<PayPalButtons
											style={{ layout: 'vertical' }}
											createOrder={(data, actions) => {
												return actions.order.create({
													purchase_units: [
														{
															amount: {
																value: order.totalPrice?.toFixed(2),
															},
														},
													],
												});
											}}
											onApprove={(data, actions) => {
												return actions.order.capture().then((details) => {
													const paymentResult = {
														id: details.id,
														status: details.status,
														update_time: details.update_time,
														email_address: details.email_address,
													};
													successPaymentHandler(paymentResult);
												});
											}}
										/>
									</PayPalScriptProvider>
								)}
							</Box>
						)}

						{loadingDeliver && <div>Loading</div>}
						{order.isPaid && (
							<ReactToPrint
								trigger={() => (
									<Button type="button" colorScheme="teal" onClick={printHandler}>
										Print Receipt
									</Button>
								)}
								content={() => componentRef.current}
							/>
						)}
						<div className='hidden'>
							<OrderPrint order={order} ref={componentRef} />
						</div>
					</Flex>
				</Grid>
			</Flex>
		</Flex>
	);
};

export default CouponScreen;
