import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Link,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { Flex, Text, Select } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { PRODUCT_REVIEW_CREATE_RESET } from "../constants/productConstants";
import Rating from "../components/Rating";
import { listMenuItemDetails } from "../actions/menuItemActions";
import { addToCart } from "../actions/cartActions";

export default function EventsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { id } = useParams();
  const [qty, setQty] = useState(1);
  const [pno, setPno] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [mainImg, setMainImg] = useState("");

  // const productDetails = useSelector((state) => state.productDetails);
  // const { loading, error, product } = productDetails;

  const menuItemDetails = useSelector((state) => state.menuItemDetails);
  const { loading, error, menuItem } = menuItemDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      alert("Review submitted");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }

    dispatch(listMenuItemDetails(id));
  }, [id, dispatch, successProductReview]);

  const addToCartHandler = () => {
    navigate(`/cart/${id}?qty=${qty}&add=event&pno=${pno}`);
    // navigate(`/cart/${menuId}?qty=1&add=event&pno=${noOfPersons}`);
  };

  const handleImgChange = (img) => {
    setMainImg(img);
  };

  const calculateFormatedDate = (date) => {
    const parsedDateTime = new Date(date);

    // Round the seconds portion to the nearest minute
    parsedDateTime.setSeconds(
      Math.round(parsedDateTime.getSeconds() / 60) * 60
    );

    // Format the resulting date and time as an ISO 8601 string
    const formattedDateTime = parsedDateTime
      .toISOString()
      .slice(0, 16)
      .replace("T", " ");
    // const formattedDateTime = parsedDateTime.toLocaleString();
    return formattedDateTime;
  };

  return (
    <div>
      <section className="overflow-hidden bg-white font-poppins">
        {loading ? (
          <div>Loading</div>
        ) : error ? (
          <div>Error</div>
        ) : (
          <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex flex-col md:flex-row gap-8 -mx-4">
              <div className="flex flex-wrap-reverse md:flex-nowrap mt-0 md:mt-4 w-full p-4 gap-4 md:w-1/2">
                <div className="flex md:flex-col gap-4 w-1/4">
                  {
                    menuItem.image && (
                      // product.image.map((img) => (
                      <div className="">
                        <button
                          className="block border border-blue-300 hover:border-blue-300"
                          onClick={() => handleImgChange(menuItem.image)}
                        >
                          <img
                            src={menuItem.image}
                            alt=""
                            className="object-contain w-[90px] h-[90px] cursor-pointer"
                          />
                        </button>
                      </div>
                    )
                    // ))
                  }
                </div>
                <div className="hidden md:flex w-full md:w-3/4 justify-center">
                  <TransformWrapper centerOnInit={true} initialScale={1}>
                    <TransformComponent
                      contentStyle={{ width: "60vh", height: "60vh" }}
                    >
                      <img
                        className="w-full h-full object-contain object-center"
                        src={mainImg ? mainImg : menuItem.image}
                        alt={menuItem.name}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
                <div className="flex md:hidden w-full md:w-3/4 justify-center">
                  {/* <TransformWrapper centerOnInit={true} initialScale={1}> */}
                  {/* <TransformComponent contentStyle={{ 'width': '60vh', 'height': '60vh' }}> */}
                  <img
                    className="w-full h-full object-contain object-center"
                    src={mainImg ? mainImg : menuItem.image}
                    alt={menuItem.name}
                  />
                  {/* </TransformComponent> */}
                  {/* </TransformWrapper> */}
                </div>
                <style>
                  {`
                                    .react-transform-component {
                                      height: 60vh; /* Set your desired width for desktop */
                                    }
    @media (min-width: 768px) { /* Adjust this breakpoint as needed */
      .react-transform-component {
        height: 60vh; /* Set your desired width for desktop */
      }
    }
  `}
                </style>
              </div>
              <div className="w-full px-4 md:w-1/2 ">
                <div className="lg:pl-20">
                  <div className="mb-8 ">
                    <span className="text-lg font-medium text-rose-500">
                      New
                    </span>
                    <h2 className="max-w-xl mt-2 mb-6 text-2xl font-semibold md:text-4xl">
                      {menuItem.name}
                    </h2>
                    <h2 className="max-w-xl mt-2 mb-6 text-2xl font-semibold md:text-4xl">
                      {menuItem?.date && calculateFormatedDate(menuItem?.date)}
                    </h2>
                    <p className="inline-block mb-2 item-center">
                      <span className="text-4xl font-bold text-gray-700">{`${menuItem.price
                        ?.toFixed(2)
                        .replace(".", ",")}€`}</span>
                    </p>
                  </div>
                  {menuItem.countInStock > 0 && (
                    <Flex className="gap-2" py="2" alignItems="center">
                      <Text>Qty: </Text>
                      <Select
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        width="20%"
                      >
                        {[...Array(menuItem.countInStock).keys()].map((i) => (
                          <option value={i + 1} key={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  )}
                  {menuItem.category === "Group Events" ? (
                    <div className="flex gap-2 py-2 items-center">
                      <label>Anzahl der Teilnehmer:</label>
                      <input
                        type="number"
                        min={1}
                        className="border w-[50px] rounded-md p-2"
                        value={pno}
                        onChange={(e) => setPno(e.target.value)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-col gap-4">
                    <div className="w-full mb-4 lg:w-4/5 lg:mb-0">
                      <button
                        className={`${
                          menuItem.countInStock === 0
                            ? "bg-gray-200 hover:bg-gray-300 text-black hover:text-red-600 border-black hover:border-red-600"
                            : "bg-white hover:bg-blue-600 text-blue-500 hover:text-gray-100 border-blue-500 hover:border-blue-600"
                        } flex items-center justify-center w-full p-3 border rounded-md enbaled:cursor-pointer disabled:cursor-not-allowed`}
                        disabled={menuItem.countInStock === 0}
                        onClick={addToCartHandler}
                      >
                        {menuItem.countInStock === 0
                          ? "Dieses Produkt ist leider ausverkauft"
                          : "In den Einkaufswagen"}
                      </button>
                    </div>

                    <p className="max-w-md mb-8 text-gray-700 whitespace-pre-line">
                      {menuItem.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
