import {
  Menu as ChakraMenu,
  MenuButton,
  Portal,
  MenuList,
  MenuItem,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiMenuKebab } from "react-icons/ci";

import Sidebar from "../components/Sidebar";
import "./sidenav.css";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteCoupon, getAllCoupons } from "../actions/couponActions";
import { IoAdd } from "react-icons/io5";

export default function CouponListAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const couponList = useSelector((state) => state.couponList);
  const { loading, error, coupons } = couponList;

  const couponDelete = useSelector((state) => state.couponDelete);
  const { success } = couponDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = coupons?.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getAllCoupons());
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate, success]);

  const createCouponHandler = () => {
    navigate("/dashboard/coupons/add?type=add");
  };

  const handleDelete = (id) => {
    dispatch(deleteCoupon(id));
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/coupons/${id}/edit?type=edit`);
  };

  return (
    <div>
      <Sidebar menu="Gutscheine" />
      {/* <!-- CONTENT --> */}
      <section id="content">
        {/* <!-- MAIN --> */}
        <main>
          <div className="table-data drop-shadow-md">
            <div class="order">
              <div class="head">
                <h3>Coupons</h3>
                <i class="bx bx-search"></i>
                <i class="bx bx-filter"></i>
                <Button onClick={createCouponHandler} colorScheme="blue">
                  <Icon as={IoAdd} mr="2" fontSize="xl" fontWeight="bold" />
                  Add Coupon
                </Button>
              </div>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message type="error">{error}</Message>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Coupon Name</th>
                      <th>Code</th>
                      <th>Discount</th>
                      <th>Status</th>
                      <th>Valid Till</th>
                      <th>Allocated To</th>
                      <th>Visibility</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems &&
                      currentItems.map((coupon) => (
                        <tr key={coupon._id}>
                          <td>{coupon.name}</td>
                          <td>{coupon.code}</td>
                          <td>
                            <p>{coupon.discount}</p>
                          </td>
                          <td>
                            <span
                              className={
                                coupon.isActive
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {coupon.isActive ? "Active" : "Expired"}
                            </span>
                          </td>
                          <td>{coupon.expirationDate.substring(0, 10)}</td>
                          <td>{coupon.allocatedTo}</td>
                          <td>{coupon.visibility ? "All" : "None"}</td>
                          <td>
                            <div>
                              <ChakraMenu>
                                <MenuButton className="text-sm font-medium leading-6 text-gray-900">
                                  <CiMenuKebab className="h-7 w-7 rotate-90" />
                                </MenuButton>
                                <Portal>
                                  <MenuList
                                    className="text-sm font-semibold uppercase leading-6 text-gray-900"
                                    zIndex="99"
                                  >
                                    <MenuItem
                                      onClick={() => handleEdit(coupon._id)}
                                    >
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => handleDelete(coupon._id)}
                                    >
                                      Delete
                                    </MenuItem>
                                  </MenuList>
                                </Portal>
                              </ChakraMenu>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {currentPage}
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={indexOfLastItem >= coupons.length}
                    >
                      Next
                    </button>
                  </div>
                </table>
              )}
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}
