import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ProductList from "../components/ProductList";
import RingsHero from "../components/RingsHero";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { listCategory, listSubCategory } from "../actions/categoryActions";
import { Select } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

export default function Wein() {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const cat = queryParams.get("cat") || "all";

  const categorytList = useSelector((state) => state.categorytList);
  const {
    loading: loadingCategory,
    error: errorCategory,
    category: categoryList,
  } = categorytList;

  const subCategoryList = useSelector((state) => state.subCategoryList);
  const {
    loading: loadingSubCategory,
    error: errorSubCategory,
    subCategory: subCategoryLists,
  } = subCategoryList;

  const [category, setCategory] = useState(cat);
  const [subCategory, setSubCategory] = useState("");
  const [taste, setTaste] = useState("");
  const [priceSort, setPriceSort] = useState(1);
  const [isVisible, setIsVisible] = useState(true);

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 700);
  };

  useEffect(() => {
    onTop();
    if (type === "category") {
      setCategory(cat);
      setSubCategory("all");
      setTaste("all");
    } else if (type === "color") {
      setCategory("all");
      setSubCategory(cat);
      setTaste("all");
    } else if (type === "taste") {
      setCategory("all");
      setSubCategory("all");
      setTaste(cat);
    } else {
      setCategory("all");
      setSubCategory("all");
      setTaste("all");
    }
  }, [cat, type]);

  console.log(cat);

  useEffect(() => {
    dispatch(listCategory("all"));
  }, [dispatch]);

  // useEffect(() => {
  //     onTop()
  //     dispatch({ type: ORDER_CREATE_RESET });
  //     if (id) {
  //         dispatch(listSubCategory(id));
  //     }
  // }, [routePath, type, dispatch]);

  const handleSelection = (e) => {
    const val = e.target.value;
    const selectedCategory = JSON.parse(val);
    setCategory(selectedCategory);
    // Use the 'id' property of 'selectedCategory' for other purposes, e.g., dispatching actions
    let id = JSON.parse(val).id;
    dispatch(listSubCategory(id));
    // Other logic...
  };

  const categories = categoryList?.filter((cat) => cat.type === "category");
  const colors = categoryList?.filter((cat) => cat.type === "color");
  const tastes = categoryList?.filter((cat) => cat.type === "taste");

  return (
    <div>
      {/* <Header /> */}
      <Helmet>
        <title>Shop - Wein</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>

      <RingsHero />

      <div
        id="headerSearch"
        className="flex items-center gap-6 p-16 w-full bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]"
      >
        <h2 className="text-white text-xl">Filter</h2>

        <Select
          width="30%"
          borderColor="white"
          value={subCategory}
          textColor="white"
          onChange={(e) => {
            setSubCategory(e.target.value);
            setCategory("all");
          }}
        >
          <option className="text-black" key="0" value="all">
            Farbe
          </option>
          {loadingCategory ? (
            <>Loading</>
          ) : errorCategory ? (
            <>error</>
          ) : (
            colors.map((subCatObj, idx) => (
              <option
                className="text-black"
                key={subCatObj._id}
                value={subCatObj.name}
              >
                {subCatObj.name}
              </option>
            ))
          )}
        </Select>

        <Select
          width="30%"
          borderColor="white"
          value={taste}
          textColor="white"
          onChange={(e) => {
            setTaste(e.target.value);
            setCategory("all");
          }}
        >
          <option className="text-black" key="0" value="all">
            Geschmack
          </option>
          {loadingCategory ? (
            <>Loading</>
          ) : errorCategory ? (
            <>error</>
          ) : (
            tastes.map((subCatObj, idx) => (
              <option className="text-black" key={idx} value={subCatObj.name}>
                {subCatObj.name}
              </option>
            ))
          )}
        </Select>

        <Select
          width="30%"
          borderColor="white"
          value={priceSort}
          textColor="white"
          onChange={(e) => setPriceSort(e.target.value)}
        >
          <option className="text-black" key="0" value={1}>
            Preis: niedrig bis hoch
          </option>
          <option className="text-black" key="1" value={-1}>
            Preis: hoch bis niedrig
          </option>
        </Select>
      </div>

      <ProductList
        list={category}
        subcat={subCategory}
        taste={taste}
        priceSort={priceSort}
        isVisible={isVisible}
      />
    </div>
  );
}
