import { RiDeleteBinLine } from "react-icons/ri";
import {
  useNavigate,
  useSearchParams,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Select, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

import { addToCart, removeFromCart } from "../actions/cartActions";
import { getPricingEntries } from "../actions/bookingItemActions";
import { getUserDetails } from "../actions/userActions";
import { CART_ADD_ITEM } from "../constants/cartConstants";
import Message from "../components/Message";
import { Helmet } from "react-helmet";

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  const { id: productId } = useParams();
  let qty = searchParams.get("qty");
  let add = searchParams.get("add");
  let pno = searchParams.get("pno") || 1;

  // const [noOfPersons, setNoOfPersons] = useState(searchParams.get('pno') || 1);

  const [wineQty, setWineQty] = useState(0);
  const [boxQty, SetBoxQty] = useState(0);
  const [totalWineAndHofladenPrice, setTotalWineAndHofladenPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalEndPrice, setTotalEndPrice] = useState(0);
  const [freightCharge, setFreightCharge] = useState(0);
  const [freightChargeWine, setFreightChargeWine] = useState(0);
  const [freightChargeWineBox, setFreightChargeWineBox] = useState(0);
  const [freightChargeHofladen, setFreightChargeHofladen] = useState(0);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const pricing = useSelector((state) => state.pricing);
  const { pricingEntries } = pricing;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getPricingEntries());
    if (productId) {
      dispatch(addToCart(qty, add, productId, pno));
    }
  }, [dispatch, productId, qty, add, pno]);

  useEffect(() => {
    if (user?.name) {
      // user.cart?.map((item) => {
      //     dispatch({
      //         type: CART_ADD_ITEM,
      //         payload: item,
      //     });
      // })
      user.cart?.forEach((item) => {
        dispatch({
          type: CART_ADD_ITEM,
          payload: item,
        });
      });
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (cartItems && cartItems.length !== 0) {
      let bottleOfWines = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen" &&
            !item.isBulk
        )
        .reduce((acc, currVal) => acc + currVal.qty, 0);
      let calculatedBoxes = Math.ceil(bottleOfWines / 6);
      let bulkWines = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen" &&
            item.isBulk
        )
        .reduce((acc, currVal) => acc + currVal.qty, 0);
      let boxOfWines = 0;
      boxOfWines = bulkWines + calculatedBoxes;
      SetBoxQty(boxOfWines);
      setWineQty(bottleOfWines);
      const orderValueOfWine = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen"
        )
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      const orderValueOfHofladen = cartItems
        .filter((item) => item.category === "hofladen")
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      const orderValueOfEvents = cartItems
        .filter((item) => item.category?.split(" ")[1] === "Events")
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      let orderValue =
        orderValueOfEvents + orderValueOfHofladen + orderValueOfWine;
      let orderValueForWineAndHofladen =
        orderValueOfWine + orderValueOfHofladen;

      let freightWine = 0;
      if (boxOfWines === 0 || orderValueForWineAndHofladen > 75) {
        setFreightChargeWineBox(0); // 12 Euro for orders below 50 Euro
        freightWine = 0;
      } else if (boxOfWines === 1) {
        setFreightChargeWineBox(12); // 9.7 Euro for orders between 50 and 75 Euro
        freightWine = 9.7;
      } else if (boxOfWines === 2) {
        setFreightChargeWineBox(24); // No freightWine charge for orders above 75 Euro
        freightWine = 19.4;
      } else if (boxOfWines >= 2 && orderValueForWineAndHofladen < 75) {
        setFreightChargeWineBox(24); // No freightWine charge for orders above 75 Euro
        freightWine = 19.4;
      }

      let freightHofladen = 0;
      if (orderValueForWineAndHofladen > 75) {
        setFreightChargeHofladen(0); // 12 Euro for orders below 50 Euro
        freightHofladen = 0;
      } else if (orderValueOfHofladen > 0 && orderValueOfHofladen < 75) {
        setFreightChargeHofladen(12); // No freightWine charge for orders above 75 Euro
        freightHofladen = 4.5;
      }

      let freight = freightHofladen + freightWine;
      cart.shippingPrice = freight;
      console.log("cart.shippingPrice", cart.shippingPrice);
      setFreightCharge(freight);

      let netWineAndHofladenPrice = orderValueOfWine + orderValueOfHofladen;
      setTotalWineAndHofladenPrice(netWineAndHofladenPrice);

      let netPrice =
        orderValueOfWine + orderValueOfHofladen + orderValueOfEvents;
      setTotalPrice(netPrice);

      let totalCartPrice = orderValue + freightHofladen + freightWine;
      cart.totalPrice = totalCartPrice;
      console.log("cart.totalPrice", cart.totalPrice);
      setTotalEndPrice(totalCartPrice);

      cart.taxPrice =
        cartItems?.reduce(
          (acc, currVal) => acc + currVal.qty * currVal.price,
          0
        ) *
        (pricingEntries[0]?.orderTax / 100);
    }
  }, [cartItems]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate(`/shipping`);
  };

  const handleQuantityChange = (e, cat, prod) => {
    let add =
      cat === "Solo Events" || cat === "Group Events" ? "event" : "prod";
    dispatch(addToCart(+e.target.value, add, prod, pno));
  };

  const handleGroupEventChange = (e, qty, prod) => {
    dispatch(addToCart(qty, "event", prod, e.target.value));
  };

  return (
    <div className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] px-4 md:px-8">
      {/* <Header /> */}
      <Helmet>
        <title>Shop - Warenkorb</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <div className="flex max-w-full border-y-2 border-white">
        <div className="flex flex-col w-full md:w-2/3 mx-auto md:flex-row justify-center gap-4 my-4">
          <div className="rounded-lg md:w-1/2">
            {cartItems?.length === 0 ? (
              <Message>
                <Text
                  as={RouterLink}
                  to="/wein?subcat=all"
                  textDecoration="underline"
                  _hover={{ color: "blue" }}
                >
                  Ihr Warenkorb ist leer. Setzen Sie jetzt Ihren Einkauf fort.
                </Text>
              </Message>
            ) : (
              <div>
                {cartItems?.map((item, idx) => (
                  <div
                    key={idx}
                    className="mb-0 md:mb-2 rounded-lg bg-white p-2 md:p-4 shadow-md flex justify-between gap-2"
                  >
                    {/* <div className='sm:w-40 sm:h-120'> */}
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-[90px] h-[90px] object-cover rounded-lg"
                    />
                    {/* </div> */}
                    <div className="sm:ml-4 flex w-4/5 sm:justify-between">
                      <div className="flex flex-col justify-between w-2/3">
                        <RouterLink
                          to={
                            item.category === "Solo Events" ||
                            item.category === "Group Events"
                              ? `/events`
                              : `/product/${item.product}`
                          }
                        >
                          <h2 className="text-sm md:text-lg font-bold text-gray-900">
                            {item.name}
                          </h2>
                        </RouterLink>
                        {/* <h2 className="text-md font-semibold text-gray-900">{item.description}</h2> */}
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <label>Anzahl</label>
                            <Select
                              value={item.qty}
                              onChange={(e) =>
                                handleQuantityChange(
                                  e,
                                  item.category,
                                  item.product
                                )
                              }
                              width="20"
                            >
                              {[...Array(item?.countInStock).keys()]?.map(
                                (i) => (
                                  <option value={i + 1} key={i + 1}>
                                    {i + 1}
                                  </option>
                                )
                              )}
                            </Select>
                          </div>

                          {item.category === "Group Events" && (
                            <div className="flex gap-2 items-center">
                              <label>Anzahl der Teilnehmer</label>
                              <input
                                type="number"
                                min={1}
                                className="border w-[50px] rounded-md p-2"
                                value={item.pno}
                                onChange={(e) =>
                                  handleGroupEventChange(
                                    e,
                                    item.qty,
                                    item.product
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6"> */}
                      <div className="flex flex-col justify-between w-1/3">
                        <p className="self-end text-lg md:text-xl font-semibold text-gray-700">
                          <span>{`${item.price
                            ?.toFixed(2)
                            .replace(".", ",")} €`}</span>
                          {/* <span
                                                            className="text-base self-end font-normal text-gray-500 line-through dark:text-gray-400">{item.price} €</span> */}
                        </p>
                        <RiDeleteBinLine
                          className="self-end text-2xl cursor-pointer"
                          onClick={() => removeFromCartHandler(item.product)}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="md:max-w-1/2">
            {cartItems?.length !== 0 && (
              <div className="my-0 md:my-6 rounded-lg border p-6 shadow-md md:mt-0 bg-white">
                <div className="mb-2 flex justify-between">
                  <p className="text-gray-700">
                    Zwischensumme (
                    {cartItems?.reduce((acc, currVal) => acc + currVal.qty, 0)})
                  </p>
                  <div className="flex flex-col items-end">
                    <p className="text-gray-700">
                      {cartItems
                        ?.reduce(
                          (acc, currVal) => acc + currVal.qty * currVal.price,
                          0
                        )
                        ?.toFixed(2)
                        .replace(".", ",")}
                      €
                    </p>
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <p className="text-gray-700">Frachtkosten</p>
                  <p className="text-gray-700">
                    {freightCharge?.toFixed(2).replace(".", ",")}€
                  </p>
                </div>
                <hr className="my-4" />
                <div className="flex flex-col">
                  <div className="flex w-full justify-between">
                    <p className="text-lg font-bold">Gesamter Betrag</p>
                    <div className="flex flex-col items-end">
                      <p className="mb-1 text-lg font-bold">
                        {/* {((cartItems.reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0)))?.toFixed(2).replace('.', ',')}€ */}
                        {cart.totalPrice?.toFixed(2).replace(".", ",")}€
                      </p>
                      <p className="text-sm text-gray-700">
                        Inklusive MwSt(
                        {cart?.taxPrice?.toFixed(2).replace(".", ",")}
                        €)
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className={`mt-6 w-full rounded-md py-1.5 font-medium text-blue-50 ${
                    (wineQty === 0 || totalWineAndHofladenPrice === 0) &&
                    totalPrice === 0
                      ? "cursor-not-allowed bg-gray-300 hover:bg-gray-500"
                      : "cursor-pointer bg-blue-500 hover:bg-blue-600"
                  }`}
                  onClick={checkoutHandler}
                  // (wineQty !== 0 && boxQty < 1) ||
                  //   (totalWineAndHofladenPrice !== 0 && totalPrice < 50)
                  disabled={
                    (wineQty === 0 || totalWineAndHofladenPrice === 0) &&
                    totalPrice === 0
                  }
                >
                  Zum Bezahlvorgang
                </button>
                <HashLink
                  to={"/#das-weinhaus"}
                  className="flex mt-3 mb-6 w-full justify-center"
                >
                  <p className="text-white hover:font-semibold bg-orange-400 rounded-md py-1 px-2">
                    Weiter einkaufen
                  </p>
                </HashLink>
                {/* <p
                  className={`text-red-600 ${
                    (wineQty !== 0 && boxQty < 1) ||
                    (totalWineAndHofladenPrice !== 0 && totalPrice < 50)
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  Hinweis: Mindestbestellsumme von 50 Euro Nettowarenwert nicht
                  erreicht!
                </p> */}
                <p
                  className={`text-green-700 ${
                    freightCharge === 0 ? "hidden" : "block"
                  }`}
                >{`Hinweis: Nur noch ${(
                  76 -
                  cartItems?.reduce(
                    (acc, currVal) => acc + currVal.qty * currVal.price,
                    0
                  )
                )
                  ?.toFixed(2)
                  .replace(".", ",")}€ bis zum kostenlosen Versand!`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
