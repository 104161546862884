import BestOfSapphire from "./screens/BestOfSapphire";
import Home from "./screens/Home";
import './App.css';
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";

import ProductPage from "./screens/ProductPage";
import Wishlist from "./screens/Wishlist";
import Cart from "./screens/Cart";
import Signup from "./screens/Signup";
import Registration from "./screens/Registration";
import ShippingScreen from "./screens/ShippingScreen";
import Header from "./components/Header";
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import Footer from "./components/Footer";
import Dashboard from "./screens/Dashboard";
import MyStore from "./screens/MyStore";
import Support from "./screens/SupportScreen";
import OrderList from "./screens/OrderListScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductForm from "./screens/ProductForm";
import OrderListAdmin from "./screens/OrderListAdmin";
import UserListScreen from "./screens/UserListScreen";
import SupportForm from "./screens/SupportAdmin";
import BannerAdmin from "./screens/BannerAdmin";
import BannerForm from "./screens/BannerForm";
import OtpScreen from "./screens/OtpScreen";
import Booking from "./screens/Booking";
import BookingInfo from "./screens/BookingInfoScreen";
import PlaceBookingScreen from "./screens/PlaceBookingScreen";
import BookingScreen from "./screens/BookingScreen";
import PaymentBookingScreen from "./screens/PaymentBooking";
import BookingMenuListScreen from "./screens/BookingMenuListScreen";
import CampingForm from "./screens/CampingForm";
import OrderScreenAdmin from "./screens/OrderScreenAdmin";
import BookingListAdmin from "./screens/BookingListAdmin";
import BookingScreenAdmin from "./screens/BookingScreenAdmin";
import BookingList from "./screens/BookingListScreen";
import FaqForm from "./screens/FaqForm";
import Category from "./screens/categoryScreen";
import CategoryForm from "./screens/categoryForm";
import SubCategory from "./screens/SubCategoryScreen";
import DisabledDateListAdmin from "./screens/DisabledDateListAdmin";
import CouponListAdmin from "./screens/CouponListAdmin";
import CouponForm from "./screens/CouponForm";
import Wein from "./screens/Wein";
import HofladenListScreen from "./screens/HofladenListScreen";
import HofladenForm from "./screens/HofladenForm";
import Hofladen from "./screens/Hofladen";
import Events from "./screens/Events";
import Alpaka from "./screens/Alpaka";
import AlpakaInfo from "./screens/AlpakaInfoScreen";
import CouponScreen from "./screens/CouponScreen";
import CouponOrderListAdmin from "./screens/CouponOrderListAdmin";
import PricingForm from "./screens/PricingAdmin";
import EventsPage from "./screens/EventsPage";
import EmailForm from "./screens/EmailForm";
import CookieBanner from "./components/CookieBanner";
import ForgotPass from "./screens/ForgotPass";
import ResetPass from "./screens/ResetPass";
import UserCartDetails from "./screens/UserCartDetails";
import BookingInfoScreen from "./screens/BookingInfoScreencopy";
import SuccessPaymentScreen from "./screens/SuccessPaymentScreen";

function App() {

  return (
    <HashRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/camping' element={<BestOfSapphire />} />
        <Route path='/alpaka' element={<Alpaka />} />
        <Route path='/wein' element={<Wein />} />
        <Route path='/hofladen' element={<Hofladen />} />
        <Route path='/events' element={<Events />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/shipping' element={<ShippingScreen />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/booking' element={<Booking />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/forgotPass' element={<ForgotPass />} />
        <Route path='/resetPass' element={<ResetPass />} />
        <Route path='/otp' element={<OtpScreen />} />
        <Route path='/registration' element={<Registration />} />
        <Route path="/successPay" element={<SuccessPaymentScreen />} />
        <Route path='/payment' element={<PaymentScreen />} />
        <Route path='/placeorder' element={<PlaceOrderScreen />} />
        <Route path='/placebooking' element={<PlaceBookingScreen />} />
        <Route path='/orders' element={<OrderList list='order' />} />
        <Route path='/bookings' element={<BookingList />} />
        <Route path='/gutscheine' element={<OrderList list='coupon' />} />
        <Route path='/supportFAQ' element={<Support />} />
        <Route path='/dashboard' element={<Dashboard />} />
        {/* <Route path='/booking/info' element={<BookingInfo />} /> */}
        <Route path='/booking/info' element={<BookingInfoScreen />} />
        <Route path='/booking/payment' element={<PaymentBookingScreen />} />
        <Route path='/dashboard/mystore' element={<MyStore />} />
        <Route path='/dashboard/category' element={<Category />} />
        <Route path='/dashboard/pricing' element={<PricingForm />} />
        <Route path='/dashboard/order' element={<OrderListAdmin />} />
        <Route path='/dashboard/coupons' element={<CouponOrderListAdmin />} />
        <Route path='/dashboard/bookings' element={<BookingListAdmin />} />
        <Route path='/dashboard/disabled' element={<DisabledDateListAdmin />} />
        <Route path='/dashboard/couponCode' element={<CouponListAdmin />} />
        <Route path='/dashboard/customers' element={<UserListScreen />} />
        <Route path='/dashboard/support' element={<SupportForm />} />
        <Route path='/dashboard/banner' element={<BannerAdmin />} />
        <Route path='/dashboard/mystore/wein' element={<ProductListScreen list='all' subcat='all' />} />
        <Route path='/dashboard/mystore/hofladen' element={<HofladenListScreen list='hofladen' subcat='all' />} />
        <Route path='/dashboard/mystore/solo-events' element={<BookingMenuListScreen list='Solo Events' />} />
        <Route path='/dashboard/mystore/group-events' element={<BookingMenuListScreen list='Group Events' />} />
        <Route path='/dashboard/mystore/camping' element={<BookingMenuListScreen list='Camping Services' />} />
        <Route path='/dashboard/mystore/alpaka' element={<BookingMenuListScreen list='Alpaka Services' />} />
        <Route path='/dashboard/category/add' element={<CategoryForm />} />
        <Route path='/dashboard/coupons/add' element={<CouponForm />} />
        <Route path='/cart/:id' element={<Cart />} />
        <Route path='/wishlist/:id' element={<Wishlist />} />
        <Route path='/product/:id' element={<ProductPage />} />
        <Route path='/events/:id' element={<EventsPage />} />
        <Route path='/order/:id' element={<OrderScreen />} />
        <Route path='/coupon/:id' element={<CouponScreen />} />
        <Route path='/booking/:id' element={<BookingScreen />} />
        <Route path='/dashboard/order/:id' element={<OrderScreenAdmin />} />
        <Route path='/dashboard/booking/:id' element={<BookingScreenAdmin />} />
        <Route path='/dashboard/category/:id' element={<SubCategory />} />
        <Route path='/alpaka/:id/buy' element={<AlpakaInfo />} />
        <Route path='/dashboard/coupons/:id/edit' element={<CouponForm />} />
        <Route path='/dashboard/product/:id/edit' element={<ProductForm />} />
        <Route path='/dashboard/hofladen/:id/edit' element={<HofladenForm />} />
        <Route path='/dashboard/camping/:id/edit' element={<CampingForm />} />
        <Route path='/dashboard/banner/:id/edit' element={<BannerForm />} />
        <Route path='/dashboard/faq/:id/edit' element={<FaqForm />} />
        <Route path='/dashboard/category/:id/add' element={<CategoryForm />} />
        <Route path='/dashboard/customers/:id/mail' element={<EmailForm />} />
        <Route path='/dashboard/customers/:id/details' element={<UserCartDetails />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
