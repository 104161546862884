import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import BestHero from "../components/BestHero";
import Search from "../components/Search";
import MenuItemList from "../components/MenuItemList";
import { useDispatch, useSelector } from "react-redux";
import { ADD_BOOKING_RESET } from "../constants/bookingConstants";
import { listMenuItems } from "../actions/menuItemActions";
import { FiThumbsUp } from "react-icons/fi";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { Helmet } from "react-helmet";

export default function Alpaka() {
  const dispatch = useDispatch();

  const menuItemList = useSelector((state) => state.menuItemList);
  const { loading, menuItems, error } = menuItemList;

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    onTop();
    dispatch(listMenuItems());
    dispatch({ type: ORDER_CREATE_RESET });
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Shop - Alpaka</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <BestHero list="Alpaka" />

      <div className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] border-b-2 border-white">
        <div className="mx-auto max-w-2xl px-4 py-2 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Alpaka</h2>
          {loading ? (
            <div>Loading</div>
          ) : error ? (
            <div>Error</div>
          ) : (
            <div className="grid gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8">
              {menuItems
                .filter((menu) => menu.category === "Alpaka Services")
                .map((menu, index) => (
                  // <div key={index} className="flex flex-col">
                  //     <div className="group">
                  //         <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-white xl:aspect-h-8 xl:aspect-w-7">
                  //             <img
                  //                 src={menu.image}
                  //                 alt={menu.name}
                  //                 className="h-full w-full object-cover object-center group-hover:opacity-75"
                  //             />
                  //         </div>
                  //         <h3 className="mt-4 text-center text-base text-white h-12 overflow-clip">{menu.name}</h3>
                  //         <p className="mt-1 text-center text-lg font-medium text-white">{`Erwachsene : ${(menu.priceAdult)?.toFixed(2).replace('.', ',')}€`}</p>
                  //         <p className="mt-1 text-center text-lg font-medium text-white">{`Kinder : ${(menu.priceChild)?.toFixed(2).replace('.', ',')}€`}</p>
                  //     </div>
                  //     <div className="w-full">
                  //         <RouterLink to={`/alpaka/${menu._id}/buy`}>
                  //             <button className="enbaled:cursor-pointer disabled:cursor-not-allowed focus:outline-none focus:ring-gray-800 focus:ring-offset-2 focus:ring-2 text-white w-full tracking-tight py-4 text-lg leading-4 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900 border border-gray-800"
                  //                 disabled={menu.countInStock === 0}
                  //             >
                  //                 Kaufen
                  //             </button>
                  //         </RouterLink>
                  //     </div>
                  // </div>
                  <div
                    key={index}
                    className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md"
                  >
                    <div className="group">
                      <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-lg bg-white xl:aspect-h-8 xl:aspect-w-8">
                        <img
                          src={menu.image}
                          alt={menu.name}
                          className="h-full w-full object-cover object-center group-hover:opacity-75"
                        />
                      </div>
                      <h3 className="mt-4 text-center text-base text-black h-12 overflow-clip">
                        {menu.name}
                      </h3>

                      <p className="mt-1 text-center text-lg font-medium text-black">{`Erwachsene : ${menu.priceAdult
                        ?.toFixed(2)
                        .replace(".", ",")}€`}</p>
                      <p className="mt-1 text-center text-lg font-medium text-black">{`Kinder : ${menu.priceChild
                        ?.toFixed(2)
                        .replace(".", ",")}€`}</p>
                    </div>
                    <RouterLink
                      to={`/alpaka/${menu._id}/buy`}
                      className="flex justify-center items-center p-2 md:p-4 bg-gray-100"
                    >
                      <button
                        disabled={menu.countInStock === 0}
                        className={`${
                          menu.countInStock === 0 ? "cursor-not-allowed" : ""
                        } text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}
                      >
                        In den Einkaufswagen
                      </button>
                    </RouterLink>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
