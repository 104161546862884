import axios from 'axios';

import {
    MENU_ITEM_CREATE_FAIL,
    MENU_ITEM_CREATE_REQUEST,
    MENU_ITEM_CREATE_SUCCESS,
    MENU_ITEM_DELETE_FAIL,
    MENU_ITEM_DELETE_REQUEST,
    MENU_ITEM_DELETE_SUCCESS,
    MENU_ITEM_DETAILS_FAIL,
    MENU_ITEM_DETAILS_REQUEST,
    MENU_ITEM_DETAILS_SUCCESS,
    MENU_ITEM_LIST_FAIL,
    MENU_ITEM_LIST_REQUEST,
    MENU_ITEM_LIST_SUCCESS,
    MENU_ITEM_UPDATE_FAIL,
    MENU_ITEM_UPDATE_REQUEST,
    MENU_ITEM_UPDATE_SUCCESS,
} from '../constants/menuItemConstants';
import { serverIp } from '../config/conf';

export const listMenuItems = () => async (dispatch) => {
    try {
        dispatch({ type: MENU_ITEM_LIST_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/menu`);

        dispatch({ type: MENU_ITEM_LIST_SUCCESS, payload: data });

    } catch (err) {
        dispatch({
            type: MENU_ITEM_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listMenuItemDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: MENU_ITEM_DETAILS_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/menu/${id}`);

        dispatch({ type: MENU_ITEM_DETAILS_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: MENU_ITEM_DETAILS_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const deleteMenuItem = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: MENU_ITEM_DELETE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`${serverIp}/api/menu/${id}`, config);

        dispatch({ type: MENU_ITEM_DELETE_SUCCESS });
    } catch (err) {
        dispatch({
            type: MENU_ITEM_DELETE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const createMenuItem = (menu) => async (dispatch, getState) => {
    try {
        dispatch({ type: MENU_ITEM_CREATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${serverIp}/api/menu`, menu, config);

        dispatch({ type: MENU_ITEM_CREATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: MENU_ITEM_CREATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const updateMenuItem = (menu) => async (dispatch, getState) => {
    try {
        dispatch({ type: MENU_ITEM_UPDATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(
            `${serverIp}/api/menu/${menu._id}`,
            menu,
            config
        );

        dispatch({ type: MENU_ITEM_UPDATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: MENU_ITEM_UPDATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};
