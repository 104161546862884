import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ReactCountryFlag from "react-country-flag"
// import CartButton from './CartButton';
import { useEffect, useState } from 'react';
import { listFeaturedProducts } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Loader from './Loader';
import Message from './Message';
import { AiFillHeart, AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Icon, Select, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react';
import { addToWishList, removeFromWishList } from '../actions/wishListActions';
import { toast } from 'react-toastify';
import { addToCart } from '../actions/cartActions';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

// const products = [
//     { id: 1, name: 'Product 1', price: 19.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download1.jpg' },
//     { id: 2, name: 'Product 2', price: 29.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download2.jpg' },
//     { id: 3, name: 'Product 3', price: 39.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download3.jpg' },
//     { id: 4, name: 'Product 4', price: 0, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download4.jpg' },
//     { id: 5, name: 'Product 5', price: 29.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download5.jpg' },
//     { id: 6, name: 'Product 6', price: 39.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download6.jpg' },
//     { id: 7, name: 'Product 7', price: 39.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download7.jpg' },
//     { id: 8, name: 'Product 8', price: 0, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download8.jpg' },
//     { id: 9, name: 'Product 9', price: 29.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download9.jpg' },
//     { id: 10, name: 'Product 10', price: 39.99, discountedPrice: 9.99, weight: '100 Gram', image: '/products/download10.jpg' },
// ];

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    console.log(props)
    return (
        <MdNavigateNext
            className={className}
            // style={{ ...style, display: "block", backgroundColor: "black", color: 'black', paddingTop: '1px', borderRadius: '50%' }}
            style={{ color: "gray", fontSize: "45px", height: '55px', width: '55px', paddingLeft: '20px' }}
            onClick={onClick}
        >
            {/* <MdNavigateNext className='' style={{ color: "gray", fontSize: "45px" }} /> */}
        </MdNavigateNext>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <MdNavigateBefore
            className={className}
            // style={{ ...style, display: "block", backgroundColor: "black", color: 'black', paddingTop: '1px', borderRadius: '50%' }}
            style={{ color: "gray", fontSize: "45px", height: '55px', width: '55px', paddingRight: '20px' }}
            onClick={onClick}
        >
            {/* text-black w-10 h-10 */}
            {/* <MdNavigateBefore className='' style={{ color: "gray", fontSize: "45px" }} /> */}
        </MdNavigateBefore>
    );
}

export default function PromoProductCard(props) {

    console.log(props);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [stockCount, setStockCount] = useState(1);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [sentToCart, setSentToCart] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [id, setId] = useState('');
    const [qty, setQty] = useState(0);
    const [size, setSize] = useState('0');

    const wishList = useSelector((state) => state.wishlist);
    const { wishListItems } = wishList;

    const settings = {
        infinite: true,
        slidesToShow: props.sts > 4 ? 4 : props.sts,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
        ]
    };

    const notify = (link, content, type) => type(
        <RouterLink to={link}>
            {content}
        </RouterLink>
    );

    const handleAddToCart = () => {
        if (qty !== 0) {
            dispatch(addToCart(qty, size, id, 'en'));
            onClose();
            if (sentToCart) {
                navigate('/cart');
            } else {
                notify('/cart', 'Item Added To Cart', toast.success);
            }
        } else {
            notify('#', 'Please select size & quantity', toast.error);
        }
    }

    const handleAddToCart2 = (pId) => {
        dispatch(addToCart(1, '1', pId, 'en'));
        onClose();
        notify('/cart', 'Item Added To Cart', toast.success);
    }

    const addToWishListHandler = (pid) => {
        dispatch(addToWishList(pid, size));
        notify('/wishlist', 'Item Added To Wishlist', toast.success);
    };

    const removeFromWishListHandler = (id) => {
        dispatch(removeFromWishList(id));
        notify('/wishlist', 'Item Removed from Wishlist', toast.error);
    };

    const handleModalOpen = (sizes, id, sent) => {
        onOpen();
        setSentToCart(sent);
        setSizes(sizes);
        setId(id);
    }

    const handleSizeChange = (e, qty) => {
        const selectedSize = e.target.value;
        setSize(selectedSize);
        const selectedQuantity = qty > 0 ? 1 : 0;
        setQty(selectedQuantity);

        // Find the size object based on the selected size
        const selectedSizeObj = sizes.find((sizeObj) =>
            sizeObj.indianSize === selectedSize
        );

        // Update the quantity state based on the available stock for the selected size
        setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
    };

    const handleQuantityChange = (e) => {
        const selectedQuantity = Number(e.target.value);
        if (selectedQuantity > 0) {
            setQty(selectedQuantity);
        }
    };

    const handleClick = (id, isBulk) => {
        navigate(`/cart/${id}?qty=${isBulk ? 1 : 6}&add=prod`);
    }

    return (
        <div className={`mx-auto max-w-7xl px-4 lg:px-8 py-3`}>
            <p className='text-center text-xl font-semibold'>{props.name}</p>
            {props.loading ? (
                <Loader />
            ) : props.error ? (
                <div></div>
            ) : (
                <div className='py-4'>
                    <Slider prevArrow={<SamplePrevArrow />} nextArrow={<SampleNextArrow />} {...settings}>
                        {props.products?.length > 0 && props.products?.map((product, index) => (
                            <div>
                                <div key={index} className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md m-3">
                                    <RouterLink
                                        key={product.id}
                                        to={`/product/${product._id}?qty=${product.isBulk ? 1 : 6}`}
                                        className="group relative"
                                    >
                                        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                                            <img
                                                src={product.image[0]}
                                                alt={product.name}
                                                className="h-full w-full object-cover object-center group-hover:opacity-75"
                                            />
                                        </div>
                                        {product.discountedPrice !== product.price && (
                                            <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded">
                                                {`-${((1 - product.discountedPrice / product.price) * 100).toFixed(0)}%`}
                                            </div>
                                        )}
                                        {/* <p className={`${(product.discountedPrice === product.price ? 'hidden' : 'flex')} m-2 bg-[#e31837] absolute top-0 left-0 text-xs text-white font-semibold p-1 rounded`}>{(((product.price - product.discountedPrice) / product.price) * 100)?.toFixed(0)}% Off</p> */}
                                        <div className="p-4">
                                            <h3 className="text-sm overflow-clip font-semibold truncate ...">{product.name}</h3>
                                            <div className="flex items-end">
                                                {product.discountedPrice !== product.price && (
                                                    <div className="flex items-end">
                                                        <p className="text-xl text-green-500">
                                                            {`${(product.discountedPrice)?.toFixed(2).replace('.', ',')}€`}
                                                        </p>
                                                        <p className="ml-2 text-sm line-through text-gray-500">
                                                            {`${(product.price)?.toFixed(2).replace('.', ',')}€`}
                                                        </p>
                                                    </div>
                                                )}
                                                {product.discountedPrice === product.price && (
                                                    <div className="text-xl text-gray-800">
                                                        {`${(product.price)?.toFixed(2).replace('.', ',')}€`}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </RouterLink>
                                    <div className="flex justify-center items-center p-2 md:p-4 bg-gray-100">
                                        {/* <RouterLink
                                        to={`/cart/${product._id}?qty=${product.isBulk ? 1 : 6}&add=prod`}
                                        className="text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900"
                                    >
                                        In den Einkaufswagen
                                    </RouterLink> */}
                                        <button
                                            disabled={product.countInStock === 0}
                                            onClick={() => handleClick(product._id, product.isBulk)}
                                            className={`${product.countInStock === 0 ? 'cursor-not-allowed' : ''} text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}>
                                            In den Einkaufswagen
                                        </button>
                                        {/* Add any additional information or buttons here */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </div>
    );
};
