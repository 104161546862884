import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import './sidenav.css'
import { Button, Icon } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { createCategory, listCategory } from "../actions/categoryActions";
import { CATEGORY_CREATE_RESET } from "../constants/categoryConstants";
import { getUserDetailsById, sentMailUser } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_SENT_MAIL_RESET } from "../constants/userConstants";

export default function EmailForm() {

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, user, error } = userDetails;

	const userSentMail = useSelector((state) => state.userSentMail);
	const { loading: loadingSent, success, error: errorSent } = userSentMail;

	const [recipient, setRecipient] = useState('');
	const [subject, setSubject] = useState('');
	const [content, setContent] = useState('');
	const [status, setStatus] = useState('');

	useEffect(() => {
		if (!user.name || user._id !== id) {
			dispatch(getUserDetailsById(id));
		}
	}, [id])

	useEffect(() => {
		if (user) {
			setRecipient(user.email);
		}
		if (success) {
			dispatch({ type: USER_SENT_MAIL_RESET });
			navigate('/dashboard/customers')
		}
	}, [user, success])

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(sentMailUser({ mail: recipient, sub: subject, body: content }))
	};

	return (
		<div>
			<Sidebar menu='Kunden' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>
					<div className="p-4 bg-white rounded shadow">
						<h2 className="text-2xl font-semibold mb-4">Send Email</h2>
						<form onSubmit={handleSubmit} className="space-y-4">
							<div>
								<label className="block font-medium text-sm">Recipient Email:</label>
								<input
									type="email"
									value={recipient}
									onChange={(e) => setRecipient(e.target.value)}
									className="w-full border border-black rounded px-3 py-2"
								/>
							</div>
							<div>
								<label className="block font-medium text-sm">Subject:</label>
								<input
									type="text"
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									className="w-full border border-black rounded px-3 py-2"
								/>
							</div>
							<div>
								<label className="block font-medium text-sm">Content:</label>
								<textarea
									value={content}
									onChange={(e) => setContent(e.target.value)}
									className="w-full border border-black rounded px-3 py-2"
									rows="4"
								/>
							</div>
							<button
								type="submit"
								className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
							>
								Send Email
							</button>
						</form>
					</div>
				</main>
			</section>
		</div>
	)
}