import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import { cartReducer } from './reducers/cartReducer';
import { bookingReducer, pricingReducer, bookingOptionReducer } from './reducers/bookingItemReducer';
import { wishListReducer } from './reducers/wishListReducer';
import {
	orderCreateReducer,
	orderDeliverReducer,
	orderDetailsReducer,
	orderListReducer,
	orderMyListReducer,
	orderPayReducer,
	orderStatsReducer,
	orderDeleteReducer
} from './reducers/orderReducer';

import {
	bestProductListReducer,
	productCreateReducer,
	productDeleteReducer,
	productDetailsReducer,
	productListReducer,
	productReviewCreateReducer,
	productUpdateReducer
} from './reducers/productReducer';

import {
	userDeleteReducer,
	userDetailsReducer,
	userListReducer,
	userLoginReducer,
	userRegisterReducer,
	userUpdateProfileReducer,
	userUpdateReducer,
	userOtpValidateReducer,
	userSentMailReducer,
	forgotPasswordReducer,
	resetPasswordReducer
} from './reducers/userReducer';

import {
	faqCreateReducer,
	faqDeleteReducer,
	faqDetailsReducer,
	faqListReducer,
	faqUpdateReducer,
	supportDetailsReducer,
	supportUpdateReducer
} from './reducers/supportFaqReducer';

import {
	heroListReducer,
	heroCreateReducer,
	heroDeleteReducer,
	heroDetailsReducer,
	heroUpdateReducer,
	heroListLatestReducer
} from './reducers/heroReducer';

import {
	menuItemCreateReducer,
	menuItemDeleteReducer,
	menuItemDetailsReducer,
	menuItemListReducer,
	menuItemUpdateReducer
} from './reducers/menuItemReducer';

import {
	bookingCompletedReducer,
	bookingCreateReducer,
	bookingDetailsReducer,
	bookingListReducer,
	bookingPayReducer,
	bookingsMyListReducer,
	bookingStatsReducer,
	updateMenuItemsReducer,
	bookingDeleteReducer
} from './reducers/bookingReducer';

import {
	categoryCreateReducer,
	categoryDeleteReducer,
	categorytListReducer,
	menuLinkUpdateReducer,
	subCategoryListReducer
} from './reducers/categoryReducer'

import {
	couponCreateReducer,
	couponDeleteReducer,
	couponGetReducer,
	couponListReducer,
	couponUpdateReducer,
	couponValidationReducer
} from './reducers/couponReducer'

import disabledDateReducer from './reducers/disabledDatesReducer';

const reducer = combineReducers({
	productList: productListReducer,
	bestProductList: bestProductListReducer,
	productDetails: productDetailsReducer,
	productDelete: productDeleteReducer,
	productCreate: productCreateReducer,
	productUpdate: productUpdateReducer,
	productReviewCreate: productReviewCreateReducer,
	cart: cartReducer,
	booking: bookingReducer,
	wishlist: wishListReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	forgotPassword: forgotPasswordReducer,
	resetPassword: resetPasswordReducer,
	userOtpValidate: userOtpValidateReducer,
	userDetails: userDetailsReducer,
	userUpdateProfile: userUpdateProfileReducer,
	userList: userListReducer,
	userDelete: userDeleteReducer,
	userUpdate: userUpdateReducer,
	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	orderMyList: orderMyListReducer,
	orderList: orderListReducer,
	orderDeliver: orderDeliverReducer,
	heroList: heroListReducer,
	heroListLatest: heroListLatestReducer,
	faqCreate: faqCreateReducer,
	faqDelete: faqDeleteReducer,
	faqDetails: faqDetailsReducer,
	faqList: faqListReducer,
	faqUpdate: faqUpdateReducer,
	supportDetails: supportDetailsReducer,
	supportUpdate: supportUpdateReducer,
	heroCreate: heroCreateReducer,
	heroDelete: heroDeleteReducer,
	heroDetails: heroDetailsReducer,
	heroUpdate: heroUpdateReducer,
	menuItemCreate: menuItemCreateReducer,
	menuItemDelete: menuItemDeleteReducer,
	menuItemDetails: menuItemDetailsReducer,
	menuItemList: menuItemListReducer,
	menuItemUpdate: menuItemUpdateReducer,
	pricing: pricingReducer,
	bookingOption: bookingOptionReducer,
	disabledDate: disabledDateReducer,
	bookingCreate: bookingCreateReducer,
	bookingDetails: bookingDetailsReducer,
	bookingPay: bookingPayReducer,
	bookingsMyList: bookingsMyListReducer,
	bookingList: bookingListReducer,
	bookingCompleted: bookingCompletedReducer,
	orderStats: orderStatsReducer,
	bookingStats: bookingStatsReducer,
	categoryCreate: categoryCreateReducer,
	categoryDelete: categoryDeleteReducer,
	categorytList: categorytListReducer,
	subCategoryList: subCategoryListReducer,
	updateMenuItems: updateMenuItemsReducer,
	couponCreate: couponCreateReducer,
	couponDelete: couponDeleteReducer,
	couponUpdate: couponUpdateReducer,
	couponGet: couponGetReducer,
	couponList: couponListReducer,
	couponValidation: couponValidationReducer,
	userSentMail: userSentMailReducer,
	orderDelete: orderDeleteReducer,
	bookingDelete: bookingDeleteReducer,
	menuLinkUpdate: menuLinkUpdateReducer,
});

const cartItemsFromStorage = localStorage.getItem('cartItems')
	? JSON.parse(localStorage.getItem('cartItems'))
	: [];

const bookingItemsFromStorage = localStorage.getItem('bookingMenuItems')
	? JSON.parse(localStorage.getItem('bookingMenuItems'))
	: [];

const wishListItemsFromStorage = localStorage.getItem('wishListItems')
	? JSON.parse(localStorage.getItem('wishListItems'))
	: [];

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null;

const shippingAddressFromLocalStorage = localStorage.getItem('shippingAddress')
	? JSON.parse(localStorage.getItem('shippingAddress'))
	: {};

const paymentMethodFromStorage = localStorage.getItem('paymentMethod')
	? JSON.parse(localStorage.getItem('paymentMethod'))
	: 'paypal';

const initialState = {
	cart: {
		cartItems: cartItemsFromStorage,
		shippingAddress: shippingAddressFromLocalStorage,
		paymentMethod: paymentMethodFromStorage,
	},
	booking: {
		bookingMenuItems: bookingItemsFromStorage,
		shippingAddress: shippingAddressFromLocalStorage,
		paymentMethod: paymentMethodFromStorage,
	},
	wishlist: {
		wishListItems: wishListItemsFromStorage,
	},
	userLogin: { userInfo: userInfoFromStorage },
};

const middlewares = [thunk];

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
