import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  ORDER_CREATE_RESET,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";
import { CART_RESET_ITEM } from "../constants/cartConstants";
import { Helmet } from "react-helmet";

const SuccessPaymentScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routePath = useLocation();
  const queryParams = new URLSearchParams(routePath.search);
  let orderStatus = queryParams.get("orders");
  let moid = queryParams.get("moid");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (orderStatus === "Success") {
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: CART_RESET_ITEM });
      dispatch({ type: ORDER_DETAILS_RESET });
    } else if (orderStatus !== "Success") {
      navigate("/shipping");
    }
  }, [orderStatus]);

  const handleContinueShopping = () => {
    navigate(`/order/${moid}`);
  };

  const handleRetryPayment = () => {
    navigate("/shipping");
  };

  return (
    <div className="flex items-center justify-center py-6">
      <Helmet>
        <title>Shop - Order Success</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      {orderStatus === "Success" ? (
        <div className="text-center">
          <img
            src="https://d8v879q3glvao.cloudfront.net/Static_images/payment+(3).gif"
            alt="Success"
            className="w-1/3 h-1/3 mx-auto"
          />
          <p className="my-2 text-center text-lg">Hallo, {userInfo?.name}</p>
          <h2 className="text-2xl font-bold">Ihre Bestellung ist bestätigt!</h2>
          <p className="text-center">
            Wir senden Ihnen eine Versandbestätigung per E-Mail, sobald Ihre
            Bestellung versandt wird.
          </p>
          <div className="flex flex-col w-full justify-center items-center gap-2">
            <button
              onClick={handleContinueShopping}
              className="mt-4 w-1/3 bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-lg"
            >
              Bestellung anzeigen
            </button>
            <Link to="/" className="hover:text-blue-500">
              Mit dem Einkaufen fortfahren
            </Link>
          </div>
        </div>
      ) : orderStatus !== "Success" ? (
        <div className="text-center">
          <img
            src="credit-card.gif"
            alt="Failure"
            className="w-1/2 h-1/2 mx-auto"
          />
          <h2 className="text-2xl mt-4">Bezahlung fehlgeschlagen!</h2>
          <button
            onClick={handleRetryPayment}
            className="mt-4 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
          >
            Wiederholen Sie die Zahlung
          </button>
        </div>
      ) : (
        <div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default SuccessPaymentScreen;
