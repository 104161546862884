import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listHero } from "../actions/heroActions";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loader from "./Loader";
import Message from "./Message";

const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: true,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 4000,
    transitionTime: 900,
    ariaLabel: 'ariaLabel',
});

export default function HofladenHero() {

    const dispatch = useDispatch();

    const heroList = useSelector((state) => state.heroList);
    const { loading, heros, error } = heroList;

    const [country, setCountry] = useState('india');

    const { i18n } = useTranslation();
    let lang = i18n.language;

    useEffect(() => {
        if (lang === 'en') {
            setCountry('india');
        } else {
            setCountry('germany')
        }
    }, [lang, i18n]);

    useEffect(() => {
        dispatch(listHero('germany', 'Hofladen'));
    }, [country, dispatch])

    return (
        <div className=''>
            {loading ? (
                <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white animate-pulse">
                    {/* Shimmer effect */}
                    <div className="flex items-center justify-center min-h-[20vh] md:min-h-[70vh] bg-slate-100 bg-no-repeat bg-animate animate-pulse">
                        <Loader />
                    </div>
                </div>
            ) : error ? (
                <Message type='error'>{error}</Message>
            ) : (
                <div className="w-full bg-white">
                    <Carousel {...getConfigurableProps()} >
                        {heros.map((hero, index) => (
                            <div key={index}>
                                {/* <RouterLink className='block h-full' to={`/product?category=${hero.link}`}> */}
                                <img loading='lazy' src={`${hero.url}`} alt='banner' />
                                {/* </RouterLink> */}
                            </div>
                        ))}
                    </Carousel>
                </div>
            )}
        </div>
    )
}