import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { createOrder } from "../actions/orderActions";
import CheckoutSteps from "../components/CheckoutSteps";
import Header from "../components/Header";
import Message from "../components/Message";
import { CART_RESET_ITEM } from "../constants/cartConstants";
import {
  ORDER_CREATE_RESET,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";
import { checkCoupon } from "../actions/couponActions";
import Loader from "../components/Loader";
import { CHECK_COUPON_RESET } from "../constants/couponConstants";
import { getPricingEntries } from "../actions/bookingItemActions";
import { Helmet } from "react-helmet";
// import Message from '../components/Message';

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [totalEndPrice, setTotalEndPrice] = useState(0);
  const [freightCharge, setFreightCharge] = useState(0);
  const [code, setCode] = useState("");

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  const pricing = useSelector((state) => state.pricing);
  const { pricingEntries } = pricing;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  cart.itemsPrice = cart.cartItems.reduce(
    (acc, currVal) => acc + currVal.price * +currVal.qty,
    0
  );

  useEffect(() => {
    if (cartItems && cartItems.length !== 0) {
      let bottleOfWines = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen" &&
            !item.isBulk
        )
        .reduce((acc, currVal) => acc + currVal.qty, 0);
      let calculatedBoxes = Math.ceil(bottleOfWines / 6);
      let bulkWines = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen" &&
            item.isBulk
        )
        .reduce((acc, currVal) => acc + currVal.qty, 0);
      let boxOfWines = bulkWines + calculatedBoxes;
      const orderValueOfWine = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen"
        )
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      const orderValueOfHofladen = cartItems
        .filter((item) => item.category === "hofladen")
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      const orderValueOfEvents = cartItems
        .filter((item) => item.category?.split(" ")[1] === "Events")
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      // let orderValue = orderValueOfEvents + orderValueOfHofladen + orderValueOfWine;
      let orderValueForWineAndHofladen =
        orderValueOfWine + orderValueOfHofladen;
      let freightWine = 0;
      if (
        orderValueForWineAndHofladen > 100 ||
        orderValueForWineAndHofladen === 0
      ) {
        // setFreightChargeWineBox(0); // 12 Euro for orders below 50 Euro
        freightWine = 0;
      } else if (boxOfWines === 1) {
        // setFreightChargeWineBox(12); // 24 Euro for orders between 50 and 100 Euro
        freightWine = 12;
      } else if (boxOfWines === 2) {
        // setFreightChargeWineBox(24); // No freightWine charge for orders above 100 Euro
        freightWine = 24;
      } else if (boxOfWines >= 2 && orderValueForWineAndHofladen < 100) {
        // setFreightChargeWineBox(24); // No freightWine charge for orders above 100 Euro
        freightWine = 24;
      }

      let freightHofladen = 0;
      if (
        orderValueForWineAndHofladen > 100 ||
        orderValueForWineAndHofladen === 0
      ) {
        // setFreightChargeHofladen(0); // 12 Euro for orders below 50 Euro
        freightHofladen = 0;
      } else if (orderValueOfHofladen > 0 && orderValueOfHofladen < 100) {
        // setFreightChargeHofladen(12); // No freightWine charge for orders above 100 Euro
        freightHofladen = 4.5;
      }

      let freight = freightWine + freightHofladen;
      setFreightCharge(freight);
    }
  }, [cartItems]);

  // cart.shippingPrice = (cart.itemsPrice < 50 ? 12 : (cart.itemsPrice >= 50 && cart.itemsPrice <= 100) ? 24 : 0);
  cart.shippingPrice = freightCharge;
  cart.taxPrice = cart.itemsPrice * (pricingEntries[0]?.orderTax / 100);
  cart.discount = cart.itemsPrice * (discount / 100);
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice - cart.discount;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        user: userInfo?._id,
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        couponCode: cart.couponCode,
        discount: discount,
        userName: cart.shippingAddress?.name,
        userEmail: cart.shippingAddress?.email,
        userContact: cart.shippingAddress?.phone,
      })
    );
  };

  useEffect(() => {
    dispatch({ type: CHECK_COUPON_RESET });
    dispatch(getPricingEntries());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: CART_RESET_ITEM });
      dispatch({ type: ORDER_DETAILS_RESET });
    }
  }, [success, navigate, order]);

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code));
    cart.couponCode = code;
  };

  return (
    <Flex justifyContent="center">
      <Helmet>
        <title>Shop - Place Order</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      {/* <Header /> */}
      <Flex w="6xl" direction="column" pb="4" px="8">
        {/* <CheckoutSteps step3 step4 /> */}

        <Grid templateColumns={{ sm: "3fr fr", md: "3fr 2fr" }} gap="20">
          {/* Column 1 */}
          <Flex direction="column">
            {/* Shipping */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Ihre Bestellung
              </Heading>
              <Text>
                <strong>Ihre Daten: </strong>
                {cart.shippingAddress.name}, {cart.shippingAddress.email},{" "}
                {cart.shippingAddress.phone}
              </Text>
              <Text>
                <strong>Ihre Adresse: </strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city},{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </Text>
            </Box>

            {/* Payment Method */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Zahlungsabwicklung
              </Heading>
              <Text>
                <strong>Ihre Zahlungsoptionen: </strong>
                {cart.paymentMethod}
              </Text>
            </Box>

            {/* Order Items */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Ihre Bestelldaten
              </Heading>
              <Box>
                {cart.cartItems.length === 0 ? (
                  <div>Ihr Warenkorb ist leer</div>
                ) : (
                  <Box py="2">
                    {cart.cartItems.map((item, idx) => (
                      <Flex
                        key={idx}
                        alignItems="center"
                        flexWrap="wrap"
                        borderBottom="1px solid gray"
                        justifyContent="space-between"
                      >
                        <Flex py="2" alignItems="center">
                          <Image
                            src={item.image}
                            alt={item.name}
                            w="12"
                            h="12"
                            objectFit="cover"
                            mr="6"
                          />
                          <Link
                            mr="6"
                            fontWeight="bold"
                            fontSize="xl"
                            as={RouterLink}
                            to={`/product/${item.product}`}
                          >
                            {item.name}
                          </Link>
                          {item.category === "Group Events" ? (
                            <Text>Teilnehmer: {item.pno}</Text>
                          ) : (
                            <></>
                          )}
                        </Flex>

                        <Text fontSize="lg" fontWeight="semibold">
                          {item.qty} x{" "}
                          {item.price?.toFixed(2).replace(".", ",")}€ ={" "}
                          {(+item.qty * item.price)
                            ?.toFixed(2)
                            .replace(".", ",")}
                          €
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>

          {/* Column 2 */}
          <Flex
            direction="column"
            bgColor="white"
            justifyContent="space-between"
            py="8"
            px="8"
            shadow="md"
            rounded="lg"
            borderColor="gray.300"
          >
            <Box>
              <Heading mb="6" as="h2" fontSize="3xl" fontWeight="bold">
                Ihre Gesamtbestellung
              </Heading>

              {/* Items Price */}
              <Flex py="2" alignitems="center" justifyContent="space-between">
                <Text fontSize="xl">Produkte</Text>
                <Text fontWeight="bold" fontSize="xl">
                  {cart.itemsPrice?.toFixed(2).replace(".", ",")}€
                </Text>
              </Flex>
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Frachtkosten</p>
                <p className="text-gray-700">
                  {cart.shippingPrice?.toFixed(2).replace(".", ",")}€
                </p>
              </div>

              <div className="flex justify-between py-2">
                <p className="text-gray-700">Rabatt({discount}%)</p>
                <p className="text-gray-700">
                  {cart.discount?.toFixed(2).replace(".", ",")}€
                </p>
              </div>

              {isValid && (
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Gutschein Code</p>
                  <p className="text-gray-700">{cart.couponCode}</p>
                </div>
              )}

              {/* Total Price */}
              <Flex
                borderTop="1px"
                py="2"
                borderColor="gray.200"
                alignitems="center"
                justifyContent="space-between"
              >
                <Text fontSize="xl">Summe</Text>
                <Text fontWeight="bold" fontSize="xl">
                  {cart.totalPrice?.toFixed(2).replace(".", ",")}€
                </Text>
              </Flex>
              <div className="flex justify-between py-2">
                <p className="text-gray-700">
                  Inklusive MwSt({pricingEntries[0]?.orderTax}%)
                </p>
                <p className="text-gray-700">
                  {cart.taxPrice?.toFixed(2).replace(".", ",")}€
                </p>
              </div>
            </Box>
            {error && <Message type="error">{error}</Message>}

            {userInfo && (
              <div className="my-8">
                <form onSubmit={checkCouponHandler}>
                  <p className="text-lg">Gutschein Code</p>
                  <input
                    type="text"
                    id="code"
                    required
                    className="w-2/3 border border-black rounded px-3 py-2"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="w-1/3 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                  >
                    Apply
                  </button>
                </form>
                {loading && <Loader />}
                {errorCoupon && <Message type="error">{errorCoupon}</Message>}
              </div>
            )}

            <Button
              size="lg"
              textTransform="uppercase"
              colorScheme="yellow"
              type="button"
              w="full"
              onClick={placeOrderHandler}
              disabled={cart.cartItems === 0}
            >
              Jetzt bestellen & zahlen
            </Button>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default PlaceOrderScreen;
