import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import BestHero from "../components/BestHero";
import MenuItemList from "../components/MenuItemList";
import { useDispatch, useSelector } from "react-redux";
import { ADD_BOOKING_RESET } from "../constants/bookingConstants";
import { getDisabledDates } from "../actions/disabledDatesAction";
import { setBookingDateOptions } from "../actions/bookingItemActions";
import { format } from "date-fns";
import { IoCalendarOutline } from "react-icons/io5";
import { DateRange } from "react-date-range";
import { HiOutlineUsers } from "react-icons/hi2";
import { GrLocation } from "react-icons/gr";
import PopupBanner from "../components/PopUpBanner";
import { Helmet } from "react-helmet";

export default function BestOfSapphire() {
  const dispatch = useDispatch();
  const routePath = useLocation();

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch({ type: ADD_BOOKING_RESET });
  }, [routePath]);

  useEffect(() => {
    onTop();
    dispatch(getDisabledDates());
  }, [dispatch]);

  const disabledDate = useSelector((state) => state.disabledDate);
  const { disabledDates, loading, error } = disabledDate;

  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date().setHours(0, 0, 0, 0),
      endDate: new Date().setHours(0, 0, 0, 0),
      key: "selection",
    },
  ]);

  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
  });

  const navigate = useNavigate();

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };

  const handleSearch = () => {
    const adjustedStartDate = new Date(date[0].startDate);
    adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
    const adjustedEndDate = new Date(date[0].endDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
    const adjustedDate = [
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ];

    localStorage.setItem(
      "bookingOption",
      JSON.stringify({ date: adjustedDate, options })
    );
    dispatch(setBookingDateOptions(adjustedDate, options));
    navigate("/booking");
  };

  const handleDateSelect = (item) => {
    setDate([item.selection]);
  };

  const handleSelectButtonClick = () => {
    setOpenDate(false); // Close the date picker
  };

  const handleSelectButtonClick2 = () => {
    setOpenOptions(false); // Close the date picker
  };

  return (
    <div>
      <Helmet>
        <title>Shop - Camping</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <BestHero list="Camping" />

      {/* <PopupBanner show={false} /> */}

      <div className="flex w-full bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] border-t-2 border-white">
        <div className="flex w-full flex-col gap-8 p-8">
          <h4 className="text-white text-3xl underline underline-offset-4">
            Wohnnmobil-Camping und unvergessliche Erlebnisse
          </h4>
          <h3 className="text-white text-2xl">
            Buche jetzt Deinen Aufenthalt mit allen Services und Erlebnissen die
            Deine Reise unvergesslich schön machen werden.
          </h3>
        </div>
      </div>
      <div className="flex py-8 w-full justify-center items-center bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]">
        <div className="headerSearch">
          <div className="headerSearchItem">
            <div>
              <span className="headerSearchText">Willkommen</span>
            </div>

            <div className="flex">
              <GrLocation className="headerIcon cursor-pointer" />
              <span className="headerSearchText">weinhaus-schowalter</span>
            </div>
          </div>
          <div className="headerSearchItem">
            <div>
              <span className="headerSearchText">Termine auswählen</span>
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <IoCalendarOutline
                  className="headerIcon cursor-pointer"
                  onClick={() => setOpenDate(!openDate)}
                />
                <span
                  onClick={() => setOpenDate(!openDate)}
                  className="headerSearchText"
                >{`Von ${format(date[0].startDate, "dd-MM-yyyy")} Bis ${format(
                  date[0].endDate,
                  "dd-MM-yyyy"
                )}`}</span>
              </div>
              {openDate && (
                <div className="flex flex-col absolute">
                  <DateRange
                    locale={locales["de"]}
                    editableDateInputs={true}
                    onChange={handleDateSelect}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    className="date"
                    minDate={new Date()}
                    disabledDates={disabledDates?.map((dateObj) => {
                      return new Date(
                        new Date(dateObj.date).toISOString().split("T")[0]
                      );
                    })}
                  />
                  <button
                    className="dateButton hidden md:flex"
                    onClick={handleSelectButtonClick}
                  >
                    Auswählen
                  </button>
                  <button
                    className="dateButton flex md:hidden"
                    onClick={handleSelectButtonClick}
                  >
                    ✔
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="headerSearchItem">
            <div>
              <span className="headerSearchText">Personen auswählen</span>
            </div>
            <div className="flex">
              <HiOutlineUsers
                className="headerIcon cursor-pointer"
                onClick={() => setOpenOptions(!openOptions)}
              />
              <span
                onClick={() => setOpenOptions(!openOptions)}
                className="headerSearchText"
              >{`${options.adult} Erwachsene · ${options.children} Kinder`}</span>
              {openOptions && (
                <div className="flex flex-col absolute">
                  <div className="options">
                    <div className="optionItem">
                      <span className="optionText">Erwachsene</span>
                      <div className="optionCounter">
                        <button
                          disabled={options.adult <= 1}
                          className="optionCounterButton"
                          onClick={() => handleOption("adult", "d")}
                        >
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {options.adult}
                        </span>
                        <button
                          disabled={options.children + options.adult >= 7}
                          className="optionCounterButton"
                          onClick={() => handleOption("adult", "i")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="optionItem">
                      <span className="optionText">Kinder</span>
                      <div className="optionCounter">
                        <button
                          disabled={options.children <= 0}
                          className="optionCounterButton"
                          onClick={() => handleOption("children", "d")}
                        >
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {options.children}
                        </span>
                        <button
                          disabled={options.children + options.adult >= 7}
                          className="optionCounterButton"
                          onClick={() => handleOption("children", "i")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className="dateButton2 hidden md:flex"
                    onClick={handleSelectButtonClick2}
                  >
                    Auswählen
                  </button>
                  <button
                    className="dateButton2 flex md:hidden"
                    onClick={handleSelectButtonClick2}
                  >
                    ✔
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <button className="headerBtn" onClick={handleSearch}>
              Berechnung
            </button>
          </div>
        </div>
      </div>

      <MenuItemList />

      <div className="flex w-full justify-center bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] border-b-2 border-white">
        <button className="headerBtn mb-4" onClick={handleSearch}>
          Berechnung
        </button>
      </div>
    </div>
  );
}
