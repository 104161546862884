import { Icon } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { CiDeliveryTruck } from 'react-icons/ci';
import { FcCancel } from 'react-icons/fc'
import { listMyOrders } from "../actions/orderActions";
import { BsPaypal } from "react-icons/bs";
import { getMyBookings } from "../actions/bookingActions";
import { GET_BOOKING_RESET } from "../constants/bookingConstants";

export default function BookingList() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const bookingsMyList = useSelector((state) => state.bookingsMyList);
    const { loading: loadingBookings, error: errorBookings, bookings } = bookingsMyList;

    useEffect(() => {
        if (!userInfo) {
            navigate(`/signup`);
        } else {
            dispatch(getMyBookings());
            dispatch({ type: GET_BOOKING_RESET });
        }
    }, [dispatch])

    return (
        <div className="flex flex-col gap-8 py-4">
            <div className="self-center text-2xl font-bold underline underline-offset-8">
                Buchungen
            </div>

            {loadingBookings ? (
                <div>
                    Loading
                </div>
            ) : errorBookings ? (
                <div>
                    Error
                </div>
            ) : (
                <div className="flex flex-col">
                    <div className="self-center text-lg text-gray-400 font-bold text-center mb-4">
                        Überprüfen Sie den Status Ihrer letzten Buchungen und laden Sie die Rechnung herunter.
                    </div>

                    {bookings.map((booking, index) => (
                        <div key={index} className="flex flex-col w-11/12 md:w-4/5 self-center border border-gray-300 rounded-md mb-8">
                            <div className="flex flex-wrap md:flex-nowrap justify-between p-4 items-center border-b border-gray-200">
                                <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-8">
                                    <div>
                                        <p className="font-semibold">Buchung-Nr.</p>
                                        <p className="text-sm text-gray-400">{booking._id}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Von</p>
                                        <p className="text-sm text-gray-400">{booking.startDate.substring(0, 10)}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Bis</p>
                                        <p className="text-sm text-gray-400">{booking.endDate.substring(0, 10)}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Gesamter Betrag</p>
                                        <p className="text-sm font-semibold">{(booking.discountedPrice)?.toFixed(2).replace('.', ',')}€</p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <RouterLink to={`/booking/${booking._id}`}>
                                        <button className="mt-4 md:mt-0 bg-blue-700 text-white p-2 rounded-lg hover:bg-blue-500">Buchungen anzeigen</button>
                                    </RouterLink>
                                    {/* <button className="bg-blue-700 text-white p-2 rounded-lg hover:bg-blue-500">View Invoice</button> */}
                                </div>
                            </div>

                            {booking.menuItems.map((item, idx) => (
                                <div key={idx} className="flex p-4 md:p-8 gap-8 border-b border-gray-200">
                                    <div className="w-1/12">
                                        <img className="rounded-md" src={item.image} />
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className="font-semibold">
                                            {item.name}
                                        </div>
                                        <div className="hidden md:flex text-gray-400 ">
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="flex flex-wrap md:flex-nowrap p-4 items-center justify-between">
                                {booking.isPaid ? (
                                    <div className="flex gap-2">
                                        <div >
                                            <Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" />Paid on,
                                        </div>
                                        <div className="font-semibold">{new Date(booking.paidAt).toDateString()}</div>
                                    </div>
                                ) : (
                                    <div>
                                        <Icon as={BsPaypal} color='blue.400' boxSize='5' /> Bitte führen Sie die Zahlung durch.
                                    </div>
                                )}
                                {booking.isCompleted === 1 ? (
                                    <div className="flex gap-2">
                                        <div >
                                            <Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" /> Abgeschlossen,
                                        </div>
                                        <div className="font-semibold">{new Date(booking.completedAt).toDateString()}</div>
                                    </div>
                                ) : booking.isCompleted === 2 ? (
                                    <div className="flex gap-2">
                                        <div >
                                            <Icon as={FcCancel} color='green.400' className="rounded-full" /> Abgesagt
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Icon as={CiDeliveryTruck} color='red.400' boxSize='5' /> wird bald abgeschlossen sein
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}