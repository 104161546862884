import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { removeFromCart, saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { serverIp } from "../config/conf";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { checkCoupon } from "../actions/couponActions";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  createOrder,
  getOrderDetails,
  payOrder,
} from "../actions/orderActions";
import {
  ORDER_CREATE_RESET,
  ORDER_DETAILS_RESET,
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from "../constants/orderConstants";
import { CART_ADD_ITEM, CART_RESET_ITEM } from "../constants/cartConstants";
import { CHECK_COUPON_RESET } from "../constants/couponConstants";
import { getPricingEntries } from "../actions/bookingItemActions";
import { Helmet } from "react-helmet";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Box } from "@chakra-ui/react";

const ShippingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routePath = useLocation();
  const queryParams = new URLSearchParams(routePath.search);
  const orderStatus = queryParams.get("orders");
  const orderId = queryParams.get("oid");
  const transactionId = queryParams.get("tid");
  const failmessage = queryParams.get("failmessage");
  const statusmessage = queryParams.get("statusmessage");

  const [wineQty, setWineQty] = useState(0);
  const [boxQty, SetBoxQty] = useState(0);
  const [totalWineAndHofladenPrice, setTotalWineAndHofladenPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalEndPrice, setTotalEndPrice] = useState(0);
  const [freightCharge, setFreightCharge] = useState(0);
  const [freightChargeWine, setFreightChargeWine] = useState(0);
  const [freightChargeWineBox, setFreightChargeWineBox] = useState(0);
  const [freightChargeHofladen, setFreightChargeHofladen] = useState(0);

  let [isOpen, setIsOpen] = useState(false);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress, cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingUser, error: errorUSer, user } = userDetails;

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  const pricing = useSelector((state) => state.pricing);
  const { pricingEntries } = pricing;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success } = orderCreate;

  cart.itemsPrice = cart.cartItems?.reduce(
    (acc, currVal) => acc + currVal.discountedPrice * +currVal.qty,
    0
  );
  // cart.giftWrapTotalCost = cart.cartItems?.reduce((acc, currVal) => acc + (currVal.giftWrap ? currVal.qty * 50 : 0), 0)?.toFixed(2);
  // cart.shippingPrice = 0; //cart.itemsPrice < 1000 ? 1000 : 0;
  // cart.taxPrice = 0; //(18 * cart.itemsPrice) / 100;
  cart.discount = cart.itemsPrice * (discount / 100);
  //   cart.totalPrice = cart.itemsPrice - cart.discount;

  const [code, setCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("paypal");
  const [name, setName] = useState(shippingAddress.name || "");
  const [phone, setPhone] = useState(shippingAddress.phone || "");
  const [email, setEmail] = useState(shippingAddress.email || "");
  const [postalCode, setPostalCode] = useState(
    shippingAddress.postalCode || ""
  );
  const [address, setAddress] = useState(shippingAddress.address || "");
  const [town, setTown] = useState(shippingAddress.town || "");
  const [state, setState] = useState(shippingAddress.state || "");
  const [city, setCity] = useState(shippingAddress.city || "");
  const [country, setCountry] = useState(shippingAddress.country || "");

  const [selectedAddress, setSelectedAddress] = useState(""); // Initialize with 'home'

  const [formHtml, setFormHtml] = useState("");

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getPricingEntries());
    if (success) {
      // window.location.href = order.links[1].href;
      cart.couponCode = "";
      navigate(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: CHECK_COUPON_RESET });
      dispatch({ type: CART_RESET_ITEM });
    }
  }, [success, navigate, order]);

  useEffect(() => {
    if (user?.name) {
      user.cart?.forEach((item) => {
        dispatch({
          type: CART_ADD_ITEM,
          payload: item,
        });
      });
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (cartItems && cartItems.length !== 0) {
      let bottleOfWines = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen" &&
            !item.isBulk
        )
        .reduce((acc, currVal) => acc + currVal.qty, 0);
      let calculatedBoxes = Math.ceil(bottleOfWines / 6);
      let bulkWines = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen" &&
            item.isBulk
        )
        .reduce((acc, currVal) => acc + currVal.qty, 0);
      let boxOfWines = 0;
      boxOfWines = bulkWines + calculatedBoxes;
      SetBoxQty(boxOfWines);
      setWineQty(bottleOfWines);
      const orderValueOfWine = cartItems
        .filter(
          (item) =>
            item.category !== "Solo Events" &&
            item.category !== "Group Events" &&
            item.category !== "hofladen"
        )
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      const orderValueOfHofladen = cartItems
        .filter((item) => item.category === "hofladen")
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      const orderValueOfEvents = cartItems
        .filter((item) => item.category?.split(" ")[1] === "Events")
        .reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0);
      let orderValue =
        orderValueOfEvents + orderValueOfHofladen + orderValueOfWine;
      let orderValueForWineAndHofladen =
        orderValueOfWine + orderValueOfHofladen;

      let freightWine = 0;
      if (boxOfWines === 0 || orderValueForWineAndHofladen > 75) {
        setFreightChargeWineBox(0); // 12 Euro for orders below 50 Euro
        freightWine = 0;
      } else if (boxOfWines === 1) {
        setFreightChargeWineBox(12); // 9.7 Euro for orders between 50 and 75 Euro
        freightWine = 9.7;
      } else if (boxOfWines === 2) {
        setFreightChargeWineBox(24); // No freightWine charge for orders above 75 Euro
        freightWine = 19.4;
      } else if (boxOfWines >= 2 && orderValueForWineAndHofladen < 75) {
        setFreightChargeWineBox(24); // No freightWine charge for orders above 75 Euro
        freightWine = 19.4;
      }

      let freightHofladen = 0;
      if (orderValueForWineAndHofladen > 75) {
        setFreightChargeHofladen(0); // 12 Euro for orders below 50 Euro
        freightHofladen = 0;
      } else if (orderValueOfHofladen > 0 && orderValueOfHofladen < 75) {
        setFreightChargeHofladen(12); // No freightWine charge for orders above 75 Euro
        freightHofladen = 4.5;
      }

      let freight = freightHofladen + freightWine;
      cart.shippingPrice = freight;
      console.log("cart.shippingPrice", cart.shippingPrice);
      setFreightCharge(freight);

      let netWineAndHofladenPrice = orderValueOfWine + orderValueOfHofladen;
      setTotalWineAndHofladenPrice(netWineAndHofladenPrice);

      let netPrice =
        orderValueOfWine + orderValueOfHofladen + orderValueOfEvents;
      setTotalPrice(netPrice);

      let totalCartPrice = orderValue + freightHofladen + freightWine;
      cart.totalPrice = totalCartPrice;
      console.log("cart.totalPrice", cart.totalPrice);
      setTotalEndPrice(totalCartPrice);

      cart.taxPrice =
        cartItems?.reduce(
          (acc, currVal) => acc + currVal.qty * currVal.price,
          0
        ) *
        (pricingEntries[0]?.orderTax / 100);
    }
  }, [cartItems]);

  useEffect(() => {
    // If formHtml is set, dynamically create a form and submit it
    if (formHtml) {
      const formContainer = document.createElement("div");
      formContainer.innerHTML = formHtml;
      const form = formContainer.querySelector("form");
      document.body.appendChild(form); // Append the form to the body
      form.submit(); // Submit the form to redirect the user to the payment page
    }
  }, [formHtml]);

  useEffect(() => {
    if (
      orderStatus !== "" &&
      orderStatus !== null &&
      orderStatus !== undefined &&
      orderStatus !== "Success"
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [orderStatus]);

  const handleAddressChange = (addressIndex) => {
    setSelectedAddress(addressIndex);

    if (
      addressIndex !== null &&
      user?.addresses?.length !== 0 &&
      user?.addresses[addressIndex]
    ) {
      const selectedAddress = user?.addresses[addressIndex];
      setAddress(selectedAddress.address);
      setPostalCode(selectedAddress.postalCode);
      setTown(selectedAddress.town);
      setState(selectedAddress.state);
      setCity(selectedAddress.city);
      setCountry(selectedAddress.country);
    } else {
      // Reset the form fields if the address index is not valid or user does not have addresses
      setPostalCode("");
      setAddress("");
      setTown("");
      setState("");
      setCity("");
      setCountry("");
    }
  };

  useEffect(() => {
    if (!userInfo) {
      // navigate('/signup');
      setName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setPostalCode("");
      setTown("");
      setState("");
      setCity("");
      setCountry("");
    } else {
      if (!user.name) {
        dispatch(getUserDetails());
      } else {
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
        setAddress(user.homeAddress?.address);
        setPostalCode(user.homeAddress?.postalCode);
        setTown(user.homeAddress?.town);
        setState(user.homeAddress?.state);
        setCity(user.homeAddress?.city);
        setCountry(user.homeAddress?.country);
      }
    }
  }, [userInfo, user]);

  const submitHandler = async (e) => {
    e.preventDefault();

    // Save the selected or new address
    const selectedAddressData = {
      address,
      town,
      city,
      state,
      postalCode,
      country,
    };

    if (userInfo) {
      if (!user.name) {
        dispatch(getUserDetails());
      } else {
        // If 'selectedAddress' is a valid index in the user's addresses array
        if (
          selectedAddress !== "new" &&
          selectedAddress !== "" &&
          user.addresses[selectedAddress]
        ) {
          console.log(selectedAddressData);
          dispatch(
            updateUserProfile({
              addresses: user.addresses.map((address, index) =>
                index === selectedAddress ? selectedAddressData : address
              ),
            })
          );
        } else {
          // Add the new address to the user's addresses array
          dispatch(
            updateUserProfile({
              addresses: [...(user.addresses || []), selectedAddressData],
            })
          );
        }
      }
    }

    // Create the order
    const cc_order_id = Math.floor(Date.now() * Math.random());

    dispatch(
      createOrder({
        user: userInfo?._id,
        // order_id: cc_order_id,
        userName: name,
        userEmail: email,
        userContact: phone,
        orderItems: cart.cartItems,
        shippingAddress: { ...selectedAddressData, name, phone, email }, // Use the selected or new address
        paymentMethod: selectedOption,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        couponCode: cart.couponCode,
        discount,
      })
    );

    console.log("selectedAddressData", selectedAddressData);
    console.log("selectedAddress", selectedAddress);
    console.log(address, town, city, state, postalCode, country);
  };

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code, "order"));
    cart.couponCode = code;
  };

  return (
    <div className="flex flex-col p-4 md:p-6">
      <Helmet>
        <title>Shop - Shipping</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment Failed
                  </Dialog.Title>
                  <div className="text-center">
                    <img
                      src="https://dqm82o9uicfbq.cloudfront.net/Static_images/credit-card+(1).gif"
                      alt="Failure"
                      className="w-1/2 mx-auto"
                    />
                  </div>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Failed Reason - {failmessage}
                    </p>
                    <p className="text-sm text-gray-500">
                      Cancel Reason - {statusmessage}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Retry Payment
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <nav className="flex justify-center pb-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              Cart
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Information
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                Payment
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col md:flex-row max-w-7xl mx-auto gap-4 justify-between">
        <div className="flex h-full max-h-[135vh] w-full md:w-1/2 flex-col shadow-md">
          <h2 className="text-2xl p-4 font-semibold leading-7 text-gray-900">
            Kontaktdaten
          </h2>
          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
            <form id="form1" onSubmit={submitHandler}>
              <div className="space-y-8">
                <div className="">
                  <div className="grid grid-cols-1 md:grid-cols-8 gap-x-6 gap-y-4">
                    <div className="md:col-span-8">
                      {/* Dropdown for selecting an existing address or adding a new one */}
                      <label
                        htmlFor="addressType"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Wählen Sie Adresse aus
                      </label>
                      <div className="mt-2">
                        <select
                          id="addressType"
                          name="addressType"
                          value={selectedAddress}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Wählen</option>
                          {user &&
                            user.addresses &&
                            user.addresses.map((address, index) => (
                              <option key={index} value={index}>
                                {address.type} - {address.address}
                              </option>
                            ))}
                          <option value="new">Neue Adresse hinzufügen</option>
                        </select>
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="name"
                          required
                          placeholder="Name*"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          id="Email"
                          required
                          placeholder="Email*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Telefonnummer
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          id="Mobile"
                          required
                          placeholder="Telefonnummer*"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="street-address"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Straße
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          placeholder="Straße*"
                          id="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Stadt
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          id="city"
                          name="city"
                          placeholder="Stadt*"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="city-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="postalcode"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        PLZ
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          id="postalCode"
                          placeholder="PLZ*"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          autoComplete="address-level1"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Land
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          id="country"
                          name="country"
                          placeholder="Land*"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          autoComplete="country-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    {/* ... (other form fields) */}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="flex flex-col space-y-2 p-6">
            <p className="text-2xl font-semibold leading-7 text-gray-900">
              Weiter mit den folgenden Zahlungsoptionen:
            </p>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="paypal"
                value="paypal"
                checked={selectedOption === "paypal"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="paypal">PayPal</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="Kreditkarte"
                value="Kreditkarte"
                checked={selectedOption === "Kreditkarte"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="Kreditkarte">Kreditkarte</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="Debitkarte"
                value="Debitkarte"
                checked={selectedOption === "Debitkarte"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="Debitkarte">Debitkarte</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="GiroPay"
                value="GiroPay"
                checked={selectedOption === "GiroPay"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="GiroPay">GiroPay</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="Sofortüberweisung"
                value="Sofortüberweisung"
                checked={selectedOption === "Sofortüberweisung"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="Sofortüberweisung">Sofortüberweisung</label>
            </div>
          </div>
        </div>

        <div className="flex h-full max-h-[135vh] w-full md:w-1/2 flex-col shadow-md">
          <h2 className="text-2xl font-semibold leading-7 p-4 text-gray-900">
            Ihre Bestelldaten
          </h2>
          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
            <div className="">
              <div className="flow-root">
                <ul role="list" className="">
                  {cartItems.map((item) => (
                    <li
                      key={item.product}
                      className="flex w-full p-4 bg-white border m-1"
                    >
                      <div className="h-20 md:h-24 w-20 md:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                        <img
                          src={item.image}
                          alt={item.category}
                          className="h-full w-full object-cover object-center"
                        />
                      </div>

                      <div className="ml-4 flex w-full flex-col">
                        <div className="flex w-full flex-col md:flex-row justify-start md:justify-between">
                          <a
                            className="font-semibold text-gray-800 w-full line-clamp-2"
                            href={`/product/${item.product}`}
                          >
                            {item.name}
                          </a>
                          <div className="font-semibold">
                            {item.discountedPrice?.toFixed(2).replace(".", ",")}
                            €
                          </div>
                        </div>
                        {item.giftWrap ? (
                          <div>Message : {item.message}</div>
                        ) : (
                          <></>
                        )}
                        <div className="flex flex-1 items-end justify-between text-sm">
                          <div className="flex gap-2">
                            <p className="text-gray-500">Qty {item.qty}</p>
                          </div>

                          <div className="flex">
                            {item.giftWrap ? "Gift Wrap Added" : ""}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="px-4 py-6 sm:px-6">
            <div className="my-2">
              <form id="form2" onSubmit={checkCouponHandler}>
                <p className="text-lg">Gutscheine</p>
                <div className="flex gap-2 w-full">
                  <input
                    type="text"
                    id="code"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="submit"
                    value="form2"
                    className="w-1/4 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                  >
                    Apply
                  </button>
                </div>
              </form>
              {loading && <Loader />}
              {errorCoupon && <Message type="error">{errorCoupon}</Message>}
            </div>

            {cartItems.length !== 0 ? (
              <div className="h-full">
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">
                    Produkte (
                    {cart.cartItems?.reduce(
                      (acc, currVal) => acc + currVal.qty,
                      0
                    )}
                    )
                  </p>
                  <p className="text-gray-700">
                    {cart.itemsPrice?.toFixed(2).replace(".", ",")}€
                  </p>
                </div>

                {/* Shipping Price */}
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Frachtkosten</p>
                  <p className="text-gray-700">
                    {cart.shippingPrice?.toFixed(2).replace(".", ",")}€
                  </p>
                </div>

                {discount !== 0 && (
                  <div className="flex justify-between py-2">
                    <p className="text-gray-700">Discount({discount}%)</p>
                    <p className="text-gray-700">
                      {cart.discount?.toFixed(2).replace(".", ",")}€
                    </p>
                  </div>
                )}

                {isValid && (
                  <div className="flex justify-between py-2">
                    <p className="text-gray-700">Gutscheine</p>
                    <p className="text-gray-700">{cart.couponCode}</p>
                  </div>
                )}

                <hr className="my-4" />
                <div className="flex flex-col">
                  <div className="flex w-full justify-between">
                    <p className="text-lg font-bold">Gesamter Betrag</p>
                    <div className="flex flex-col items-end">
                      <p id="totalPrice" className="mb-1 text-lg font-bold">
                        {/* {((cartItems.reduce((acc, currVal) => acc + currVal.qty * currVal.price, 0)))?.toFixed(2).replace('.', ',')}€ */}
                        {cart.totalPrice?.toFixed(2).replace(".", ",")}€
                      </p>
                      <p className="text-sm text-gray-700">
                        Inklusive MwSt(
                        {cart?.taxPrice?.toFixed(2).replace(".", ",")}
                        €)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="flex w-full justify-center">
              <button
                type="submit"
                value="form1"
                form="form1"
                className="rounded-md w-1/2 bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Jetzt bestellen & zahlen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingScreen;
