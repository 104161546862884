import { Link as RouterLink, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Button, Icon, Select } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  createCategory,
  deleteCategory,
  listCategory,
  updateMenuLink,
} from "../actions/categoryActions";
import {
  CATEGORY_CREATE_RESET,
  MENU_LINK_UPDATE_RESET,
} from "../constants/categoryConstants";
import { RiDeleteBinLine } from "react-icons/ri";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "../components/StrictModeDroppable";

export default function Category() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categorytList = useSelector((state) => state.categorytList);
  const { loading, error, category } = categorytList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryDelete = useSelector((state) => state.categoryDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = categoryDelete;
  const { success: updateSuccess } = useSelector(
    (state) => state.menuLinkUpdate
  );

  const [menu, setMenu] = useState([]);

  const [type, setType] = useState("category");

  useEffect(() => {
    if (!userInfo.isAdmin) {
      navigate("/login");
    } else {
		dispatch({ type: CATEGORY_CREATE_RESET });
		dispatch({ type: MENU_LINK_UPDATE_RESET });
		dispatch(listCategory(type));
    }
  }, [dispatch, navigate, userInfo, successDelete, type, updateSuccess]);

  useEffect(() => {
    if (!loading && category.length !== 0) {
      setMenu(category?.filter((cat) => cat.type === type));
    }
  }, [category]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteCategory(id));
    }
  };

  const createCategoryHandler = (type) => {
    navigate(`/dashboard/category/add?type=${type}`);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return; // No valid drop target
    }

    const filteredMenu = menu?.filter((cat) => cat.type === "category");
    const reorderedMenuLinks = [...filteredMenu];

    const [reorderedMenuLink] = reorderedMenuLinks.splice(
      result.source.index,
      1
    );
    reorderedMenuLinks.splice(result.destination.index, 0, reorderedMenuLink);
    const updatedMenuLinks = reorderedMenuLinks.map((menuLink, index) => ({
      ...menuLink,
      order: index + 1, // Assuming 1-based indexing
    }));

    updatedMenuLinks.forEach((menu) => {
      dispatch(updateMenuLink(menu));
    });
    setMenu(updatedMenuLinks);
  };

  const categories = category?.filter((cat) => cat.type === type);

  return (
    <div>
      <Sidebar menu="Kagetorien" />
      {/* <!-- CONTENT --> */}
      <section id="content">
        {/* <!-- MAIN --> */}
        <main>
          <div className="container">
            <div>
              <h3>Kagetorien</h3>
              {/* <i class='bx bx-search' ></i>
								<i class='bx bx-filter' ></i> */}
              <div className="flex gap-2">
                <Select width="50%" onChange={(e) => setType(e.target.value)}>
                  <option key="1" value="category">
                    Category
                  </option>
                  <option key="2" value="color">
                    Color
                  </option>
                  <option key="3" value="taste">
                    Taste
                  </option>
                </Select>
                <Button
                  colorScheme="blue"
                  onClick={() => createCategoryHandler(type)}
                >
                  <Icon as={IoAdd} mr="2" fontSize="xl" fontWeight="bold" />
                  Add Parameter
                </Button>
              </div>
            </div>

            {loading ? (
              <>Loading</>
            ) : error ? (
              <>Error</>
            ) : (
              <div className="w-full h-full">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="imageList" direction="vertical">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {menu.map((catObj, index) => (
                          <Draggable
                            key={index}
                            draggableId={`image-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index}
                                  className="flex justify-between item-center rounded-lg p-4 my-4 bg-white drop-shadow-sm"
                                >
                                  <RouterLink
                                    to={`/dashboard/category`}
                                    className="group"
                                  >
                                    <p className="text-center text-lg font-medium text-gray-900">
                                      {catObj.name}
                                    </p>
                                  </RouterLink>
                                  <RiDeleteBinLine
                                    className="text-2xl text-red-500"
                                    onClick={() => deleteHandler(catObj._id)}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </div>
        </main>
      </section>
    </div>
  );
}
