import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { listProducts } from '../actions/productActions'

export default function ProductList(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const productList = useSelector((state) => state.productList);
    const { loading, products, error } = productList;

    const { i18n } = useTranslation();
    let lang = i18n.language;

    useEffect(() => {
        console.log(props.list, props.subcat, props.taste, props.priceSort, props.isVisible);
        dispatch(listProducts(props.list, props.subcat, props.taste, props.priceSort, props.isVisible));
    }, [dispatch, props.list, props.subcat, props.taste, props.priceSort, props.isVisible]);

    const productsByCategory = {};

    // Step 2: Group products by category
    products?.forEach((product) => {
        const category = product.category;
        if (!productsByCategory[category]) {
            productsByCategory[category] = [];
        }
        productsByCategory[category].push(product);
    });

    const handleClick = (id, isBulk) => {
        navigate(`/cart/${id}?qty=${isBulk ? 1 : 1}&add=prod`);
    }

    // const productsByCategory = {};
    // products?.forEach((product) => {
    //     const category = product.category;
    //     if (!productsByCategory[category]) {
    //         productsByCategory[category] = [];
    //     }
    //     productsByCategory[category].push(product);
    // });

    return (
        <div className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] border-b-2 border-white">
            <div className="mx-auto max-w-2xl px-4 py-2 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-6">
                <h2 className="sr-only">Products</h2>

                {loading ? (
                    <div>Loading</div>
                ) : error ? (
                    <div>
                        Error
                        {console.log(error)}
                    </div>
                ) : (
                    <div>
                        {Object.keys(productsByCategory).map((category, index) => (
                            <div key={index} className="py-4">
                                <h2 className="text-lg text-white font-semibold">{category}</h2>
                                <div className="grid grid-cols-2 gap-x-4 gap-y-4 xl:gap-y-10 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
                                    {/* {products.map((product, index) => ( */}
                                    {productsByCategory[category].map((product, index) => (
                                        <div key={index} className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md">
                                            <RouterLink
                                                key={product.id}
                                                to={`/product/${product._id}?qty=${product.isBulk ? 1 : 1}`}
                                                className="group relative"
                                            >
                                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                                                    <img
                                                        src={product.image[0]}
                                                        alt={product.name}
                                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                                    />
                                                </div>
                                                {product.discountedPrice !== product.price && (
                                                    <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded">
                                                        {`-${((1 - product.discountedPrice / product.price) * 100).toFixed(0)}%`}
                                                    </div>
                                                )}
                                                {/* <p className={`${(product.discountedPrice === product.price ? 'hidden' : 'flex')} m-2 bg-[#e31837] absolute top-0 left-0 text-xs text-white font-semibold p-1 rounded`}>{(((product.price - product.discountedPrice) / product.price) * 100)?.toFixed(0)}% Off</p> */}
                                                <div className="p-4">
                                                    <h3 className="text-sm overflow-clip font-semibold truncate ...">{product.name}</h3>
                                                    <div className="flex items-end">
                                                        {product.discountedPrice !== product.price && (
                                                            <div className="flex items-end">
                                                                <p className="text-xl text-green-500">
                                                                    {`${(product.discountedPrice)?.toFixed(2).replace('.', ',')}€`}
                                                                </p>
                                                                <p className="ml-2 text-sm line-through text-gray-500">
                                                                    {`${(product.price)?.toFixed(2).replace('.', ',')}€`}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {product.discountedPrice === product.price && (
                                                            <div className="text-xl text-gray-800">
                                                                {`${(product.price)?.toFixed(2).replace('.', ',')}€`}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </RouterLink>
                                            <div className="flex justify-center items-center p-2 md:p-4 bg-gray-100">
                                                {/* <RouterLink
                                        to={`/cart/${product._id}?qty=${product.isBulk ? 1 : 6}&add=prod`}
                                        className="text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900"
                                    >
                                        In den Einkaufswagen
                                    </RouterLink> */}
                                                <button
                                                    disabled={product.countInStock === 0}
                                                    onClick={() => handleClick(product._id, product.isBulk)}
                                                    className={`${product.countInStock === 0 ? 'cursor-not-allowed' : ''} text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}>
                                                    In den Einkaufswagen
                                                </button>
                                                {/* Add any additional information or buttons here */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
