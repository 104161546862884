export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const SUB_CATEGORY_LIST_REQUEST = "SUB_CATEGORY_LIST_REQUEST";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST_FAIL = "SUB_CATEGORY_LIST_FAIL";

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";

export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_FAIL = "CATEGORY_CREATE_FAIL";
export const CATEGORY_CREATE_RESET = "CATEGORY_CREATE_RESET";

export const MENU_LINK_UPDATE_REQUEST = "MENU_LINK_UPDATE_REQUEST";
export const MENU_LINK_UPDATE_SUCCESS = "MENU_LINK_UPDATE_SUCCESS";
export const MENU_LINK_UPDATE_FAIL = "MENU_LINK_UPDATE_FAIL";
export const MENU_LINK_UPDATE_RESET = "MENU_LINK_UPDATE_RESET";
