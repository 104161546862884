import { Fragment, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import BestHero from "../components/BestHero";
import Search from "../components/Search";
import MenuItemList from "../components/MenuItemList";
import { useDispatch, useSelector } from "react-redux";
import { ADD_BOOKING_RESET } from "../constants/bookingConstants";
import { listMenuItems } from "../actions/menuItemActions";
import { FiThumbsUp } from "react-icons/fi";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { Dialog, Transition } from "@headlessui/react";
import { Helmet } from "react-helmet";

export default function Events() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [isOpen, setIsOpen] = useState(false);
  const [menuId, setMenuId] = useState("");
  const [noOfPersons, setNoOfPersons] = useState(1);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const menuItemList = useSelector((state) => state.menuItemList);
  const { loading, menuItems, error } = menuItemList;

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    onTop();
    dispatch(listMenuItems());
    dispatch({ type: ORDER_CREATE_RESET });
  }, [dispatch]);

  const calculateFormatedDate = (date) => {
    const parsedDateTime = new Date(date);

    // Round the seconds portion to the nearest minute
    parsedDateTime.setSeconds(
      Math.round(parsedDateTime.getSeconds() / 60) * 60
    );

    // Format the resulting date and time as an ISO 8601 string
    const formattedDateTime = parsedDateTime
      .toISOString()
      .slice(0, 16)
      .replace("T", " ");
    // const formattedDateTime = parsedDateTime.toLocaleString();
    return formattedDateTime;
  };

  const handleGroupEventToCart = () => {
    navigate(`/cart/${menuId}?qty=1&add=event&pno=${noOfPersons}`);
    closeModal();
  };

  const handleModalOpen = (id) => {
    setMenuId(id);
    openModal();
  };

  return (
    <div>
      <Helmet>
        <title>Shop - Events</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <BestHero list="Events" />

      <div className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] border-b-2 border-white">
        <div className="mx-auto max-w-2xl px-4 py-2 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Events</h2>

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Anzahl der Teilnehmer
                      </Dialog.Title>
                      <div className="mt-2">
                        <input
                          className="relative block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          min={1}
                          type="number"
                          required
                          value={noOfPersons}
                          onChange={(e) => setNoOfPersons(e.target.value)}
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => handleGroupEventToCart()}
                        >
                          Weiter
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {loading ? (
            <div>Loading</div>
          ) : error ? (
            <div>Error</div>
          ) : (
            <div className="flex flex-col gap-6">
              <div
                className={`${
                  menuItems.filter((menu) => menu.category === "Group Events")
                    .length === 0
                    ? "hidden"
                    : ""
                }`}
              >
                <h1 className="text-white text-xl font-semibold pb-6">
                  Group Events
                </h1>
                <div className="grid gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8">
                  {menuItems
                    .filter(
                      (menu) =>
                        menu.category === "Group Events" &&
                        new Date(menu.date) > new Date()
                    )
                    .map((menu, index) => (
                      // <div key={index} className="flex flex-col">
                      //     <RouterLink to={`/events/${menu._id}`} className="group">
                      //         <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-white xl:aspect-h-8 xl:aspect-w-7">
                      //             <img
                      //                 src={menu.image}
                      //                 alt={menu.name}
                      //                 className="h-full w-full object-cover object-center group-hover:opacity-75"
                      //             />
                      //         </div>
                      //         <h3 className="mt-4 text-center text-base text-white h-12 overflow-clip">{menu.name}</h3>

                      //         <h3 className="text-center text-base text-white overflow-clip">{calculateFormatedDate(menu.date)}</h3>
                      //         <p className="mt-1 text-center text-lg font-medium text-white">{`${(menu.price)?.toFixed(2).replace('.', ',')}€`}</p>

                      //     </RouterLink>
                      //     <div className="w-full">
                      //         {menu.category === 'Group Events' ? (
                      //             <div className="">
                      //                 <button className="enbaled:cursor-pointer disabled:cursor-not-allowed focus:outline-none focus:ring-gray-800 focus:ring-offset-2 focus:ring-2 text-white w-full tracking-tight py-4 text-lg leading-4 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900 border border-gray-800"
                      //                     disabled={menu.countInStock === 0}
                      //                     onClick={() => handleModalOpen(menu._id)}
                      //                 >
                      //                     In den Einkaufswagen
                      //                 </button>
                      //             </div>
                      //         ) : (
                      //             <RouterLink to={`/cart/${menu._id}?qty=1&add=event`}>
                      //                 <button className="enbaled:cursor-pointer disabled:cursor-not-allowed focus:outline-none focus:ring-gray-800 focus:ring-offset-2 focus:ring-2 text-white w-full tracking-tight py-4 text-lg leading-4 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900 border border-gray-800"
                      //                     disabled={menu.countInStock === 0}
                      //                 >
                      //                     In den Einkaufswagen
                      //                 </button>
                      //             </RouterLink>
                      //         )}
                      //     </div>
                      // </div>
                      <div
                        key={index}
                        className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md"
                      >
                        <RouterLink
                          to={`/events/${menu._id}`}
                          className="group"
                        >
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-lg bg-white xl:aspect-h-8 xl:aspect-w-8">
                            <img
                              src={menu.image}
                              alt={menu.name}
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                          <h3 className="mt-4 text-center text-base text-black h-12 overflow-clip">
                            {menu.name}
                          </h3>

                          <h3 className="text-center text-base text-black overflow-clip">
                            {calculateFormatedDate(menu.date)}
                          </h3>
                          <p className="mt-1 text-center text-lg font-medium text-black">{`${menu.price
                            ?.toFixed(2)
                            .replace(".", ",")}€`}</p>
                        </RouterLink>
                        <div className="w-full">
                          {menu.category === "Group Events" ? (
                            <div className="flex justify-center items-center p-2 md:p-4 bg-gray-100">
                              <button
                                disabled={menu.countInStock === 0}
                                onClick={() => handleModalOpen(menu._id)}
                                className={`${
                                  menu.countInStock === 0
                                    ? "cursor-not-allowed"
                                    : ""
                                } text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}
                              >
                                In den Einkaufswagen
                              </button>
                            </div>
                          ) : (
                            <RouterLink
                              to={`/cart/${menu._id}?qty=1&add=event`}
                              className="flex justify-center items-center p-2 md:p-4 bg-gray-100"
                            >
                              <button
                                disabled={menu.countInStock === 0}
                                className={`${
                                  menu.countInStock === 0
                                    ? "cursor-not-allowed"
                                    : ""
                                } text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}
                              >
                                In den Einkaufswagen
                              </button>
                            </RouterLink>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div
                className={`${
                  menuItems.filter((menu) => menu.category === "Solo Events")
                    .length === 0
                    ? "hidden"
                    : ""
                }`}
              >
                <h1 className="text-white text-xl font-semibold pb-6">
                  Solo Events
                </h1>
                <div className="grid gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8">
                  {menuItems
                    .filter(
                      (menu) =>
                        menu.category === "Solo Events" &&
                        new Date(menu.date) > new Date()
                    )
                    .map((menu, index) => (
                      <div
                        key={index}
                        className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md"
                      >
                        <RouterLink
                          to={`/events/${menu._id}`}
                          className="group"
                        >
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-lg bg-white xl:aspect-h-8 xl:aspect-w-8">
                            <img
                              src={menu.image}
                              alt={menu.name}
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                          <h3 className="mt-4 text-center text-base text-black h-12 overflow-clip">
                            {menu.name}
                          </h3>

                          <h3 className="text-center text-base text-black overflow-clip">
                            {calculateFormatedDate(menu.date)}
                          </h3>
                          <p className="mt-1 text-center text-lg font-medium text-black">{`${menu.price
                            ?.toFixed(2)
                            .replace(".", ",")}€`}</p>
                        </RouterLink>
                        <div className="w-full">
                          {menu.category === "Group Events" ? (
                            <div className="flex justify-center items-center p-2 md:p-4 bg-gray-100">
                              <button
                                disabled={menu.countInStock === 0}
                                onClick={() => handleModalOpen(menu._id)}
                                className={`${
                                  menu.countInStock === 0
                                    ? "cursor-not-allowed"
                                    : ""
                                } text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}
                              >
                                In den Einkaufswagen
                              </button>
                            </div>
                          ) : (
                            <RouterLink
                              to={`/cart/${menu._id}?qty=1&add=event`}
                              className="flex justify-center items-center p-2 md:p-4 bg-gray-100"
                            >
                              <button
                                disabled={menu.countInStock === 0}
                                className={`${
                                  menu.countInStock === 0
                                    ? "cursor-not-allowed"
                                    : ""
                                } text-white text-xs md:text-sm bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] p-2 md:p-4 rounded-full hover:bg-gradient-to-r hover:from-[#ed6d15] hover:via-gray-700 hover:to-gray-900`}
                              >
                                In den Einkaufswagen
                              </button>
                            </RouterLink>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
