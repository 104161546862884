import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { listHero } from "../actions/heroActions";
import Loader from "./Loader";
import Message from "./Message";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 3000,
  transitionTime: 600,
  ariaLabel: "ariaLabel",
});

export default function Hero() {
  const dispatch = useDispatch();

  const heroList = useSelector((state) => state.heroList);
  const { loading, heros, error } = heroList;

  const [country, setCountry] = useState("india");
  const [activeIndex, setActiveIndex] = useState(0);

  const { i18n } = useTranslation();
  let lang = i18n.language;

  useEffect(() => {
    if (lang === "en") {
      setCountry("india");
    } else {
      setCountry("germany");
    }
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(listHero("germany", "Main"));
  }, [country, dispatch]);

  const handleChange = (i) => {
    setActiveIndex(i);
  };

  return (
    <div className="">
      {loading ? (
        <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white animate-pulse">
          {/* Shimmer effect */}
          <div className="flex items-center justify-center min-h-[20vh] md:min-h-[70vh] bg-slate-100 bg-no-repeat bg-animate animate-pulse">
            <Loader />
          </div>
        </div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <div className="w-full bg-white group">
          <Carousel {...getConfigurableProps()} onChange={handleChange}>
            {heros.map((hero, index) => (
              <div key={index} className="relative">
                {/* <RouterLink className='block h-full' to={`/product?category=${hero.link}`}> */}
                <img
                  loading="lazy"
                  src={`${hero.url}`}
                  alt="banner"
                  className="w-full md:h-full object-cover object-center"
                />
                {/* <div className={`absolute w-full h-full z-10 top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 bg-black duration-3000 ease-in-out delay-600 ${activeIndex === index ? 'opacity-50' : 'opacity-0'}`}></div> */}
                {/* </RouterLink> */}
                {hero?.description && hero?.description !== "" && (
                  <p
                    className={`hidden md:block absolute w-[300px] md:w-1/2 whitespace-pre-line z-10 top-[65%] md:top-3/4 left-[10%] md:left-1/4 -translate-y-2/4 -translate-x-2/4 bg-black text-white text-sm md:text-lg p-2 rounded-lg ${
                      activeIndex === index
                        ? "animate-fade-up animate-once animate-duration-[1500ms] animate-ease-in-out"
                        : "opacity-0"
                    }`}
                  >
                    {hero.description}
                  </p>
                )}
                {/* <p className='legend' style={{ fontSize: '15px' }}>{hero.description}</p> */}
                <a
                  href={hero.link}
                  className={`absolute cursor-pointer z-10 top-1/2 left-1/2 bg-[#ed6d15] text-white text-sm md:text-2xl font-semibold -translate-y-2/4 -translate-x-2/4 p-2 rounded-lg transition transform duration-3000 ease-in-out ${
                    activeIndex === index ? "opacity-100" : "opacity-0"
                  }`}
                >
                  Mehr dazu
                </a>
                {/* <a href={hero.link} className='legend bg-[#ed6d15] text-white font-bold'>JETZT BUCHEN</a> */}
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
