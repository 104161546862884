import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import { Flex, Text, Select } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { PRODUCT_REVIEW_CREATE_RESET } from "../constants/productConstants";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import Rating from "../components/Rating";
import { Helmet } from "react-helmet";

export default function ProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { i18n } = useTranslation();

  const { id } = useParams();
  const [qty, setQty] = useState(searchParams.get("qty"));
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [mainImg, setMainImg] = useState("");

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      alert("Review submitted");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }

    dispatch(listProductDetails(id));
  }, [id, dispatch, successProductReview]);

  const addToCartHandler = () => {
    navigate(`/cart/${id}?qty=${qty}&add=prod`);
  };

  const handleImgChange = (img) => {
    setMainImg(img);
  };

  return (
    <div>
      <section className="overflow-hidden bg-white font-poppins">
        {loading ? (
          <div>Loading</div>
        ) : error ? (
          <div>Error</div>
        ) : (
          <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <Helmet>
              <title>{`Shop - ${product.name}`}</title>
              {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
            </Helmet>
            <div className="flex flex-col md:flex-row gap-8 -mx-4">
              <div className="flex flex-wrap-reverse md:flex-nowrap mt-0 md:mt-4 w-full p-4 gap-4 md:w-1/2">
                <div className="flex md:flex-col gap-4 w-1/4">
                  {product.image &&
                    product.image.map((img) => (
                      <div className="" key={img}>
                        <button
                          className="block border border-blue-300 hover:border-blue-300"
                          onClick={() => handleImgChange(img)}
                        >
                          <img
                            src={img}
                            alt=""
                            className="object-contain w-[90px] h-[90px] cursor-pointer"
                          />
                        </button>
                      </div>
                    ))}
                </div>
                <div className="hidden md:flex w-full md:w-3/4 justify-center">
                  <TransformWrapper centerOnInit={true} initialScale={1}>
                    <TransformComponent
                      contentStyle={{ width: "60vh", height: "60vh" }}
                    >
                      <img
                        className="w-full h-full object-contain object-center"
                        src={mainImg ? mainImg : product.image[0]}
                        alt={product.name}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
                <div className="flex md:hidden w-full md:w-3/4 justify-center">
                  {/* <TransformWrapper centerOnInit={true} initialScale={1}> */}
                  {/* <TransformComponent contentStyle={{ 'width': '60vh', 'height': '60vh' }}> */}
                  <img
                    className="w-full h-full object-contain object-center"
                    src={mainImg ? mainImg : product.image[0]}
                    alt={product.name}
                  />
                  {/* </TransformComponent> */}
                  {/* </TransformWrapper> */}
                </div>
                <style>
                  {`
                                    .react-transform-component {
                                      height: 60vh; /* Set your desired width for desktop */
                                    }
    @media (min-width: 768px) { /* Adjust this breakpoint as needed */
      .react-transform-component {
        height: 60vh; /* Set your desired width for desktop */
      }
    }
  `}
                </style>
              </div>
              <div className="w-full px-4 md:w-1/2 ">
                <div className="">
                  {product.discountedPrice !== product.price && (
                    <div className="w-[60px] text-center bg-red-500 text-white px-2 py-1 rounded">
                      {`-${(
                        (1 - product.discountedPrice / product.price) *
                        100
                      ).toFixed(0)}%`}
                    </div>
                  )}
                  <div className="mb-8 ">
                    <h2 className="max-w-xl mt-2 mb-6 text-2xl font-semibold md:text-4xl">
                      {product.name}
                    </h2>
                    <h2 className="max-w-xl mt-2 mb-6 text-lg font-semibold md:text-2xl">
                      Jahr {product.year}
                    </h2>
                    <h2 className="max-w-xl mt-2 mb-6 text-lg font-semibold md:text-xl">
                      Code {product.sku}
                    </h2>
                    {product.category !== "hofladen" && (
                      <p className="text-lg">{`Volume of Bottle: ${product.volOfBottle
                        ?.toFixed(2)
                        .replace(".", ",")} L`}</p>
                    )}
                    <p className="flex mb-2 items-end">
                      {/* <span className="text-4xl font-bold text-gray-700">{`${(product.discountedPrice)?.toFixed(2).replace('.', ',')}€`}</span> */}
                      {product.discountedPrice !== product.price && (
                        <div className="flex items-end">
                          <p className="text-xl font-semibold text-green-500">
                            {`${product.discountedPrice
                              ?.toFixed(2)
                              .replace(".", ",")}€`}
                          </p>
                          <p className="ml-2 text-sm line-through text-gray-500">
                            {`${product.price?.toFixed(2).replace(".", ",")}€`}
                          </p>
                        </div>
                      )}
                      {product.discountedPrice === product.price && (
                        <div className="text-xl font-semibold text-gray-800">
                          {`${product.price?.toFixed(2).replace(".", ",")}€`}
                        </div>
                      )}
                      {product.category !== "hofladen" && !product.isBulk && (
                        <div className="">
                          <span className="text-3xl mx-2">|</span>
                          <span className="text-xl font-semibold text-gray-700">{`${(
                            product.discountedPrice / product.volOfBottle
                          )
                            ?.toFixed(2)
                            .replace(".", ",")}€ / 1L`}</span>
                        </div>
                      )}
                    </p>
                  </div>
                  {product.countInStock > 0 && (
                    <Flex className="gap-2" py="2" alignItems="center">
                      <Text>Qty: </Text>
                      <Select
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        width="20%"
                      >
                        {[...Array(product.countInStock).keys()].map((i) => (
                          <option value={i + 1} key={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  )}
                  <div className="flex flex-col gap-4">
                    <div className="w-full mb-4 lg:w-4/5 lg:mb-0">
                      <button
                        className="flex items-center justify-center w-full p-3 text-blue-500 border border-blue-500 rounded-md enbaled:cursor-pointer disabled:cursor-not-allowed hover:bg-blue-600 hover:border-blue-600 hover:text-gray-100"
                        disabled={product.countInStock === 0}
                        onClick={addToCartHandler}
                      >
                        In den Einkaufswagen
                      </button>
                    </div>

                    <p className="max-w-md mb-8 text-gray-700 text-justify whitespace-pre-line">
                      {product.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {product.additionalInfo && (
              <div className="my-4 w-full">
                <h2 className="text-2xl mb-4 font-bold">
                  ZUSÄTZLICHE ANGABEN:
                </h2>
                <div className="flex flex-col gap-4 w-full md:w-2/5">
                  {product.additionalInfo?.grapeVariety !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Rebsorte:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.grapeVariety}
                      </p>
                    </div>
                  )}
                  {product.additionalInfo?.taste !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Geschmack:</p>
                      <p className="w-1/2">{product.additionalInfo?.taste}</p>
                    </div>
                  )}
                  {product.additionalInfo?.qualityLevel !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Qualitätsstufe:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.qualityLevel}
                      </p>
                    </div>
                  )}
                  {product.additionalInfo?.growingArea !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Anbaugebiet:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.growingArea}
                      </p>
                    </div>
                  )}
                  {product.additionalInfo?.residualSugar !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Restzucker:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.residualSugar}(g/l)
                      </p>
                    </div>
                  )}
                  {product.additionalInfo?.acid !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Säure:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.acid}(g/l)
                      </p>
                    </div>
                  )}
                  {product.additionalInfo?.alcohol !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Alkohol:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.alcohol}(vol. %)
                      </p>
                    </div>
                  )}
                  {product.additionalInfo?.allergens !== "" && (
                    <div className="flex gap-2">
                      <p className="w-1/2">Allergene:</p>
                      <p className="w-1/2">
                        {product.additionalInfo?.allergens}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
}
