import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Sidebar from '../components/Sidebar';
import './sidenav.css'
import { RiDeleteBin6Line } from 'react-icons/ri';
import { InputGroup, InputRightElement, Select, Textarea } from '@chakra-ui/react';
import { serverIp } from '../config/conf';
import Loader from '../components/Loader';
import { listMenuItemDetails, updateMenuItem } from '../actions/menuItemActions';
import { MENU_ITEM_UPDATE_RESET } from '../constants/menuItemConstants';
import Message from '../components/Message';

const CampingForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routePath = useLocation();
    const queryParams = new URLSearchParams(routePath.search);
    const categoryQry = queryParams.get('category');

    const { id: productId } = useParams();

    const [uploading, setUploading] = useState(false);

    const menuItemDetails = useSelector((state) => state.menuItemDetails);
    const { menuItem } = menuItemDetails;

    const menuItemUpdate = useSelector((state) => state.menuItemUpdate);
    const {
        success: successUpdate,
        error: errorUpdate,
        loading: loadingUpdate
    } = menuItemUpdate;


    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [category, setCategory] = useState('');
    const [priceAdult, setPriceAdult] = useState(0);
    const [priceChild, setPriceChild] = useState(0);
    const [priceGroup, setPriceGroup] = useState(0);
    const [price, setPrice] = useState(0);
    const [purchasable, setPurchasable] = useState(false);
    const [countInStock, setCountInStock] = useState(0);

    const handleImageDelete = () => {
        if (window.confirm('Are you sure?')) {
            setImage('');
        } else {
            console.log('Invalid index');
        }
    }

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: MENU_ITEM_UPDATE_RESET });
            dispatch(listMenuItemDetails(productId));
            // if (category === 'Solo Events') {
            //     navigate(`/dashboard/mystore/solo-events`);
            // } else if (category === 'Group Events') {
            //     navigate(`/dashboard/mystore/group-events`);
            // } 
            // else if (category === 'Camping Services') {
            //     navigate(`/dashboard/mystore/camping`);
            // } else {
            //     navigate(`/dashboard/mystore/alpaka`);
            // }
            navigate(`/dashboard/mystore/${categoryQry}`);
        } else {
            if (!menuItem.name || menuItem._id !== productId) {
                dispatch(listMenuItemDetails(productId));
            } else {
                setName(menuItem.name);
                setImage(menuItem.image);
                setDescription(menuItem.description);
                setCategory(menuItem.category);
                setPriceAdult(menuItem.priceAdult);
                setPriceChild(menuItem.priceChild);
                setPriceGroup(menuItem.priceGroup);
                setPrice(menuItem.price);
                setCountInStock(menuItem.countInStock);
                if (menuItem.category === 'Solo Events' || menuItem.category === 'Group Events') {
                    const parsedDateTime = new Date(menuItem.date);

                    // Round the seconds portion to the nearest minute
                    parsedDateTime?.setSeconds(Math.round(parsedDateTime?.getSeconds() / 60) * 60);

                    // Format the resulting date and time as an ISO 8601 string
                    const formattedDateTime = parsedDateTime?.toISOString().slice(0, 16);
                    setDate(formattedDateTime)
                }
            }
        }
    }, [dispatch, navigate, productId, menuItem, successUpdate]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            updateMenuItem({
                _id: productId,
                name,
                image,
                description,
                date,
                category,
                priceAdult,
                priceChild,
                priceGroup,
                price,
                countInStock
            })
        );
    };

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        setUploading(true);

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const { data } = await axios.post(`${serverIp}/api/uploads`, formData, config);
            setImage(data);
            setUploading(false);
        } catch (err) {
            console.error(err);
            setUploading(false);
        }
    };

    return (

        <div>
            <Sidebar menu='My Store' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <h2 className="text-2xl font-semibold mb-4">Add/Edit Events</h2>

                    {loadingUpdate && <Loader />}
                    {errorUpdate && <Message type='error'>{errorUpdate}</Message>}
                    <div className="container mx-auto mt-8">
                        <form onSubmit={submitHandler} className=''>
                            <div className='flex gap-4'>
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="flex items-center justify-center w-full">
                                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX 1)</p>
                                            </div>
                                            <input id="dropzone-file" type="file" accept="image/*" className="hidden" onChange={uploadFileHandler} />
                                            {uploading && <Loader />}
                                        </label>
                                    </div>
                                    <div className="mb-4">
                                        <div>
                                            <label htmlFor="name" className="block font-medium text-sm mb-1">
                                                Image
                                            </label>
                                            <div className='flex'>
                                                <img className='w-1/5' src={image} alt={''} />
                                                <p className='w-3/5'>{image.split('\\').pop()}</p>
                                                {image && <RiDeleteBin6Line onClick={() => handleImageDelete()} className='w-1/5' />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block font-medium text-sm mb-1">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="category" className="block font-medium text-sm mb-1">
                                            Category
                                        </label>
                                        <Select borderColor='black' value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option value='Solo Events'>Solo Events</option>
                                            <option value='Group Events'>Group Events</option>
                                            <option value='Camping Services'>Camping</option>
                                            <option value='Alpaka Services'>Alpaka</option>
                                            {/* <option value='Offline Menu'>Offline Menu</option> */}
                                        </Select>
                                    </div>
                                    {(category === 'Solo Events' || category === 'Group Events') && (
                                        <div className="mb-4">
                                            <label htmlFor="date" className="block font-medium text-sm mb-1">
                                                Date - Time
                                            </label>
                                            <input
                                                type="datetime-local"
                                                id="date"
                                                className="w-full border border-black rounded px-3 py-2"
                                                value={date}
                                                onChange={(e) => {
                                                    setDate(e.target.value)
                                                    console.log(e.target.value)
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(category === 'Camping Services' || category === 'Alpaka Services') && (
                                        <div>
                                            <div className='flex gap-2'>
                                                <div className="mb-4 w-1/2">
                                                    <label htmlFor="priceAdult" className="block font-medium text-sm mb-1">
                                                        Price(Adult)
                                                    </label>
                                                    <InputGroup>
                                                        <input
                                                            type="number"
                                                            id="priceAdult"
                                                            className="w-full border border-black rounded px-3 py-2"
                                                            value={priceAdult}
                                                            onChange={(e) => setPriceAdult(e.target.value)}
                                                        />
                                                        <InputRightElement children='€' />
                                                    </InputGroup>
                                                </div>
                                            </div>
                                            <div className='flex gap-2'>
                                                <div className="mb-4 w-1/2">
                                                    <label htmlFor="priceChild" className="block font-medium text-sm mb-1">
                                                        Price(Child)
                                                    </label>
                                                    <InputGroup>
                                                        <input
                                                            type="number"
                                                            id="priceChild"
                                                            className="w-full border border-black rounded px-3 py-2"
                                                            value={priceChild}
                                                            onChange={(e) => setPriceChild(e.target.value)}
                                                        />
                                                        <InputRightElement children='€' />
                                                    </InputGroup>
                                                </div>
                                            </div>
                                            <div className='flex gap-2'>
                                                <div className="mb-4 w-1/2">
                                                    <label htmlFor="priceGroup" className="block font-medium text-sm mb-1">
                                                        Price(Group)
                                                    </label>
                                                    <InputGroup>
                                                        <input
                                                            type="number"
                                                            id="priceGroup"
                                                            className="w-full border border-black rounded px-3 py-2"
                                                            value={priceGroup}
                                                            onChange={(e) => setPriceGroup(e.target.value)}
                                                        />
                                                        <InputRightElement children='€' />
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(category === 'Solo Events' || category === 'Group Events') && (
                                        <div className='flex gap-2'>
                                            <div className="mb-4 w-1/2">
                                                <label htmlFor="price" className="block font-medium text-sm mb-1">
                                                    Price
                                                </label>
                                                <InputGroup>
                                                    <input
                                                        type="number"
                                                        id="price"
                                                        className="w-full border border-black rounded px-3 py-2"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    />
                                                    <InputRightElement children='€' />
                                                </InputGroup>
                                            </div>
                                        </div>
                                    )}

                                    {(category === 'Solo Events' || category === 'Group Events') && (
                                        <div className='flex gap-2'>
                                            <div className="mb-4 w-1/2">
                                                <label htmlFor="stock" className="block font-medium text-sm mb-1">
                                                    Stock
                                                </label>
                                                <input
                                                    type="number"
                                                    id="stock"
                                                    className="w-full border border-black rounded px-3 py-2"
                                                    value={countInStock}
                                                    onChange={(e) => setCountInStock(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="mb-4">
                                        <label htmlFor="description" className="block font-medium text-sm mb-1">
                                            Description
                                        </label>
                                        <Textarea
                                            placeholder='Enter description in english'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            borderColor='black'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Add other form inputs for image, tags, bestOfSapphire, category, countInStock, priceIn, priceDe, descriptionIn, descriptionDe, sizeIn, sizeDe */}
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </main>
            </section>
        </div>


    );
};

export default CampingForm;