import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DefinedRange, DateRange, DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { IoCalendarOutline } from "react-icons/io5";
import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";

import { getOrderStats, listOrders } from "../actions/orderActions";
import Sidebar from "../components/Sidebar";
import "./sidenav.css";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getBookingStats } from "../actions/bookingActions";
import { BsBookmarkPlus, BsCurrencyEuro } from "react-icons/bs";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import {
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Select,
  Text,
  Menu as ChakraMenu,
} from "@chakra-ui/react";
import { BiFilter, BiSort } from "react-icons/bi";
import { CiMenuKebab } from "react-icons/ci";
import { GET_BOOKING_RESET } from "../constants/bookingConstants";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDate, setOpenDate] = useState(false);
  const [state, setState] = useState(0);
  const [date, setDate] = useState([
    {
      startDate: new Date(0),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const bookingStats = useSelector((state) => state.bookingStats);
  const {
    loading: loadingBookingStats,
    count: countBooking,
    totalSales: totalSalesBooking,
    error: errorBookingStats,
    menuStatistics,
  } = bookingStats;

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderStats = useSelector((state) => state.orderStats);
  const {
    loading: loadingOrderStats,
    count: countOrder,
    totalSales: totalSalesOrder,
    error: errorOrderStats,
    weinStatistics,
    hofladenStatistics,
    alpakaStatistics,
    eventStatistics,
  } = orderStats;

  const [sortBookingFor, setSortBookingFor] = useState("startDate");
  const [sortBookingTo, setSortBookingTo] = useState(true);

  const [sortOrderFor, setSortOrderFor] = useState("createdAt");
  const [sortOrderTo, setSortOrderTo] = useState(true);

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
      dispatch(
        getOrderStats(
          date[0].startDate,
          date[0].endDate,
          state,
          sortOrderFor,
          sortOrderTo
        )
      );
      dispatch(
        getBookingStats(
          date[0].startDate,
          date[0].endDate,
          state,
          sortBookingFor,
          sortBookingTo
        )
      );
      dispatch({ type: GET_BOOKING_RESET });
      dispatch({ type: ORDER_DETAILS_RESET });
    } else {
      navigate("/login");
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    date,
    state,
    sortBookingFor,
    sortBookingTo,
    sortOrderFor,
    sortOrderTo,
  ]);

  const handleSelectButtonClick = () => {
    setOpenDate(false); // Close the date picker
  };

  return (
    <div>
      <Sidebar menu="Dashboard" />
      {/* <!-- CONTENT --> */}
      <section id="content">
        {/* <!-- MAIN --> */}
        <main>
          <div>
            <div>
              <div className="flex w-full justify-between">
                <div className="flex w-1/2 gap-2 items-center">
                  <IoCalendarOutline
                    className="headerIcon cursor-pointer"
                    onClick={() => setOpenDate(!openDate)}
                  />
                  <span
                    onClick={() => setOpenDate(!openDate)}
                    className="headerSearchText"
                  >{`From ${format(
                    date[0].startDate,
                    "dd-MM-yyyy"
                  )} to ${format(date[0].endDate, "dd-MM-yyyy")}`}</span>
                  {openDate && (
                    <div className="flex flex-col absolute">
                      <DateRangePicker
                        editableDateInputs={true}
                        onChange={(item) => {
                          setDate([item.selection]);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        className="date"
                        disabledDates={[]}
                        showSelectionPreview={true}
                      />
                      <button
                        className="dateButton3 hidden md:flex"
                        onClick={handleSelectButtonClick}
                      >
                        Auswählen
                      </button>
                      <button
                        className="dateButton3 flex md:hidden"
                        onClick={handleSelectButtonClick}
                      >
                        ✔
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex gap-2 w-1/3">
                  <Select
                    width="30%"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option key="1" value={0}>
                      New
                    </option>
                    <option key="1" value={1}>
                      Completed
                    </option>
                    <option key="1" value={2}>
                      Cancelled
                    </option>
                  </Select>
                  <h1 className="font-bold text-4xl">Dashboard</h1>
                </div>
              </div>
            </div>
          </div>

          <ul className="box-info mb-8">
            <li className="drop-shadow-md">
              <MdOutlineAddShoppingCart className="bx bxs-calendar-check" />
              {loadingOrderStats ? (
                <Loader />
              ) : errorOrderStats ? (
                <Message type="error">{errorOrderStats}</Message>
              ) : (
                <span className="text w-1/2">
                  <h3>{countOrder.length}</h3>
                  <p>Bestellungen</p>
                </span>
              )}
            </li>
            <li className="drop-shadow-md">
              <BsBookmarkPlus className="bx bxs-calendar-check" />
              {loadingBookingStats ? (
                <Loader />
              ) : errorBookingStats ? (
                <Message type="error">{errorBookingStats}</Message>
              ) : (
                <span className="text w-1/2">
                  <h3>{countBooking.length}</h3>
                  <p>Buchungen</p>
                </span>
              )}
            </li>
            <li className="drop-shadow-md">
              <BsCurrencyEuro className="bx bxs-dollar-circle" />
              {loadingOrderStats ? (
                <Loader />
              ) : errorOrderStats ? (
                <Message type="error">{errorOrderStats}</Message>
              ) : (
                <span className="text w-1/2">
                  <h3>{totalSalesOrder.toFixed(2)}€</h3>
                  <p>Verkäufe(Bestellungen)</p>
                </span>
              )}
            </li>
            <li className="drop-shadow-md">
              <BsCurrencyEuro className="bx bxs-dollar-circle" />
              {loadingBookingStats ? (
                <Loader />
              ) : errorBookingStats ? (
                <Message type="error">{errorBookingStats}</Message>
              ) : (
                <span className="text w-1/2">
                  <h3>{totalSalesBooking.toFixed(2)}€</h3>
                  <p>Verkäufe(Buchungen)</p>
                </span>
              )}
            </li>
            {loadingOrderStats ? (
              <Loader />
            ) : errorOrderStats ? (
              <Message type="error">{errorOrderStats}</Message>
            ) : (
              weinStatistics.map((statObj) => (
                <li className="drop-shadow-md">
                  <div className="flex items-center justify-center rounded-lg w-[80px] h-[80px] bg-orange-200">
                    Wein
                  </div>
                  <span className="text w-1/2">
                    <h3>{statObj.totalSales.toFixed(2)}€</h3>
                    <p>{statObj.totalQuantity} Einheiten</p>
                  </span>
                </li>
              ))
            )}
            {loadingOrderStats ? (
              <Loader />
            ) : errorOrderStats ? (
              <Message type="error">{errorOrderStats}</Message>
            ) : (
              hofladenStatistics.map((statObj) => (
                <li className="drop-shadow-md">
                  <div className="flex items-center justify-center rounded-lg w-[80px] h-[80px] bg-green-200">
                    Hofladen
                  </div>
                  <span className="text w-1/2">
                    <h3>{statObj.totalSales.toFixed(2)}€</h3>
                    <p>{statObj.totalQuantity} Einheiten</p>
                  </span>
                </li>
              ))
            )}
            {loadingOrderStats ? (
              <Loader />
            ) : errorOrderStats ? (
              <Message type="error">{errorOrderStats}</Message>
            ) : (
              eventStatistics.map((statObj) => (
                <li className="drop-shadow-md">
                  <div className="flex items-center justify-center rounded-lg w-[80px] h-[80px] bg-yellow-200">
                    Events
                  </div>
                  <span className="text w-1/2">
                    <h3>{statObj.totalSales.toFixed(2)}€</h3>
                    <p>{statObj.totalQuantity} Einheiten</p>
                  </span>
                </li>
              ))
            )}
            {loadingOrderStats ? (
              <Loader />
            ) : errorOrderStats ? (
              <Message type="error">{errorOrderStats}</Message>
            ) : (
              alpakaStatistics.map((statObj) => (
                <li className="drop-shadow-md">
                  <div className="flex items-center justify-center rounded-lg w-[80px] h-[80px] bg-yellow-200">
                    Alpaka
                  </div>
                  <span className="text w-1/2">
                    <h3>{statObj.totalSales.toFixed(2)}€</h3>
                    <p>{statObj.totalQuantity} Einheiten</p>
                  </span>
                </li>
              ))
            )}
          </ul>

          <div className="table-data drop-shadow-md mb-8">
            <div class="order">
              <div class="head">
                <h3>Menu Items</h3>
                <i class="bx bx-search"></i>
                <i class="bx bx-filter"></i>
              </div>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message type="error">{error}</Message>
              ) : (
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Count</th>
                      <th>Sales</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuStatistics.map((menuObj) => (
                      <>
                        {menuObj?.menuStatistics?.map((menu, idx) => (
                          <tr key={idx}>
                            <td>{menu.name}</td>
                            <td>{menu.totalQuantity}</td>
                            <td>{menu.totalSales}€</td>
                          </tr>
                        ))}
                        <tr className="text-lg font-bold">
                          <td>Total</td>
                          <td>{menuObj.totalMenuQuantity}</td>
                          <td>{menuObj.totalMenuSales}€</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="table-data drop-shadow-md mb-8">
            <div class="order">
              <div class="head">
                <h3>Buchungen</h3>
                <i class="bx bx-search"></i>
                <i class="bx bx-filter"></i>
                {/* <CSVLink data={csvData}>Download CSV</CSVLink> */}
                {/* <CSVDownload data={csvData} target="_blank" /> */}
              </div>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message type="error">{error}</Message>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Customer</th>
                      <th
                        onClick={() => {
                          setSortBookingFor("startDate");
                          setSortBookingTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Von
                          <BiSort />
                        </div>
                      </th>
                      <th
                        onClick={() => {
                          setSortBookingFor("endDate");
                          setSortBookingTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Bis
                          <BiSort />
                        </div>
                      </th>
                      <th>Booking ID</th>
                      <th
                        onClick={() => {
                          setSortBookingFor("discountedPrice");
                          setSortBookingTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Price
                          <BiSort />
                        </div>
                      </th>
                      <th>
                        <div className="flex relative peer group">
                          {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                          {/* <div>Paid Status({paidStatus === true ? 'Paid' : paidStatus === false ? 'Unpaid' : 'All'})</div> */}
                          <div>Paid Status</div>
                          <BiFilter />
                        </div>
                        {/* <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                                                    <button onClick={() => setPaidStatus('all')} className="px-2 py-4 hover:bg-gray-200">All</button>
                                                    <button onClick={() => setPaidStatus(true)} className="px-2 py-4 hover:bg-gray-200">Paid</button>
                                                    <button onClick={() => setPaidStatus(false)} className="px-2 py-4 hover:bg-gray-200">Unpaid</button>
                                                </div> */}
                      </th>
                      <th>
                        <div className="flex relative peer group">
                          {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                          {/* <div>Completion Status({completionStatus === true ? 'Completed' : completionStatus === false ? 'Pending' : 'All'})</div> */}
                          <div>Completion Status</div>
                          <BiFilter />
                        </div>
                        {/* <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                                                    <button onClick={() => setCompletionStatus('all')} className="px-2 py-4 hover:bg-gray-200">All</button>
                                                    <button onClick={() => setCompletionStatus(true)} className="px-2 py-4 hover:bg-gray-200">Completed</button>
                                                    <button onClick={() => setCompletionStatus(false)} className="px-2 py-4 hover:bg-gray-200">Pending</button>
                                                </div> */}
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {countBooking &&
                      countBooking.map((booking) => (
                        <tr key={booking._id}>
                          <td>
                            <p className="text-lg font-bold">
                              {booking.userName}
                            </p>
                          </td>
                          <td>{booking.startDate.substring(0, 10)}</td>
                          <td>{booking.endDate.substring(0, 10)}</td>
                          <td className="text-xs">{booking._id}</td>
                          <td>
                            {booking.discountedPrice
                              ?.toFixed(2)
                              .replace(".", ",")}
                            €
                          </td>
                          <td>
                            <span
                              className={
                                booking.isPaid
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {booking.isPaid ? "Paid" : "Unpaid"}
                            </span>
                          </td>
                          <td>
                            <span
                              className={
                                booking.isCompleted === 1
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {booking.isCompleted === 1
                                ? "Completed"
                                : booking.isCompleted === 0
                                ? "Pending"
                                : "Cancelled"}
                            </span>
                          </td>
                          <td>
                            <div>
                              <ChakraMenu>
                                <RouterLink>
                                  <MenuButton className="text-sm font-medium leading-6 text-gray-900">
                                    <CiMenuKebab className="h-7 w-7 rotate-90" />
                                  </MenuButton>
                                </RouterLink>
                                <Portal>
                                  <MenuList
                                    className="text-sm font-semibold uppercase leading-6 text-gray-900"
                                    zIndex="99"
                                  >
                                    <MenuItem
                                      as={RouterLink}
                                      to={`/dashboard/booking/${booking._id}`}
                                    >
                                      VIEW BOOKING
                                    </MenuItem>
                                  </MenuList>
                                </Portal>
                              </ChakraMenu>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="table-data drop-shadow-md">
            <div class="order">
              <div class="head">
                <h3>Bestellungen</h3>
                <i class="bx bx-search"></i>
                <i class="bx bx-filter"></i>
                {/* <CSVLink data={csvData}>Download CSV</CSVLink> */}
                {/* <CSVDownload data={csvData} target="_blank" /> */}
              </div>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message type="error">{error}</Message>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Customer</th>
                      <th
                        onClick={() => {
                          setSortOrderFor("createdAt");
                          setSortOrderTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Date
                          <BiSort />
                        </div>
                      </th>
                      <th>Order ID</th>
                      <th
                        onClick={() => {
                          setSortOrderFor("totalPrice");
                          setSortOrderTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Price
                          <BiSort />
                        </div>
                      </th>
                      <th>
                        <div className="flex relative peer group">
                          {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                          {/* <div>Paid Status({paidStatus === true ? 'Paid' : paidStatus === false ? 'Unpaid' : 'All'})</div> */}
                          <div>Paid Status</div>
                          <BiFilter />
                        </div>
                        {/* <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                                                    <button onClick={() => setPaidStatus('all')} className="px-2 py-4 hover:bg-gray-200">All</button>
                                                    <button onClick={() => setPaidStatus(true)} className="px-2 py-4 hover:bg-gray-200">Paid</button>
                                                    <button onClick={() => setPaidStatus(false)} className="px-2 py-4 hover:bg-gray-200">Unpaid</button>
                                                </div> */}
                      </th>
                      <th>
                        <div className="flex relative peer group">
                          {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                          {/* <div>Completion Status({completionStatus === true ? 'Completed' : completionStatus === false ? 'Pending' : 'All'})</div> */}
                          <div>Delivery Status</div>
                          <BiFilter />
                        </div>
                        {/* <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                                                    <button onClick={() => setCompletionStatus('all')} className="px-2 py-4 hover:bg-gray-200">All</button>
                                                    <button onClick={() => setCompletionStatus(true)} className="px-2 py-4 hover:bg-gray-200">Completed</button>
                                                    <button onClick={() => setCompletionStatus(false)} className="px-2 py-4 hover:bg-gray-200">Pending</button>
                                                </div> */}
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {countOrder &&
                      countOrder.map((order) => (
                        <tr key={order._id}>
                          <td>
                            <p className="text-lg font-bold">
                              {order.userName}
                            </p>
                          </td>
                          <td>{order.createdAt.substring(0, 10)}</td>
                          <td className="text-xs">{order._id}</td>
                          <td>
                            {order.totalPrice?.toFixed(2).replace(".", ",")}€
                          </td>
                          <td>
                            <span
                              className={
                                order.isPaid
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {order.isPaid ? "Paid" : "Unpaid"}
                            </span>
                          </td>
                          <td>
                            <span
                              className={
                                order.isDelivered === 1
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {order.isDelivered === 1
                                ? "Completed"
                                : order.isDelivered === 0
                                ? "Pending"
                                : "Cancelled"}
                            </span>
                          </td>
                          <td>
                            <div>
                              <ChakraMenu>
                                <RouterLink>
                                  <MenuButton className="text-sm font-medium leading-6 text-gray-900">
                                    <CiMenuKebab className="h-7 w-7 rotate-90" />
                                  </MenuButton>
                                </RouterLink>
                                <Portal>
                                  <MenuList
                                    className="text-sm font-semibold uppercase leading-6 text-gray-900"
                                    zIndex="99"
                                  >
                                    <MenuItem
                                      as={RouterLink}
                                      to={`/dashboard/order/${order._id}`}
                                    >
                                      VIEW ORDER
                                    </MenuItem>
                                  </MenuList>
                                </Portal>
                              </ChakraMenu>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}
