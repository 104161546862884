import {
	WISHLIST_ADD_ITEM,
	WISHLIST_REMOVE_ITEM
} from '../constants/wishListConstants'

export const wishListReducer = (state = { wishListItems: [] }, action) => {
	switch (action.type) {
		case WISHLIST_ADD_ITEM:
			const item = action.payload;
			const existingItemIndex = state.wishListItems.findIndex(
				(i) => i.product === item.product && i.size === item.size
			);

			if (existingItemIndex !== -1) {
				// Item with the same product and size exists, update its quantity or any other property
				const updatedWishListItems = [...state.wishListItems];
				updatedWishListItems[existingItemIndex] = item;

				return { ...state, wishListItems: updatedWishListItems };
			} else {
				// Item with a different size, add it to the wishlist
				return { ...state, wishListItems: [...state.wishListItems, item] };
			}
		case WISHLIST_REMOVE_ITEM:
			return {
				...state,
				wishListItems: state.wishListItems.filter((i) => i.product !== action.payload),
			};
		default:
			return state;
	}
};
