import {
  Menu as ChakraMenu,
  MenuButton,
  Portal,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiMenuKebab } from "react-icons/ci";
import { CSVLink } from "react-csv";
import { read, utils, writeFile } from "xlsx";

import { deleteOrder, listOrders } from "../actions/orderActions";
import Sidebar from "../components/Sidebar";
import "./sidenav.css";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";
import { BiFilter, BiSort } from "react-icons/bi";

export default function OrderListAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders = [] } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderDelete = useSelector((state) => state.orderDelete);
  const { success } = orderDelete;

  const [paidStatus, setPaidStatus] = useState("all");
  const [completionStatus, setCompletionStatus] = useState("all");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    paidStatus === "all" && completionStatus === "all"
      ? orders
          ?.filter((order) => order.type === "order")
          .slice(indexOfFirstItem, indexOfLastItem)
      : orders
          ?.filter(
            (order) =>
              order.type === "order" &&
              (paidStatus === "all" || order.isPaid === paidStatus) &&
              (completionStatus === "all" ||
                order.isDelivered === completionStatus)
          )
          .slice(indexOfFirstItem, indexOfLastItem);

  const [sortFor, setSortFor] = useState("createdAt");
  const [sortTo, setSortTo] = useState(true);

  let csvData = [];

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(sortFor, sortTo));
      dispatch({ type: ORDER_DETAILS_RESET });
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate, success, sortFor, sortTo]);

  const calculateFormatedDate = (date) => {
    const parsedDateTime = new Date(date);

    // Round the seconds portion to the nearest minute
    parsedDateTime.setSeconds(
      Math.round(parsedDateTime.getSeconds() / 60) * 60
    );

    // Format the resulting date and time as an ISO 8601 string
    const formattedDateTime = parsedDateTime
      .toISOString()
      .slice(0, 16)
      .replace("T", " ");
    // const formattedDateTime = parsedDateTime.toLocaleString();
    return formattedDateTime;
  };

  if (orders) {
    // Transforming orders data into CSV format
    // && !order.orderItems?.some((item) => (item.category === "Solo Events" || item.category === "Group Events" || item.category === "Events"))
    csvData = orders
      .filter(
        (order) =>
          order.type === "order" &&
          (paidStatus === "all" || order.isPaid === paidStatus) &&
          (completionStatus === "all" || order.isDelivered === completionStatus)
      )
      .map((order) => {
        const orderItems = order.orderItems
          .map((orderItem) => `${orderItem.name} (${orderItem.qty})`)
          .join(", ");
        const { name, phone, email, postalCode, address, city, country } =
          order.shippingAddress;
        return [
          name,
          email,
          phone,
          `${postalCode},${address},${city},${country}`,
          order.orderItems?.some(
            (item) =>
              item.category === "Solo Events" ||
              item.category === "Group Events" ||
              item.category === "Events"
          )
            ? "Event"
            : "Order",
          order.orderItems?.some(
            (item) =>
              item.category === "Solo Events" ||
              item.category === "Group Events" ||
              item.category === "Events"
          )
            ? calculateFormatedDate(
                order.orderItems?.find(
                  (item) =>
                    item.category === "Solo Events" ||
                    item.category === "Group Events" ||
                    item.category === "Events"
                )?.date
              )
            : order.createdAt.substring(0, 10),
          orderItems,
          order.paymentMethod,
          order.isPaid ? "Paid" : "Unpaid",
          order.isDelivered === 1
            ? "Completed"
            : order.isDelivered === 2
            ? "Cancelled"
            : "Pending",
          order.couponCode,
          order.discount,
          order.totalPrice,
        ];
      });

    csvData.unshift([
      "Name",
      "Email",
      "Phone",
      "Address",
      "Type",
      "Date",
      "Products(Quantity)",
      "Payment Method",
      "is Paid",
      "Status",
      "Coupon",
      "Discount",
      "order Total",
    ]);
  }

  const exportFile = () => {
    /* generate worksheet from state */
    const ws = utils.json_to_sheet(csvData);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFile(wb, "SheetJSReactAoO.xlsx");
  };

  const handleDelete = (id, isDelivered) => {
    if (isDelivered !== 2) {
      if (window.confirm("Are you sure?")) {
        dispatch(deleteOrder(id));
      }
    } else {
      if (window.confirm("Already Cancelled?")) {
      }
    }
  };

  return (
    <div>
      <Sidebar menu="Bestellungen" />
      {/* <!-- CONTENT --> */}
      <section id="content">
        {/* <!-- MAIN --> */}
        <main>
          <div className="table-data drop-shadow-md">
            <div class="order">
              <div class="head">
                <h3>Bestellungen</h3>
                <i class="bx bx-search"></i>
                <i class="bx bx-filter"></i>
                <div className="flex gap-4">
                  <CSVLink data={csvData}>Download CSV</CSVLink>
                  <button onClick={() => exportFile()}>Export to Excel</button>
                </div>
              </div>

              {loading ? (
                <Loader />
              ) : error ? (
                <Message type="error">{error}</Message>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Customer</th>
                      <th
                        onClick={() => {
                          setSortFor("createdAt");
                          setSortTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Date
                          <BiSort />
                        </div>
                      </th>
                      <th>Event</th>
                      <th>Teilnehmer</th>
                      <th>Event Date</th>
                      {/* <th>Order ID</th> */}
                      <th
                        onClick={() => {
                          setSortFor("totalPrice");
                          setSortTo((prevState) => !prevState);
                        }}
                      >
                        <div className="flex items-center cursor-pointer">
                          Price
                          <BiSort />
                        </div>
                      </th>
                      <th>
                        <div className="flex relative peer group">
                          {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                          <div>
                            Paid Status(
                            {paidStatus === true
                              ? "Paid"
                              : paidStatus === false
                              ? "Unpaid"
                              : "All"}
                            )
                          </div>
                          <BiFilter />
                        </div>
                        <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                          <button
                            onClick={() => setPaidStatus("all")}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            All
                          </button>
                          <button
                            onClick={() => setPaidStatus(true)}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            Paid
                          </button>
                          <button
                            onClick={() => setPaidStatus(false)}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            Unpaid
                          </button>
                        </div>
                      </th>
                      <th>
                        <div className="flex relative peer group">
                          {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                          <div>
                            Delivery Status(
                            {completionStatus === 1
                              ? "Completed"
                              : completionStatus === 0
                              ? "Pending"
                              : completionStatus === 2
                              ? "Cancelled"
                              : "All"}
                            )
                          </div>
                          <BiFilter />
                        </div>
                        <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                          <button
                            onClick={() => setCompletionStatus("all")}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            All
                          </button>
                          <button
                            onClick={() => setCompletionStatus(1)}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            Completed
                          </button>
                          <button
                            onClick={() => setCompletionStatus(0)}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            Pending
                          </button>
                          <button
                            onClick={() => setCompletionStatus(2)}
                            className="px-2 py-4 hover:bg-gray-200"
                          >
                            Cancelled
                          </button>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems &&
                      currentItems.map((order) => (
                        <tr key={order._id}>
                          <td>
                            <p className="text-lg font-bold">
                              {order.userName}
                            </p>
                          </td>
                          <td>{order.createdAt.substring(0, 10)}</td>

                          <td>
                            {order.orderItems?.some(
                              (item) =>
                                item.category === "Solo Events" ||
                                item.category === "Group Events" ||
                                item.category === "Events"
                            )
                              ? "Events"
                              : ""}
                          </td>

                          <td className="text-center">
                            {order.orderItems?.find(
                              (item) =>
                                item.category === "Solo Events" ||
                                item.category === "Group Events" ||
                                item.category === "Events"
                            )?.pno || ""}
                          </td>

                          {order.orderItems?.some(
                            (item) =>
                              item.category === "Solo Events" ||
                              item.category === "Group Events" ||
                              item.category === "Events"
                          ) ? (
                            <td>
                              {calculateFormatedDate(
                                order.orderItems?.find(
                                  (item) =>
                                    item.category === "Solo Events" ||
                                    item.category === "Group Events" ||
                                    item.category === "Events"
                                )?.date
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}

                          <td>
                            {order.totalPrice?.toFixed(2).replace(".", ",")}€
                          </td>
                          <td>
                            <span
                              className={
                                order.isPaid
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {order.isPaid ? "Paid" : "Unpaid"}
                            </span>
                          </td>
                          <td>
                            <span
                              className={
                                order.isDelivered !== 2
                                  ? "status completed"
                                  : "status pending"
                              }
                            >
                              {order.isDelivered === 1
                                ? "Completed"
                                : order.isDelivered === 0
                                ? "Pending"
                                : "Cancelled"}
                            </span>
                          </td>
                          <td>
                            <div>
                              <ChakraMenu>
                                <RouterLink>
                                  <MenuButton className="text-sm font-medium leading-6 text-gray-900">
                                    <CiMenuKebab className="h-7 w-7 rotate-90" />
                                  </MenuButton>
                                </RouterLink>
                                <Portal>
                                  <MenuList
                                    className="text-sm font-semibold uppercase leading-6 text-gray-900"
                                    zIndex="99"
                                  >
                                    <MenuItem
                                      as={RouterLink}
                                      to={`/dashboard/order/${order._id}`}
                                    >
                                      VIEW ORDER
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleDelete(
                                          order._id,
                                          order.isDelivered
                                        )
                                      }
                                    >
                                      CANCEL ORDER
                                    </MenuItem>
                                  </MenuList>
                                </Portal>
                              </ChakraMenu>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {currentPage}
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={indexOfLastItem >= orders.length}
                    >
                      Next
                    </button>
                  </div>
                </table>
              )}
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}
