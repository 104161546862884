import React from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useRef } from "react";

import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from "../constants/orderConstants";
import { getOrderDetails, payOrder } from "../actions/orderActions";
import { ImCheckboxChecked } from "react-icons/im";
import { FcCancel } from "react-icons/fc";
import { CiDeliveryTruck } from "react-icons/ci";
import { getPricingEntries } from "../actions/bookingItemActions";

class OrderPrint extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <div className="p-12">
        <Box borderBottom="1px" borderColor="gray.300">
          <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
            Ihre Bestellung
          </Heading>
          <Text>
            Name: <strong>{order.shippingAddress.name}</strong>
          </Text>
          <Text>
            Email:{" "}
            <strong>
              <a href={`mailto:${order.shippingAddress.email}`}>
                {order.shippingAddress.email}
              </a>
            </strong>
          </Text>
          {order.type !== "coupon" && (
            <Text>
              Address:{" "}
              <strong>
                {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.country}
              </strong>
            </Text>
          )}
          {order.type === "coupon" && (
            <>
              <Text>
                Erwachsene: <strong>{order.adults}</strong>
              </Text>
              <Text>
                Kinder: <strong>{order.children}</strong>
              </Text>
            </>
          )}
          <Flex mt="4">
            {order.isDelivered === 1 ? (
              <div className="flex gap-2">
                <div>
                  <Icon
                    as={ImCheckboxChecked}
                    color="green.400"
                    className="rounded-full"
                  />{" "}
                  Abgeschlossen,
                </div>
                <div className="font-semibold">
                  {new Date(order.completedAt).toDateString()}
                </div>
              </div>
            ) : order.isDelivered === 2 ? (
              <div className="flex gap-2">
                <div>
                  <Icon
                    as={FcCancel}
                    color="green.400"
                    className="rounded-full"
                  />{" "}
                  Abgesagt
                </div>
              </div>
            ) : (
              <div>
                <Icon as={CiDeliveryTruck} color="red.400" boxSize="5" /> wird
                bald abgeschlossen sein
              </div>
            )}
          </Flex>
        </Box>
        <Box borderBottom="1px" py="6" borderColor="gray.300">
          <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
            Zahlungsabwicklung
          </Heading>
          <Text>
            <strong>Ihre Zahlungsoptionen: </strong>
            {order.paymentMethod.toUpperCase()}
          </Text>
          <Text mt="4">
            {order.isPaid ? (
              <>Bezahlt {order.paidAt?.substring(0, 10)}</>
            ) : (
              <>Zu zahlen</>
            )}
          </Text>
        </Box>

        <Box borderBottom="1px" py="6" borderColor="gray.300">
          <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
            Ihre Bestelldaten
          </Heading>
          <Box>
            {order.orderItems.length === 0 ? (
              <div>Ihre Bestelldaten</div>
            ) : (
              <Box py="2">
                {order.orderItems.map((item, idx) => (
                  <Flex
                    key={idx}
                    alignItems={order.type === "coupon" ? "start" : "center"}
                    flexDirection={order.type === "coupon" ? "column" : "row"}
                    justifyContent="space-between"
                  >
                    <Flex py="2" alignItems="center">
                      <Text fontWeight="bold" fontSize="xl">
                        {item.name}
                      </Text>
                      {item.category === "Group Events" ? (
                        <Text>Teilnehmer: {item.pno}</Text>
                      ) : (
                        <></>
                      )}
                    </Flex>

                    {order.type === "coupon" ? (
                      <Text fontSize="lg" fontWeight="semibold">
                        {order.adults} x{" "}
                        {item.priceAdult?.toFixed(2).replace(".", ",")}€ +{" "}
                        {order.children} x{" "}
                        {item.priceChild?.toFixed(2).replace(".", ",")}€ ={" "}
                        {(
                          order.adults * item.priceAdult +
                          order.children * item.priceChild
                        )
                          ?.toFixed(2)
                          .replace(".", ",")}
                        €
                      </Text>
                    ) : (
                      <Text fontSize="lg" fontWeight="semibold">
                        {item.qty} x {item.price?.toFixed(2).replace(".", ",")}€
                        ={" "}
                        {(+item.qty * item.price)?.toFixed(2).replace(".", ",")}
                        €
                      </Text>
                    )}
                  </Flex>
                ))}
              </Box>
            )}
          </Box>
        </Box>
        <Box px={{ sm: "12", md: "0" }}>
          <Heading mb="6" as="h2" fontSize="3xl" fontWeight="bold">
            Ihre Gesamtbestellung
          </Heading>

          {/* Items Price */}
          {order.type !== "coupon" && (
            <Flex
              borderBottom="1px"
              py="2"
              borderColor="gray.200"
              alignitems="center"
              justifyContent="space-between"
            >
              <Text fontSize="xl">Produkte</Text>
              <Text fontWeight="bold" fontSize="xl">
                {order.itemsPrice?.toFixed(2).replace(".", ",")}€
              </Text>
            </Flex>
          )}

          {order.shippingPrice && (
            <div className="flex justify-between py-2">
              <p className="text-gray-700">Frachtkosten</p>
              <p className="text-gray-700">
                {order.shippingPrice?.toFixed(2).replace(".", ",")}€
              </p>
            </div>
          )}

          {order.discount !== 0 && (
            <div className="flex justify-between py-2">
              <p className="text-gray-700">Rabatt(%)</p>
              <p className="text-gray-700">{order.discount}%</p>
            </div>
          )}

          {order.couponCode && (
            <div className="flex justify-between py-2">
              <p className="text-gray-700">Gutschein Code</p>
              <p className="text-gray-700">{order.couponCode}</p>
            </div>
          )}

          {/* Total Price */}
          <Flex
            borderTop="1px"
            py="2"
            borderColor="gray.200"
            justifyContent="space-between"
            alignitems="center"
            gap="4"
          >
            <Text fontSize="xl">Summe</Text>
            <Text fontWeight="bold" fontSize="xl">
              {order.totalPrice?.toFixed(2).replace(".", ",")}€
            </Text>
          </Flex>
          <div className="flex justify-between py-2">
            <p className="text-gray-700">Inklusive MwSt</p>
            <p className="text-gray-700">
              {order.taxPrice?.toFixed(2).replace(".", ",")}€
            </p>
          </div>
        </Box>
      </div>
    );
  }
}

const OrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const componentRef = useRef();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const pricing = useSelector((state) => state.pricing);
  const { pricingEntries } = pricing;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    order.itemsPrice = order.orderItems.reduce(
      (acc, currVal) => acc + currVal.price * currVal.qty,
      0
    );
  }

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
    navigate(`/successPay?orders=Success&moid=${orderId}`)
  };

  useEffect(() => {
    dispatch(getPricingEntries());
    dispatch({ type: ORDER_PAY_RESET });
    dispatch({ type: ORDER_DELIVER_RESET });

    if (!order || successPay) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, successPay, order, successDeliver]);

  const printHandler = () => {
    if (componentRef && componentRef.current) {
      componentRef.current.print();
    }
  };

  return loading ? (
    <div>Loading</div>
  ) : // <Loader />
  error ? (
    <div type="error">{error}</div>
  ) : (
    <Flex justifyContent="center">
      {/* <Header /> */}
      <Flex w="6xl" py="5" direction="column">
        <Grid templateColumns={{ sm: "3fr fr", md: "3fr 2fr" }} gap="5">
          {/* Column 1 */}
          <Flex direction="column" shadow="md" p="4">
            {/* Shipping */}
            <Box borderBottom="1px" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Ihre Bestellung
              </Heading>
              <Text>
                Name: <strong>{order.shippingAddress.name}</strong>
              </Text>
              <Text>
                Email:{" "}
                <strong>
                  <a href={`mailto:${order.shippingAddress.email}`}>
                    {order.shippingAddress.email}
                  </a>
                </strong>
              </Text>
              {order.type !== "coupon" && (
                <Text>
                  Address:{" "}
                  <strong>
                    {order.shippingAddress.address},{" "}
                    {order.shippingAddress.city},{" "}
                    {order.shippingAddress.postalCode},{" "}
                    {order.shippingAddress.country}
                  </strong>
                </Text>
              )}
              {order.type === "coupon" && (
                <>
                  <Text>
                    Erwachsene: <strong>{order.adults}</strong>
                  </Text>
                  <Text>
                    Kinder: <strong>{order.children}</strong>
                  </Text>
                </>
              )}
              <Flex mt="4">
                {order.isDelivered === 1 ? (
                  <div className="flex gap-2">
                    <div>
                      <Icon
                        as={ImCheckboxChecked}
                        color="green.400"
                        className="rounded-full"
                      />{" "}
                      Abgeschlossen,
                    </div>
                    <div className="font-semibold">
                      {new Date(order.completedAt).toDateString()}
                    </div>
                  </div>
                ) : order.isDelivered === 2 ? (
                  <div className="flex gap-2">
                    <div>
                      <Icon
                        as={FcCancel}
                        color="green.400"
                        className="rounded-full"
                      />{" "}
                      Abgesagt
                    </div>
                  </div>
                ) : (
                  <div>
                    <Icon as={CiDeliveryTruck} color="red.400" boxSize="5" />{" "}
                    wird bald abgeschlossen sein
                  </div>
                )}
              </Flex>
            </Box>

            {/* Payment Method */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Zahlungsabwicklung
              </Heading>
              <Text>
                Ihre Zahlungsoptionen: <strong>{order.paymentMethod}</strong>
              </Text>
              <Text mt="4">
                {order.isPaid ? (
                  <>Bezahlt {order.paidAt?.substring(0, 10)}</>
                ) : (
                  <>Zu zahlen</>
                )}
              </Text>
            </Box>

            {/* Order Items */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Ihre Bestelldaten
              </Heading>
              <Box>
                {order.orderItems.length === 0 ? (
                  <div>Ihre Bestelldaten</div>
                ) : (
                  <Box py="2">
                    {order.orderItems.map((item, idx) => (
                      <Flex
                        key={idx}
                        alignItems={
                          order.type === "coupon" ? "start" : "center"
                        }
                        flexDirection={
                          order.type === "coupon" ? "column" : "row"
                        }
                        justifyContent="space-between"
                      >
                        <Flex py="2" alignItems="center">
                          <Image
                            src={item.image}
                            alt={item.name}
                            w="12"
                            h="12"
                            objectFit="cover"
                            mr="6"
                          />
                          <Link
                            mr="6"
                            fontWeight="semibold"
                            as={RouterLink}
                            to={`/product/${item.product}`}
                          >
                            {item.name}
                          </Link>
                          {item.category === "Group Events" ? (
                            <Text>Teilnehmer: {item.pno}</Text>
                          ) : (
                            <></>
                          )}
                        </Flex>

                        {order.type === "coupon" ? (
                          <Text fontSize="lg" fontWeight="semibold">
                            {order.adults} x{" "}
                            {item.priceAdult?.toFixed(2).replace(".", ",")}€ +{" "}
                            {order.children} x{" "}
                            {item.priceChild?.toFixed(2).replace(".", ",")}€ ={" "}
                            {(
                              order.adults * item.priceAdult +
                              order.children * item.priceChild
                            )
                              ?.toFixed(2)
                              .replace(".", ",")}
                            €
                          </Text>
                        ) : (
                          <Text fontSize="lg" fontWeight="semibold">
                            {item.qty} x{" "}
                            {item.price?.toFixed(2).replace(".", ",")}€ ={" "}
                            {(+item.qty * item.price)
                              ?.toFixed(2)
                              .replace(".", ",")}
                            €
                          </Text>
                        )}
                      </Flex>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>

          {/* Column 2 */}
          <Flex
            mx={{ sm: "0", md: "4" }}
            direction="column"
            bgColor="white"
            // justifyContent={{ sm: 'start', md: 'space-between' }}
            p={{ sm: "2", md: "8" }}
            shadow="md"
            w="full"
            gap="4"
            rounded="lg"
            borderColor="gray.300"
          >
            <Box px={{ sm: "12", md: "0" }}>
              <Heading mb="6" as="h2" fontSize="3xl" fontWeight="bold">
                Ihre Gesamtbestellung
              </Heading>

              {/* Items Price */}
              {/* {order.type !== 'coupon' && (
								<Flex
									borderBottom='1px'
									py='2'
									borderColor='gray.200'
									alignitems='center'
									justifyContent='space-between'>
									<Text fontSize='xl'>Produkte</Text>
									<Text fontWeight='bold' fontSize='xl'>
										{(order.itemsPrice)?.toFixed(2).replace('.', ',')}€
									</Text>
								</Flex>
							)} */}

              <div className="flex justify-between py-2">
                <p className="text-gray-700">Produkte</p>
                <p className="text-gray-700">
                  {order.itemsPrice?.toFixed(2).replace(".", ",")}€
                </p>
              </div>

              {order.shippingPrice !== 0 && (
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Frachtkosten</p>
                  <p className="text-gray-700">
                    {order.shippingPrice?.toFixed(2).replace(".", ",")}€
                  </p>
                </div>
              )}

              {order.discount !== 0 && (
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Rabatt(%)</p>
                  <p className="text-gray-700">{order.discount}%</p>
                </div>
              )}

              {order.couponCode && (
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Gutschein Code</p>
                  <p className="text-gray-700">{order.couponCode}</p>
                </div>
              )}

              {/* Total Price */}
              <Flex
                borderTop="1px"
                py="2"
                borderColor="gray.200"
                alignitems="center"
                justifyContent="space-between"
              >
                <Text fontSize="xl">Summe</Text>
                <Text fontWeight="bold" fontSize="xl">
                  {order.totalPrice?.toFixed(2).replace(".", ",")}€
                </Text>
              </Flex>
              <div className="flex justify-between py-2">
                <p className="text-gray-700">Inklusive MwSt</p>
                <p className="text-gray-700">
                  {order.taxPrice?.toFixed(2).replace(".", ",")}€
                </p>
              </div>
            </Box>

            {/* PAYMENT BUTTON */}
            {!order.isPaid && (
              <Box>
                {loadingPay ? (
                  <div>Loading</div>
                ) : (
                  // <Loader />
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "AdOtJGzC_gvmx7PD2HQ3qw8s5B1E8FqLQMlMwyDrixthJdW7FX0WdmzbqaumH-954dXb8lOMivXFb66Z",
                      // "Afy5BRK2-IIN4WeQpcIa1JH3l-VJAWOJAiz5rwrjtNsdSdlHJpd7kdaD9zgmJbUmw-miOHOmCV7HDNjD",
                      components: "buttons",
                      currency: "EUR",
                      locale: "de_DE",
                    }}
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={(data, actions) => {
                        if (order.type === "coupon") {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: order.totalPrice?.toFixed(2),
                                },
                              },
                            ],
                          });
                        } else {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: order.totalPrice?.toFixed(2),
                                },
                                shipping: {
                                  address: {
                                    address_line_1:
                                      order?.shippingAddress?.address || "",
                                    address_line_2:
                                      order?.shippingAddress?.city || "",
                                    admin_area_2:
                                      order?.shippingAddress?.country || "",
                                    admin_area_1: "",
                                    postal_code:
                                      order?.shippingAddress?.postalCode || "",
                                    country_code: "DE" || "",
                                  },
                                },
                              },
                            ],
                          });
                        }
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          const paymentResult = {
                            id: details.id,
                            status: details.status,
                            update_time: details.update_time,
                            email_address: details.email_address,
                          };
                          successPaymentHandler(paymentResult);
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                )}
              </Box>
            )}

            {loadingDeliver && <div>Loading</div>}
            {order.isPaid && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    type="button"
                    colorScheme="teal"
                    onClick={printHandler}
                  >
                    Ihre Rechnung ausdrucken
                  </Button>
                )}
                content={() => componentRef.current}
              />
            )}
            <div className="hidden">
              <OrderPrint order={order} ref={componentRef} />
            </div>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default OrderScreen;
