import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { addToWishList, removeFromWishList } from "../actions/wishListActions";
import Header from "../components/Header";
import { Helmet } from "react-helmet";

export default function Wishlist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { id: productId } = useParams();
  let qty = searchParams.get("qty");
  let size = searchParams.get("size");

  const wishList = useSelector((state) => state.wishlist);
  const { wishListItems } = wishList;

  useEffect(() => {
    if (productId) {
      dispatch(addToWishList(qty, size, productId));
    }
  }, [dispatch, productId, qty]);

  const removeFromWishListHandler = (id) => {
    dispatch(removeFromWishList(id));
  };

  const addToCartHandler = (id, qty, size) => {
    navigate();
  };

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col gap-2 max-w-full justify-center pt-32 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]">
      <Helmet>
        <title>Shop - Whishlist</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <div className="p-10 border-t-2 border-white">
        <h1 className="text-xl md:text-3xl lg:text-4xl tracking-tight font-semibold leading-8 lg:leading-9 text-white dark:text-white dark:text-white">
          Wishlist
        </h1>
      </div>
      <div className="mt-4 px-10">
        <p className="text-lg md:text-2xl tracking-tight leading-6 text-white dark:text-white">
          {wishListItems.reduce((acc, currVal) => acc + currVal.qty, 0)} items
        </p>
      </div>
      <div className="mt-4 lg:mt-12 grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-10 lg:gap-y-8">
        {wishListItems.length === 0 ? (
          <div>Your wishlist is empty</div>
        ) : (
          wishListItems.map((item) => (
            <div className="flex flex-col">
              <div className="relative">
                <img className="hidden lg:block" src={item.image} alt="bag" />
                <img
                  className="hidden sm:block lg:hidden"
                  src={item.image}
                  alt="bag"
                />
                <img className="sm:hidden" src={item.image} alt="bag" />
                <button
                  aria-label="close"
                  className="top-4 right-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 absolute p-1.5 bg-gray-800 dark:bg-white dark:text-gray-800 text-white hover:text-gray-400"
                  onClick={() => removeFromWishListHandler(item.product)}
                >
                  <svg
                    className="fil-current"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <Accordion allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <div
                      id="menu1"
                      className="flex flex-col jusitfy-start items-start mt-4"
                    >
                      <div className="mt-2">
                        <p className="tracking-tight text-base font-medium leading-4 text-gray-800 dark:text-white">
                          {item.description}
                        </p>
                      </div>
                      {/* <div className="mt-6">
                                                <p className="tracking-tight text-base font-medium leading-4 text-gray-800 dark:text-white">42 size</p>
                                            </div> */}
                      <div className="mt-6">
                        <p className="tracking-tight text-xl font-medium leading-4 text-gray-800 dark:text-white">
                          ₹{item.price}
                        </p>
                      </div>
                      <div className="flex jusitfy-between flex-col lg:flex-row items-center mt-10 w-full space-y-4 lg:space-y-0 lg:space-x-4 xl:space-x-8">
                        <div className="w-full">
                          <RouterLink to={`/product/${item.product}`}>
                            <button className="focus:outline-none focus:ring-gray-800 focus:ring-offset-2 focus:ring-2 text-white w-full tracking-tight py-4 text-lg leading-4 hover:bg-black bg-gray-800 border border-gray-800 dark:hover:bg-gray-700 dark:hover:text-white">
                              More Information
                            </button>
                          </RouterLink>
                        </div>
                        <div className="w-full">
                          <RouterLink
                            to={`/cart/${item.product}?qty=${item.qty}&size=${item.size}`}
                          >
                            <button className="focus:outline-none focus:ring-gray-800 focus:ring-offset-2 focus:ring-2 text-white w-full tracking-tight py-4 text-lg leading-4 hover:bg-black bg-gray-800 border border-gray-800 dark:hover:bg-gray-700 dark:hover:text-white">
                              Add to cart
                            </button>
                          </RouterLink>
                        </div>
                      </div>
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
          ))
        )}
      </div>

      <div className="flex justify-center w-full px-4 my-10 lg:mb-0 border-b-2 border-white">
        <button
          className="flex items-center m-4 justify-center w-full md:w-1/5 p-2 md:p-4 text-blue-500 border border-blue-500 rounded-md dark:text-gray-200 dark:border-blue-600 hover:bg-blue-600 hover:border-blue-600 hover:text-gray-100 dark:bg-blue-600 dark:hover:bg-blue-700 dark:hover:border-blue-700 dark:hover:text-gray-300"
          onClick={handleContinue}
        >
          Continue Shopping
        </button>
      </div>
    </div>
  );
}
