import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import './sidenav.css'
import Loader from '../components/Loader';
import Message from '../components/Message';
import { FAQ_UPDATE_RESET } from '../constants/supportFaqConstants';
import { listFaqDetails, updateFaq } from '../actions/supportFaqActions';

const FaqForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id: faqId } = useParams();

    const faqDetails = useSelector((state) => state.faqDetails);
    const { loading, error , faq } = faqDetails;

    const faqUpdate = useSelector((state) => state.faqUpdate);
    const {
        success: successUpdate,
        error: errorUpdate,
        loading: loadingUpdate
    } = faqUpdate;


    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: FAQ_UPDATE_RESET });
            dispatch(listFaqDetails(faqId));
            navigate(`/dashboard/support`);
        } else {
            if (faq?.question || faq?._id !== faqId) {
                dispatch(listFaqDetails(faqId));
            } else {
                setQuestion(faq.question);
                setAnswer(faq.answer);
            }
        }
    }, [dispatch, navigate, faqId, faq, successUpdate]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            updateFaq({
                _id: faqId,
                question,
                answer
            })
        );
    };

    return (

        <div>
            <Sidebar menu='Support' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <h2 className="text-2xl font-semibold mb-4">Add/Edit Faq</h2>

                    {loadingUpdate && <Loader />}
                    {errorUpdate && <Message type='error'>{errorUpdate}</Message>}
                    <div className="container mx-auto mt-8">
                        <form onSubmit={submitHandler} className=''>
                            <div className='flex flex-col gap-4'> 
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="mb-4">
                                        <label htmlFor="question" className="block font-medium text-sm mb-1">
                                            Question
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            title='Please Enter Question'
                                            id="question"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="answer" className="block font-medium text-sm mb-1">
                                            Answer
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            title='Please Enter Answer'
                                            id="answer"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={answer}
                                            onChange={(e) => setAnswer(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </main>
            </section>
        </div>
    );
};

export default FaqForm;