import { LockClosedIcon } from '@heroicons/react/20/solid'
import { Link as RouterLink, redirect, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import { forgotPassword as forgotpass } from '../actions/userActions'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';

export default function ForgotPass() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [searchParams] = useSearchParams();
    let redirect = searchParams.get('redirect') || '/';

    const [email, setEmail] = useState('');

    const forgotPassword = useSelector((state) => state.forgotPassword);
    const { loading: loadingOtp, successOTP, error } = forgotPassword;

    useEffect(() => {
        if (successOTP) {
            navigate('/otp?type=forgotpass')
        }
    }, [redirect, successOTP]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(forgotpass(email));
    };

    return (
        <div className="flex min-h-full items-center justify-center px-4 py-12 border-b-2 border-white sm:px-6 lg:px-8 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]">
            <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-white">
                        Geben Sie Ihre E-Mail-Adresse ein, um OTP zu erhalten
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="flex flex-col gap-4 -space-y-px rounded-md shadow-sm">
                        <div>
                            <label htmlFor="email-address" className="text-white">
                                Email
                            </label>
                            <Input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                bg='white'
                                placeholder=" Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                            </span>
                            Send
                        </button>
                    </div>
                    <div className="flex justify-center text-sm">
                        <RouterLink to="/registration" className="font-medium text-indigo-600 hover:text-indigo-500">
                            New User? Register
                        </RouterLink>
                    </div>
                </form>
                {error && <div>{error}</div>}
            </div>
        </div>
    )
}
