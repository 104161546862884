import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { serverIp } from '../config/conf';
import * as types from '../constants/bookingConstants';
import axios from 'axios';

// Add a booking
export const addBooking = (bookingData) => async (dispatch) => {
    try {
        dispatch({ type: types.ADD_BOOKING_REQUEST });

        const { data } = await axios.post(`${serverIp}/api/bookings`, bookingData);

        dispatch({
            type: types.ADD_BOOKING_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.ADD_BOOKING_FAILURE,
            payload: error.response.data.error,
        });
    }
};

// Get a booking by ID
export const getBookingById = (bookingId) => async (dispatch) => {
    try {
        dispatch({ type: types.GET_BOOKING_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/bookings/${bookingId}`);

        dispatch({
            type: types.GET_BOOKING_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_BOOKING_FAILURE,
            payload: error.response.data.error,
        });
    }
};

// Update a booking to paid
export const updateBookingToPaid = (bookingId, paymentResult) => async (dispatch) => {
    try {
        dispatch({ type: types.UPDATE_BOOKING_TO_PAID_REQUEST });

        const { data } = await axios.put(`${serverIp}/api/bookings/${bookingId}/pay`, paymentResult);

        dispatch({
            type: types.UPDATE_BOOKING_TO_PAID_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.UPDATE_BOOKING_TO_PAID_FAILURE,
            payload: error.response.data.error,
        });
    }
};

// Get logged in user's bookings
export const getMyBookings = () => async (dispatch, getState) => {
    try {
        dispatch({ type: types.GET_MY_BOOKINGS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`${serverIp}/api/bookings/mybookings`, config);

        dispatch({
            type: types.GET_MY_BOOKINGS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_MY_BOOKINGS_FAILURE,
            payload: error.response.data.error,
        });
    }
};

// Get all bookings
export const getBookings = (sortFor, sortTo) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.GET_BOOKINGS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`${serverIp}/api/bookings?sortFor=${sortFor}&sortTo=${sortTo}`, config);

        dispatch({
            type: types.GET_BOOKINGS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_BOOKINGS_FAILURE,
            payload: error.response.data.error,
        });
    }
};

export const deleteBooking = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.BOOKING_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/bookings/${id}`, config);

		dispatch({ type: types.BOOKING_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: types.BOOKING_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

// Update a booking to completed
export const updateBookingToCompleted = (bookingId) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.UPDATE_BOOKING_TO_COMPLETED_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`${serverIp}/api/bookings/${bookingId}/complete`, {}, config);

        dispatch({
            type: types.UPDATE_BOOKING_TO_COMPLETED_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.UPDATE_BOOKING_TO_COMPLETED_FAILURE,
            payload: error.response.data.error,
        });
    }
};

export const getBookingStats = (startDate, endDate, state, sortFor, sortTo) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.GET_BOOKING_COUNT_AND_SALES_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`${serverIp}/api/bookings/stats?startDate=${startDate}&endDate=${endDate}&state=${state}&sortFor=${sortFor}&sortTo=${sortTo}`, config);

        dispatch({
            type: types.GET_BOOKING_COUNT_AND_SALES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_BOOKING_COUNT_AND_SALES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const updateBookingMenuItems = (bookingId, menuItems) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.UPDATE_MENU_ITEMS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`${serverIp}/api/bookings/${bookingId}/menuitems`, { menuItems }, config);

        dispatch({
            type: types.UPDATE_MENU_ITEMS_SUCCESS,
            payload: data,
        });

        toast.success('Menu items updated successfully!');
    } catch (error) {
        dispatch({
            type: types.UPDATE_MENU_ITEMS_FAILURE,
            payload: error.response.data.error,
        });
    }
};