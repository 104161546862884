import {
	FAQ_CREATE_FAIL,
	FAQ_CREATE_REQUEST,
	FAQ_CREATE_RESET,
	FAQ_CREATE_SUCCESS,
	FAQ_DELETE_FAIL,
	FAQ_DELETE_REQUEST,
	FAQ_DELETE_SUCCESS,
	FAQ_DETAILS_FAIL,
	FAQ_DETAILS_REQUEST,
	FAQ_DETAILS_SUCCESS,
	FAQ_LIST_FAIL,
	FAQ_LIST_REQUEST,
	FAQ_LIST_SUCCESS,
	FAQ_UPDATE_FAIL,
	FAQ_UPDATE_REQUEST,
	FAQ_UPDATE_RESET,
	FAQ_UPDATE_SUCCESS,
    SUPPORT_DETAILS_FAIL,
    SUPPORT_DETAILS_REQUEST,
    SUPPORT_DETAILS_SUCCESS,
    SUPPORT_UPDATE_FAIL,
    SUPPORT_UPDATE_REQUEST,
    SUPPORT_UPDATE_RESET,
    SUPPORT_UPDATE_SUCCESS
} from '../constants/supportFaqConstants';

export const supportDetailsReducer = (state = { support: {} }, action) => {
	switch (action.type) {
		case SUPPORT_DETAILS_REQUEST:
			return { ...state, loading: true };
		case SUPPORT_DETAILS_SUCCESS:
			return { loading: false, support: action.payload };
		case SUPPORT_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const supportUpdateReducer = (state = { support: {} }, action) => {
	switch (action.type) {
		case SUPPORT_UPDATE_REQUEST:
			return { ...state, loading: true };
		case SUPPORT_UPDATE_SUCCESS:
			return { loading: false, success: true, support: action.payload };
		case SUPPORT_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case SUPPORT_UPDATE_RESET:
			return { support: {} };
		default:
			return state;
	}
};

export const faqListReducer = (state = { faqs: [] }, action) => {
	switch (action.type) {
		case FAQ_LIST_REQUEST:
			return { loading: true, faqs: [] };
		case FAQ_LIST_SUCCESS:
			return { loading: false, faqs: action.payload };
		case FAQ_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const faqDetailsReducer = (state = { faq: {} }, action) => {
	switch (action.type) {
		case FAQ_DETAILS_REQUEST:
			return { ...state, loading: true };
		case FAQ_DETAILS_SUCCESS:
			return { loading: false, faq: action.payload };
		case FAQ_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const faqDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case FAQ_DELETE_REQUEST:
			return { loading: true };
		case FAQ_DELETE_SUCCESS:
			return { loading: false, success: true };
		case FAQ_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const faqCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case FAQ_CREATE_REQUEST:
			return { loading: true };
		case FAQ_CREATE_SUCCESS:
			return { loading: false, success: true, faq: action.payload };
		case FAQ_CREATE_FAIL:
			return { loading: false, error: action.payload };
		case FAQ_CREATE_RESET:
			return {};
		default:
			return state;
	}
};

export const faqUpdateReducer = (state = { faq: {} }, action) => {
	switch (action.type) {
		case FAQ_UPDATE_REQUEST:
			return { ...state, loading: true };
		case FAQ_UPDATE_SUCCESS:
			return { loading: false, success: true, faq: action.payload };
		case FAQ_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case FAQ_UPDATE_RESET:
			return { faq: {} };
		default:
			return state;
	}
};