import {
    Menu as ChakraMenu,
    MenuButton,
    Portal,
    MenuList,
    MenuItem,
    Button
} from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiMenuKebab } from 'react-icons/ci';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import Sidebar from "../components/Sidebar"
import './sidenav.css'
import Loader from "../components/Loader";
import Message from "../components/Message";
import { addDisabledDate, deleteDisabledDate, getDisabledDates } from '../actions/disabledDatesAction';
import { IoCalendarOutline } from 'react-icons/io5';
import { format } from 'date-fns';
import { ToastContainer } from 'react-toastify';

export default function DisabledDateListAdmin() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openDate, setOpenDate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [date, setDate] = useState(null);
    const itemsPerPage = 10;

    const disabledDate = useSelector((state) => state.disabledDate);
    const { disabledDates, loading, error, successAdd, successDelete } = disabledDate;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = disabledDates?.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (userInfo && userInfo.isAdmin) {
            dispatch(getDisabledDates());
        } else {
            navigate('/login');
        }
    }, [dispatch, userInfo, navigate, successAdd, successDelete]);

    const handleDateDisable = () => {
        const adjustedStartDate = new Date(date);
        adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
        dispatch(addDisabledDate(adjustedStartDate))
    }

    const handleDelete = (id) => {
        dispatch(deleteDisabledDate(id));
    }

    return (
        <div>
            <Sidebar menu='Deaktivierte Termine' />
            {/* <!-- CONTENT --> */}
            <section id="content">

                {/* <!-- MAIN --> */}
                <main>
                    <div className="table-data drop-shadow-md">
                        <div class="order">
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
                            <div class="head">
                                <div className="flex items-center gap-2">
                                    <IoCalendarOutline className="headerIcon cursor-pointer" onClick={() => setOpenDate(!openDate)} />
                                    <span
                                        onClick={() => setOpenDate(!openDate)}
                                        className="headerSearchText"
                                    >{`${format(new Date(date), "MM/dd/yyyy")}`}</span>
                                    <Button colorScheme='red' onClick={handleDateDisable}>Disable</Button>
                                    {openDate && (
                                        <Calendar onChange={item => {
                                            console.log(item)
                                            setDate(item);
                                            setOpenDate(!openDate);
                                        }}
                                            date={date} minDate={new Date()} className="date"
                                            disabledDates={disabledDates?.map((dateObj) => {
                                                return new Date(new Date(dateObj.date).toISOString().split('T')[0]);
                                            })}
                                        />
                                    )}
                                </div>
                                <h3>Deaktivierte Termine</h3>
                            </div>

                            {loading ? (
                                <Loader />
                            ) : error ? (
                                <Message type='error'>{error}</Message>
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems && currentItems.map((disabled) => (
                                            <tr key={disabled._id}>
                                                <td>{disabled._id}</td>
                                                <td>{disabled.date.substring(0, 10)}</td>
                                                <td><span className={'status pending'}>Disabled</span></td>
                                                <td>
                                                    <div>
                                                        <ChakraMenu>
                                                            <RouterLink>
                                                                <MenuButton className="text-sm font-medium leading-6 text-gray-900"><CiMenuKebab className='h-7 w-7 rotate-90' /></MenuButton>
                                                            </RouterLink>
                                                            <Portal>
                                                                <MenuList className="text-sm font-semibold uppercase leading-6 text-gray-900" zIndex='99'>
                                                                    <MenuItem onClick={() => handleDelete(disabled._id)}>Delete</MenuItem>
                                                                </MenuList>
                                                            </Portal>
                                                        </ChakraMenu>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <div className='flex gap-2'>
                                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                        {currentPage}
                                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastItem >= disabledDates.length}>Next</button>
                                    </div>
                                </table>
                            )}
                        </div>
                    </div>
                </main>
            </section>
        </div>
    )
}