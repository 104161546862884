import { useDispatch, useSelector } from "react-redux";
import Category from "../components/Category";
import Hero from "../components/Hero";
import PromoProductCard from "../components/PromoProductList";
import "./style.css";
import { listBestProducts, listProducts } from "../actions/productActions";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Home() {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingFruits,
    products: fruits,
    error: errorFruits,
  } = productList;

  const bestProductList = useSelector((state) => state.bestProductList);
  const {
    loading: loadingFeatured,
    products: featured,
    error: errorFeatured,
  } = bestProductList;

  useEffect(() => {
    dispatch(listProducts("all", "all", "all", "1", "all"));
    dispatch(listBestProducts("all"));
  }, [dispatch]);

  return (
    <div className="bg-white">
      {/* <Header /> */}
      <Helmet>
        <title>Shop - Weinhaus Schowalter</title>
        <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        />
      </Helmet>

      <Hero />

      <Category />

      <PromoProductCard
        name={"Neueste Weine"}
        products={fruits?.slice(0, 10)}
        sts={fruits?.length}
        loading={loadingFruits}
        error={errorFruits}
      />

      <PromoProductCard
        name={"Angebote & Specials"}
        products={featured?.slice(0, 10)}
        sts={featured?.length}
        loading={loadingFeatured}
        error={errorFeatured}
      />
    </div>
  );
}
