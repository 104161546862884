import {
	USER_DELETE_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_RESET,
	USER_DETAILS_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_RESET,
	USER_LIST_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_FAIL,
	USER_REGISTER_REQUEST,
	USER_REGISTER_RESET,
	USER_REGISTER_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_PROFILE_FAIL,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_RESET,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_UPDATE_REQUEST,
	USER_UPDATE_RESET,
	USER_UPDATE_SUCCESS,
	USER_OTP_VALIDATE_FAIL,
	USER_OTP_VALIDATE_SUCCESS,
	USER_OTP_VALIDATE_REQUEST,
	USER_OTP_VALIDATE_RESET,
	USER_SENT_MAIL_REQUEST,
	USER_SENT_MAIL_SUCCESS,
	USER_SENT_MAIL_FAIL,
	USER_SENT_MAIL_RESET,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAILURE,
	FORGOT_PASSWORD_RESET,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAILURE,
	RESET_PASSWORD
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true };
		case USER_LOGIN_SUCCESS:
			return { loading: false, userInfo: action.payload };
		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload };
		case USER_LOGOUT:
			return {};
		default:
			return state;
	}
};

export const userRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REGISTER_REQUEST:
			return { loading: true };
		case USER_REGISTER_SUCCESS:
			return { loading: false, userInfo: action.payload };
		case USER_REGISTER_FAIL:
			return { loading: false, error: action.payload };
		case USER_REGISTER_RESET:
			return {};
		default:
			return state;
	}
};

export const userOtpValidateReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_OTP_VALIDATE_REQUEST:
			return { loading: true };
		case USER_OTP_VALIDATE_SUCCESS:
			return { loading: false, success: true };
		case USER_OTP_VALIDATE_FAIL:
			return { loading: false, error: action.payload };
		case USER_OTP_VALIDATE_RESET:
			return {};
		default:
			return state;
	}
};

export const userDetailsReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_DETAILS_REQUEST:
			return { ...state, loading: true };
		case USER_DETAILS_SUCCESS:
			return { loading: false, user: action.payload };
		case USER_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		case USER_DETAILS_RESET:
			return { user: {} };
		default:
			return state;
	}
};

export const userUpdateProfileReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_PROFILE_REQUEST:
			return { loading: true };
		case USER_UPDATE_PROFILE_SUCCESS:
			return { loading: false, user: action.payload, success: true };
		case USER_UPDATE_PROFILE_FAIL:
			return { loading: false, error: action.payload };
		case USER_UPDATE_PROFILE_RESET:
			return { user: {} };
		default:
			return state;
	}
};

export const userListReducer = (state = { users: [] }, action) => {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loading: true };
		case USER_LIST_SUCCESS:
			return { loading: false, users: action.payload };
		case USER_LIST_FAIL:
			return { loading: false, error: action.payload };
		case USER_LIST_RESET:
			return { users: [] };
		default:
			return state;
	}
};

export const userDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_DELETE_REQUEST:
			return { loading: true };
		case USER_DELETE_SUCCESS:
			return { loading: false, success: true };
		case USER_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const userUpdateReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_REQUEST:
			return { ...state, loading: true };
		case USER_UPDATE_SUCCESS:
			return { loading: false, user: action.payload, success: true };
		case USER_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case USER_UPDATE_RESET:
			return { user: {} };
		default:
			return state;
	}
};

export const userSentMailReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_SENT_MAIL_REQUEST:
			return { loading: true };
		case USER_SENT_MAIL_SUCCESS:
			return { loading: false, success: true };
		case USER_SENT_MAIL_FAIL:
			return { loading: false, error: action.payload };
		case USER_SENT_MAIL_RESET:
			return {};
		default:
			return state;
	}
};

const initialState = {
	loading: false,
	successOTP: false,
	email: '',
	message: '',
	error: null,
};

export const forgotPasswordReducer = (state = initialState, action) => {
	switch (action.type) {
		case FORGOT_PASSWORD_REQUEST:
			return {
				...state,
				loading: true,
				successOTP: false,
				email: '',
				message: '',
				error: null,
			};
		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				successOTP: true,
				email: action.payload.email,
				message: action.payload.message,
				error: null,
			};
		case FORGOT_PASSWORD_FAILURE:
			return {
				...state,
				loading: false,
				successOTP: false,
				email: '',
				message: '',
				error: action.payload.error,
			};
		case FORGOT_PASSWORD_RESET:
			return {};
		default:
			return state;
	}
};

const initialState2 = {
	loading: false,
	success: false,
	error: null,
};

export const resetPasswordReducer = (state = initialState2, action) => {
	switch (action.type) {
		case RESET_PASSWORD_REQUEST:
			return {
				...state,
				loading: true,
				success: false,
				error: null,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				error: null,
			};
		case RESET_PASSWORD_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				error: action.payload.error,
			};
		case RESET_PASSWORD:
			return {}
		default:
			return state;
	}
};