import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { createOrder } from "../actions/orderActions";
import CheckoutSteps from "../components/CheckoutSteps";
import Header from "../components/Header";
import { getPricingEntries } from "../actions/bookingItemActions";
import { addBooking } from "../actions/bookingActions";
import { ADD_BOOKING_RESET } from "../constants/bookingConstants";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { checkCoupon } from "../actions/couponActions";
import { CHECK_COUPON_RESET } from "../constants/couponConstants";
import {
  BOOKING_ITEM_RESET,
  RESET_BOOKING_DATE_OPTIONS,
} from "../constants/bookingItemConstants";
import { Helmet } from "react-helmet";
// import Message from '../components/Message';

const PlaceBookingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const booking = useSelector((state) => state.booking);
  const { bookingMenuItems } = booking;

  const pricing = useSelector((state) => state.pricing);
  const { pricingEntries } = pricing;

  const bookingOption = useSelector((state) => state.bookingOption);
  const { date, options } = bookingOption;

  const [perNightRent, setPerNightRent] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [code, setCode] = useState("");

  useEffect(() => {
    dispatch(getPricingEntries());
    dispatch({ type: CHECK_COUPON_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (isValid) {
      calculateDiscountedPrice(discount);
    }
  }, [dispatch, isValid, discount]);

  const calculateDiscountedPrice = (discount) => {
    const discountPrice = perNightRent - perNightRent * (discount / 100);
    console.log(discountPrice);
    setDiscountedPrice(discountPrice);
    booking.discountedPrice = discountPrice;
    booking.discount = discount;
  };

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code));
    booking.couponCode = code;
  };

  useEffect(() => {
    calculateTotalPrice(bookingMenuItems, pricingEntries, date, options);
  }, [dispatch, bookingMenuItems, pricingEntries, date, options]);

  const calculateTotalPrice = (
    bookingMenuItems,
    pricingEntries,
    date,
    options
  ) => {
    // Calculate number of nights
    const start = new Date(date[0].startDate);
    const end = new Date(date[0].endDate);
    const adult = options.adult;
    const child = options.children;
    const numberOfNights = Math.floor((end - start) / (1000 * 60 * 60 * 24));

    // Get the per day price from pricingEntries
    const perDayPrice =
      pricingEntries.length > 0 ? pricingEntries[0].perDayPrice : 0;
    const campingTax =
      pricingEntries.length > 0 ? pricingEntries[0].campingTax : 0;

    // Calculate the base price for the booking
    const basePrice = perDayPrice * numberOfNights;
    setPerNightRent(basePrice);
    setDiscountedPrice(basePrice);
    booking.perNightRent = basePrice;
    booking.discountedPrice = basePrice;
    booking.campingTax = campingTax;

    // Calculate total adult price
    const totalAdultPrice = bookingMenuItems.reduce((total, item) => {
      const itemPrice = item.priceAdult * adult;
      return total + itemPrice;
    }, 0);

    // Calculate total child price
    const totalChildPrice = bookingMenuItems.reduce((total, item) => {
      const itemPrice = item.priceChild * child;
      return total + itemPrice;
    }, 0);

    // Calculate total group price
    const totalGroupPrice = bookingMenuItems.reduce((total, item) => {
      const itemPrice = item.priceGroup * 1;
      return total + itemPrice;
    }, 0);
    const menuTotal = totalAdultPrice + totalChildPrice + totalGroupPrice;
    booking.menuTotal = menuTotal;

    // Calculate overall total price
    const totalPrice =
      basePrice + totalAdultPrice + totalChildPrice + totalGroupPrice;
    booking.totalPrice = totalPrice;

    return totalPrice;
  };

  const bookingCreate = useSelector((state) => state.bookingCreate);
  const { booking: createdBooking, success, error } = bookingCreate;

  const placeBookingHandler = () => {
    dispatch(
      addBooking({
        user: userInfo?._id,
        userName: booking.shippingAddress.name,
        userEmail: booking.shippingAddress.email,
        userContact: booking.shippingAddress.phone,
        startDate: date[0].startDate,
        endDate: date[0].endDate,
        adults: options.adult,
        children: options.children,
        menuItems: booking.bookingMenuItems,
        paymentMethod: booking.paymentMethod,
        totalBookingPrice: booking.perNightRent,
        totalMenuPrice: booking.menuTotal,
        totalPrice: booking.totalPrice,
        couponCode: booking.couponCode,
        discount: discount,
        discountedPrice: booking.discountedPrice,
      })
    );
  };

  useEffect(() => {
    if (success) {
      navigate(`/booking/${createdBooking._id}`);
      dispatch({ type: ADD_BOOKING_RESET });
      dispatch({ type: BOOKING_ITEM_RESET });
      dispatch({ type: RESET_BOOKING_DATE_OPTIONS });
    }
  }, [success, navigate, createdBooking]);

  return (
    <Flex justifyContent="center">
      <Helmet>
        <title>Shop - Place Bookings</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      {/* <Header /> */}
      <Flex w="6xl" direction="column" pb="4" px="8">
        {/* <CheckoutSteps step2 step3 step4 /> */}

        <Grid templateColumns={{ sm: "3fr fr", md: "3fr 2fr" }} gap="20">
          {/* Column 1 */}
          <Flex direction="column">
            {/* Shipping */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Buchung
              </Heading>
              <Text>
                <strong>Customer: </strong>
                {booking.shippingAddress.name}, {booking.shippingAddress.phone},{" "}
                {booking.shippingAddress.email}
              </Text>
            </Box>

            {/* Payment Method */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Zahlungsabwicklung
              </Heading>
              <Text>
                <strong>Ihre Zahlungsoptionen: </strong>
                {booking.paymentMethod}
              </Text>
            </Box>

            {/* Order Items */}
            <Box borderBottom="1px" py="6" borderColor="gray.300">
              <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                Wunschliste (möchte gerne vor Ort haben)
              </Heading>
              <Box>
                {booking.bookingMenuItems.length === 0 ? (
                  <div>Ihr Warenkorb ist leer</div>
                ) : (
                  <Box py="2">
                    {booking.bookingMenuItems.map((item, idx) => (
                      <Flex
                        key={idx}
                        alignItems="center"
                        justifyContent="space-between"
                        gap="4"
                      >
                        <Flex py="2" alignItems="center">
                          <Image
                            src={item.image}
                            alt={item.name}
                            w="12"
                            h="12"
                            objectFit="cover"
                            mr="6"
                          />
                          <Text fontWeight="semibold" fontSize="lg">
                            {item.name}
                          </Text>
                        </Flex>

                        <Flex>
                          <Text fontSize="lg" fontWeight="semibold">
                            {/* {options.adult} x {item.priceAdult}€ + {options.children} x {item.priceChild}€ + {options.adult + options.children} x {item.priceGroup}€ = */}
                            {(
                              +options.adult * item.priceAdult +
                              +options.children * item.priceChild +
                              1 * item.priceGroup
                            )
                              ?.toFixed(2)
                              .replace(".", ",")}
                            €
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Box>
                )}
              </Box>
              {booking?.menuTotal && (
                <Flex
                  borderBottom="1px"
                  py="2"
                  borderColor="gray.200"
                  alignitems="center"
                  justifyContent="space-between"
                >
                  <Flex direction="column" gap={1} w="80%">
                    <Text fontWeight="bold" fontSize="xl">
                      Gesamtpreis Ihrer Wunschliste
                    </Text>
                    <Text fontSize="sm" color="red">
                      (* Bitte beachten Sie: Dies ist eine Wunschliste und noch
                      nicht bestätigt, daher zahlen Sie heute nur den Stellplatz
                      und Ihre Wünsche dann bei uns vor Ort.)
                    </Text>
                    <Text fontSize="sm" color="red">{`*
                                        Wichtiger Hinweis zu Stornierungen von Buchungen für Camping und unseren Events: Bitte beachten Sie, dass wir für Sie stets einen perfekten Aufenthalt planen und das natürlich auch mit Planung und Bereitstellung von Ressourcen verbunden ist. Daher gelten für Stornierungen die folgenden Bedingungen und wir bitten um Ihr Verständnis.`}</Text>

                    <Text fontSize="sm" color="red">{`*
                                        Terminverschiebungen sind stets für Sie völlig kostenfrei. Für Stornierungen gelten die folgenden Bedingungen. - A) Bis 14 Tage vor dem Anreisetermin ist die Stornierung kostenfrei möglich. - B) Bis 7 Tage vor dem Anreisetermin erstatten wir Ihnen 50% der gezahlten Buchungskosten. - Bis 3 Tage vor dem Anreisetermin erstatten wir 20% der gezahlten Buchungskosten.
                                    `}</Text>
                  </Flex>
                  <Text fontWeight="bold" fontSize="xl">
                    {booking.menuTotal?.toFixed(2).replace(".", ",")}€
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>

          {/* Column 2 */}
          <Flex
            direction="column"
            bgColor="white"
            justifyContent="space-between"
            py="8"
            px="8"
            shadow="md"
            rounded="lg"
            borderColor="gray.300"
          >
            <Box>
              <Heading mb="6" as="h2" fontSize="3xl" fontWeight="bold">
                Ihre Stellplatzbuchung
              </Heading>

              <div className="flex justify-between">
                <p className="text-gray-700">Ihre Reisedaten</p>
                <p className="text-gray-700">
                  Von {new Date(date[0].startDate).toISOString().split("T")[0]}{" "}
                  Bis {new Date(date[0].endDate).toISOString().split("T")[0]}
                </p>
              </div>

              <div className="flex justify-between">
                <p className="text-gray-700">Erwachsene</p>
                <p className="text-gray-700">{options.adult}</p>
              </div>

              <div className="flex justify-between">
                <p className="text-gray-700">Kinder</p>
                <p className="text-gray-700">{options.children}</p>
              </div>

              <div className="flex justify-between">
                <p className="text-gray-700">
                  Stellplatz Übernachtung x (
                  {Math.floor(
                    (new Date(date[0].endDate) - new Date(date[0].startDate)) /
                      (1000 * 60 * 60 * 24)
                  )}
                  )
                </p>
                <p className="text-gray-700">
                  {booking.perNightRent?.toFixed(2).replace(".", ",")}€
                </p>
              </div>

              <div className="flex justify-between">
                <p className="text-gray-700">Rabatt(%)</p>
                <p className="text-gray-700">{discount}%</p>
              </div>

              {isValid && (
                <div className="flex justify-between">
                  <p className="text-gray-700">Gutschein Code</p>
                  <p className="text-gray-700">{booking.couponCode}</p>
                </div>
              )}

              {/* Total Price */}
              <Flex
                borderTop="1px"
                py="2"
                borderColor="gray.200"
                alignitems="center"
                justifyContent="space-between"
              >
                <Text fontSize="xl">Gesamter Betrag</Text>
                <Text fontWeight="bold" fontSize="xl">
                  {booking.discountedPrice?.toFixed(2).replace(".", ",")}€
                </Text>
              </Flex>
              <div className="flex justify-between py-2">
                <p className="text-gray-700">
                  Inklusive MwSt({pricingEntries[0]?.campingTax}%)
                </p>
                <p className="text-gray-700">
                  {(booking.perNightRent * (booking.campingTax / 100))
                    ?.toFixed(2)
                    .replace(".", ",")}
                  €
                </p>
              </div>
            </Box>

            {error && <Message type="error">{error}</Message>}
            {userInfo && (
              <div className="mt-8">
                <form onSubmit={checkCouponHandler}>
                  <p className="text-lg">Gutschein Code</p>
                  <input
                    type="text"
                    id="code"
                    required
                    className="w-2/3 border border-black rounded px-3 py-2"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="w-1/3 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                  >
                    Apply
                  </button>
                </form>
                {loading && <Loader />}
                {errorCoupon && <Message type="error">{errorCoupon}</Message>}
              </div>
            )}

            <Button
              size="lg"
              textTransform="uppercase"
              colorScheme="yellow"
              type="button"
              w="full"
              onClick={placeBookingHandler}
              disabled={booking.bookingMenuItems === 0}
            >
              Stellplatz jetzt buchen
            </Button>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default PlaceBookingScreen;
