import { Link as RouterLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import './sidenav.css'
import { Button, Icon } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { createCategory, listCategory } from "../actions/categoryActions";
import { CATEGORY_CREATE_RESET } from "../constants/categoryConstants";

export default function CategoryForm() {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const { id } = useParams();
	console.log(id);

	const [name, setName] = useState('');
	const [parentCategory, setParentCategory] = useState(id || null);
	const [type, setType] = useState(searchParams.get('type'))

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const categoryCreate = useSelector((state) => state.categoryCreate);
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		category: mainCategory,
	} = categoryCreate;

	useEffect(() => {
		if (!userInfo.isAdmin) {
			navigate('/login');
		}

		if (successCreate) {
			dispatch({ type: CATEGORY_CREATE_RESET });
			navigate(`/dashboard/category`);
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		successCreate,
		mainCategory,
	]);

	useEffect(() => {
		if (id) {
			setParentCategory(id);
		} else {
			setParentCategory(null);
		}
	}, [id]);

	const createCategoryHandler = (e) => {
		e.preventDefault();
		dispatch(createCategory(name, parentCategory, type))
	};

	return (
		<div>
			<Sidebar menu='Category' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>
					<div className="flex min-h-full border-b items-center justify-center px-4 sm:px-6 lg:px-8">
						<div className="w-full max-w-md space-y-8">
							<div>
								<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
									{id ? 'Add Sub Category' : 'Add New category'}
								</h2>
							</div>
							<form className="mt-8 space-y-6" onSubmit={createCategoryHandler}>
								<input type="hidden" name="remember" defaultValue="true" />
								<div className="flex flex-col gap-4 -space-y-px rounded-md shadow-sm">
									<div>
										<label htmlFor="name" className="">
											Name
										</label>
										<input
											id="name"
											name="name"
											type="text"
											autoComplete="name"
											required
											className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											placeholder=" Name"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
									<div>
										<label htmlFor="type" className="">
											Type
										</label>
										<select
											className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											value={type} onChange={(e) => setType(e.target.value)}>
											<option key='1' value='category'>Category</option>
											<option key='2' value='color'>Color</option>
											<option key='3' value='taste'>Taste</option>
										</select>
									</div>
								</div>
								<div>
									<button
										type="submit"
										className="group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Create
									</button>
								</div>
							</form>
							{errorCreate && <div>{errorCreate}</div>}
						</div>
					</div>
				</main>
			</section>
		</div>
	)
}