import { RiDeleteBinLine } from 'react-icons/ri'
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';

import Message from '../components/Message';
import { getPricingEntries, removeFromBooking } from '../actions/bookingItemActions';
import { HashLink } from 'react-router-hash-link';
import { GET_BOOKING_RESET } from '../constants/bookingConstants';
import { checkCoupon } from '../actions/couponActions';
import { CHECK_COUPON_RESET } from '../constants/couponConstants';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';

export default function Booking() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const booking = useSelector((state) => state.booking);
    const { bookingMenuItems } = booking;

    const pricing = useSelector((state) => state.pricing);
    const { pricingEntries } = pricing;

    const bookingOption = useSelector((state) => state.bookingOption);
    const { date, options } = bookingOption;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [bookingPrice, setBookingPrice] = useState(0);
    const [perNightRent, setPerNightRent] = useState(0);
    const [menuSubtotal, setMenuSubtotal] = useState(0);

    const removeFromBookingHandler = (id) => {
        dispatch(removeFromBooking(id));
    };

    const checkoutHandler = () => {
        navigate(`/booking/info`);
    };

    useEffect(() => {
        dispatch(getPricingEntries());
        dispatch({ type: GET_BOOKING_RESET });
    }, [dispatch]);

    useEffect(() => {
        calculateTotalPrice(bookingMenuItems, pricingEntries, date, options);
    }, [dispatch, bookingMenuItems, pricingEntries, date, options])

    const calculateTotalPrice = (bookingMenuItems, pricingEntries, date, options) => {

        // Calculate number of nights
        const start = new Date(date[0].startDate);
        const end = new Date(date[0].endDate);
        const adult = options.adult;
        const child = options.children;
        const numberOfNights = Math.floor((end - start) / (1000 * 60 * 60 * 24));

        // Get the per day price from pricingEntries
        const perDayPrice = pricingEntries.length > 0 ? pricingEntries[0].perDayPrice : 0;
        const campingTax = pricingEntries.length > 0 ? pricingEntries[0].campingTax : 0;
        booking.campingTax = campingTax;

        // Calculate the base price for the booking
        const basePrice = perDayPrice * numberOfNights;
        setPerNightRent(basePrice);
        booking.perNightRent = basePrice;

        // Calculate total adult price
        const totalAdultPrice = bookingMenuItems.reduce((total, item) => {
            const itemPrice = item.priceAdult * adult;
            return total + itemPrice;
        }, 0);

        // Calculate total child price
        const totalChildPrice = bookingMenuItems.reduce((total, item) => {
            const itemPrice = item.priceChild * child;
            return total + itemPrice;
        }, 0);

        // Calculate total group price
        const totalGroupPrice = bookingMenuItems.reduce((total, item) => {
            const itemPrice = item.priceGroup * 1;
            return total + itemPrice;
        }, 0);
        const menuTotal = totalAdultPrice + totalChildPrice + totalGroupPrice;
        // setMenuSubtotal(menuTotal);
        booking.menuTotal = menuTotal;
        console.log(menuTotal);

        // Calculate overall total price
        const totalPrice = basePrice + totalAdultPrice + totalChildPrice + totalGroupPrice;
        // setBookingPrice(totalPrice);
        booking.totalPrice = totalPrice;
        console.log(totalPrice);

        return totalPrice;
    };

    return (
        <div className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] px-4 md:px-8">
            <Helmet>
        <title>Shop - Booking</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
            {/* <Header /> */}
            <div className="flex max-w-full border-y-2 border-white">

                <div className="flex flex-col max-w-6xl mx-auto md:flex-row justify-center gap-4 my-4">
                    <div className="rounded-lg md:w-1/2">
                        {bookingMenuItems.length === 0 && (
                            <Message>
                                <Link to='/camping/#campEvent' as={RouterLink} textDecoration='underline' _hover={{ color: 'blue' }}>
                                    Sie können hier und jetzt unsere besonderen Erlebnisse und Camping-Services anfragen, damit Ihr Aufenthalt wundervoll und unvergesslich wird. Sagen Sie uns was Sie gerne erleben wollen und wir planen das für Sie ein. Die Zahlung dafür erfolgt dann bei uns vor Ort.  Heute zahlen Sie nur den Stellplatz!
                                </Link>
                            </Message>
                        )}
                        {bookingMenuItems.length !== 0 && bookingMenuItems.map((item) => (
                            <div className="mb-2 rounded-lg bg-white p-4 md:p-6 shadow-md flex justify-between gap-2">
                                <img src={item.image} alt={item.name} className="w-[90px] h-[90px] object-cover rounded-lg" />
                                <div className="flex w-4/5 sm:justify-between">
                                    <div className="flex flex-col gap-2 sm:mt-0 w-full">
                                        <h2 className="text-sm md:text-lg font-bold text-gray-900">{item.name}</h2>
                                        <h2 className="hidden md:flex text-md font-semibold text-gray-900 overflow-hidden">{item.description}</h2>
                                    </div>
                                    {/* <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6"> */}
                                    <div className="flex flex-col justify-between w-1/3">
                                        <p className="self-end text-lg md:text-2xl font-bold text-gray-700">
                                            <span>{((+options.adult * item.priceAdult) + (+options.children * item.priceChild) + ((1) * item.priceGroup))?.toFixed(2).replace('.', ',')}€</span>
                                        </p>
                                        <RiDeleteBinLine className="self-end text-2xl cursor-pointer" onClick={() => removeFromBookingHandler(item._id)} />
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        ))}
                        {booking?.menuTotal && (
                            <Flex
                                borderBottom='1px'
                                py='2'
                                bg='white'
                                rounded='lg'
                                p='6'
                                mb='2'
                                borderColor='gray.200'
                                alignitems='center'
                                justifyContent='space-between'>
                                <Flex direction='column' gap={1} w='80%'>
                                    <Text fontWeight='bold' fontSize='xl'>Gesamtpreis Ihrer Wunschliste</Text>
                                    <Text fontSize='sm' color='red'>(* Bitte beachten Sie: Dies ist eine Wunschliste und noch nicht bestätigt, daher zahlen Sie heute nur den Stellplatz und Ihre Wünsche dann bei uns vor Ort.)</Text>
                                    <Text fontSize='sm' color='red'>{`*
                                        Wichtiger Hinweis zu Stornierungen von Buchungen für Camping und unseren Events: Bitte beachten Sie, dass wir für Sie stets einen perfekten Aufenthalt planen und das natürlich auch mit Planung und Bereitstellung von Ressourcen verbunden ist. Daher gelten für Stornierungen die folgenden Bedingungen und wir bitten um Ihr Verständnis.`}</Text>

                                    <Text fontSize='sm' color='red'>{`*
                                        Terminverschiebungen sind stets für Sie völlig kostenfrei. Für Stornierungen gelten die folgenden Bedingungen. - A) Bis 14 Tage vor dem Anreisetermin ist die Stornierung kostenfrei möglich. - B) Bis 7 Tage vor dem Anreisetermin erstatten wir Ihnen 50% der gezahlten Buchungskosten. - Bis 3 Tage vor dem Anreisetermin erstatten wir 20% der gezahlten Buchungskosten.
                                    `}</Text>
                                </Flex>
                                <Text fontWeight='bold' fontSize='xl'>
                                    {(booking.menuTotal)?.toFixed(2).replace('.', ',')}€
                                </Text>
                            </Flex>
                        )}
                    </div>
                    <div className="md:w-1/3">
                        <div className='my-6 rounded-lg border bg-white p-6 shadow-md md:mt-0'>
                            <div className="flex justify-between">
                                <p className="text-gray-700">Ihre Reisedaten</p>
                                <p className="text-gray-700">Von {new Date(date[0].startDate).toISOString().split('T')[0]} Bis {new Date(date[0].endDate).toISOString().split('T')[0]}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-700">Erwachsene</p>
                                <p className="text-gray-700">{options.adult}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-700">Kinder</p>
                                <p className="text-gray-700">{options.children}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-700">Stellplatz Übernachtung x ({Math.floor((new Date(date[0].endDate) - new Date(date[0].startDate)) / (1000 * 60 * 60 * 24))})</p>
                                <p className="text-gray-700">{(perNightRent)?.toFixed(2).replace('.', ',')}€</p>
                            </div>

                            <hr className="my-4" />
                            <div className="flex justify-between">
                                <p className="text-xl font-bold">Gesamter Betrag</p>
                                <div className="flex flex-col items-end">
                                    <p className="mb-1 text-xl font-bold">
                                        {(perNightRent)?.toFixed(2).replace('.', ',')}€
                                    </p>
                                    <p className="text-sm text-gray-700">Inklusive MwSt({(((perNightRent) * (booking.campingTax / 100)))?.toFixed(2).replace('.', ',')}€)</p>
                                </div>
                            </div>
                            <button className="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600" onClick={checkoutHandler}>Jetzt bezahlen</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}