import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Stack,
	Radio,
	RadioGroup,
	Spacer,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { savePaymentMethod } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import FormContainer from '../components/FormContainer';

const PaymentBookingScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const cart = useSelector((state) => state.cart);
	const { shippingAddress, paymentMethod } = cart;

	const [paymentMethodRadio, setPaymentMethodRadio] = useState(
		paymentMethod || 'paypal'
	);

	useEffect(() => {
		if (!shippingAddress) {
			navigate('/booking/info');
		}
	}, [navigate, shippingAddress]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(savePaymentMethod(paymentMethodRadio));
		navigate('/placebooking');
	};

	return (
		<Flex>
			{/* <Header /> */}
			<Flex className='bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]' w='full' alignItems='center' justifyContent='center' py='8'>
				<FormContainer>
					{/* <CheckoutSteps step1 step2 step3 /> */}

					<Heading as='h2' mb='8' fontSize='3xl'>
						Bezahlverfahren
					</Heading>

					<form onSubmit={submitHandler}>
						<FormControl as='fieldset'>
							<FormLabel as='legend'>Weiter mit den folgenden Zahlungsoptionen:</FormLabel>
							<RadioGroup
								value={paymentMethodRadio}
								onChange={setPaymentMethodRadio}>
								<Stack alignItems='start' direction='column' space='24px'>
									<Radio value='paypal'>- PayPal</Radio>
									<Radio value='Kreditkarte'>- Kreditkarte</Radio>
									<Radio value='Debitkarte'>- Debitkarte</Radio>
									<Radio value='GiroPay'>- GiroPay</Radio>
									<Radio value='Sofortüberweisung'>- Sofortüberweisung</Radio>
								</Stack>
							</RadioGroup>
							{/* <div>- PayPal</div>
							<div>- Kreditkarte / Debitkarte</div>
							<div>- GiroPay</div>
							<div>- Sofortüberweisung</div> */}
						</FormControl>

						<Spacer h='3' />

						<Button type='submit' colorScheme='teal' mt='4'>
							Weiter
						</Button>
					</form>
				</FormContainer>
			</Flex>
		</Flex>
	);
};

export default PaymentBookingScreen;
