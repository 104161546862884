import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { serverIp } from '../config/conf';
import * as types from '../constants/disableDatesContants';
import axios from 'axios';

export const addDisabledDate = (date) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.ADD_DISABLED_DATE_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

        const { data } = await axios.post(`${serverIp}/api/disable`, { date }, config);

        dispatch({
            type: types.ADD_DISABLED_DATE_SUCCESS,
            payload: data,
        });

        toast.success('Date Disabled successfully!');
    } catch (error) {
        dispatch({
            type: types.ADD_DISABLED_DATE_FAILURE,
            payload: error.response.data.error,
        });
    }
};

export const deleteDisabledDate = (dateId) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.DELETE_DISABLED_DATE_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

        await axios.delete(`${serverIp}/api/disable/${dateId}`, config);

        dispatch({
            type: types.DELETE_DISABLED_DATE_SUCCESS,
            payload: dateId,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_DISABLED_DATE_FAILURE,
            payload: error.response.data.error,
        });
    }
};

export const getDisabledDates = () => async (dispatch) => {
    try {
        dispatch({ type: types.GET_DISABLED_DATES_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/disable`);

        dispatch({
            type: types.GET_DISABLED_DATES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_DISABLED_DATES_FAILURE,
            payload: error.response.data.error,
        });
    }
};
