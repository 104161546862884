import axios from 'axios';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM } from '../constants/wishListConstants';
import { serverIp } from '../config/conf';

export const addToWishList = (qty, size, id) => async (dispatch, getState) => {
	const { data } = await axios.get(`${serverIp}/api/products/${id}`);

	dispatch({
		type: WISHLIST_ADD_ITEM,
		payload: {
			product: data._id,
			name: data.name,
			image: data.image[0],
			price: data.price,
			description: data.description,
			countInStock: data.countInStock,
			category : data.category,
			qty: +qty,
			size: size
		},
	});

	localStorage.setItem('wishListItems', JSON.stringify(getState().wishList.wishListItems));
};

export const removeFromWishList = (id) => (dispatch, getState) => {
	dispatch({ type: WISHLIST_REMOVE_ITEM, payload: id });

	localStorage.setItem('wishListItems', JSON.stringify(getState().wishList.wishListItems));
};
