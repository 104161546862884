import { useEffect, useState } from 'react';
import Accordion from './Accordion';

function CookieBanner(props) {
    const [showBanner, setShowBanner] = useState(true);
    const [showAcc, setShowAcc] = useState(false);

    useEffect(() => {
        let cookieStatus = localStorage.getItem('cookieAccept') || "false";
        if (cookieStatus === 'true') {
            setShowBanner(false)
        } else {
            setShowBanner(true)
        }
    }, [])

    const handleAccept = () => {
        // Logic to handle accepting the cookie policy
        localStorage.setItem('cookieAccept', JSON.stringify(true));
        setShowBanner(false);
        props.setShowBannerOnClick(false);

    };

    const handleEssential = () => {
        localStorage.setItem('cookieAccept', JSON.stringify(true));
        setShowBanner(false);
        props.setShowBannerOnClick(false);
    }

    const handleClose = () => {
        setShowBanner(false);
        props.setShowBannerOnClick(false);
    }

    return (
        <>
            {(showBanner || props.showBannerOnClick) && (
                <div className="bg-gray-800 text-white py-4 px-6 fixed bottom-0 left-0 right-0 z-50">
                    <div className="flex flex-col justify-between gap-4">
                        <div className='w-full flex justify-between'>
                            <p className='text-lg font-bold'>Datenschutzeinstellungen</p>
                            <button onClick={handleClose} className="flex justify-center items-center text-white cursor-pointer h-8 w-8 bg-red-400">x</button>
                        </div>
                        <p className="text-sm text-justify">
                            Wir benötigen Ihre Zustimmung, bevor Sie unsere Website weiter besuchen können.<br /><br />
                            Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.<br />
                            Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer <a href='https://weinhaus-schowalter.de/datenschutzerklaerung/' className='cursor-pointer text-orange-500'>Datenschutzerklärung</a>.<br />
                            Hier finden Sie eine Übersicht über alle verwendeten Cookies.  Sie können Ihre Auswahl jederzeit unter <a onClick={() => setShowAcc(val => !val)} className='cursor-pointer text-orange-500'>Einstellungen</a> widerrufen oder anpassen.
                        </p>
                        <div className='flex gap-4'>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded focus:outline-none"
                                onClick={handleAccept}
                            >
                                Ich akzeptiere
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded focus:outline-none"
                                onClick={handleEssential}
                            >
                                Nur essenzielle Cookies akzeptieren
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded focus:outline-none"
                                onClick={handleAccept}
                            >
                                Speichern
                            </button>
                        </div>
                        {showAcc && <Accordion />}
                    </div>
                </div>
            )}
            {/* Rest of your app */}
        </>
    );
}

export default CookieBanner;