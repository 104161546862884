import {
	Menu as ChakraMenu,
	MenuButton,
	Portal,
	MenuList,
	MenuItem
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { deleteUser, listUsers } from '../actions/userActions';
import { CiMenuKebab } from 'react-icons/ci';
import { CSVLink } from 'react-csv';
import { read, utils, writeFile } from 'xlsx';

import Loader from '../components/Loader';
import Message from '../components/Message';
import Sidebar from '../components/Sidebar';

const UserListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success: successDelete } = userDelete;

	const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = users?.slice(indexOfFirstItem, indexOfLastItem);

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listUsers('user'));
		} else {
			navigate('/login');
		}
	}, [dispatch, navigate, userInfo, successDelete]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id));
		}
	};

    let csvData = [];

    if (users) {
        // Transforming orders data into CSV format
        csvData = users.map((user) => {
            return [user.name, user.email, user.phone, user.createdAt.substring(0, 10) ];
        });

        csvData.unshift(["Name", "Email", "Phone", "Registration Date" ]);
    }

    const exportFile = () => {
        /* generate worksheet from state */
        const ws = utils.json_to_sheet(csvData);
        /* create workbook and append worksheet */
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        /* export to XLSX */
        writeFile(wb, 'SheetJSReactAoO.xlsx');
    };

	return (
		<div>
            <Sidebar menu='Kunden' />
            {/* <!-- CONTENT --> */}
            <section id="content">

                {/* <!-- MAIN --> */}
                <main>
                    <div className="table-data drop-shadow-md">
                        <div class="order">
                            <div class="head">
                                <h3>Kunden</h3>
                                <i class='bx bx-search' ></i>
                                <i class='bx bx-filter' ></i>
                                <div className='flex gap-4'>
                                    <CSVLink data={csvData}>Download CSV</CSVLink>
                                    <button onClick={() => exportFile()}>Export to Excel</button>
                                </div>
                            </div>

                            {loading ? (
                                <Loader />
                            ) : error ? (
                                <Message type='error'>{error}</Message>
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>ID</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems && currentItems.map((user) => (
                                            <tr key={user._id}>
                                                <td>
                                                    <p className='text-lg font-bold'>{user.name}</p>
                                                </td>
                                                <td className='text-xs'>{user._id}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                <td><span className='status completed'>Active</span></td>
                                                <td>
                                                    <div>
                                                        <ChakraMenu>
                                                            <RouterLink>
                                                                <MenuButton className="text-sm font-medium leading-6 text-gray-900"><CiMenuKebab className='h-7 w-7 rotate-90' /></MenuButton>
                                                            </RouterLink>
                                                            <Portal>
                                                                <MenuList className="text-sm font-semibold uppercase leading-6 text-gray-900" zIndex='99'>
                                                                    <MenuItem as={RouterLink} to={`/dashboard/customers/${user._id}/details`} >User Details</MenuItem>
                                                                    <MenuItem as={RouterLink} to={`/dashboard/customers/${user._id}/mail`} >Send Email</MenuItem>
                                                                </MenuList>
                                                            </Portal>
                                                        </ChakraMenu>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <div className='flex gap-2'>
                                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                        {currentPage}
                                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastItem >= users.length}>Next</button>
                                    </div>
                                </table>
                            )}
                        </div>
                    </div>
                </main>
            </section>
        </div>
	);
};

export default UserListScreen;
