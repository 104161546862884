export const ADD_BOOKING_REQUEST = 'ADD_BOOKING_REQUEST';
export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILURE = 'ADD_BOOKING_FAILURE';
export const ADD_BOOKING_RESET = 'ADD_BOOKING_RESET';

export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILURE = 'GET_BOOKING_FAILURE';
export const GET_BOOKING_RESET = 'GET_BOOKING_RESET';

export const BOOKING_DELETE_REQUEST = 'BOOKING_DELETE_REQUEST';
export const BOOKING_DELETE_SUCCESS = 'BOOKING_DELETE_SUCCESS';
export const BOOKING_DELETE_FAIL = 'BOOKING_DELETE_FAIL';

export const UPDATE_BOOKING_TO_PAID_REQUEST = 'UPDATE_BOOKING_TO_PAID_REQUEST';
export const UPDATE_BOOKING_TO_PAID_SUCCESS = 'UPDATE_BOOKING_TO_PAID_SUCCESS';
export const UPDATE_BOOKING_TO_PAID_FAILURE = 'UPDATE_BOOKING_TO_PAID_FAILURE';
export const UPDATE_BOOKING_TO_PAID_RESET = 'UPDATE_BOOKING_TO_PAID_RESET';

export const GET_MY_BOOKINGS_REQUEST = 'GET_MY_BOOKINGS_REQUEST';
export const GET_MY_BOOKINGS_SUCCESS = 'GET_MY_BOOKINGS_SUCCESS';
export const GET_MY_BOOKINGS_FAILURE = 'GET_MY_BOOKINGS_FAILURE';
export const GET_MY_BOOKINGS_RESET = 'GET_MY_BOOKINGS_RESET';

export const GET_BOOKINGS_REQUEST = 'GET_BOOKINGS_REQUEST';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';

export const UPDATE_BOOKING_TO_COMPLETED_REQUEST = 'UPDATE_BOOKING_TO_COMPLETED_REQUEST';
export const UPDATE_BOOKING_TO_COMPLETED_SUCCESS = 'UPDATE_BOOKING_TO_COMPLETED_SUCCESS';
export const UPDATE_BOOKING_TO_COMPLETED_FAILURE = 'UPDATE_BOOKING_TO_COMPLETED_FAILURE';
export const UPDATE_BOOKING_TO_COMPLETED_RESET = 'UPDATE_BOOKING_TO_COMPLETED_RESET';

export const GET_BOOKING_COUNT_AND_SALES_REQUEST = 'GET_BOOKING_COUNT_AND_SALES_REQUEST';
export const GET_BOOKING_COUNT_AND_SALES_SUCCESS = 'GET_BOOKING_COUNT_AND_SALES_SUCCESS';
export const GET_BOOKING_COUNT_AND_SALES_FAIL = 'GET_BOOKING_COUNT_AND_SALES_FAIL';

export const UPDATE_MENU_ITEMS_REQUEST = 'UPDATE_MENU_ITEMS_REQUEST';
export const UPDATE_MENU_ITEMS_SUCCESS = 'UPDATE_MENU_ITEMS_SUCCESS';
export const UPDATE_MENU_ITEMS_FAILURE = 'UPDATE_MENU_ITEMS_FAILURE';
export const UPDATE_MENU_ITEMS_RESET = 'UPDATE_MENU_ITEMS_RESET';
