import { Icon, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { CiDeliveryTruck } from 'react-icons/ci';
import { listMyOrders } from "../actions/orderActions";
import { BsPaypal } from "react-icons/bs";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";
import { FcCancel } from "react-icons/fc";

export default function OrderList(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const orderMyList = useSelector((state) => state.orderMyList);
    const { loading: loadingOrders, error: errorOrders, orders } = orderMyList;

    useEffect(() => {
        if (!userInfo) {
            navigate(`/signup`);
        } else {
            dispatch(listMyOrders());
            dispatch({ type: ORDER_DETAILS_RESET });
        }
    }, [dispatch])

    return (
        <div className="flex flex-col gap-8 py-4">
            <div className="self-center text-2xl font-bold underline underline-offset-8">
                {props.list === 'order' ? 'Bestellungen' : 'Gutscheine'}
            </div>

            {loadingOrders ? (
                <div>
                    Loading
                </div>
            ) : errorOrders ? (
                <div>
                    Error
                </div>
            ) : (
                <div className="flex flex-col">
                    <div className="self-center text-lg text-gray-400 font-bold text-center mb-4">
                        {props.list === 'order' ? 'Überprüfen Sie den Status Ihrer letzten Bestellungen und entdecken Sie ähnliche Produkte.' : 'Überprüfen Sie den Status Ihrer letzten Gutscheine.'}
                    </div>

                    {orders.filter((order) => order.type === props.list).map((order, index) => (
                        <div key={index} className="flex flex-col w-11/12 md:w-4/5 self-center border border-gray-300 rounded-md mb-8">
                            <div className="flex flex-wrap md:flex-nowrap justify-between p-4 items-center border-b border-gray-200">
                                <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-8">
                                    <div>
                                        <p className="font-semibold">{props.list === 'order' ? 'Bestellungen-Nr.' : 'Gutscheine-Nr.'}</p>
                                        <p className="text-sm text-gray-400">{order._id}</p>
                                    </div>
                                    <div className={props.list === 'order' ? 'flex' : 'hidden'}>
                                        <p className="font-semibold">Date</p>
                                        <p className="text-sm text-gray-400">{order.createdAt.substring(0, 10)}</p>
                                    </div>
                                    {order.type === 'coupon' && (
                                        <>
                                            <div>
                                                <p className="font-semibold">Erwachsene</p>
                                                <p className="text-sm text-gray-400">{order.adults}</p>
                                            </div>
                                            <div>
                                                <p className="font-semibold">Kinder</p>
                                                <p className="text-sm text-gray-400">{order.children}</p>
                                            </div>
                                        </>
                                    )}
                                    <div>
                                        <p className="font-semibold">Gesamter Betrag</p>
                                        <p className="text-sm font-semibold">{(order.totalPrice)?.toFixed(2).replace('.', ',')}€</p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <RouterLink to={`/order/${order._id}`}>
                                        <button className="mt-4 md:mt-0 bg-blue-700 text-white p-2 rounded-lg hover:bg-blue-500">{props.list === 'order' ? 'Bestellung anzeigen' : 'Gutscheine anzeigen'}</button>
                                    </RouterLink>
                                    {/* <button className="bg-blue-700 text-white p-2 rounded-lg hover:bg-blue-500">View Invoice</button> */}
                                </div>
                            </div>

                            {order.orderItems.map((item, idx) => (
                                <div key={idx} className="flex p-4 md:p-8 justify-between gap-8 border-b border-gray-200">
                                    <div className="w-1/5">
                                        <img className="rounded-md" src={item.image} />
                                    </div>
                                    <div className="flex w-full flex-col gap-4">
                                        <div className="flex flex-col justify-between gap-2">
                                            <div className="font-semibold">
                                                {item.name}
                                            </div>
                                            {/* <div className="self-end font-semibold">
                                                {item.qty} x {(item.price)?.toFixed(2).replace('.', ',')}€ = {(+item.qty * item.price)?.toFixed(2).replace('.', ',')}€
                                            </div> */}
                                            {order.type === 'coupon' ? (
                                                <Text fontSize={{sm:'md',md:'lg'}} fontWeight='semibold'>
                                                    {order.adults} x {(item.priceAdult)?.toFixed(2).replace('.', ',')}€ + {order.children} x {(item.priceChild)?.toFixed(2).replace('.', ',')}€ = {((order.adults * item.priceAdult) + (order.children * item.priceChild))?.toFixed(2).replace('.', ',')}€
                                                </Text>
                                            ) : (
                                                <Text fontSize={{sm:'md',md:'lg'}} fontWeight='semibold'>
                                                    {item.qty} x {(item.price)?.toFixed(2).replace('.', ',')}€ = {(+item.qty * item.price)?.toFixed(2).replace('.', ',')}€
                                                </Text>
                                            )}
                                        </div>
                                        <div className="hidden md:flex text-gray-400">
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="flex flex-wrap md:flex-nowrap p-4 items-center justify-between">
                                {order.isPaid ? (
                                    <div className="flex gap-2">
                                        <div >
                                            <Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" />Paid on,
                                        </div>
                                        <div className="font-semibold">{new Date(order.paidAt).toDateString()}</div>
                                    </div>
                                ) : (
                                    <div>
                                        <Icon as={BsPaypal} color='blue.400' boxSize='5' /> Bitte führen Sie die Zahlung durch.
                                    </div>
                                )}
                                {order.isDelivered === 1 ? (
                                    <div className="flex gap-2">
                                        <div >
                                            <Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" /> Abgeschlossen,
                                        </div>
                                        <div className="font-semibold">{new Date(order.completedAt).toDateString()}</div>
                                    </div>
                                ) : order.isDelivered === 2 ? (
                                    <div className="flex gap-2">
                                        <div >
                                            <Icon as={FcCancel} color='green.400' className="rounded-full" /> Abgesagt
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Icon as={CiDeliveryTruck} color='red.400' boxSize='5' /> wird bald abgeschlossen sein
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}