import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import ProductList from "../components/ProductList";
import { useDispatch } from "react-redux";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import HofladenHero from "../components/HofladenHero";
import { Helmet } from "react-helmet";

export default function Hofladen() {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subcat = queryParams.get("subcat");
  const cat = queryParams.get("cat");

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    onTop();
    dispatch({ type: ORDER_CREATE_RESET });
  }, [routePath]);

  return (
    <div>
      {/* <Header /> */}
      <Helmet>
        <title>Shop - Hofladen</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>

      <HofladenHero />

      <ProductList list="hofladen" qty={1} subcat={subcat} taste={"all"} isVisible={true} />

      {/* <Latest /> */}

      {/* <Footer /> */}
    </div>
  );
}
