import {
	BOOKING_ADD_ITEM,
	BOOKING_REMOVE_ITEM,
	BOOKING_SAVE_PAYMENT_METHOD,
	BOOKING_SAVE_SHIPPING_ADDRESS,
	CREATE_PRICING_REQUEST,
	CREATE_PRICING_SUCCESS,
	CREATE_PRICING_FAIL,
	GET_PRICING_REQUEST,
	GET_PRICING_SUCCESS,
	GET_PRICING_FAIL,
	UPDATE_PRICING_REQUEST,
	UPDATE_PRICING_SUCCESS,
	UPDATE_PRICING_FAIL,
	DELETE_PRICING_REQUEST,
	DELETE_PRICING_SUCCESS,
	DELETE_PRICING_FAIL,
	SET_BOOKING_DATE_OPTIONS,
	BOOKING_ITEM_RESET,
	RESET_BOOKING_DATE_OPTIONS,
	UPDATE_PRICING_RESET
} from '../constants/bookingItemConstants';

export const bookingReducer = (state = { bookingMenuItems: [] }, action) => {
	switch (action.type) {
		case BOOKING_ADD_ITEM:
			const item = action.payload;
			const existingItemIndex = state.bookingMenuItems.findIndex(
				(i) => i._id === item._id
			);

			if (existingItemIndex !== -1) {
				// Item with the same product and size exists, update its quantity or any other property
				const updatedCartItems = [...state.bookingMenuItems];
				updatedCartItems[existingItemIndex] = item;

				return { ...state, bookingMenuItems: updatedCartItems };
			} else {
				// Item with a different size, add it to the cart
				return { ...state, bookingMenuItems: [...state.bookingMenuItems, item] };
			}
		case BOOKING_REMOVE_ITEM:
			return {
				...state,
				bookingMenuItems: state.bookingMenuItems.filter((i) => i._id !== action.payload),
			};
		case BOOKING_ITEM_RESET:
			return { ...state, bookingMenuItems: [] };
		case BOOKING_SAVE_SHIPPING_ADDRESS:
			return { ...state, shippingAddress: action.payload };
		case BOOKING_SAVE_PAYMENT_METHOD:
			return { ...state, paymentMethod: action.payload };
		default:
			return state;
	}
};

export const pricingReducer = (state = { pricingEntries: [] }, action) => {
	switch (action.type) {
		case CREATE_PRICING_REQUEST:
		case GET_PRICING_REQUEST:
		case UPDATE_PRICING_REQUEST:
		case DELETE_PRICING_REQUEST:
			return { ...state, loading: true };

		case CREATE_PRICING_SUCCESS:
			return { ...state, loading: false, success: true, pricingEntry: action.payload };

		case GET_PRICING_SUCCESS:
			return { ...state, loading: false, pricingEntries: action.payload };

		case UPDATE_PRICING_SUCCESS:
			return { ...state, loading: false, successUpdate: true, pricingEntry: action.payload };
		case UPDATE_PRICING_RESET:
			return { ...state, loading: false, successUpdate: false, pricingEntry: {} };

		case DELETE_PRICING_SUCCESS:
			return { ...state, loading: false, success: true };

		case CREATE_PRICING_FAIL:
		case GET_PRICING_FAIL:
		case UPDATE_PRICING_FAIL:
		case DELETE_PRICING_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

const initialState = localStorage.getItem('bookingOption')
	? JSON.parse(localStorage.getItem('bookingOption'))
	: {
		date: [{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		}],
		options: {
			adult: 1,
			children: 0,
		},
	};

export const bookingOptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_BOOKING_DATE_OPTIONS:
			return {
				...state,
				date: action.payload.date,
				options: action.payload.options,
			};
		case RESET_BOOKING_DATE_OPTIONS:
			return {
				date: [{
					startDate: new Date(),
					endDate: new Date(),
					key: "selection",
				}],
				options: {
					adult: 1,
					children: 0,
				}
			}
		default:
			return state;
	}
};