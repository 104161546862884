import { LockClosedIcon } from '@heroicons/react/20/solid'
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import { resetPassword as resetPass } from '../actions/userActions'

export default function ResetPass() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [searchParams] = useSearchParams();
    let redirect = searchParams.get('redirect') || '/otp?type=0';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(null);

    const userRegister = useSelector((state) => state.userRegister);
    const { error, userInfo } = userRegister;

    const forgotPassword = useSelector((state) => state.forgotPassword);
    const { loading: loadingOTP, successOTP, error: errorOTP, email: emailOTP } = forgotPassword;

    const resetPassword = useSelector((state) => state.resetPassword);
    const { loading, success, error: errorReset } = resetPassword;

    useEffect(() => {
        if (success) {
            navigate('/signup');
        }
        if (successOTP && emailOTP) {
            setEmail(emailOTP);
        }
    }, [navigate, userInfo, redirect, success, successOTP, emailOTP]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
        } else {
            dispatch(resetPass(email, password));
        }
    };
    return (
        <div className="flex min-h-full border-b-2 items-center justify-center px-4 py-12 sm:px-6 lg:px-8 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]">
            <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
                        Passwort zurücksetzen
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="flex flex-col gap-4 -space-y-px rounded-md shadow-sm">
                        <div>
                            <label htmlFor="password" className="text-white">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder=" Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="text-white">
                                Confirm Password
                            </label>
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder=" Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                            </span>
                            Reset
                        </button>
                    </div>
                </form>
                {error && <div>{error}</div>}
                {message && <div>{message}</div>}
            </div>
        </div>
    )
}
