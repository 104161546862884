import {
    Button,
    Icon,
    Select
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createHero, deleteHero, listHero } from '../actions/heroActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Sidebar from '../components/Sidebar';
import { IoAdd } from 'react-icons/io5';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import { HERO_CREATE_RESET } from '../constants/heroConstants';

const BannerAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);

    const heroList = useSelector((state) => state.heroList);
    const { loading, heros, error } = heroList;

    const [country, setCountry] = useState('germany');
    const [category, setCategory] = useState('Main');

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const heroDelete = useSelector((state) => state.heroDelete);
    const { success: successDelete } = heroDelete;

    const heroCreate = useSelector((state) => state.heroCreate);
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		hero: createdhero,
	} = heroCreate;

    useEffect(() => {

        dispatch({ type: HERO_CREATE_RESET });

        if (userInfo && userInfo.isAdmin) {
            dispatch(listHero(country, category));
        } else {
            navigate('/login');
        }

        if (successCreate) {
			navigate(`/dashboard/banner/${createdhero._id}/edit`);
		} else {
			dispatch(listHero(country, category));
		}
    }, [dispatch, navigate, userInfo, successCreate,successDelete, country, category]);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? heros?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === heros?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    const handleBannerDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteHero(id))
            prevSlide()
        }
    }

    const handleBannerEdit = (id) => {
        navigate(`/dashboard/banner/${id}/edit`);
    }

    const createHeroHandler = () => {
		dispatch(createHero());
	};

    return (
        <div>
            <Sidebar menu='Banner' />
            {/* <!-- CONTENT --> */}
            <section id="content">

                {/* <!-- MAIN --> */}
                <main>
                    <div className="table-data drop-shadow-md">
                        <div class="order">
                            <div class="head">
                                <h3>Banner's</h3>
                                <i class='bx bx-search' ></i>
                                <i class='bx bx-filter' ></i>
                                <div className='flex gap-4'>
                                <div className="relative z-20">
                                    <Button as={Select}
                                        colorScheme='blue'
                                        onChange={(e) => setCategory(e.target.value)}
                                        value={category}
                                    >
                                        <option className='text-black' value="Main">Main</option>
                                        <option className='text-black' value="Wein">Wein</option>
                                        <option className='text-black' value="Hofladen">Hofladen</option>
                                        <option className='text-black' value="Events">Events</option>
                                        <option className='text-black' value="Camping">Camping</option>
                                        <option className='text-black' value="Alpaka">Alpaka</option>
                                    </Button>
                                </div>
                                <Button onClick={createHeroHandler} colorScheme='blue'>
                                    <Icon as={IoAdd} mr='2' fontSize='xl' fontWeight='bold' />Add
                                </Button>
                                </div>
                            </div>

                            {loading ? (
                                <Loader />
                            ) : error ? (
                                <Message type='error'>{error}</Message>
                            ) : (
                                <div className='w-full h-300px md:w-full md:h-[400px] py-4 px-1 relative group z-10'>
                                    <div
                                        style={{ backgroundImage: `url(${heros[currentIndex]?.url})` }}
                                        className='w-full h-full rounded-2xl bg-center bg-no-repeat bg-contain md:bg-cover duration-500'
                                    ></div>
                                    {/* Left Arrow */}
                                    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 hover:bg-black/30 text-white cursor-pointer'>
                                        <BsChevronCompactLeft onClick={prevSlide} size={30} />
                                    </div>
                                    {/* Right Arrow */}
                                    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 hover:bg-black/30 text-white cursor-pointer'>
                                        <BsChevronCompactRight onClick={nextSlide} size={30} />
                                    </div>
                                    {/* Center Buttons */}
                                    <div className='hidden group-hover:flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center space-x-4'>
                                        {/* Edit Button */}
                                        <div className='text-2xl rounded-lg p-2 bg-black/20 hover:bg-black/30 text-white cursor-pointer'>
                                            {/* Replace the 'editSlide' function with your desired edit functionality */}
                                            <button onClick={() => handleBannerEdit(heros[currentIndex]?._id)}>Edit</button>
                                        </div>
                                        {/* Delete Button */}
                                        <div className='text-2xl rounded-lg p-2 bg-black/20 hover:bg-black/30 text-white cursor-pointer'>
                                            {/* Replace the 'deleteSlide' function with your desired delete functionality */}
                                            <button onClick={() => handleBannerDelete(heros[currentIndex]?._id)}>Delete</button>
                                        </div>
                                    </div>
                                    <div className='flex top-4 justify-center py-2'>
                                        {heros.map((hero, slideIndex) => (
                                            <div
                                                key={slideIndex}
                                                onClick={() => goToSlide(slideIndex)}
                                                className='text-2xl cursor-pointer'
                                            >
                                                <RxDotFilled />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </section>
        </div>
    );
};

export default BannerAdmin;
