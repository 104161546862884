import {
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_RESET,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_LIST_FAIL,
  SUB_CATEGORY_LIST_REQUEST,
  SUB_CATEGORY_LIST_SUCCESS,
  MENU_LINK_UPDATE_REQUEST,
  MENU_LINK_UPDATE_SUCCESS,
  MENU_LINK_UPDATE_FAIL,
  MENU_LINK_UPDATE_RESET,
} from "../constants/categoryConstants";

export const categorytListReducer = (state = { category: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, category: [] };
    case CATEGORY_LIST_SUCCESS:
      return { loading: false, category: action.payload };
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subCategoryListReducer = (state = { subCategory: [] }, action) => {
  switch (action.type) {
    case SUB_CATEGORY_LIST_REQUEST:
      return { loading: true, subCategory: [] };
    case SUB_CATEGORY_LIST_SUCCESS:
      return { loading: false, subCategory: action.payload };
    case SUB_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return { loading: true };
    case CATEGORY_CREATE_SUCCESS:
      return { loading: false, success: true, category: action.payload };
    case CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORY_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

const initialState = {
  menuLink: null,
  menuLinks: [],
  loading: false,
  error: null,
  success: false,
};

export const menuLinkUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_LINK_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MENU_LINK_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuLink: action.payload,
        success: true,
      };
    case MENU_LINK_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case MENU_LINK_UPDATE_RESET:
      return {
        ...state,
        menuLink: null,
        menuLinks: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};
