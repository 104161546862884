export const BOOKING_ADD_ITEM = 'BOOKING_ADD_ITEM';
export const BOOKING_REMOVE_ITEM = 'BOOKING_REMOVE_ITEM';
export const BOOKING_SAVE_SHIPPING_ADDRESS = 'BOOKING_SAVE_SHIPPING_ADDRESS';
export const BOOKING_SAVE_PAYMENT_METHOD = 'BOOKING_SAVE_PAYMENT_METHOD';

export const BOOKING_ITEM_RESET = 'BOOKING_ITEM_RESET';

export const CREATE_PRICING_REQUEST = 'CREATE_PRICING_REQUEST';
export const CREATE_PRICING_SUCCESS = 'CREATE_PRICING_SUCCESS';
export const CREATE_PRICING_FAIL = 'CREATE_PRICING_FAIL';

export const GET_PRICING_REQUEST = 'GET_PRICING_REQUEST';
export const GET_PRICING_SUCCESS = 'GET_PRICING_SUCCESS';
export const GET_PRICING_FAIL = 'GET_PRICING_FAIL';

export const UPDATE_PRICING_REQUEST = 'UPDATE_PRICING_REQUEST';
export const UPDATE_PRICING_SUCCESS = 'UPDATE_PRICING_SUCCESS';
export const UPDATE_PRICING_FAIL = 'UPDATE_PRICING_FAIL';
export const UPDATE_PRICING_RESET = 'UPDATE_PRICING_RESET';

export const DELETE_PRICING_REQUEST = 'DELETE_PRICING_REQUEST';
export const DELETE_PRICING_SUCCESS = 'DELETE_PRICING_SUCCESS';
export const DELETE_PRICING_FAIL = 'DELETE_PRICING_FAIL';

export const SET_BOOKING_DATE_OPTIONS = 'SET_BOOKING_DATE_OPTIONS';
export const RESET_BOOKING_DATE_OPTIONS = 'RESET_BOOKING_DATE_OPTIONS';
