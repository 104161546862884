import { useState } from "react"

const minusIcon = '-'
const plusIcon = '+'

function Accordion1() {
    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = () => setExpanded((current) => !current)

    return (
        <div className="my-2 sm:my-4 md:my-6 shadow-md border-t bg-white">
            <div className="px-6 text-left items-center h-20 select-none flex justify-between flex-row" onClick={toggleExpanded}>
                <div className="flex flex-col">
                    <h5 className="flex-1 text-black font-semibold">
                        {'Essenziell (1)'}
                    </h5>
                    <p className="text-xs text-left text-black font-semibold">
                        {'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.'}
                    </p>
                </div>
                <div className="flex-none pl-2 text-black cursor-pointer">{expanded ? minusIcon : plusIcon}</div>
            </div>
            <div className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-900 ease-in ${expanded ? "max-h-52" : "max-h-0"}`}>
                <table class="table-fixed border-collapse border border-slate-500 mb-6 w-full">
                    <tbody>
                        <tr className="text-left">
                            <td className="text-xs p-1 text-left text-black font-bold border border-slate-600">Name</td>
                            <td className="text-xs p-1 text-left text-black border border-slate-600">Borlabs Cookie</td>
                        </tr>
                        <tr className="text-left">
                            <td className="text-xs p-1 text-left text-black font-bold border border-slate-600">Anbieter</td>
                            <td className="text-xs p-1 text-left text-black border border-slate-600">Eigentümer dieser Website, <a href="https://weinhaus-schowalter.de/services/" className="text-orange-500 cursor-pointer">Impressum</a></td>
                        </tr>
                        <tr className="text-left">
                            <td className="text-xs p-1 text-left text-black font-bold border border-slate-600">Zweck</td>
                            <td className="text-xs p-1 text-left text-black border border-slate-600">Speichert die Einstellungen der Besucher, die in der Cookie Box von Borlabs Cookie ausgewählt wurden.</td>
                        </tr>
                        <tr className="text-left">
                            <td className="text-xs p-1 text-left text-black font-bold border border-slate-600">Cookie Name</td>
                            <td className="text-xs p-1 text-left text-black border border-slate-600">borlabs-cookie</td>
                        </tr>
                        <tr className="text-left">
                            <td className="text-xs p-1 text-left text-black font-bold border border-slate-600">Cookie Laufzeit</td>
                            <td className="text-xs p-1 text-left text-black border border-slate-600">1 Jahr</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default function Accordion() {
    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = () => setExpanded((current) => !current)

    return (
        <section className="text-center">
            <Accordion1 />
            <div className="my-2 sm:my-4 md:my-6 shadow-md border-t bg-white" onClick={toggleExpanded}>
                <div className="px-6 text-left items-center h-20 select-none flex justify-between flex-row">
                    <div className="flex flex-col">
                        <h5 className="flex-1 text-black font-semibold">
                            {'Externe Medien (0)'}
                        </h5>
                        <p className="text-xs text-left text-black font-semibold">
                            {'Inhalte von Videoplattformen und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.'}
                        </p>
                    </div>
                    <div className="flex-none pl-2 text-black cursor-pointer">{expanded ? minusIcon : plusIcon}</div>
                </div>
            </div>
        </section>
    )
}