import axios from 'axios';
import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_SAVE_PAYMENT_METHOD,
	CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cartConstants';
import { serverIp } from '../config/conf';

export const addToCart = (qty, add, id, pno) => async (dispatch, getState) => {
	if (add === 'prod') {
		const { data } = await axios.get(`${serverIp}/api/products/${id}`);

		const cartItem = {
			product: data._id,
			name: data.name,
			isBulk: data.isBulk,
			image: data.image[0],
			year: data.year,
			volOfBottle: data.volOfBottle,
			discountedPrice: data.discountedPrice,
			price: data.price,
			description: data.description,
			countInStock: data.countInStock,
			category: data.category,
			qty: +qty,
			pno: pno
		}

		const {
			userLogin: { userInfo },
		} = getState();

		userInfo && await axios.post(`${serverIp}/api/users/addToCart`, cartItem, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		});

		dispatch({
			type: CART_ADD_ITEM,
			payload: cartItem,
		});

		localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
	} else {
		const { data } = await axios.get(`${serverIp}/api/menu/${id}`);

		const cartItem = {
			product: data._id,
			date: data.date,
			name: data.name,
			isBulk: data.isBulk,
			image: data.image,
			price: data.price,
			discountedPrice: data.price,
			description: data.description,
			countInStock: data.countInStock,
			category: data.category,
			qty: +qty,
			pno: pno,
		}

		const {
			userLogin: { userInfo },
		} = getState();

		userInfo && await axios.post(`${serverIp}/api/users/addToCart`, cartItem, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		});

		dispatch({
			type: CART_ADD_ITEM,
			payload: cartItem,
		});

		localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
	}

};

export const removeFromCart = (id) => async (dispatch, getState) => {
	const {
		userLogin: { userInfo },
	} = getState();

	userInfo && await axios.delete(`${serverIp}/api/users/removeFromCart/${id}`, {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	});

	dispatch({ type: CART_REMOVE_ITEM, payload: id });

	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
	dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });

	localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
	dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });

	localStorage.setItem('paymentMethod', JSON.stringify(data));
};
