import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../components/Sidebar';
import './sidenav.css'
import { useNavigate } from 'react-router-dom';
import { getPricingEntries, updatePricingEntry } from '../actions/bookingItemActions';
import { UPDATE_PRICING_RESET } from '../constants/bookingItemConstants';

const PricingForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [campingLimit, setCampingLimit] = useState(0);
    const [perNightRent, setPerNightRent] = useState(0);
    const [orderTax, setOderTax] = useState(0);
    const [campingTax, setCampingTax] = useState(0);
    const [alpakaTax, setAplakaTax] = useState(0);

    const pricing = useSelector((state) => state.pricing);
    const { pricingEntries, successUpdate, pricingEntry } = pricing;

    useEffect(() => {
        if (successUpdate) {
            navigate(`/dashboard/pricing`);
            dispatch({ type: UPDATE_PRICING_RESET })
            dispatch(getPricingEntries());
        } else {
            if (!pricingEntries[0]?.perDayPrice) {
                dispatch(getPricingEntries());
            } else {
                setCampingLimit(pricingEntries[0].campingLimit)
                setPerNightRent(pricingEntries[0].perDayPrice);
                setOderTax(pricingEntries[0].orderTax);
                setCampingTax(pricingEntries[0].campingTax);
                setAplakaTax(pricingEntries[0].alpakaTax);
            }
        }
    }, [dispatch, navigate, pricingEntries, successUpdate]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updatePricingEntry(pricingEntries[0]._id, {
            campingLimit: campingLimit,
            perDayPrice: perNightRent,
            orderTax: orderTax,
            campingTax: campingTax,
            alpakaTax: alpakaTax
        }))
    };

    return (
        <div>
            <Sidebar menu='Preisgestaltung' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <div className="table-data flex flex-col">
                        <div className="flex flex-col gap-8 py-4 drop-shadow-md">
                            <div className="self-center text-2xl font-bold underline underline-offset-8">
                                Preisgestaltung
                            </div>
                            <div className="self-center text-lg text-gray-400 font-bold text-center">
                                Preisgestaltung und Steuern verwalten
                            </div>
                            <form onSubmit={submitHandler}>
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 gap-y-10">
                                    <div className="mb-2">
                                        <label htmlFor="campingLimit" className="block font-medium text-sm mb-1">
                                            Camping Spot(per day)
                                        </label>
                                        <input
                                            type="number"
                                            id="campingLimit"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={campingLimit}
                                            onChange={(e) => setCampingLimit(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="nightrent" className="block font-medium text-sm mb-1">
                                            Stellplatz Übernachtung
                                        </label>
                                        <input
                                            type="number"
                                            id="nightrent"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={perNightRent}
                                            onChange={(e) => setPerNightRent(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="orderTax" className="block font-medium text-sm mb-1">
                                            Order Tax(%)
                                        </label>
                                        <input
                                            type="number"
                                            id="orderTax"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={orderTax}
                                            onChange={(e) => setOderTax(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="campingtax" className="block font-medium text-sm mb-1">
                                            Camping Tax(%)
                                        </label>
                                        <input
                                            type="number"
                                            id="campingtax"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={campingTax}
                                            onChange={(e) => setCampingTax(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="alpakaTax" className="block font-medium text-sm mb-1">
                                            Alpaka Tax
                                        </label>
                                        <input
                                            type="number"
                                            id="alpakaTax"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={alpakaTax}
                                            onChange={(e) => setAplakaTax(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="self-center w-28 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </main>
            </section >
        </div >
    );
};

export default PricingForm;