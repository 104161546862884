import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import './sidenav.css'
import { Button, Icon } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { createCategory, listCategory } from "../actions/categoryActions";
import { CATEGORY_CREATE_RESET } from "../constants/categoryConstants";
import { getUserDetailsById, sentMailUser } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_SENT_MAIL_RESET } from "../constants/userConstants";

export default function UserCartDetails() {

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, user, error } = userDetails;

	useEffect(() => {
		dispatch(getUserDetailsById(id));
	}, [id])

	return (
		<div>
			<Sidebar menu='Kunden' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>
					<div className="p-4 bg-white rounded shadow">
						<h2 className="text-2xl font-semibold mb-4">User Details</h2>

						{loading ? (
							<Loader />
						) : error ? (
							<Message type="error">{error}</Message>
						) : (
							<div>
								{/* User Details */}
								<div className="mb-8">
									<h3 className="text-xl font-semibold mb-2">User Information</h3>
									<p>Name: {user.name}</p>
									<p>Email: {user.email}</p>
									<p>Phone: {user.phone}</p>
								</div>

								{/* Cart Details */}
								<div>
									<h3 className="text-xl font-semibold mb-2">Cart Information</h3>
									<ul>
										{/* Map through cart items */}
										{user.cart?.map((item) => (
											<li key={item.sku} className="mb-4">
												<div className="flex items-center">
													<img src={item.image} alt={item.name} className="w-16 h-16 mr-4" />
													<div>
														<p className="font-semibold">{item.name}</p>
														<p>SKU: {item.sku}</p>
														<p>Price: ${item.price}</p>
														<p>Discounted Price: ${item.discountedPrice}</p>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						)}
					</div>
				</main>
			</section>
		</div>
	)
}