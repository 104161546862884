import { Icon } from "@chakra-ui/react";
import { MdOutlineEmail, MdSupportAgent } from 'react-icons/md'
import { HiOutlineChatBubbleBottomCenterText } from 'react-icons/hi2'
import { IoCallOutline } from 'react-icons/io5'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFaqs, listSupportDetails } from "../actions/supportFaqActions";

export default function Support() {

    const dispatch = useDispatch();

    const faqList = useSelector((state) => state.faqList);
    const { loading, error, faqs } = faqList;

    const supportDetails = useSelector((state) => state.supportDetails);
    const { loading: loadingSupport, error: errorSupport, support } = supportDetails;

    useEffect(() => {
        dispatch(listFaqs());
        dispatch(listSupportDetails());
    }, [dispatch]);

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-8 py-4 border border-b-gray-400">
                <div className="self-center text-2xl font-bold underline underline-offset-8">
                    Support
                </div>
                <div className="self-center text-lg text-gray-400 font-bold text-center">
                    Brauchen Sie weitere Hilfe? Unser Support-Team steht Ihnen zur Verfügung.
                </div>

                {loadingSupport ? (
                    <div>
                        Loading
                    </div>
                ) : errorSupport ? (
                    <div>
                        Error
                    </div>
                ) : (
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 gap-y-20">
                        <div className="flex flex-col justify-center items-center gap-2">
                            <Icon as={MdOutlineEmail} boxSize='10' />
                            <p className="text-lg font-bold">Schreiben Sie uns</p>
                            {support && <a className="text-lg font-bold" href={`mailto:${support.mail}`}>{support.mail}</a>}

                        </div>
                        <div className="flex flex-col justify-center items-center gap-2">
                            <Icon as={IoCallOutline} boxSize='10' />
                            <p className="text-lg font-bold">Jetzt anrufen</p>
                            {support && <a className="text-lg font-bold" href={`tel:+${support.call}`}>+{support.call}</a>}

                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-col mt-10 gap-8 py-4 border-b">
                <div className="self-center text-2xl font-bold underline underline-offset-8">
                    FAQ
                </div>
                <div className="self-center text-lg text-gray-400 font-bold text-center">
                    Häufig gestellte Fragen
                </div>
                <div>
                    {loading ? (
                        <div>
                            Loading
                        </div>
                    ) : error ? (
                        <div>
                            Error
                        </div>
                    ) : (
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 gap-y-20">
                            {faqs.map((faq, index) => (
                                <div key={index} className="px-4 flex flex-col justify-center items-center gap-2">
                                    <p className="text-lg font-bold">Q. {faq.question}</p>
                                    <p className="text-md font-semibold">ANS:- {faq.answer}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}