import axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { listMenuItems } from "../actions/menuItemActions";
import {
  addToBooking,
  getPricingEntries,
  removeFromBooking,
} from "../actions/bookingItemActions";
import { FiThumbsUp } from "react-icons/fi";
import { serverIp } from "../config/conf";

export default function MenuItemList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItemList = useSelector((state) => state.menuItemList);
  const { loading, menuItems, error } = menuItemList;

  const { i18n } = useTranslation();
  let lang = i18n.language;

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    dispatch(listMenuItems());
    dispatch(getPricingEntries());
  }, [dispatch]);

  const handleItemClick = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      dispatch(removeFromBooking(id));
    } else {
      setSelectedItems([...selectedItems, id]);
      dispatch(addToBooking(id));
    }
  };

  const MenuItem = ({ id, imageSrc, itemName, itemDescription }) => {
    const [expanded, setExpanded] = useState(false);
    const isSelected = selectedItems.includes(id);

    const toggleEXpanded = () => {
      setExpanded(!expanded);
    };

    return (
      <div
        className={`flex flex-col pb-4 items-center justify-between border border-gray-200 rounded-lg shadow md:max-w-xl cursor-pointer ${
          isSelected
            ? "bg-orange-200 hover:bg-orange-200"
            : "bg-white hover:bg-gray-100"
        }`}
      >
        <div className="overflow-hidden">
          <img
            className="object-cover object-center w-full rounded-lg md:rounded-none md:rounded-lg aspect-[1]"
            // w-96 md:w-80 h-96 md:h-80 
            src={imageSrc}
            alt=""
          />
          <div className={`flex flex-col justify-between p-4 leading-normal`}>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              {itemName}
            </h5>
            <p className={`mb-3 font-normal text-gray-700`}>
              {expanded ? itemDescription : itemDescription.slice(0, 100)}
              {itemDescription.length > 16 && (
                <span
                  className="text-blue-500 hover:underline"
                  onClick={() => toggleEXpanded()}
                >
                  {expanded ? "See Less" : "...See More"}
                </span>
              )}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <FiThumbsUp
            className={`self-center text-2xl text-blue-600 ${
              isSelected ? "flex animate-jump-in" : "hidden"
            }`}
          />
          <button
            onClick={() => handleItemClick(id)}
            className={`py-2 px-4 rounded-md text-white font-semibold ${
              isSelected ? "bg-red-500" : "bg-green-500"
            }`}
          >
            {isSelected ? "Abwählen" : "Auswählen"}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]">
      <div className="w-full p-2 md:p-8">
        <h3 id="campEvent" className="text-white text-xl mb-4">
          Unsere genialen Camping-Services - Du darfst es einfach genießen! Was
          dürfen wir Dir Gutes tun?
        </h3>
        <div className="grid grid-cols-1 gap-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4">
          {loading ? (
            <>Loading</>
          ) : error ? (
            <>Error</>
          ) : (
            menuItems
              .filter((item) => item.category === "Camping Services")
              .map((item, index) => (
                <MenuItem
                  key={index}
                  id={item._id}
                  imageSrc={item.image}
                  itemName={item.name}
                  itemDescription={item.description}
                />
              ))
          )}
        </div>
      </div>
    </div>
  );
}
