import React from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	Heading,
	Icon,
	Image,
	Link,
	Text,
} from '@chakra-ui/react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { getBookingById, updateBookingToPaid } from '../actions/bookingActions';
import { type } from '@testing-library/user-event/dist/type';
import { BOOKING_ITEM_RESET } from '../constants/bookingItemConstants';
import { UPDATE_BOOKING_TO_COMPLETED_RESET, UPDATE_BOOKING_TO_PAID_RESET } from '../constants/bookingConstants';
import { getPricingEntries } from '../actions/bookingItemActions';
import { ImCheckboxChecked } from 'react-icons/im';
import { FcCancel } from 'react-icons/fc';
import { CiDeliveryTruck } from 'react-icons/ci';

class OrderPrint extends React.Component {
	render() {
		const { booking, pricingEntries } = this.props;
		return (
			<div className='p-6'>
				<Box borderBottom='1px' py='6' borderColor='gray.300'>
					<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
						Buchung
					</Heading>
					<Text>
						Name: <strong>{booking.userName}</strong>
					</Text>
					<Text>
						Email:{' '}
						<strong>
							<a href={`mailto:${booking.userEmail}`}>{booking.userEmail}</a>
						</strong>
					</Text>
					<Text>
						Number: {' '}
						<strong>{booking.userContact}</strong>
					</Text>
					<Text mt='4'>
						{booking.isCompleted === 1 ? (
							<div className="flex gap-2">
								<div >
									<Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" /> Abgeschlossen,
								</div>
								<div className="font-semibold">{new Date(booking.completedAt).toDateString()}</div>
							</div>
						) : booking.isCompleted === 2 ? (
							<div className="flex gap-2">
								<div >
									<Icon as={FcCancel} color='green.400' className="rounded-full" /> Abgesagt
								</div>
							</div>
						) : (
							<div>
								<Icon as={CiDeliveryTruck} color='red.400' boxSize='5' /> wird bald abgeschlossen sein
							</div>
						)}
					</Text>
				</Box>
				<Box borderBottom='1px' py='6' borderColor='gray.300'>
					<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
						Zahlungsabwicklung
					</Heading>
					<Text>
						<strong>Ihre Zahlungsoptionen: </strong>
						{booking.paymentMethod.toUpperCase()}
					</Text>
					<Text mt='4'>
						{booking.isPaid ? (
							<>
								Bezahlt {booking.paidAt.substring(0, 10)}
							</>
						) : (
							<>Zu zahlen</>
						)}
					</Text>
				</Box>
				<Box borderBottom='1px' py='6' borderColor='gray.300'>
					<Heading mb='6' as='h2' fontSize='2xl' fontWeight='semibold'>
						Ihre Stellplatzbuchung
					</Heading>

					<div className="flex justify-between">
						<p className="text-gray-700">Ihre Reisedaten</p>
						<p className="text-gray-700">Von {new Date(booking.startDate).toISOString().split('T')[0]} Bis {new Date(booking.endDate).toISOString().split('T')[0]}</p>
					</div>

					<div className="flex justify-between">
						<p className="text-gray-700">Erwachsene</p>
						<p className="text-gray-700">{booking.adults}</p>
					</div>

					<div className="flex justify-between">
						<p className="text-gray-700">Kinder</p>
						<p className="text-gray-700">{booking.children}</p>
					</div>

					<div className="flex justify-between">
						<p className="text-gray-700">Stellplatz Übernachtung x ({Math.floor((new Date(booking.endDate) - new Date(booking.startDate)) / (1000 * 60 * 60 * 24))})</p>
						<p className="text-gray-700">{(booking.totalBookingPrice)?.toFixed(2).replace('.', ',')}€</p>
					</div>

					{booking?.discount && (
						<div className="flex justify-between">
							<p className="text-gray-700">Rabatt(%)</p>
							<p className="text-gray-700">{booking.discount}%</p>
						</div>
					)}

					{booking?.couponCode && (
						<div className="flex justify-between">
							<p className="text-gray-700">Gutschein Code</p>
							<p className="text-gray-700">{booking.couponCode}</p>
						</div>
					)}

					{/* Total Price */}
					<Flex
						borderTop='1px'
						py='2'
						borderColor='gray.200'
						alignitems='center'
						justifyContent='space-between'>
						<Text fontSize='xl'>Gesamter Betrag</Text>
						<Text fontWeight='bold' fontSize='xl'>
							{(booking.discountedPrice)?.toFixed(2).replace('.', ',')}€
						</Text>
					</Flex>
					<div className="flex justify-between py-2">
						<p className="text-gray-700">Inklusive MwSt</p>
						<p className="text-gray-700">{((booking.discountedPrice) * (pricingEntries[0]?.campingTax / 100))?.toFixed(2).replace('.', ',')}€</p>
					</div>
				</Box>

				<Flex
					py='2'
					borderColor='gray.200'
					alignitems='center'
					justifyContent='space-between'>
					<Flex direction='column' gap={1}>
						<Text fontSize='sm' color='red'>{`*
                                        Wichtiger Hinweis zu Stornierungen von Buchungen für Camping und unseren Events: Bitte beachten Sie, dass wir für Sie stets einen perfekten Aufenthalt planen und das natürlich auch mit Planung und Bereitstellung von Ressourcen verbunden ist. Daher gelten für Stornierungen die folgenden Bedingungen und wir bitten um Ihr Verständnis.`}</Text>

						<Text fontSize='sm' color='red'>{`*
                                        Terminverschiebungen sind stets für Sie völlig kostenfrei. Für Stornierungen gelten die folgenden Bedingungen. - A) Bis 14 Tage vor dem Anreisetermin ist die Stornierung kostenfrei möglich. - B) Bis 7 Tage vor dem Anreisetermin erstatten wir Ihnen 50% der gezahlten Buchungskosten. - Bis 3 Tage vor dem Anreisetermin erstatten wir 20% der gezahlten Buchungskosten.
                                    `}</Text>
					</Flex>
				</Flex>
			</div>
		);
	}
};

const BookingScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: bookingId } = useParams();
	const componentRef = useRef();

	const bookingDetails = useSelector((state) => state.bookingDetails);
	const { booking, loading, error } = bookingDetails;

	const bookingPay = useSelector((state) => state.bookingPay);
	const { loading: loadingPay, success: successPay } = bookingPay;

	const bookingCompleted = useSelector((state) => state.bookingCompleted);
	const { loading: loadingCompleted, success: successCompleted } = bookingCompleted;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const pricing = useSelector((state) => state.pricing);
	const { pricingEntries } = pricing;

	const successPaymentHandler = (paymentResult) => {
		dispatch(updateBookingToPaid(bookingId, paymentResult));
	};

	useEffect(() => {
		dispatch(getPricingEntries());
		dispatch({ type: UPDATE_BOOKING_TO_PAID_RESET });
		dispatch({ type: UPDATE_BOOKING_TO_COMPLETED_RESET });

		if (!booking || successPay) {
			dispatch({ type: UPDATE_BOOKING_TO_PAID_RESET });
			dispatch({ type: UPDATE_BOOKING_TO_COMPLETED_RESET });
			dispatch(getBookingById(bookingId));
		}
	}, [dispatch, bookingId, successPay, booking, successCompleted]);

	const printHandler = () => {
		if (componentRef && componentRef.current) {
			componentRef.current.print();
		}
	};

	return loading ? (
		<div>Loading</div>
		// <Loader />
	) : error ? (
		<div type='error'>{error}</div>
	) : (
		<Flex justifyContent='center'>
			{/* <Header /> */}
			<Flex w='6xl' py='5' direction='column'>
				<Grid templateColumns={{ sm: '3fr fr', md: '3fr 2fr' }} gap='5'>
					{/* Column 1 */}
					<Flex direction='column' mx='4' shadow='md' p='8'>
						{/* Shipping */}
						<Box borderBottom='1px' borderColor='gray.300'>
							<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
								Buchung
							</Heading>
							<Text>
								Name: <strong>{booking.userName}</strong>
							</Text>
							<Text>
								Email:{' '}
								<strong>
									<a href={`mailto:${booking.userEmail}`}>{booking.userEmail}</a>
								</strong>
							</Text>
							<Text>
								Number: {' '}
								<strong>{booking.userContact}</strong>
							</Text>

							<Text mt='4'>
								{booking.isCompleted === 1 ? (
									<div className="flex gap-2">
										<div >
											<Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" /> Abgeschlossen,
										</div>
										<div className="font-semibold">{new Date(booking.completedAt).toDateString()}</div>
									</div>
								) : booking.isCompleted === 2 ? (
									<div className="flex gap-2">
										<div >
											<Icon as={FcCancel} color='green.400' className="rounded-full" /> Abgesagt
										</div>
									</div>
								) : (
									<div>
										<Icon as={CiDeliveryTruck} color='red.400' boxSize='5' /> wird bald abgeschlossen sein
									</div>
								)}
							</Text>
						</Box>

						{/* Payment Method */}
						<Box borderBottom='1px' py='6' borderColor='gray.300'>
							<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
								Zahlungsabwicklung
							</Heading>
							<Text>
								<strong>Ihre Zahlungsoptionen: </strong>
								{booking.paymentMethod.toUpperCase()}
							</Text>
							<Text mt='4'>
								{booking.isPaid ? (
									<>
										Bezahlt {booking.paidAt.substring(0, 10)}
									</>
								) : (
									<>Zu zahlen</>
								)}
							</Text>
						</Box>

						{/* Order Items */}
						<Box borderBottom='1px' py='6' borderColor='gray.300'>
							<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
								Wunschliste (möchte ich auf der Website haben)
							</Heading>
							<Box>
								{booking.menuItems.length === 0 ? (
									<div>Ihr Warenkorb ist leer</div>
								) : (
									<Box py='2'>
										{booking.menuItems.map((item, idx) => (
											<Flex
												key={idx}
												alignItems='center'
												justifyContent='space-between'
												gap='4'
											>
												<Flex py='2' alignItems='center'>
													<Image
														src={item.image}
														alt={item.name}
														w='12'
														h='12'
														objectFit='cover'
														mr='6'
													/>
													<Text
														fontWeight='semibold'
														fontSize='lg'>
														{item.name}
													</Text>
												</Flex>

												<Flex>
													<Text fontSize='lg' fontWeight='semibold'>
														{/* {options.adult} x {item.priceAdult}€ + {options.children} x {item.priceChild}€ + {options.adult + options.children} x {item.priceGroup}€ = */}
														{((+booking.adults * item.priceAdult) + (+booking.children * item.priceChild) + ((1) * item.priceGroup))?.toFixed(2).replace('.', ',')}€
													</Text>
												</Flex>
											</Flex>
										))}
									</Box>
								)}
							</Box>
							{booking?.totalMenuPrice && (
								<Flex
									py='2'
									borderColor='gray.200'
									alignitems='center'
									justifyContent='space-between'>
									<Flex direction='column' gap={1}>
										<Text fontWeight='bold' fontSize='xl'>Gesamtpreis Ihrer Wunschliste</Text>
										<Text fontSize='sm' color='red'>(* Bitte beachten Sie: Dies ist eine Wunschliste und noch nicht bestätigt, daher zahlen Sie heute nur den Stellplatz und Ihre Wünsche dann bei uns vor Ort.)</Text>
										<Text fontSize='sm' color='red'>{`*
                                        Wichtiger Hinweis zu Stornierungen von Buchungen für Camping und unseren Events: Bitte beachten Sie, dass wir für Sie stets einen perfekten Aufenthalt planen und das natürlich auch mit Planung und Bereitstellung von Ressourcen verbunden ist. Daher gelten für Stornierungen die folgenden Bedingungen und wir bitten um Ihr Verständnis.`}</Text>

										<Text fontSize='sm' color='red'>{`*
                                        Terminverschiebungen sind stets für Sie völlig kostenfrei. Für Stornierungen gelten die folgenden Bedingungen. - A) Bis 14 Tage vor dem Anreisetermin ist die Stornierung kostenfrei möglich. - B) Bis 7 Tage vor dem Anreisetermin erstatten wir Ihnen 50% der gezahlten Buchungskosten. - Bis 3 Tage vor dem Anreisetermin erstatten wir 20% der gezahlten Buchungskosten.
                                    `}</Text>
									</Flex>
									<Text fontWeight='bold' fontSize='xl'>
										{(booking.totalMenuPrice)?.toFixed(2).replace('.', ',')}€
									</Text>
								</Flex>
							)}
						</Box>
					</Flex>

					{/* Column 2 */}
					<Flex
						mx='4'
						direction='column'
						bgColor='white'
						gap='4'
						// justifyContent='space-between'
						py='8'
						px='8'
						shadow='md'
						rounded='lg'
						borderColor='gray.300'>
						<Box>
							<Heading mb='6' as='h2' fontSize='3xl' fontWeight='bold'>
								Ihre Stellplatzbuchung
							</Heading>

							<div className="flex justify-between">
								<p className="text-gray-700">Ihre Reisedaten</p>
								<p className="text-gray-700">Von {new Date(booking.startDate).toISOString().split('T')[0]} Bis {new Date(booking.endDate).toISOString().split('T')[0]}</p>
							</div>

							<div className="flex justify-between">
								<p className="text-gray-700">Erwachsene</p>
								<p className="text-gray-700">{booking.adults}</p>
							</div>

							<div className="flex justify-between">
								<p className="text-gray-700">Kinder</p>
								<p className="text-gray-700">{booking.children}</p>
							</div>

							<div className="flex justify-between">
								<p className="text-gray-700">Stellplatz Übernachtung x ({Math.floor((new Date(booking.endDate) - new Date(booking.startDate)) / (1000 * 60 * 60 * 24))})</p>
								<p className="text-gray-700">{(booking.totalBookingPrice)?.toFixed(2).replace('.', ',')}€</p>
							</div>

							{booking?.discount && (
								<div className="flex justify-between">
									<p className="text-gray-700">Rabatt(%)</p>
									<p className="text-gray-700">{booking.discount}%</p>
								</div>
							)}

							{booking?.couponCode && (
								<div className="flex justify-between">
									<p className="text-gray-700">Gutschein Code</p>
									<p className="text-gray-700">{booking.couponCode}</p>
								</div>
							)}

							{/* Total Price */}
							<Flex
								borderTop='1px'
								alignitems='center'
								borderColor='gray.200'
								justifyContent='space-between'>
								<Text fontSize='xl'>Gesamter Betrag</Text>
								<Text fontWeight='bold' fontSize='xl'>
									{(booking.discountedPrice)?.toFixed(2).replace('.', ',')}€
								</Text>
							</Flex>
							<div className="flex justify-between py-2">
								<p className="text-gray-700">Inklusive MwSt</p>
								<p className="text-gray-700">{((booking.discountedPrice) * (pricingEntries[0]?.campingTax / 100))?.toFixed(2).replace('.', ',')}€</p>
							</div>
						</Box>

						{/* PAYMENT BUTTON */}
						{(!booking.isPaid && booking.discountedPrice !== 0) && (
							<Box>
								{loading ? (
									<div>Loading</div>
									// <Loader />
								) : (
									<PayPalScriptProvider
										options={{
											'client-id':
												'AdOtJGzC_gvmx7PD2HQ3qw8s5B1E8FqLQMlMwyDrixthJdW7FX0WdmzbqaumH-954dXb8lOMivXFb66Z',
											components: 'buttons',
											currency: 'EUR',
											locale: 'de_DE',
										}}>
										<PayPalButtons
											style={{ layout: 'vertical' }}
											createOrder={(data, actions) => {
												return actions.order.create({
													purchase_units: [
														{
															amount: {
																value: booking?.discountedPrice?.toFixed(2),
															},
														},
													],
												});
											}}
											onApprove={(data, actions) => {
												return actions.order.capture().then((details) => {
													const paymentResult = {
														id: details.id,
														status: details.status,
														update_time: details.update_time,
														email_address: details.email_address,
													};
													successPaymentHandler(paymentResult);
												});
											}}
										/>
									</PayPalScriptProvider>
								)}
							</Box>
						)}

						{(!booking.isPaid && booking.discountedPrice === 0) && (
							<Button type="button" colorScheme="teal" onClick={() => successPaymentHandler({ id: 'Free', status: 'COMPLETED', update_time: Date.now(), email_address: booking.userName })}>
								Anfrage senden
							</Button>
						)}

						{loadingCompleted && <div>Loading</div>}
						{userInfo &&
							booking.isPaid && (
								<ReactToPrint
									trigger={() => (
										<Button type="button" colorScheme="teal" onClick={printHandler}>
											Ihre Rechnung ausdrucken
										</Button>
									)}
									content={() => componentRef.current}
								/>
							)}
						<div className='hidden'>
							<OrderPrint booking={booking} pricingEntries={pricingEntries} ref={componentRef} />
						</div>
					</Flex>
				</Grid>
			</Flex >
		</Flex >
	);
};

export default BookingScreen;
