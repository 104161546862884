export const ADD_DISABLED_DATE_REQUEST = 'ADD_DISABLED_DATE_REQUEST';
export const ADD_DISABLED_DATE_SUCCESS = 'ADD_DISABLED_DATE_SUCCESS';
export const ADD_DISABLED_DATE_FAILURE = 'ADD_DISABLED_DATE_FAILURE';

export const DELETE_DISABLED_DATE_REQUEST = 'DELETE_DISABLED_DATE_REQUEST';
export const DELETE_DISABLED_DATE_SUCCESS = 'DELETE_DISABLED_DATE_SUCCESS';
export const DELETE_DISABLED_DATE_FAILURE = 'DELETE_DISABLED_DATE_FAILURE';

export const GET_DISABLED_DATES_REQUEST = 'GET_DISABLED_DATES_REQUEST';
export const GET_DISABLED_DATES_SUCCESS = 'GET_DISABLED_DATES_SUCCESS';
export const GET_DISABLED_DATES_FAILURE = 'GET_DISABLED_DATES_FAILURE';
