import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Select } from '@chakra-ui/react';
import Resizer from "react-image-file-resizer";
import axios from 'axios';

import { listHeroDetails, updateHero } from '../actions/heroActions';
import { HERO_UPDATE_RESET } from '../constants/heroConstants';
import { serverIp } from '../config/conf';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import './sidenav.css'

const BannerForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id: heroId } = useParams();

    const [uploading, setUploading] = useState(false);

    const heroDetails = useSelector((state) => state.heroDetails);
    const { hero } = heroDetails;

    const heroUpdate = useSelector((state) => state.heroUpdate);
    const {
        success: successUpdate,
    } = heroUpdate;

    const [link, setLink] = useState('');
    const [url, setUrl] = useState('');
    const [category, setCategory] = useState('Main');
    const [country, setCountry] = useState('germany');
    const [description, setDescription] = useState('');

    const handleImageDelete = () => {
        if (window.confirm('Are you sure?')) {
            setUrl('');
        }
    }

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: HERO_UPDATE_RESET });
            navigate(`/dashboard/banner`);
        } else {
            if (!hero.url || hero._id !== heroId) {
                dispatch(listHeroDetails(heroId));
            } else {
                setLink(hero.link);
                setUrl(hero.url);
                setCategory(hero.category);
                setCountry(hero.country);
                setDescription(hero.description);
            }
        }
    }, [dispatch, navigate, heroId, hero, successUpdate]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            updateHero({
                _id: heroId,
                link,
                url,
                category,
                country,
                description
            })
        );
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                800,
                600,
                "JPEG",
                100,
                0,
                (uri) => {
                    // console.log(uri)
                    // resolve(uri);
                    const byteString = atob(uri.split(",")[1]);
                    const mimeString = uri.split(",")[0].split(":")[1].split(";")[0];
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });

                    resolve(blob);
                },
                "base64"
            );
        });

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        // const reducedImage = await resizeFile(file);
        // const resizedFile = new File([reducedImage], file.name, { type: file.type });
        const formData = new FormData();
        formData.append('image', file);
        setUploading(true);

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const { data } = await axios.post(`${serverIp}/api/uploads`, formData, config);
            setUrl(data);
            setUploading(false);
        } catch (err) {
            console.error(err);
            setUploading(false);
        }
    };

    return (

        <div>
            <Sidebar menu='My Store' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <h2 className="text-2xl font-semibold mb-4">Add/Edit Banner</h2>
                    <div className="container mx-auto mt-8">
                        <form onSubmit={submitHandler} className=''>
                            <div className='flex gap-4'>
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="flex items-center justify-center w-full">
                                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX 1)</p>
                                            </div>
                                            <input id="dropzone-file" type="file" accept="image/*" className="hidden" onChange={uploadFileHandler} />
                                            {uploading && <Loader />}
                                        </label>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block font-medium text-sm mb-1">
                                            Image
                                        </label>
                                        <div className='flex'>
                                            <img className='w-1/5' src={url} alt={url} />
                                            <p className='w-3/5'>{url.split('\\').pop()}</p>
                                            {url && <RiDeleteBin6Line onClick={() => handleImageDelete()} className='w-1/5' />}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block font-medium text-sm mb-1">
                                            Link
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={link}
                                            onChange={(e) => setLink(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="category" className="block font-medium text-sm mb-1">
                                            Category
                                        </label>
                                        <Select borderColor='black' value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option className='text-black' value="Main">Main</option>
                                            <option className='text-black' value="Wein">Wein</option>
                                            <option className='text-black' value="Hofladen">Hofladen</option>
                                            <option className='text-black' value="Events">Events</option>
                                            <option className='text-black' value="Camping">Camping</option>
                                            <option className='text-black' value="Alpaka">Alpaka</option>
                                        </Select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="desc" className="block font-medium text-sm mb-1">
                                            Description
                                        </label>
                                        <textarea
                                            id="desc"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Add other form inputs for image, tags, bestOfSapphire, category, countInStock, priceIn, priceDe, descriptionIn, descriptionDe, sizeIn, sizeDe */}
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </main>
            </section>
        </div>
    );
};

export default BannerForm;