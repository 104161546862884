import {
    ADD_DISABLED_DATE_REQUEST,
    ADD_DISABLED_DATE_SUCCESS,
    ADD_DISABLED_DATE_FAILURE,
    DELETE_DISABLED_DATE_REQUEST,
    DELETE_DISABLED_DATE_SUCCESS,
    DELETE_DISABLED_DATE_FAILURE,
    GET_DISABLED_DATES_REQUEST,
    GET_DISABLED_DATES_SUCCESS,
    GET_DISABLED_DATES_FAILURE,
} from '../constants/disableDatesContants';

const initialState = {
    disabledDates: [],
    loading: false,
    error: null,
    successAdd: false,
    successDelete: false
};

const disabledDateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DISABLED_DATE_REQUEST:
        case DELETE_DISABLED_DATE_REQUEST:
        case GET_DISABLED_DATES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_DISABLED_DATE_SUCCESS:
            return {
                ...state,
                disabledDates: [...state.disabledDates, action.payload],
                loading: false,
                error: null,
                successAdd: true,
            };
        case DELETE_DISABLED_DATE_SUCCESS:
            return {
                ...state,
                disabledDates: state.disabledDates.filter(date => date._id !== action.payload),
                loading: false,
                error: null,
                successDelete: true,
            };
        case GET_DISABLED_DATES_SUCCESS:
            return {
                ...state,
                disabledDates: action.payload,
                loading: false,
                error: null,
            };
        case ADD_DISABLED_DATE_FAILURE:
        case DELETE_DISABLED_DATE_FAILURE:
        case GET_DISABLED_DATES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default disabledDateReducer;
