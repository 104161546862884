import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import axios from 'axios';

import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import { listProductDetails, updateProduct } from '../actions/productActions';
import Sidebar from '../components/Sidebar';
import './sidenav.css'
import { RiDeleteBin6Line } from 'react-icons/ri';
import { InputGroup, InputRightElement, Select, Textarea } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { serverIp } from '../config/conf';
import Loader from '../components/Loader';
import { listCategory, listSubCategory } from '../actions/categoryActions';

const HofladenForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routePath = useLocation();
	const queryParams = new URLSearchParams(routePath.search);
	const categoryQry = queryParams.get('category');

    const { id: productId } = useParams();
    const max_size_count = 5;

    const [uploading, setUploading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');

    const productDetails = useSelector((state) => state.productDetails);
    const { product } = productDetails;

    const productUpdate = useSelector((state) => state.productUpdate);
    const {
        success: successUpdate,
    } = productUpdate;

    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [year, setYear] = useState('');
    const [image, setImage] = useState([]);
    const [category, setCategory] = useState('hofladen');
    const [subCategory, setSubCategory] = useState('all');
    const [countInStock, setCountInStock] = useState(0);
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');

    const [isBulk, setIsBulk] = useState(false);

    const [offers, setOffers] = useState([]); // State to manage offers locally

    const [offerStartDate, setOfferStartDate] = useState('');
    const [offerEndDate, setOfferEndDate] = useState('');
    const [offerDiscountPercent, setOfferDiscountPercent] = useState('');

    const handleOfferSubmit = () => {
        // Validate offer fields (add your validation logic here)
        if (!offerStartDate || !offerEndDate || !offerDiscountPercent) {
            // Handle validation error, show a message, etc.
            return;
        }

        // Create a new offer object
        const newOffer = {
            startDate: offerStartDate,
            endDate: offerEndDate,
            discountPercent: offerDiscountPercent,
        };

        // Update the offers state with the new offer
        setOffers([...offers, newOffer]);

        // Clear the offer input fields
        setOfferStartDate('');
        setOfferEndDate('');
        setOfferDiscountPercent('');
    };

    const handleRemoveOffer = (index) => {
        // Remove the offer at the specified index
        const updatedOffers = [...offers];
        updatedOffers.splice(index, 1);
        setOffers(updatedOffers);
    };

    const handleImageDelete = (indexToRemove) => {
        if (window.confirm('Are you sure?')) {
            if (indexToRemove >= 0 && indexToRemove < image.length) {
                const updatedImages = [...image]; // Create a copy of the array
                updatedImages.splice(indexToRemove, 1); // Remove the element at indexToRemove
                setImage(updatedImages); // Update the state with the modified array
            } else {
                console.log('Invalid index');
            }
        }
    }

    useEffect(() => {
        dispatch(listCategory());
    }, [dispatch])

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET });
            dispatch(listProductDetails(productId));
            navigate(`/dashboard/mystore/${categoryQry}`);
        } else {
            if (!product.name || product._id !== productId) {
                dispatch(listProductDetails(productId));
            } else {
                setName(product.name);
                setSku(product.sku);
                setYear(product.year);
                setIsBulk(product.isBulk);
                setImage(product.image);
                setPrice(product.price);
                setCountInStock(product.countInStock);
                setDescription(product.description);
                setOffers(product.offers);
            }
        }
    }, [dispatch, navigate, productId, product, successUpdate]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            updateProduct({
                _id: productId,
                name,
                sku,
                year,
                isBulk,
                image,
                price,
                description,
                category,
                subCategory,
                countInStock,
                offers
            })
        );
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                500,
                "JPEG",
                100,
                0,
                (uri) => {
                    // console.log(uri)
                    // resolve(uri);
                    const byteString = atob(uri.split(",")[1]);
                    const mimeString = uri.split(",")[0].split(":")[1].split(";")[0];
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });

                    resolve(blob);
                },
                "base64"
            );
        });

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const reducedImage = await resizeFile(file);
        const resizedFile = new File([reducedImage], file.name, { type: file.type });
        const formData = new FormData();
        formData.append('image', resizedFile);
        setUploading(true);

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const { data } = await axios.post(`${serverIp}/api/uploads`, formData, config);
            if (image.length < 5) {
                console.log(image);
                setImage([...image, data]);
            } else {
                console.log('Maximum image limit reached');
            }
            setUploading(false);
        } catch (err) {
            console.error(err);
            setUploading(false);
        }
    };

    return (

        <div>
            <Sidebar menu='My Store' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <h2 className="text-2xl font-semibold mb-4">Add/Edit Wein</h2>
                    <div className="container mx-auto mt-8">
                        <form onSubmit={submitHandler} className=''>
                            <div className='flex gap-4'>
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="flex items-center justify-center w-full">
                                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX 5)</p>
                                            </div>
                                            <input id="dropzone-file" type="file" accept="image/*" className="hidden" onChange={uploadFileHandler} />
                                            {uploading && <Loader />}
                                        </label>
                                    </div>
                                    <div className="mb-4">
                                        {image.map((img, index) => (
                                            <div key={index}>
                                                <label htmlFor="name" className="block font-medium text-sm mb-1">
                                                    Image {index + 1}
                                                </label>
                                                <div className='flex'>
                                                    <img className='w-1/5' src={img} alt={index} />
                                                    <p className='w-3/5'>{img.split('\\').pop()}</p>
                                                    <RiDeleteBin6Line onClick={() => handleImageDelete(index)} className='w-1/5' />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="w-1/2 p-4 bg-white rounded shadow">
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block font-medium text-sm mb-1">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="sku" className="block font-medium text-sm mb-1">
                                            Code
                                        </label>
                                        <input
                                            type="text"
                                            id="sku"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={sku}
                                            onChange={(e) => setSku(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="category" className="block font-medium text-sm mb-1">
                                            Category
                                        </label>
                                        <Select borderColor='black' value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option key='1' value='hofladen'>Hofladen</option>
                                        </Select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="subcategory" className="block font-medium text-sm mb-1">
                                            Sub Category
                                        </label>
                                        <Select borderColor='black' value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
                                            <option key='1' value='all'>All</option>
                                        </Select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="price" className="block font-medium text-sm mb-1">
                                            Price
                                        </label>
                                        <InputGroup>
                                            <input
                                                type="number"
                                                id="price"
                                                className="w-full border border-black rounded px-3 py-2"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                            />
                                            <InputRightElement children='€' />
                                        </InputGroup>
                                    </div>

                                    <div className="p-2 bg-white rounded shadow mb-4">
                                        {/* Display existing offers */}
                                        <h2 className="text-lg font-semibold mb-2">Existing Offers</h2>
                                        {offers.length === 0 ? (
                                            <p>No offers added yet.</p>
                                        ) : (
                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="border px-4 py-2">Start Date</th>
                                                        <th className="border px-4 py-2">End Date</th>
                                                        <th className="border px-4 py-2">Discount Percent</th>
                                                        <th className="border px-4 py-2">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {offers.map((offer, index) => (
                                                        <tr key={index}>
                                                            <td className="border px-4 py-2">{new Date(offer.startDate)?.toLocaleDateString('de-DE')}</td>
                                                            <td className="border px-4 py-2">{new Date(offer.endDate)?.toLocaleDateString('de-DE')}</td>
                                                            <td className="border px-4 py-2">{`${offer.discountPercent}%`}</td>
                                                            <td className="border px-4 py-2">
                                                                <button
                                                                    type="button"
                                                                    className="text-red-500"
                                                                    onClick={() => handleRemoveOffer(index)}
                                                                >
                                                                    <RiDeleteBin6Line />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="offerStartDate" className="block font-medium text-sm mb-1">
                                            Offer Start Date
                                        </label>
                                        <input
                                            type="date"
                                            id="offerStartDate"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={offerStartDate}
                                            onChange={(e) => setOfferStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="offerEndDate" className="block font-medium text-sm mb-1">
                                            Offer End Date
                                        </label>
                                        <input
                                            type="date"
                                            id="offerEndDate"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={offerEndDate}
                                            onChange={(e) => setOfferEndDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="offerDiscountPercent" className="block font-medium text-sm mb-1">
                                            Offer Discount Percent
                                        </label>
                                        <input
                                            type="number"
                                            id="offerDiscountPercent"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={offerDiscountPercent}
                                            onChange={(e) => setOfferDiscountPercent(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                                        onClick={handleOfferSubmit}
                                    >
                                        Add Offer
                                    </button>

                                    <div className='flex gap-2'>
                                        <div className="mb-4 w-1/2">
                                            <label htmlFor="stock" className="block font-medium text-sm mb-1">
                                                Stock
                                            </label>
                                            <input
                                                type="number"
                                                id="stock"
                                                className="w-full border border-black rounded px-3 py-2"
                                                value={countInStock}
                                                onChange={(e) => setCountInStock(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="description" className="block font-medium text-sm mb-1">
                                            Description
                                        </label>
                                        <Textarea
                                            placeholder='Enter description in english'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            borderColor='black'
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </main>
            </section>
        </div>


    );
};

export default HofladenForm;