import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  Link as RouterLink,
  redirect,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { login } from "../actions/userActions";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import {
  FORGOT_PASSWORD_RESET,
  RESET_PASSWORD,
  USER_LOGOUT,
  USER_OTP_VALIDATE_RESET,
} from "../constants/userConstants";
import { Helmet } from "react-helmet";

export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect") || "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const userOtpValidate = useSelector((state) => state.userOtpValidate);
  const { loading: loadingOtp, success, error: errorOtp } = userOtpValidate;

  useEffect(() => {
    if (userInfo && userInfo.successOTP && !success) {
      navigate("/otp?type=signup");
      dispatch({ type: USER_LOGOUT });
    } else if (userInfo && userInfo._id) {
      navigate(redirect);
    }
    dispatch({ type: FORGOT_PASSWORD_RESET });
    dispatch({ type: USER_OTP_VALIDATE_RESET });
    dispatch({ type: RESET_PASSWORD });
  }, [userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const togglePasswordVisibility = () => {
    setShowPass((prevShowPass) => !prevShowPass);
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 border-b-2 border-white sm:px-6 lg:px-8 bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15]">
      <Helmet>
        <title>Shop - SignUp</title>
        {/* <meta
          name="description"
          content={
            "Genießen ist etwas, das man lernen muss. Mit unserem Wein helfen wir dir gerne dabei. Wein und Natur ist für uns ganzheitliches Denken und das Verständnis davon, dass Qualität bei den Wurzeln beginnt."
          }
        /> */}
      </Helmet>
      <div className="w-full max-w-lg space-y-8">
        <div>
          {/* <img
                            className="mx-auto h-12 w-auto"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Your Company"
                        /> */}
          <h2 className="text-center text-3xl font-bold tracking-tight text-white">
            Melden Sie sich bei Ihrem Konto an
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
                            Or{' '}
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                start your 14-day free trial
                            </a>
                        </p> */}
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitHandler}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="flex flex-col gap-4 -space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="text-white">
                Email
              </label>
              <Input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                bg="white"
                placeholder=" Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="text-white">
                Password
              </label>
              <InputGroup>
                <Input
                  id="password"
                  name="password"
                  type={showPass ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  bg="white"
                  placeholder=" Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement bg="white" roundedRight="md">
                  <button
                    className="bg-white"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPass ? (
                      <AiFillEyeInvisible
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <AiFillEye className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </InputRightElement>
              </InputGroup>
            </div>
          </div>

          <div className="flex items-center justify-end">
            {/* <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div> */}

            <div className="text-sm">
              <RouterLink
                to="/forgotPass"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </RouterLink>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </button>
          </div>
          <div className="flex justify-center text-sm">
            <RouterLink
              to="/registration"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              New User? Register
            </RouterLink>
          </div>
        </form>
        {error && <div>{error}</div>}
      </div>
    </div>
  );
}
