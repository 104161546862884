import {
	ADD_BOOKING_FAILURE,
	ADD_BOOKING_REQUEST,
	ADD_BOOKING_SUCCESS,
	GET_BOOKINGS_FAILURE,
	GET_BOOKINGS_REQUEST,
	GET_BOOKINGS_SUCCESS,
	GET_BOOKING_FAILURE,
	GET_BOOKING_REQUEST,
	GET_BOOKING_SUCCESS,
	GET_BOOKING_RESET,
	GET_MY_BOOKINGS_FAILURE,
	GET_MY_BOOKINGS_REQUEST,
	GET_MY_BOOKINGS_SUCCESS,
	GET_MY_BOOKINGS_RESET,
	UPDATE_BOOKING_TO_COMPLETED_FAILURE,
	UPDATE_BOOKING_TO_COMPLETED_REQUEST,
	UPDATE_BOOKING_TO_COMPLETED_SUCCESS,
	UPDATE_BOOKING_TO_COMPLETED_RESET,
	UPDATE_BOOKING_TO_PAID_FAILURE,
	UPDATE_BOOKING_TO_PAID_REQUEST,
	UPDATE_BOOKING_TO_PAID_SUCCESS,
	UPDATE_BOOKING_TO_PAID_RESET,
	GET_BOOKING_COUNT_AND_SALES_REQUEST,
	GET_BOOKING_COUNT_AND_SALES_SUCCESS,
	GET_BOOKING_COUNT_AND_SALES_FAIL,
	ADD_BOOKING_RESET,
	UPDATE_MENU_ITEMS_REQUEST,
	UPDATE_MENU_ITEMS_SUCCESS,
	UPDATE_MENU_ITEMS_FAILURE,
	BOOKING_DELETE_REQUEST,
	BOOKING_DELETE_SUCCESS,
	BOOKING_DELETE_FAIL
} from '../constants/bookingConstants';

export const bookingCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_BOOKING_REQUEST:
			return { loading: true };
		case ADD_BOOKING_SUCCESS:
			return { loading: false, success: true, booking: action.payload };
		case ADD_BOOKING_FAILURE:
			return { loading: false, error: action.payload };
		case ADD_BOOKING_RESET:
			return {};
		default:
			return state;
	}
};

export const bookingDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case BOOKING_DELETE_REQUEST:
			return { loading: true };
		case BOOKING_DELETE_SUCCESS:
			return { loading: false, success: true };
		case BOOKING_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const bookingDetailsReducer = (
	state = { menuItems: [], loading: true },
	action
) => {
	switch (action.type) {
		case GET_BOOKING_REQUEST:
			return { ...state, loading: true };
		case GET_BOOKING_SUCCESS:
			return { loading: false, booking: action.payload };
		case GET_BOOKING_FAILURE:
			return { loading: false, error: action.payload };
		case GET_BOOKING_RESET:
			return { menuItems: [], loading: true };
		default:
			return state;
	}
};

export const bookingPayReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_BOOKING_TO_PAID_REQUEST:
			return { loading: true };
		case UPDATE_BOOKING_TO_PAID_SUCCESS:
			return { loading: false, success: true };
		case UPDATE_BOOKING_TO_PAID_FAILURE:
			return { loading: false, error: action.payload };
		case UPDATE_BOOKING_TO_PAID_RESET:
			return {};
		default:
			return state;
	}
};

export const bookingsMyListReducer = (state = { bookings: [] }, action) => {
	switch (action.type) {
		case GET_MY_BOOKINGS_REQUEST:
			return { loading: true };
		case GET_MY_BOOKINGS_SUCCESS:
			return { loading: false, bookings: action.payload };
		case GET_MY_BOOKINGS_FAILURE:
			return { loading: false, error: action.payload };
		case GET_MY_BOOKINGS_RESET:
			return { bookings: [] };
		default:
			return state;
	}
};

export const bookingListReducer = (state = { bookings: [] }, action) => {
	switch (action.type) {
		case GET_BOOKINGS_REQUEST:
			return { loading: true };
		case GET_BOOKINGS_SUCCESS:
			return { loading: false, bookings: action.payload };
		case GET_BOOKINGS_FAILURE:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const bookingCompletedReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_BOOKING_TO_COMPLETED_REQUEST:
			return { loading: true };
		case UPDATE_BOOKING_TO_COMPLETED_SUCCESS:
			return { loading: false, success: true };
		case UPDATE_BOOKING_TO_COMPLETED_FAILURE:
			return { loading: false, error: action.payload };
		case UPDATE_BOOKING_TO_COMPLETED_RESET:
			return {};
		default:
			return state;
	}
};

const initialState = {
	loading: false,
	count: [],
	totalSales: 0,
	menuStatistics:[],
	error: null,
};

export const bookingStatsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_BOOKING_COUNT_AND_SALES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_BOOKING_COUNT_AND_SALES_SUCCESS:
			return {
				...state,
				loading: false,
				count: action.payload.count,
				totalSales: action.payload.totalSales,
				menuStatistics: action.payload.menuStatistics,
				error: null
			};
		case GET_BOOKING_COUNT_AND_SALES_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const initialState2 = {
	booking: null,
	loading: false,
	error: null,
	success: false
};

export const updateMenuItemsReducer = (state = initialState2, action) => {
	switch (action.type) {
		case UPDATE_MENU_ITEMS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case UPDATE_MENU_ITEMS_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				booking: action.payload,
			};
		case UPDATE_MENU_ITEMS_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
