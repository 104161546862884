import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import "./sidenav.css";
import { Select, Textarea } from "@chakra-ui/react";
import {
  createCoupon,
  getCouponById,
  updateCoupon,
} from "../actions/couponActions";
import {
  COUPON_UPDATE_RESET,
  CREATE_COUPON_RESET,
} from "../constants/couponConstants";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Loader from "../components/Loader";
import Message from "../components/Message";

const CouponForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routePath = useLocation();
  const queryParams = new URLSearchParams(routePath.search);
  let type = queryParams.get("type") || "";

  const { id: couponId } = useParams();

  const [name, setName] = useState("");
  const [category, setCategory] = useState("All");
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [expirationDate, setExpirationDate] = useState(null);
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true); // assuming default value is true
  const [visibility, setVisibility] = useState(true); // assuming default value is true
  const [allocatedTo, setAllocatedTo] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const couponCreate = useSelector((state) => state.couponCreate);
  const { success } = couponCreate;

  const couponGet = useSelector((state) => state.couponGet);
  const { coupon, error, loading } = couponGet;

  const couponUpdate = useSelector((state) => state.couponUpdate);
  const { success: successUpdate, error: errorUpdate } = couponUpdate;

  useEffect(() => {
    if (!userInfo.isAdmin) {
      navigate("/login");
    } else {
      // dispatch(getCouponById(couponId));
      // dispatch(listMenuLinks());
    }
  }, [dispatch, navigate, userInfo, couponId]);

  useEffect(() => {
    if (type === "edit" && successUpdate) {
      dispatch({ type: COUPON_UPDATE_RESET });
      dispatch(getCouponById(couponId));
      navigate(`/dashboard/couponCode`);
    } else {
      if (type === "edit" && (!coupon?.name || coupon?._id !== couponId)) {
        dispatch(getCouponById(couponId));
      } else if (type === "edit" && coupon?.name) {
        setName(coupon.name);
        setCategory(coupon.category);
        setCode(coupon.code);
        setDiscount(coupon.discount);
        setExpirationDate(formatDate(coupon?.expirationDate));
        setDescription(coupon.description);
        setIsActive(coupon.isActive);
        setVisibility(coupon.visibility);
        setAllocatedTo(coupon.allocatedTo);
      }
    }

    if (type === "add" && success) {
      dispatch({ type: CREATE_COUPON_RESET });
      navigate(`/dashboard/couponCode`);
    }
  }, [dispatch, navigate, couponId, coupon, successUpdate, type, success]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (type === "edit") {
      dispatch(
        updateCoupon({
          _id: couponId,
          name,
          category,
          code,
          discount,
          expirationDate,
          description,
          isActive,
          visibility,
          allocatedTo,
        })
      );
    } else {
      dispatch(
        createCoupon({
          name,
          category,
          code,
          discount,
          expirationDate,
          description,
          isActive,
          visibility,
          allocatedTo,
        })
      );
    }
  };

  const formatDate = (apiDate) => {
    // Convert the API date to a Date object
    const dateObject = new Date(apiDate);

    // Get the year, month, and day
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, "0");

    // Format it as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  // useEffect(() => {
  //     scrollToTop();
  //     if (success) {
  //         navigate(`/dashboard/couponCode`);
  //         dispatch({ type: CREATE_COUPON_RESET });
  //     }
  // }, [success]);

  const cancelHandler = () => {
    const isEdit = type === "edit";

    // Display a confirmation dialog
    const shouldCancel = isEdit
      ? window.confirm(
          "Are you sure you want to cancel editing? Any unsaved changes will be lost."
        )
      : window.confirm(
          "Are you sure you want to cancel adding a new coupon? Any entered data will be lost."
        );

    // If the user confirms, navigate back to the product list
    if (shouldCancel) {
      if (isEdit) {
        navigate(`/dashboard/couponCode`);
      } else {
        navigate(`/dashboard/couponCode`);
      }
    }
  };

  return (
    <div>
      <Sidebar menu="Gutscheine" />
      <section id="content">
        <main>
          <div className="flex gap-4 items-center mb-4">
            <IoArrowBackCircleOutline
              onClick={() => cancelHandler()}
              className="cursor-pointer text-3xl font-semibold"
            />
            <h2 className="text-2xl font-semibold"> Add/Edit Coupon</h2>
          </div>
          <div className="container mx-auto mt-8">
            <form onSubmit={submitHandler} className="">
              <div className="flex flex-wrap p-4 bg-white rounded shadow">
                {/* Add a new input field for 'name' */}
                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="name"
                    className="block font-medium text-sm mb-1"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    required
                    className="w-full border border-black rounded px-3 py-2"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="category"
                    className="block font-medium text-sm mb-1"
                  >
                    Category
                  </label>
                  <Select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="border-black"
                  >
                    <option value="All">All</option>
                    <option value="firstOrder">1st Order</option>
                    <option value="orderCoupon">Order Coupon</option>
                    <option value="bookingCoupon">Booking Coupon</option>
                    {/* {menuLinks.map((menu, idx) => (
                        <option key={idx} value={menu.name}>
                          {menu.name}
                        </option>
                      ))} */}
                  </Select>
                </div>

                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="code"
                    className="block font-medium text-sm mb-1"
                  >
                    Code
                  </label>
                  <input
                    type="text"
                    id="code"
                    required
                    className="w-full border border-black rounded px-3 py-2"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="category"
                    className="block font-medium text-sm mb-1"
                  >
                    Discount(%)
                  </label>
                  <input
                    type="number"
                    id="discount"
                    required
                    className="w-full border border-black rounded px-3 py-2"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="category"
                    className="block font-medium text-sm mb-1"
                  >
                    expirationDate
                  </label>
                  <input
                    type="date"
                    id="date"
                    required
                    className="w-full border border-black rounded px-3 py-2"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="description"
                    className="block font-medium text-sm mb-1"
                  >
                    Description
                  </label>
                  <Textarea
                    placeholder="Enter description in english"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    borderColor="black"
                  />
                </div>
                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="allocatedTo"
                    className="block font-medium text-sm mb-1"
                  >
                    Allocated To
                  </label>
                  <input
                    type="text"
                    id="allocatedTo"
                    className="w-full border border-black rounded px-3 py-2"
                    value={allocatedTo}
                    onChange={(e) => setAllocatedTo(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-1/2 p-1">
                  <label
                    htmlFor="visibility"
                    className="block font-medium text-sm mb-1"
                  >
                    Visibility
                  </label>
                  <input
                    type="checkbox"
                    id="visibility"
                    checked={visibility}
                    onChange={() => setVisibility(!visibility)}
                    className="mr-2 leading-tight"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                  onClick={(e) => cancelHandler()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </main>
      </section>
    </div>
  );
};

export default CouponForm;
