import {
    Menu as ChakraMenu,
    MenuButton,
    Portal,
    MenuList,
    MenuItem
} from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiMenuKebab } from 'react-icons/ci';
import { CSVLink, CSVDownload } from "react-csv";
import { read, utils, writeFile } from 'xlsx';

import Sidebar from "../components/Sidebar"
import './sidenav.css'
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteBooking, getBookings } from '../actions/bookingActions';
import { GET_BOOKING_RESET } from '../constants/bookingConstants';
import { BiFilter, BiSort } from 'react-icons/bi';

export default function BookingListAdmin() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const bookingList = useSelector((state) => state.bookingList);
    const { loading, error, bookings = [] } = bookingList;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const bookingDelete = useSelector((state) => state.bookingDelete);
    const { success } = bookingDelete;

    const [paidStatus, setPaidStatus] = useState('all');
    const [completionStatus, setCompletionStatus] = useState('all');

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = paidStatus === 'all' && completionStatus === 'all' ? bookings?.slice(indexOfFirstItem, indexOfLastItem) : bookings?.filter((booking) => (paidStatus === 'all' || booking.isPaid === paidStatus) && (completionStatus === 'all' || booking.isCompleted === completionStatus)).slice(indexOfFirstItem, indexOfLastItem);

    let csvData = [];

    const [sortFor, setSortFor] = useState('startDate');
    const [sortTo, setSortTo] = useState(true);

    useEffect(() => {
        if (userInfo && userInfo.isAdmin) {
            dispatch(getBookings(sortFor, sortTo));
            dispatch({ type: GET_BOOKING_RESET });
        } else {
            navigate('/login');
        }
    }, [dispatch, userInfo, navigate, success, sortFor, sortTo]);

    if (bookings) {
        // Transforming bookings data into CSV format
        csvData = bookings.map((booking) => {
            const menuItems = booking.menuItems.map((menuItem) => menuItem.name).join(', ');
            return [booking.userName, booking.userEmail, booking.userContact, booking.startDate.substring(0, 10), booking.endDate.substring(0, 10), booking.adults, booking.children, menuItems, booking.paymentMethod, booking.isPaid ? 'Paid' : 'Unpaid', booking.isCompleted === 1 ? 'Completed' : booking.isCompleted === 2 ? 'Cancelled' : 'Pending', booking.totalBookingPrice, booking.totalMenuPrice, booking.couponCode, booking.discount, booking.discountedPrice];
        });

        csvData.unshift(["Name", "Email", "Phone", "Start Date", "End Date", "Adults", "Children", "Services", "Payment Method", "is Paid", "Status", "Booking Price", "Services Total", "Coupon", "Discount", "Booking Total"]);
    }

    const exportFile = () => {
        /* generate worksheet from state */
        const ws = utils.json_to_sheet(csvData);
        /* create workbook and append worksheet */
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        /* export to XLSX */
        writeFile(wb, 'SheetJSReactAoO.xlsx');
    };

    const handleDelete = (id, isCompleted) => {
        if (isCompleted !== 2) {
            if (window.confirm('Are you sure?')) {
                dispatch(deleteBooking(id));
            }
        } else {
            if (window.confirm('Already Canceled')) {
            }
        }
    }

    return (
        <div>
            <Sidebar menu='Buchungen' />
            {/* <!-- CONTENT --> */}
            <section id="content">

                {/* <!-- MAIN --> */}
                <main>
                    <div className="table-data drop-shadow-md">
                        <div class="order">
                            <div class="head">
                                <h3>Buchungen</h3>
                                <i class='bx bx-search' ></i>
                                <i class='bx bx-filter' ></i>
                                <div className='flex gap-4'>
                                    <CSVLink data={csvData}>Download CSV</CSVLink>
                                    <button onClick={() => exportFile()}>Export to Excel</button>
                                </div>
                            </div>

                            {loading ? (
                                <Loader />
                            ) : error ? (
                                <Message type='error'>{error}</Message>
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Customer</th>
                                            <th onClick={() => {
                                                setSortFor('createdAt')
                                                setSortTo((prevState) => !prevState)
                                            }}>
                                                <div className="flex items-center cursor-pointer" >
                                                    Date<BiSort />
                                                </div>
                                            </th>
                                            <th onClick={() => {
                                                setSortFor('startDate')
                                                setSortTo((prevState) => !prevState)
                                            }}>
                                                <div className="flex items-center cursor-pointer">
                                                    Von<BiSort />
                                                </div>
                                            </th>
                                            <th onClick={() => {
                                                setSortFor('endDate')
                                                setSortTo((prevState) => !prevState)
                                            }}>
                                                <div className="flex items-center cursor-pointer">
                                                    Bis<BiSort />
                                                </div>
                                            </th>
                                            {/* <th>Booking ID</th> */}
                                            <th onClick={() => {
                                                setSortFor('discountedPrice')
                                                setSortTo((prevState) => !prevState)
                                            }}>
                                                <div className="flex items-center cursor-pointer">
                                                    Price<BiSort />
                                                </div>
                                            </th>
                                            <th>
                                                <div className="flex relative peer group">
                                                    {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                                                    <div>Paid Status({paidStatus === true ? 'Paid' : paidStatus === false ? 'Unpaid' : 'All'})</div>
                                                    <BiFilter />
                                                </div>
                                                <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                                                    <button onClick={() => setPaidStatus('all')} className="px-2 py-4 hover:bg-gray-200">All</button>
                                                    <button onClick={() => setPaidStatus(true)} className="px-2 py-4 hover:bg-gray-200">Paid</button>
                                                    <button onClick={() => setPaidStatus(false)} className="px-2 py-4 hover:bg-gray-200">Unpaid</button>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="flex relative peer group">
                                                    {/* <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" /> */}
                                                    <div>Completion Status({completionStatus === 1 ? 'Completed' : completionStatus === 0 ? 'Pending' : completionStatus === 2 ? 'Cancelled' : 'All'})</div>
                                                    <BiFilter />
                                                </div>
                                                <div className="absolute hidden peer-hover:flex hover:flex w-[100px] flex-col bg-white drop-shadow-lg">
                                                    <button onClick={() => setCompletionStatus('all')} className="px-2 py-4 hover:bg-gray-200">All</button>
                                                    <button onClick={() => setCompletionStatus(1)} className="px-2 py-4 hover:bg-gray-200">Completed</button>
                                                    <button onClick={() => setCompletionStatus(0)} className="px-2 py-4 hover:bg-gray-200">Pending</button>
                                                    <button onClick={() => setCompletionStatus(2)} className="px-2 py-4 hover:bg-gray-200">Cancelled</button>
                                                </div>
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems && currentItems.map((booking) => (
                                            <tr key={booking._id}>
                                                <td>
                                                    <p className='text-base font-semibold'>{booking.userName}</p>
                                                </td>
                                                <td>
                                                    {booking.createdAt.substring(0, 10)}
                                                </td>
                                                <td>{booking.startDate.substring(0, 10)}</td>
                                                <td>{booking.endDate.substring(0, 10)}</td>
                                                {/* <td className='text-xs'>{booking._id}</td> */}
                                                <td>{(booking.discountedPrice)?.toFixed(2).replace('.', ',')}€</td>
                                                <td><span className={booking.isPaid ? 'status completed' : 'status pending'}>{booking.isPaid ? 'Paid' : 'Unpaid'}</span></td>
                                                <td><span className={booking.isCompleted === 1 ? 'status completed' : 'status pending'}>{booking.isCompleted === 1 ? 'Completed' : booking.isCompleted === 0 ? 'Pending' : 'Cancelled'}</span></td>
                                                <td>
                                                    <div>
                                                        <ChakraMenu>
                                                            <RouterLink>
                                                                <MenuButton className="text-sm font-medium leading-6 text-gray-900"><CiMenuKebab className='h-7 w-7 rotate-90' /></MenuButton>
                                                            </RouterLink>
                                                            <Portal>
                                                                <MenuList className="text-sm font-semibold uppercase leading-6 text-gray-900" zIndex='99'>
                                                                    <MenuItem as={RouterLink} to={`/dashboard/booking/${booking._id}`} >View Booking</MenuItem>
                                                                    <MenuItem onClick={() => handleDelete(booking._id, booking.isCompleted)}>CANCEL BOOKING</MenuItem>
                                                                </MenuList>
                                                            </Portal>
                                                        </ChakraMenu>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <div className='flex gap-2'>
                                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                        {currentPage}
                                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastItem >= bookings.length}>Next</button>
                                    </div>
                                </table>
                            )}
                        </div>
                    </div>
                </main>
            </section>
        </div>
    )
}