import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Icon } from '@chakra-ui/react'
import { MdCategory, MdDashboard, MdDomainDisabled } from 'react-icons/md'
import { IoStorefrontSharp } from 'react-icons/io5'
import { FaShoppingBag } from 'react-icons/fa'
import { RiUserFill, RiSettings5Fill, RiLogoutBoxRLine, RiCoupon3Fill } from 'react-icons/ri'
import { TbReceiptTax } from 'react-icons/tb'
import { BiSupport, BiCarousel } from 'react-icons/bi'
import { RxAvatar } from 'react-icons/rx';
import { BsBookmarkCheckFill } from 'react-icons/bs'
import './sidenav.css'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

const sidebar = [
    { name: "Dashboard", href: "/dashboard", icon: MdDashboard },
    { name: "Geschäftsbereiche", href: "/dashboard/mystore", icon: IoStorefrontSharp },
    { name: "Kagetorien", href: "/dashboard/category", icon: MdCategory },
    { name: "Preisgestaltung", href: "/dashboard/pricing", icon: TbReceiptTax },
    { name: "Bestellungen", href: "/dashboard/order", icon: FaShoppingBag },
    { name: "Buchungen", href: "/dashboard/bookings", icon: BsBookmarkCheckFill },
    { name: "Gutscheine verkauft", href: "/dashboard/coupons", icon: RiCoupon3Fill },
    { name: "Gutscheine", href: "/dashboard/couponCode", icon: RiCoupon3Fill },
    { name: "Deaktivierte Termine", href: "/dashboard/disabled", icon: MdDomainDisabled },
    { name: "Kunden", href: "/dashboard/customers", icon: RiUserFill },
    { name: "Support", href: "/dashboard/support", icon: BiSupport },
    { name: "Banner", href: "/dashboard/banner", icon: BiCarousel },
]

export default function Sidebar(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/');
    };

    return (
        <div>
            {/* SIDEBAR */}
            <section id="sidebar" className=''>
                <RouterLink to='/' className="flex justify-center brand">
                    <span className="sr-only">Sapphire 365</span>
                    <img
                        className="w-3/5 h-16 mt-8"
                        src="../../../logo_WG_Schowalter_orange_main.png"
                        alt=""
                    />
                </RouterLink>
                <ul className="side-menu top">
                    {sidebar.map((side, index) => (
                        <li key={index} className={side.name === props.menu ? 'active' : ''}>
                            <RouterLink to={side.href}>
                                <Icon as={side.icon} className='bx' />
                                <span className="hidden text md:flex">{side.name}</span>
                            </RouterLink>
                        </li>
                    ))
                    }
                </ul>

            </section>
            {/* SIDEBAR */}
            <div>
                {/* <!-- CONTENT --> */}
                <section id="content">
                    {/* <!-- NAVBAR --> */}
                    <nav>
                        <div className='flex w-full justify-between'>
                            <div className='flex items-center'>
                                <a href="#" className="profile">
                                    <Icon as={RxAvatar} boxSize='10' />
                                    {/* <img src="img/people.png" /> */}
                                </a>
                                <p>{userInfo.name}</p>
                            </div>
                            <div className="flex items-center">
                                <ul className="side-menu">
                                    <li>
                                        <a href='#' onClick={logoutHandler} className="flex items-center">
                                            <Icon as={RiLogoutBoxRLine} className='bx' color='red.400' />
                                            <span className="hidden text-red-400 md:flex">Logout</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* <!-- NAVBAR --> */}
                </section>
            </div>
        </div>
    )
}