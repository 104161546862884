import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import { createFaq, deleteFaq, listFaqs, listSupportDetails, updateSupport } from '../actions/supportFaqActions';
import './sidenav.css'
import { FAQ_CREATE_RESET, SUPPORT_UPDATE_RESET } from '../constants/supportFaqConstants';
import { useNavigate } from 'react-router-dom';
import { Icon, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { IoAdd, IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { CiMenuKebab } from 'react-icons/ci';

const SupportForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mail, setMail] = useState('');
    const [call, setCall] = useState(0);
    const [address, setAddress] = useState('');

    const faqList = useSelector((state) => state.faqList);
    const { loading, error, faqs } = faqList;

    const supportDetails = useSelector((state) => state.supportDetails);
    const { support } = supportDetails;

    const supportUpdate = useSelector((state) => state.supportUpdate);
    const {
        success: successUpdate,
    } = supportUpdate;

    const faqCreate = useSelector((state) => state.faqCreate);
    const { loading: loadingFaqCreate, success: successFaqCreate, error: erroeFaqCreate, faq: createdFaq } = faqCreate;

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: SUPPORT_UPDATE_RESET });
            dispatch(listSupportDetails());
            navigate(`/dashboard/support`);
        } else {
            if (!support?.mail) {
                dispatch(listSupportDetails());
            } else {
                setMail(support.mail);
                setCall(support.call);
                setAddress(support.address);
            }
        }

        if (successFaqCreate) {
            dispatch({ type: FAQ_CREATE_RESET });
            navigate(`/dashboard/faq/${createdFaq._id}/edit`);
        }
    }, [dispatch, navigate, support, successUpdate, createdFaq, successFaqCreate]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            updateSupport({
                mail,
                call,
                address
            })
        );
    };

    useEffect(() => {
        dispatch(listFaqs());
        dispatch(listSupportDetails());
    }, [dispatch, successFaqCreate]);

    const createFaqHandler = () => {
        dispatch(createFaq({
            question: '',
            answer: ''
        }));
    }

    const deleteHandler = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteFaq(id))
        }
    }

    return (
        <div>
            <Sidebar menu='Support' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <div className="table-data flex flex-col">
                        <div className="flex flex-col gap-8 py-4 drop-shadow-md">
                            <div className="self-center text-2xl font-bold underline underline-offset-8">
                                Support
                            </div>
                            <div className="self-center text-lg text-gray-400 font-bold text-center">
                                Need more help? Our support team is here to help you.
                            </div>
                            <form onSubmit={submitHandler}>
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 gap-y-10">
                                    <div className="mb-2">
                                        <label htmlFor="Email" className="block font-medium text-sm mb-1">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            id="Email"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={mail}
                                            onChange={(e) => setMail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="phone" className="block font-medium text-sm mb-1">
                                            Phone
                                        </label>
                                        <input
                                            type="text"
                                            id="phone"
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={call}
                                            onChange={(e) => setCall(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="address" className="block font-medium text-sm mb-1">
                                            Address
                                        </label>
                                        <textarea
                                            className="w-full border border-black rounded px-3 py-2"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="self-center w-28 bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                                >
                                    Submit
                                </button>
                            </form>

                        </div>
                        <div className="table-data drop-shadow-md">
                            <div class="order">
                                <div class="head">
                                    <h3>FAQ's</h3>
                                    <i class='bx bx-search' ></i>
                                    <i class='bx bx-filter' ></i>
                                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded"
                                        onClick={createFaqHandler}
                                    >
                                        <Icon as={IoAdd} mr='2' fontSize='xl' fontWeight='bold' />Add FAQ
                                    </button>
                                </div>
                                {loading ? (
                                    <div>
                                        Loading
                                    </div>
                                ) : error ? (
                                    <div>
                                        Error
                                    </div>
                                ) : (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Question</th>
                                                <th>Answer</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {faqs.map((faq, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>
                                                        {faq.question}
                                                    </td>
                                                    <td>
                                                        {faq.answer}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Menu>
                                                                <RouterLink>
                                                                    <MenuButton className="text-sm font-medium leading-6 text-gray-900"><CiMenuKebab className='h-7 w-7 rotate-90' /></MenuButton>
                                                                </RouterLink>
                                                                <Portal>
                                                                    <MenuList className="text-sm font-semibold uppercase leading-6 text-gray-900" zIndex='99'>
                                                                        <MenuItem as={RouterLink} to={`/dashboard/faq/${faq._id}/edit`} ><IoPencilSharp className='h-5 w-5 mr-4' /> Edit</MenuItem>
                                                                        <MenuItem onClick={() => deleteHandler(faq._id)} color='red.400'><IoTrashBinSharp className='h-5 w-5 mr-4' /> Delete</MenuItem>
                                                                    </MenuList>
                                                                </Portal>
                                                            </Menu>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </section >
        </div >
    );
};

export default SupportForm;