import React from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import "./sidenav.css";

import Sidebar from "../components/Sidebar";
import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from "../constants/orderConstants";
import {
  deliverOrder,
  getOrderDetails,
  payOrder,
} from "../actions/orderActions";
import { ImCheckboxChecked } from "react-icons/im";
import { FcCancel } from "react-icons/fc";
import { CiDeliveryTruck } from "react-icons/ci";

const OrderScreenAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: orderId } = useParams();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    order.itemsPrice = order.orderItems.reduce(
      (acc, currVal) => acc + currVal.price * currVal.qty,
      0
    );
  }

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };

  useEffect(() => {
    dispatch({ type: ORDER_PAY_RESET });
    dispatch({ type: ORDER_DELIVER_RESET });

    if (!order || successPay) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, successPay, order, successDeliver]);

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
    navigate(`/dashboard/order`);
  };

  return loading ? (
    <div>Loading</div>
  ) : // <Loader />
  error ? (
    <div type="error">{error}</div>
  ) : (
    <div>
      <Sidebar
        menu={order.type === "coupon" ? "Gutscheine verkauft" : "Bestellungen"}
      />
      {/* <!-- CONTENT --> */}
      <section id="content">
        {/* <!-- MAIN --> */}
        <main>
          <Flex>
            {/* <Header /> */}
            <Flex w="full" py="5" direction="column">
              <Grid templateColumns={{ sm: "3fr fr", md: "3fr 2fr" }} gap="5">
                {/* Column 1 */}
                <Flex
                  direction="column"
                  mx="4"
                  shadow="md"
                  p="8"
                  bgColor="white"
                >
                  {/* Shipping */}
                  <Box borderBottom="1px" borderColor="gray.300">
                    <Heading
                      as="h2"
                      mb="3"
                      fontSize="2xl"
                      fontWeight="semibold"
                    >
                      Ihre Bestellung
                    </Heading>
                    <Text>
                      Name: <strong>{order.shippingAddress?.name}</strong>
                    </Text>
                    <Text>
                      Email:{" "}
                      <strong>
                        <a href={`mailto:${order.shippingAddress?.email}`}>
                          {order.shippingAddress?.email}
                        </a>
                      </strong>
                    </Text>
                    {order.type !== "coupon" && (
                      <Text>
                        Address:{" "}
                        <strong>
                          {order.shippingAddress.address},{" "}
                          {order.shippingAddress.city},{" "}
                          {order.shippingAddress.postalCode},{" "}
                          {order.shippingAddress.country}
                        </strong>
                      </Text>
                    )}
                    {order.type === "coupon" && (
                      <>
                        <Text>
                          Erwachsene: <strong>{order.adults}</strong>
                        </Text>
                        <Text>
                          Kinder: <strong>{order.children}</strong>
                        </Text>
                      </>
                    )}
                    <Text mt="4">
                      {order.isDelivered === 1 ? (
                        <div className="flex gap-2">
                          <div>
                            <Icon
                              as={ImCheckboxChecked}
                              color="green.400"
                              className="rounded-full"
                            />{" "}
                            Abgeschlossen,
                          </div>
                          <div className="font-semibold">
                            {new Date(order.deliveredAt).toDateString()}
                          </div>
                        </div>
                      ) : order.isDelivered === 2 ? (
                        <div className="flex gap-2">
                          <div>
                            <Icon
                              as={FcCancel}
                              color="green.400"
                              className="rounded-full"
                            />{" "}
                            Abgesagt
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Icon
                            as={CiDeliveryTruck}
                            color="red.400"
                            boxSize="5"
                          />{" "}
                          wird bald abgeschlossen sein
                        </div>
                      )}
                    </Text>
                  </Box>

                  {/* Payment Method */}
                  <Box borderBottom="1px" py="6" borderColor="gray.300">
                    <Heading
                      as="h2"
                      mb="3"
                      fontSize="2xl"
                      fontWeight="semibold"
                    >
                      Zahlungsabwicklung
                    </Heading>
                    <Text>
                      <strong>Ihre Zahlungsoptionen: </strong>
                      {order.paymentMethod.toUpperCase()}
                    </Text>
                    <Text mt="4">
                      {order.isPaid ? (
                        <>Bezahlt {order.paidAt.substring(0, 10)}</>
                      ) : (
                        <>Zu zahlen</>
                      )}
                    </Text>
                  </Box>

                  {/* Order Items */}
                  <Box borderBottom="1px" py="6" borderColor="gray.300">
                    <Heading
                      as="h2"
                      mb="3"
                      fontSize="2xl"
                      fontWeight="semibold"
                    >
                      Ihre Bestelldaten
                    </Heading>
                    <Box>
                      {order.orderItems.length === 0 ? (
                        <div>Ihre Bestelldaten</div>
                      ) : (
                        <Box py="2">
                          {order.orderItems.map((item, idx) => (
                            <Flex
                              key={idx}
                              alignItems={
                                order.type === "coupon" ? "start" : "center"
                              }
                              flexDirection={
                                order.type === "coupon" ? "column" : "row"
                              }
                              justifyContent="space-between"
                            >
                              <Flex py="2" alignItems="center">
                                <Image
                                  src={item.image}
                                  alt={item.name}
                                  w="12"
                                  h="12"
                                  objectFit="cover"
                                  mr="6"
                                />
                                <Link
                                  fontWeight="bold"
                                  fontSize="lg"
                                  as={RouterLink}
                                  to={`/product/${item.product}`}
                                >
                                  {item.name}
                                </Link>
                                {item.category === "Group Events" ? (
                                  <Text>Teilnehmer: {item.pno}</Text>
                                ) : (
                                  <></>
                                )}
                              </Flex>

                              {order.type === "coupon" ? (
                                <Text fontSize="lg" fontWeight="semibold">
                                  {order.adults} x{" "}
                                  {item.priceAdult
                                    ?.toFixed(2)
                                    .replace(".", ",")}
                                  € + {order.children} x{" "}
                                  {item.priceChild
                                    ?.toFixed(2)
                                    .replace(".", ",")}
                                  € ={" "}
                                  {(
                                    order.adults * item.priceAdult +
                                    order.children * item.priceChild
                                  )
                                    ?.toFixed(2)
                                    .replace(".", ",")}
                                  €
                                </Text>
                              ) : (
                                <Text fontSize="lg" fontWeight="semibold">
                                  {item.qty} x{" "}
                                  {item.price?.toFixed(2).replace(".", ",")}€ ={" "}
                                  {(+item.qty * item.price)
                                    ?.toFixed(2)
                                    .replace(".", ",")}
                                  €
                                </Text>
                              )}
                            </Flex>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Flex>

                {/* Column 2 */}
                <Flex
                  mx="4"
                  direction="column"
                  bgColor="white"
                  justifyContent="space-between"
                  py="8"
                  px="8"
                  shadow="md"
                  rounded="lg"
                  borderColor="gray.300"
                >
                  <Box>
                    <Heading mb="6" as="h2" fontSize="3xl" fontWeight="bold">
                      Ihre Gesamtbestellung
                    </Heading>

                    {/* Items Price */}
                    {/* {order.type !== "coupon" && (
                      <Flex
                        borderBottom="1px"
                        py="2"
                        borderColor="gray.200"
                        alignitems="center"
                        justifyContent="space-between"
                      >
                        <Text fontSize="xl">Produkte</Text>
                        <Text fontWeight="bold" fontSize="xl">
                          {order.itemsPrice?.toFixed(2).replace(".", ",")}€
                        </Text>
                      </Flex>
                    )} */}

                    <div className="flex justify-between py-2">
                      <p className="text-gray-700">Produkte</p>
                      <p className="text-gray-700">
                        {order.itemsPrice?.toFixed(2).replace(".", ",")}€
                      </p>
                    </div>

                    {order.shippingPrice !== 0 && (
                      <div className="flex justify-between py-2">
                        <p className="text-gray-700">Frachtkosten</p>
                        <p className="text-gray-700">
                          {order.shippingPrice?.toFixed(2).replace(".", ",")}€
                        </p>
                      </div>
                    )}

                    {order.discount !== 0 && (
                      <div className="flex justify-between py-2">
                        <p className="text-gray-700">Rabatt(%)</p>
                        <p className="text-gray-700">{order.discount}%</p>
                      </div>
                    )}

                    {order.couponCode && (
                      <div className="flex justify-between py-2">
                        <p className="text-gray-700">Gutschein Code</p>
                        <p className="text-gray-700">{order.couponCode}</p>
                      </div>
                    )}

                    {/* Total Price */}
                    <Flex
                      borderTop="1px"
                      py="2"
                      borderColor="gray.200"
                      alignitems="center"
                      justifyContent="space-between"
                    >
                      <Text fontSize="xl">Summe</Text>
                      <Text fontWeight="bold" fontSize="xl">
                        {order.totalPrice?.toFixed(2).replace(".", ",")}€
                      </Text>
                    </Flex>
                    <div className="flex justify-between py-2">
                      <p className="text-gray-700">Inklusive MwSt</p>
                      <p className="text-gray-700">
                        {order.taxPrice?.toFixed(2).replace(".", ",")}€
                      </p>
                    </div>
                  </Box>

                  {loadingDeliver && <>Loading</>}
                  {userInfo &&
                    userInfo.isAdmin &&
                    order.isPaid &&
                    !order.isDelivered && (
                      <Button
                        type="button"
                        colorScheme="teal"
                        onClick={deliverHandler}
                      >
                        Als geliefert markieren
                      </Button>
                    )}
                </Flex>
              </Grid>
            </Flex>
          </Flex>
        </main>
      </section>
    </div>
  );
};

export default OrderScreenAdmin;
