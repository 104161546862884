import { Link as RouterLink } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import './sidenav.css'

export default function MyStore() {
    return (
        <div>
            <Sidebar menu='Geschäftsbereiche' />
            {/* <!-- CONTENT --> */}
            <section id="content">
                {/* <!-- MAIN --> */}
                <main>
                    <div className="font-semibold my-6">
                        Inventarverwaltung....
                    </div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                        <div className="flex flex-col rounded-lg p-4 bg-white drop-shadow-sm hover:scale-105 transition-all hover:drop-shadow-md">
                            <RouterLink to={`/dashboard/mystore/wein?category=wein`} className="group">
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src='../Schowalter-Lifestyle-Juni2023-23.jpg'
                                        alt='category'
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                                <p className="mt-4 text-center text-lg font-medium text-gray-900">Wein</p>
                                <h3 className="mt-1 text-center text-sm text-gray-700">Sie können hier alle Arten von Wein hinzufügen.</h3>
                            </RouterLink>
                        </div>

                        <div className="flex flex-col rounded-lg p-4 drop-shadow-sm bg-white hover:scale-105 transition-all hover:drop-shadow-md">
                            <RouterLink to={`/dashboard/mystore/hofladen?category=hofladen`} className="group">
                                <p className="mt-1 text-center text-lg font-medium text-gray-900">Hofladen</p>
                                <h3 className="my-2 text-center text-sm text-gray-700">Produktverwaltung im Hofladen</h3>
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src='../Alpakaseife-1024x683.jpg'
                                        alt='category'
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                            </RouterLink>
                        </div>

                        <div className="flex flex-col rounded-lg p-4 drop-shadow-sm bg-white hover:scale-105 transition-all hover:drop-shadow-md">
                            <RouterLink to={`/dashboard/mystore/solo-events?category=solo-events`} className="group">
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src='../Kein-Titel-1080-1080-px-2.png'
                                        alt='category'
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                                <p className="mt-1 text-center text-lg font-medium text-gray-900">Solo Events</p>
                                <h3 className="my-2 text-center text-sm text-gray-700">Verwaltung aller Events</h3>
                            </RouterLink>
                        </div>

                        <div className="flex flex-col rounded-lg p-4 drop-shadow-sm bg-white hover:scale-105 transition-all hover:drop-shadow-md">
                            <RouterLink to={`/dashboard/mystore/group-events?category=group-events`} className="group">
                                <p className="mt-1 text-center text-lg font-medium text-gray-900">Group Events</p>
                                <h3 className="my-2 text-center text-sm text-gray-700">Verwaltung aller Events</h3>
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src='../Kein-Titel-1080-1080-px-2.png'
                                        alt='category'
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                            </RouterLink>
                        </div>

                        <div className="flex flex-col rounded-lg p-4 drop-shadow-sm bg-white hover:scale-105 transition-all hover:drop-shadow-md">
                            <RouterLink to={`/dashboard/mystore/camping?category=camping`} className="group">
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src='../camping.png'
                                        alt='category'
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                                <p className="mt-1 text-center text-lg font-medium text-gray-900">Camping</p>
                                <h3 className="my-2 text-center text-sm text-gray-700">Verwalten Sie Ihre Campingdienste</h3>
                            </RouterLink>
                        </div>

                        <div className="flex flex-col rounded-lg p-4 drop-shadow-sm bg-white hover:scale-105 transition-all hover:drop-shadow-md">
                            <RouterLink to={`/dashboard/mystore/alpaka?category=alpaka`} className="group">
                                <p className="mt-1 text-center text-lg font-medium text-gray-900">Alpaka</p>
                                <h3 className="my-2 text-center text-sm text-gray-700">Verwaltung aller Alpaka's</h3>
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src='../Alpaka_Date.jpg'
                                        alt='category'
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                            </RouterLink>
                        </div>
                    </div>
                </main>
            </section>
        </div>
    )
}