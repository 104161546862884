import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import './sidenav.css'
import { Button, Icon } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteCategory, listSubCategory } from "../actions/categoryActions";
import { CATEGORY_CREATE_RESET } from "../constants/categoryConstants";
import Message from "../components/Message";
import { RiDeleteBinLine } from "react-icons/ri";

export default function SubCategory() {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const subCategoryList = useSelector((state) => state.subCategoryList);
	const { loading, error, subCategory } = subCategoryList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const categoryDelete = useSelector((state) => state.categoryDelete);
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = categoryDelete;

	useEffect(() => {
		dispatch({ type: CATEGORY_CREATE_RESET });

		if (!userInfo.isAdmin) {
			navigate('/login');
		} else {
			dispatch(listSubCategory(id));
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		successDelete
	]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteCategory(id));
		}
	};

	const createCategoryHandler = () => {
		navigate(`/dashboard/category/${id}/add`);
	};

	return (
		<div>
			<Sidebar menu='Kategorie' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>
					<div className="table-data drop-shadow-md">
						<div className="order">
							<div className="head">
								<h3>Sub Kategorie</h3>
								<Button colorScheme='blue' onClick={createCategoryHandler} >
									<Icon as={IoAdd} mr='2' fontSize='xl' fontWeight='bold' />Add Sub Kategorie
								</Button>
							</div>

							{loading ? (
								<>Loading</>
							) : error ? (
								<Message type="error">{error}</Message>
							) : (
								<div>
									{subCategory.map((catObj, idx) => (
										<div key={idx} className="flex justify-between item-center rounded-lg p-4 my-4 bg-white drop-shadow-sm">
											<div className="group">
												<p className="text-center text-lg font-medium text-gray-900">{catObj.name}</p>
											</div>
											<RiDeleteBinLine className="text-2xl text-red-500" onClick={() => deleteHandler(catObj._id)} />
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</main>
			</section>
		</div>
	)
}