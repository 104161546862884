import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import {
  login,
  register,
  validateOtpUser,
  forgotPassword as forgotpass,
} from "../actions/userActions";
import { USER_OTP_VALIDATE_RESET } from "../constants/userConstants";

export default function OtpScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(300);
  const [resendTime, setResendTime] = useState(120);

  const [searchParams] = useSearchParams();

  let type = searchParams.get("type");

  const [email, setEmail] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const inputRefs = useRef([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const userOtpValidate = useSelector((state) => state.userOtpValidate);
  const { loading: loadingOtp, success, error: errorOtp } = userOtpValidate;

  const forgotPassword = useSelector((state) => state.forgotPassword);
  const {
    loading: loadingOTP,
    successOTP,
    error: errorOTP,
    email: emailOTP,
  } = forgotPassword;

  const loginDataFromStorage = JSON.parse(localStorage.getItem("userInfo"));

  const resisterDataFromStorage = JSON.parse(
    localStorage.getItem("userInfoRegister")
  );

  useEffect(() => {
    // if (type === 0) {
    //   if (userInfo && userInfo.email) {
    //     setEmail(userInfo.email);
    //   }
    // } else {
    //   if (successOTP && emailOTP) {
    //     setEmail(emailOTP);
    //   }
    // }
    dispatch({ type: USER_OTP_VALIDATE_RESET });
    if (type === "register") {
      if (resisterDataFromStorage && resisterDataFromStorage.email) {
        setEmail(resisterDataFromStorage.email);
      }
    } else if (type === "signup") {
      if (loginDataFromStorage && loginDataFromStorage.email) {
        setEmail(loginDataFromStorage.email);
      }
    } else if (type === "forgotpass") {
      if (successOTP && emailOTP) {
        setEmail(emailOTP);
      }
    }
  }, [successOTP, emailOTP]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const resendInterval = setInterval(() => {
      setResendTime((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (resendTime === 0) {
      clearInterval(resendInterval);
    }
    return () => {
      clearInterval(resendInterval);
    };
  }, [resendTime]);

  const formattedTime = (seconds) => {
    return `${Math.floor(seconds / 60)}:${(seconds % 60).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    )}`;
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_OTP_VALIDATE_RESET });
      if (type === "register" || type === "signup") {
        navigate("/signup");
      } else if (type === "forgotpass") {
        navigate("/resetPass");
      }
    }
  }, [success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(validateOtpUser(email, otpValue, type));
  };

  function handleInput(inputIndex, event) {
    const { value } = event.target;
    const updatedOtpValue =
      otpValue.substring(0, inputIndex) +
      value +
      otpValue.substring(inputIndex + 1);
    setOtpValue(updatedOtpValue);

    const prevInput = inputRefs.current[inputIndex - 1];
    const nextInput = inputRefs.current[inputIndex + 1];

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      !value &&
      prevInput
    ) {
      prevInput.focus();
    } else if (value && nextInput) {
      nextInput.focus();
    }
  }

  function handleKeyDown(event, inputIndex) {
    const input = event.target;
    const maxLength = parseInt(input.getAttribute("maxLength"));

    if (event.key !== "Backspace" && input.value.length >= maxLength) {
      const nextInput = inputRefs.current[inputIndex + 1];

      if (nextInput) {
        event.preventDefault();
        nextInput.focus();
      }
    }
  }

  const handleResend = () => {
    if (resendTime === 0) {
      if (type === "register") {
        dispatch(register("", email, ``, ""));
      } else if (type === "signup") {
        dispatch(login(email, ""));
      } else if (type === "forgotpass") {
        dispatch(forgotpass(email));
      }
      setSeconds(300);
      setResendTime(120);
      dispatch({ type: USER_OTP_VALIDATE_RESET });
    }
  };

  return (
    <div className="flex min-h-full border-b items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Verify with OTP
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitHandler}>
          <div className="flex justify-center">
            <div id="div" className="flex gap-4">
              <input
                ref={(ref) => (inputRefs.current[0] = ref)}
                type="text"
                maxLength="1"
                className="w-8 h-8 p-2 border border-solid border-black"
                value={otpValue.charAt(0)}
                onChange={(e) => handleInput(0, e)}
                onKeyDown={(e) => handleKeyDown(e, 0)}
              />
              <input
                ref={(ref) => (inputRefs.current[1] = ref)}
                type="text"
                maxLength="1"
                className="w-8 h-8 p-2 border border-solid border-black"
                value={otpValue.charAt(1)}
                onChange={(e) => handleInput(1, e)}
                onKeyDown={(e) => handleKeyDown(e, 1)}
              />
              <input
                ref={(ref) => (inputRefs.current[2] = ref)}
                type="text"
                maxLength="1"
                className="w-8 h-8 p-2 border border-solid border-black"
                value={otpValue.charAt(2)}
                onChange={(e) => handleInput(2, e)}
                onKeyDown={(e) => handleKeyDown(e, 2)}
              />
              <input
                ref={(ref) => (inputRefs.current[3] = ref)}
                type="text"
                maxLength="1"
                className="w-8 h-8 p-2 border border-solid border-black"
                value={otpValue.charAt(3)}
                onChange={(e) => handleInput(3, e)}
                onKeyDown={(e) => handleKeyDown(e, 3)}
              />
            </div>
          </div>

          <div className="flex w-full justify-end items-center mb-6">
            <button
              type="button"
              onClick={handleResend}
              disabled={formattedTime(resendTime) !== "0:00"}
              className="flex gap-1 text-[#8e295b] hover:text-[#6b1d45] font-semibold text-sm disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <p>Resend OTP</p>
              <p
                className={`${
                  formattedTime(resendTime) === "0:00" ? "hidden" : "block"
                }`}
              >
                in {formattedTime(resendTime)}
              </p>
            </button>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="group relative flex w-full md:w-1/2 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Verify
            </button>
          </div>
          <div className="flex justify-center text-sm">
            <RouterLink
              to="/registration"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Haveing trouble logging in ? Get Help
            </RouterLink>
          </div>
        </form>
        {error && <div>{error}</div>}
      </div>
    </div>
  );
}
