import {
	Button,
	Icon,
	Menu as ChakraMenu,
	MenuButton,
	Portal,
	MenuList,
	MenuItem
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { IoAdd, IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CiMenuKebab } from 'react-icons/ci';

import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import Message from '../components/Message';
import './sidenav.css'
import { createMenuItem, deleteMenuItem, listMenuItems } from '../actions/menuItemActions';
import { MENU_ITEM_CREATE_RESET } from '../constants/menuItemConstants';

const BookingMenuListScreen = (props) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const routePath = useLocation();
	const queryParams = new URLSearchParams(routePath.search);
	const categoryQry = queryParams.get('category');

	const menuItemList = useSelector((state) => state.menuItemList);
	const { loading, menuItems, error } = menuItemList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const menuItemDelete = useSelector((state) => state.menuItemDelete);
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = menuItemDelete;

	const menuItemCreate = useSelector((state) => state.menuItemCreate);
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		menuItem: createdMenuItem,
	} = menuItemCreate;

	useEffect(() => {
		dispatch({ type: MENU_ITEM_CREATE_RESET });

		if (!userInfo.isAdmin) {
			navigate('/login');
		}

		if (successCreate) {
			navigate(`/dashboard/camping/${createdMenuItem._id}/edit?category=${categoryQry}`);
		} else {
			dispatch(listMenuItems());
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		successDelete,
		successCreate,
		createdMenuItem
	]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteMenuItem(id));
		}
	};

	const createProductHandler = () => {
		dispatch(createMenuItem({
			name: 'sample name',
			image: '',
			description: 'sample description',
			category: 'Camping Services',
			priceAdult: 0,
			priceChild: 0,
			priceGroup: 0
		}));
	};

	return (
		<div>
			<Sidebar menu='My Store' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>

					<div className="table-data drop-shadow-md">
						<div class="order">
							<div class="head">
								<h3>{props.list}</h3>
								{/* <i class='bx bx-search' ></i>
								<i class='bx bx-filter' ></i> */}
								<Button onClick={createProductHandler} colorScheme='blue'>
									<Icon as={IoAdd} mr='2' fontSize='xl' fontWeight='bold' />Add Product
								</Button>
							</div>
							{loadingDelete && <Loader />}
							{errorDelete && <Message type='error'>{errorDelete}</Message>}
							{loadingCreate && <Loader />}
							{errorCreate && <Message type='error'>{errorCreate}</Message>}

							{loading ? (
								<Loader />
							) : error ? (
								<Message type='error'>{error}</Message>
							) : (
								<table>
									<thead>
										<tr>
											<th>Image</th>
											<th className='w-80'>Name</th>
											<th>Category</th>
											{props.list !== 'Events' && (
												<>
													<th>Price(Adult)</th>
													<th>Price(Child)</th>
													<th>Price(Group)</th>
												</>
											)}
											{props.list === 'Events' && (
												<th>Price</th>
											)}
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{menuItems.filter(menu => menu.category === props.list).map((menu, index) => (
											<tr key={index}>
												<td>
													{/* <Icon as={RxAvatar} boxSize='10' /> */}
													<img src={menu.image} alt={menu.name} />
												</td>
												<td>
													<p className='w-80'>{menu.name}</p>
												</td>
												<td>
													{menu.category}
												</td>
												{props.list !== 'Events' && (
													<>
														<td>
															<p>{(menu.priceAdult)?.toFixed(2).replace('.', ',')}€</p>
														</td>
														<td>
															<p>{(menu.priceChild)?.toFixed(2).replace('.', ',')}€</p>
														</td>
														<td>
															<p>{(menu.priceGroup)?.toFixed(2).replace('.', ',')}€</p>
														</td>
													</>
												)}
												{props.list === 'Events' && (
													<td>
														<p>{(menu.price)?.toFixed(2).replace('.', ',')}€</p>
													</td>
												)}
												<td>
													<div>
														<ChakraMenu>
																<MenuButton className="text-sm font-medium leading-6 text-gray-900"><CiMenuKebab className='h-7 w-7 rotate-90' /></MenuButton>
															<Portal>
																<MenuList className="text-sm font-semibold uppercase leading-6 text-gray-900" zIndex='99'>
																	<MenuItem as={RouterLink} to={`/dashboard/camping/${menu._id}/edit?category=${categoryQry}`} ><IoPencilSharp className='h-5 w-5 mr-4' /> Edit</MenuItem>
																	<MenuItem color='red.400' onClick={() => deleteHandler(menu._id)}><IoTrashBinSharp className='h-5 w-5 mr-4' /> Delete</MenuItem>
																</MenuList>
															</Portal>
														</ChakraMenu>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</main>
			</section>
		</div>
	)
};

export default BookingMenuListScreen;
