import axios from 'axios';

import {
	ORDER_CREATE_FAIL,
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_SUCCESS,
	ORDER_DELETE_FAIL,
	ORDER_DELETE_REQUEST,
	ORDER_DELETE_SUCCESS,
	ORDER_DELIVER_FAIL,
	ORDER_DELIVER_REQUEST,
	ORDER_DELIVER_SUCCESS,
	ORDER_DETAILS_FAIL,
	ORDER_DETAILS_REQUEST,
	ORDER_DETAILS_SUCCESS,
	ORDER_LIST_FAIL,
	ORDER_LIST_REQUEST,
	ORDER_LIST_SUCCESS,
	ORDER_MY_LIST_FAIL,
	ORDER_MY_LIST_REQUEST,
	ORDER_MY_LIST_SUCCESS,
	ORDER_PAY_FAIL,
	ORDER_PAY_REQUEST,
	ORDER_PAY_SUCCESS,
	ORDER_STATS_FAIL,
	ORDER_STATS_REQUEST,
	ORDER_STATS_SUCCESS,
} from '../constants/orderConstants';
import { serverIp } from '../config/conf';

export const createOrder = (order) => async (dispatch) => {
	try {
		dispatch({ type: ORDER_CREATE_REQUEST });

		const { data } = await axios.post(`${serverIp}/api/orders`, order);

		dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ORDER_CREATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const getOrderDetails = (id) => async (dispatch) => {
	try {
		dispatch({ type: ORDER_DETAILS_REQUEST });

		const { data } = await axios.get(`${serverIp}/api/orders/${id}`);

		dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ORDER_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const deleteOrder = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/orders/${id}`, config);

		dispatch({ type: ORDER_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: ORDER_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const payOrder =
	(orderId, paymentResult) => async (dispatch) => {
		try {
			dispatch({ type: ORDER_PAY_REQUEST });

			const { data } = await axios.put(
				`${serverIp}/api/orders/${orderId}/pay`,
				paymentResult
			);

			dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
		} catch (err) {
			dispatch({
				type: ORDER_PAY_FAIL,
				payload:
					err.response && err.response.data.message
						? err.response.data.message
						: err.message,
			});
		}
	};

export const listMyOrders = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_MY_LIST_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/orders/myorders`, config);

		dispatch({ type: ORDER_MY_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ORDER_MY_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listOrders = (sortFor, sortTo) => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_LIST_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/orders?sortFor=${sortFor}&sortTo=${sortTo}`, config);

		dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ORDER_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const deliverOrder = (order) => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_DELIVER_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/orders/${order._id}/deliver`,
			order,
			config
		);

		dispatch({ type: ORDER_DELIVER_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ORDER_DELIVER_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const getOrderStats = (startDate, endDate, state, sortFor, sortTo) => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_STATS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.get(`${serverIp}/api/orders/stats?startDate=${startDate}&endDate=${endDate}&state=${state}&sortFor=${sortFor}&sortTo=${sortTo}`, config);

		dispatch({
			type: ORDER_STATS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: ORDER_STATS_FAIL,
			payload: error.response && error.response.data.message
				? error.response.data.message
				: error.message,
		});
	}
};
