import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Message from "./Message";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const callouts = [
    {
        name: 'WEINE',
        description: 'Köstliche Weine aus der Pfalz',
        imageSrc: 'Schowalter-Lifestyle-Juni2023-23.jpg',
        imageAlt: 'WEINE',
        href: '/wein?cat=all',
    },
    {
        name: 'HOFLADEN',
        description: 'Regionale natürliche Produkte',
        imageSrc: 'Alpakaseife-1024x683.jpg',
        imageAlt: 'HOFLADEN',
        href: '/hofladen?cat=hofladen',
    },
    {
        name: 'EVENTS',
        description: 'Genieße tolle Events bei uns',
        imageSrc: 'image2.png',
        imageAlt: 'EVENTS',
        href: '/events',
    },
    {
        name: 'CAMPING',
        description: 'Der Stellplatz der besonderen Art',
        imageSrc: 'camping.png',
        imageAlt: 'CAMPING',
        href: '/camping/#headerSearch',
    },
    {
        name: 'ALPAKAS',
        description: 'Verbringe etwas Zeit mit süßen Alpakas',
        imageSrc: 'titelbild.jpg',
        imageAlt: 'ALPAKAS',
        href: '/alpaka',
    },
    // {
    //     name: 'Support & FAQ',
    //     description: 'Du hast Fragen?',
    //     imageSrc: 'image1.png',
    //     imageAlt: 'Support & FAQ',
    //     href: '/supportFAQ',
    // },
]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    console.log(props)
    return (
        <MdNavigateNext
            className={className}
            // style={{ ...style, display: "block", backgroundColor: "black", color: 'black', paddingTop: '1px', borderRadius: '50%' }}
            style={{ color: "gray", fontSize: "45px", height: '55px', width: '55px', paddingLeft: '20px' }}
            onClick={onClick}
        >
            {/* <MdNavigateNext className='' style={{ color: "gray", fontSize: "45px" }} /> */}
        </MdNavigateNext>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <MdNavigateBefore
            className={className}
            // style={{ ...style, display: "block", backgroundColor: "black", color: 'black', paddingTop: '1px', borderRadius: '50%' }}
            style={{ color: "gray", fontSize: "45px", height: '55px', width: '55px', paddingRight: '20px' }}
            onClick={onClick}
        >
            {/* text-black w-10 h-10 */}
            {/* <MdNavigateBefore className='' style={{ color: "gray", fontSize: "45px" }} /> */}
        </MdNavigateBefore>
    );
}

export default function Category() {

    const { t, i18n } = useTranslation();

    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div id="das-weinhaus" className="bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] py-4">

            <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6`}>
                <p className='text-center text-xl text-white font-semibold'>Kategorie auswählen</p>

                <Slider prevArrow={<SamplePrevArrow />} nextArrow={<SampleNextArrow />} {...settings}>
                    {callouts.map((callout, idx) => (
                        <div key={idx} className={`cursor-pointer p-3 md:p-6 group`}>
                            <RouterLink className='relative h-24 md:h-48 block' to={callout.href}>
                                <img src={callout.imageSrc} alt={callout.imageAlt} className="w-full h-full object-cover object-conter ease-in transition hover:scale-105" />
                                <p className='absolute absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 bg-black bg-opacity-30 hover:bg-opacity-60 w-full h-full flex text-white items-center justify-center text-sm md:text-xl'>{callout.name}</p>
                            </RouterLink>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 border-y-2 border-white">

                {/* <div className="bg-gray-100 my-4">
                    <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl py-6 sm:py-24 lg:max-w-none lg:py-8">
                            <h2 className="text-2xl text-center font-bold text-gray-900">Willkommen in unserem Shop!</h2>
                            <h3 className="text-lg text-center text-justify font-semibold text-gray-900">Buche jetzt Dein unvergessliches Camping-Erlebnis, tolle Events oder kaufe unsere regionalen Weine und Produkte. Viel Spaß beim Stöbern!</h3>

                            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
                                {callouts.map((callout) => (
                                    <div key={callout.name} className="group relative">
                                        <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-64">
                                            <img
                                                src={callout.imageSrc}
                                                alt={callout.imageAlt}
                                                className="h-full w-full object-cover object-center"
                                            />
                                        </div>
                                        <h3 className="mt-6 text-sm text-gray-500">
                                            <RouterLink to={callout.href}>
                                                <span className="absolute inset-0" />
                                                {callout.name}
                                            </RouterLink>
                                        </h3>
                                        <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="flex flex-col gap-4 mx-auto max-w-2xl py-15 sm:py-20 lg:max-w-none lg:py-5">
                    <div className="flex w-full justify-center">
                        <h1 className="text-xl text-white md:text-5xl underline underline-offset-8 decoration-blue-800 decoration-2 font-semibold my-6">DAS WEINGUT</h1>
                    </div>

                    <div className="lg:grid lg:grid-cols-3 lg:gap-x-6 mb-4">
                        <RouterLink className="flex flex-col gap-8 justify-start opacity-75 hover:opacity-100">
                            <p className="font-bold text-9xl pb-2 text-white">
                                01
                            </p>
                            <p className="font-semibold text-3xl pb-2 text-white">
                                Wir lieben was wir tun!
                            </p>
                            <p className="font-semibold text-xl pb-2 text-white">
                                Die Wurzeln unseres Weins gehen tief. Seit 1794 ist das Weingut Schowalter im Familienbesitz. Das hilft uns die Weinberge so zu kennen, zu lieben und zu pflegen, wie sie es verdienen. Das Handwerk lernten daher sowohl Martin als auch Ute von klein auf. Doch neben der gut gereiften Tradition, streben sie auch danach, mutig neue Wege zu beschreiten. Neben den klassischen Trauben, suchen sie deshalb im Exotischen eine neue Geschmacksvielfalt.
                            </p>
                        </RouterLink>
                        <RouterLink className="flex flex-col gap-8 justify-start opacity-75 hover:opacity-100">
                            <p className="font-bold text-9xl pb-2 text-white">
                                02
                            </p>
                            <p className="font-semibold text-3xl pb-2 text-white">
                                Mehr als Wein
                            </p>
                            <p className="font-semibold text-xl pb-2 text-white">
                                Wir denken GRÖSSER. Mit unserem Hof streben wir nach einem gesamtheitlichen Konzept. Nachhaltigkeit ist nicht nur ein Wert, den man vertreten kann sondern ein Lebensstil. Wir als Winzerfamilie leben von der Natur und haben daher das Anliegen uns auch darum zu kümmern. Wir leben energieeffizient mit unserer nachgeführten PV-Anlage. Durch den Anbau von Miscanthus (China-Schilf) binden wir seit 2007 pro Hektar 53 Tonnen CO² pro Jahr und generieren unseren eigenen Bio-Dünger. Das Projekt Bee-Rangers schafft Lebensraum für unsere kleinen Freunde und auf unserem Hof fühlen sich auch die etwas größeren Alpaka wohl.
                            </p>
                        </RouterLink>
                        <RouterLink className="flex flex-col gap-8 justify-start opacity-75 hover:opacity-100">
                            <p className="font-bold text-9xl pb-2 text-white">
                                03
                            </p>
                            <p className="font-semibold text-3xl pb-2 text-white">
                                Unsere Weine
                            </p>
                            <p className="font-semibold text-xl pb-2 text-white">
                                Da wir tatsächlich noch selbst im Weinberg stehen, kennen wir das Bedürfnis jedes einzelnen Rebstocks.

                                Wir reagieren nachhaltig mit viel Ruhe und Erfahrung aus zehn Generationen, um euch die bestmögliche Qualität zu präsentieren.

                                Vom daily stuff über Prickendes bis hin zu High end ist für jeden etwas dabei.
                            </p>
                        </RouterLink>
                    </div>

                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col w-full md:w-2/3">
                            <p className="font-semibold text-3xl pb-2 text-white">
                                SOZIALES ENGAGEMENT
                            </p>
                            <p className="font-semibold text-xl pb-2 text-white">
                                Wir leben nicht allein auf der Welt. Wenn man sich dem bewusst ist, guckt man über seinen Tellerrand hinaus. Erfahrungen aus den Slums in Südafrika und Begegnung mit Heimatlosigkeit in Amerika, haben uns in dieser Hinsicht Wachgerüttelt. Wenn man dann Menschen kennen lernt, die Hoffnung in die Welt bringen, unterstützen wir gerne.
                            </p>
                            <a className="font-semibold text-lg pb-2 text-white" href="https://www.chosengeneration.de/" target="_blank">
                                https://www.chosengeneration.de/
                            </a>
                            <a className="font-semibold text-lg pb-2 text-white" href="https://vhicf.org/de/" target="_blank">
                                https://vhicf.org/de/
                            </a>
                        </div>
                        <div className="w-full md:w-1/3">
                            <img className="w-full h-[262px] mb-4 left-0 top-0" src="Design_ohne_Titel.png"></img>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
