import axios from "axios";
import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  GET_ALL_COUPONS_REQUEST,
  GET_ALL_COUPONS_SUCCESS,
  GET_ALL_COUPONS_FAILURE,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  CHECK_COUPON_FAILURE,
  CHECK_COUPON_REQUEST,
  CHECK_COUPON_SUCCESS,
  COUPON_UPDATE_REQUEST,
  COUPON_UPDATE_SUCCESS,
  COUPON_UPDATE_FAIL,
} from "../constants/couponConstants";
import { serverIp } from "../config/conf";

// Create a new coupon
export const createCoupon = (couponData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_COUPON_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await axios.post(
      `${serverIp}/api/coupons/create`,
      couponData,
      config
    );

    dispatch({
      type: CREATE_COUPON_SUCCESS,
      payload: response.data.coupon,
    });
  } catch (error) {
    dispatch({
      type: CREATE_COUPON_FAILURE,
      payload: error.response.data.message || "Failed to create coupon",
    });
  }
};

export const updateCoupon = (couponData) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUPON_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await axios.put(
      `${serverIp}/api/coupons/${couponData?._id}`,
      couponData,
      config
    );

    dispatch({
      type: COUPON_UPDATE_SUCCESS,
      payload: response.data.coupon,
    });
  } catch (error) {
    dispatch({
      type: COUPON_UPDATE_FAIL,
      payload: error.response.data.message || "Failed to create coupon",
    });
  }
};

// Get all coupons
export const getAllCoupons = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_COUPONS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    if (userInfo && userInfo?.isAdmin) {
      const { data } = await axios.get(`${serverIp}/api/coupons/adminCoupon`);

      dispatch({
        type: GET_ALL_COUPONS_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`${serverIp}/api/coupons`);

      dispatch({
        type: GET_ALL_COUPONS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COUPONS_FAILURE,
      payload: error.response.data.message || "Failed to get coupons",
    });
  }
};

// Get a single coupon by ID
export const getCouponById = (couponId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_COUPON_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await axios.get(
      `${serverIp}/api/coupons/${couponId}`,
      config
    );

    dispatch({
      type: GET_COUPON_SUCCESS,
      payload: response.data.coupon,
    });
  } catch (error) {
    dispatch({
      type: GET_COUPON_FAILURE,
      payload: error.response.data.message || "Failed to get coupon",
    });
  }
};

// Delete a coupon by ID
export const deleteCoupon = (couponId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_COUPON_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/coupons/${couponId}`, config);

    dispatch({ type: DELETE_COUPON_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_COUPON_FAILURE,
      payload: error.response.data.message || "Failed to delete coupon",
    });
  }
};

// Check the validation of a coupon code
export const checkCoupon = (couponCode, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECK_COUPON_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await axios.post(
      `${serverIp}/api/coupons/check?type=${type}`,
      { couponCode },
      config
    );

    dispatch({
      type: CHECK_COUPON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CHECK_COUPON_FAILURE,
      payload: error.response.data.message || "Failed to check coupon",
    });
  }
};
