import axios from 'axios';
import {
	BOOKING_ADD_ITEM,
	BOOKING_REMOVE_ITEM,
	BOOKING_SAVE_PAYMENT_METHOD,
	BOOKING_SAVE_SHIPPING_ADDRESS,
	CREATE_PRICING_REQUEST,
	CREATE_PRICING_SUCCESS,
	CREATE_PRICING_FAIL,
	GET_PRICING_REQUEST,
	GET_PRICING_SUCCESS,
	GET_PRICING_FAIL,
	UPDATE_PRICING_REQUEST,
	UPDATE_PRICING_SUCCESS,
	UPDATE_PRICING_FAIL,
	DELETE_PRICING_REQUEST,
	DELETE_PRICING_SUCCESS,
	DELETE_PRICING_FAIL,
	SET_BOOKING_DATE_OPTIONS
} from '../constants/bookingItemConstants';
import { serverIp } from '../config/conf';

export const addToBooking = (id) => async (dispatch, getState) => {

	console.log(id);
	const { data } = await axios.get(`${serverIp}/api/menu/${id}`);

	dispatch({
		type: BOOKING_ADD_ITEM,
		payload: {
			_id: data._id,
			name: data.name,
			image: data.image,
			priceAdult: data.priceAdult,
			priceChild: data.priceChild,
			priceGroup: data.priceGroup,
			description: data.description,
			category: data.category
		},
	});

	localStorage.setItem('bookingMenuItems', JSON.stringify(getState().booking.bookingMenuItems));
};

export const removeFromBooking = (id) => (dispatch, getState) => {
	dispatch({ type: BOOKING_REMOVE_ITEM, payload: id });

	localStorage.setItem('bookingMenuItems', JSON.stringify(getState().booking.bookingMenuItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
	dispatch({ type: BOOKING_SAVE_SHIPPING_ADDRESS, payload: data });

	localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
	dispatch({ type: BOOKING_SAVE_PAYMENT_METHOD, payload: data });

	localStorage.setItem('paymentMethod', JSON.stringify(data));
};

export const createPricingEntry = (perDayPrice) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_PRICING_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.post(`${serverIp}/api/pricing`, { perDayPrice }, config);

		dispatch({ type: CREATE_PRICING_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: CREATE_PRICING_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		});
	}
};

// Get all pricing entries
export const getPricingEntries = () => async (dispatch) => {
	try {
		dispatch({ type: GET_PRICING_REQUEST });

		const { data } = await axios.get(`${serverIp}/api/price`);

		dispatch({ type: GET_PRICING_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: GET_PRICING_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		});
	}
};

// Update pricing entry
export const updatePricingEntry = (pricingId, priceObject) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_PRICING_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(`${serverIp}/api/price/${pricingId}`, priceObject, config);

		dispatch({ type: UPDATE_PRICING_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: UPDATE_PRICING_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		});
	}
};

// Delete pricing entry
export const deletePricingEntry = (pricingId) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_PRICING_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		await axios.delete(`${serverIp}/api/price/${pricingId}`, config);

		dispatch({ type: DELETE_PRICING_SUCCESS });
	} catch (error) {
		dispatch({
			type: DELETE_PRICING_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		});
	}
};

export const setBookingDateOptions = (date, options) => ({
	type: SET_BOOKING_DATE_OPTIONS,
	payload: { date, options },
});
