import { IoIosCall } from "react-icons/io";
import { GrMail } from "react-icons/gr";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { UserCircleIcon, ShoppingBagIcon, HeartIcon } from '@heroicons/react/20/solid'
import { Dialog } from '@headlessui/react'
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
    Menu as ChakraMenu, MenuButton, Portal, MenuList, MenuItem,
    useDisclosure,
    Flex
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import { logout } from '../actions/userActions';
import { listSupportDetails } from "../actions/supportFaqActions";
import { BsBookmarkCheckFill, BsCartCheckFill } from "react-icons/bs";
import { listCategory } from "../actions/categoryActions";
import '../screens/style.css'

const usermenu = [
    { name: 'Profile', href: '/profile' },
    { name: 'Logout', href: '/' },
    { name: 'Dashboard', href: '/dashboard' },
]

export default function Header() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { t, i18n } = useTranslation();

    const checkLanguage = () => {
        let lang = localStorage.getItem('languageMethod');
        if (!lang) {
            return 'en';
        }
        return JSON.parse(lang);
    }

    const [lang, setLang] = useState(checkLanguage);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

    const [selectedCountry, setSelectedCountry] = useState("India");
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [menuOneOpen, setMenuOneOpen] = useState(false);
    const [menuTwoOpen, setMenuTwoOpen] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleLinkClick = () => {
        // Close the menu when a link is clicked
        setIsMenuOpen(false);
    };

    const [display, setDisplay] = useState('flex');

    const { isOpen, onOpen, onClose } = useDisclosure()

    const categorytList = useSelector((state) => state.categorytList);
    const { loading: loadingCategory, error: errorCategory, category } = categorytList;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const supportDetails = useSelector((state) => state.supportDetails);
    const { loading: loadingSupport, error: errorSupport, support } = supportDetails;

    useEffect(() => {
        dispatch(listSupportDetails());
        dispatch(listCategory('all'));
    }, [dispatch]);

    useEffect(() => {
        location.pathname.split('/')[1] === `dashboard` ? setDisplay('none') : setDisplay('flex');
    }, [location])

    const logoutHandler = () => {
        dispatch(logout());
        setMobileMenuOpen(false)
        navigate('/');
    };

    useEffect(() => {
        window.onscroll = function () { myFunction() };

        let header = document.getElementById("myHeader");
        let sticky = header.offsetTop;

        function myFunction() {
            if (window.scrollY > sticky) {
                header.classList.add("fixed");
            } else {
                header.classList.remove("fixed");
            }
        }
    }, [])

    return (
        // position='fixed' top='0' zIndex='20' width='full'
        <Flex as='header' className="bg-white" display={display} direction='column' w='full' h='full' >
            <div className="callbutton">
                <a href="tel:+491712634326"><img src="../calling.png" /></a>
            </div>

            <div className="whatsappbutton">
                <a href="https://api.whatsapp.com/send/?phone=491712634326&text=Hello+Weinhaus+Schowalter.&type=phone_number&app_absent=0"
                    target="_blank"><img src="../WhatsApp.png" /></a>
            </div>

            {/* <div className="flex h-10 items-center justify-between bg-cyan-800 px-4 text-sm font-medium text-white sm:px-6 lg:px-8"> */}
            {loadingSupport ? (
                <div>
                    Loading
                </div>
            ) : errorSupport ? (
                <div>
                    Error
                </div>
            ) : (
                <div className="flex h-10 items-center justify-center md:justify-between bg-gray-900 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                    <a className="hidden md:flex" href={`tel:+${support.call}`}><IoIosCall className='mt-1' /><p>+{support.call}</p></a>
                    <div className="self-center text-xs md:text-md">{i18n.language === "de" ? support.offerDe : support.offerIn}</div>
                    <a className="hidden md:flex" href={`mailto:${support.mail}`}><GrMail className='mt-1' /><p>{support.mail}</p></a>
                </div>
            )}
            {!mobileMenuOpen && <nav id="myHeader" className="flex top-0 z-[200] w-full bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] items-center justify-between p-6 lg:px-8" aria-label="Global">

                <div className="flex lg:flex">
                    <RouterLink to={location.pathname.split('/')[1] !== '' ? '/' : `https://weinhaus-schowalter.de/`} className="-m-1.5 p-1.0">
                        <span className="sr-only">weinhaus-schowalter</span>
                        <img
                            className="h-16 w-auto hover:animate-wiggle"
                            src="../logo_WH_Schowalter_orange_main.png"
                            alt=""
                        />
                    </RouterLink>
                </div>

                <div>
                    <div className="flex relative peer group" onMouseEnter={handleMenuOpen} onMouseLeave={handleMenuClose}>
                        <img
                            src="../menu_logo.png"
                            className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500"
                        />
                        <HashLink
                            to={'/wein?cat=all'}
                            className="hidden ml-[30px] md:flex text-md font-bold uppercase leading-6 text-white hover:text-gray-100"
                            onClick={handleLinkClick}
                        >
                            WEINE
                        </HashLink>
                    </div>
                    <div
                        className={`absolute ${isMenuOpen ? 'flex' : 'hidden'} z-40 w-[200px] flex-col bg-white drop-shadow-lg`}
                        onMouseEnter={handleMenuOpen}
                        onMouseLeave={handleMenuClose}
                    >
                        <HashLink key="1" to={'/wein?cat=all'} className="px-5 py-2 hover:bg-gray-200 hover:font-semibold" onClick={handleLinkClick}>
                            Alle Weine
                        </HashLink>
                        {loadingCategory ? (
                            <>Loading</>
                        ) : errorCategory ? (
                            <>Error</>
                        ) : (category.filter((cat) => cat.type === 'category').map((catObj, idx) => (
                            <HashLink key={idx} to={`/wein?cat=${catObj.name}&type=${catObj.type}`} className="px-5 py-2 hover:bg-gray-200 hover:font-semibold" onClick={handleLinkClick}>{catObj.name}</HashLink>
                        ))
                        )}
                    </div>
                </div>

                <div>
                    <div className="flex relative peer group">
                        <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" />
                        <HashLink to={'/hofladen?cat=hofladen'} className="hidden ml-[30px] md:flex text-md font-bold uppercase leading-6 text-white hover:text-gray-100">HOFLADEN</HashLink>
                    </div>
                </div>

                <div>
                    <div className="flex relative peer group">
                        <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" />
                        <HashLink to={'/events'} className="hidden ml-[30px] md:flex text-md font-bold uppercase leading-6 text-white hover:text-gray-100">EVENTS</HashLink>
                    </div>
                </div>

                <div>
                    <div className="flex relative peer group">
                        <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" />
                        <HashLink to={'/camping'} className="hidden ml-[30px] md:flex text-md font-bold uppercase leading-6 text-white hover:text-gray-100">CAMPING</HashLink>
                    </div>
                </div>

                <div>
                    <div className="flex relative peer group">
                        <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" />
                        <HashLink to={'/alpaka'} className="hidden ml-[30px] md:flex text-md font-bold uppercase leading-6 text-white hover:text-gray-100">ALPAKAS</HashLink>
                    </div>
                </div>

                <div>
                    <div className="flex relative peer group">
                        <img src="../menu_logo.png" className="absolute w-[30px] hidden group-hover:flex animate-fade-right animate-duration-500" />
                        <div className="hidden ml-[30px] md:flex text-md font-bold uppercase leading-6 text-white hover:text-gray-100">Ihre Historie</div>
                    </div>
                    <div className="absolute hidden peer-hover:flex hover:flex z-40 w-[200px] flex-col bg-white drop-shadow-lg">
                        <RouterLink to={`/orders`} className="px-5 py-4 hover:bg-gray-200">Bestellungen</RouterLink>
                        <RouterLink to={`/bookings`} className="px-5 py-4 hover:bg-gray-200">Buchungen</RouterLink>
                        <RouterLink to={`/gutscheine`} className="px-5 py-4 hover:bg-gray-200">Gutscheine</RouterLink>
                        <RouterLink to={`/supportFAQ`} className="px-5 py-4 hover:bg-gray-200">Support & FAQ</RouterLink>
                    </div>
                </div>


                <div className='flex gap-4 lg:gap-x-5'>
                    <div>
                        <RouterLink to="/cart" className="text-sm font-medium leading-6 text-white">
                            <span aria-hidden="true">
                                {/* &rarr; */}
                                <BsCartCheckFill className='h-6 w-6' />
                            </span>
                        </RouterLink>
                    </div>
                    <div>
                        <RouterLink to="/booking" className="text-sm font-medium leading-6 text-white">
                            <span aria-hidden="true">
                                {/* &rarr; */}
                                <BsBookmarkCheckFill className='h-6 w-6' />
                            </span>
                        </RouterLink>
                    </div>
                    <div>
                        {userInfo ? (
                            <ChakraMenu>
                                <RouterLink>
                                    <MenuButton className="text-sm font-medium leading-6 text-white"><UserCircleIcon className='h-7 w-7' /></MenuButton>
                                </RouterLink>
                                <Portal>
                                    <MenuList className="text-sm font-semibold uppercase leading-6 text-white" zIndex='210'>
                                        {/* <RouterLink to={usermenu[0].href}>
                                            <MenuItem  >{usermenu[0].name}</MenuItem>
                                        </RouterLink> */}
                                        {userInfo.isAdmin && (
                                            <RouterLink to={usermenu[2].href}>
                                                <MenuItem>{usermenu[2].name}</MenuItem>
                                            </RouterLink>
                                        )}
                                        <MenuItem onClick={logoutHandler}>{usermenu[1].name}</MenuItem>
                                    </MenuList>
                                </Portal>
                            </ChakraMenu>
                        ) : (
                            <RouterLink to="/signup" className="text-sm font-medium leading-6 text-slate-100">
                                <span aria-hidden="true">
                                    <UserCircleIcon className='h-7 w-7' />
                                </span>
                            </RouterLink>
                        )
                        }
                    </div>
                </div>
                <div className='flex gap-2 lg:hidden'>
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </nav>}
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <RouterLink to="/" className="-m-1.5 p-1.0" onClick={() => setMobileMenuOpen(false)}>
                            <span className="sr-only">Sapphire 365</span>
                            <img
                                className="h-12 w-auto"
                                src="../logo_claim_orange.png"
                                alt=""
                            />
                        </RouterLink>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-2 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="py-6">

                                <ul className="flex flex-col">
                                    {userInfo && userInfo.isAdmin && (
                                        <li className="group">
                                            <RouterLink to={usermenu[2].href} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                                {usermenu[2].name}
                                            </RouterLink>
                                        </li>
                                    )}
                                    <li>
                                        <RouterLink to={`/wein?cat=all`} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Wein
                                        </RouterLink>
                                        <ul className="flex flex-col gap-4 pl-8">
                                            <li>
                                                <HashLink key='1' to={'/wein?cat=all'} onClick={() => setMobileMenuOpen(false)} className="px-4 text-gray-400 hover:text-white">Alle Weine</HashLink>
                                            </li>
                                            {loadingCategory ? (
                                                <>Loading</>
                                            ) : errorCategory ? (
                                                <>Error</>
                                            ) : (category.filter((cat) => cat.type === 'category').map((catObj, idx) => (
                                                <li key={idx}>
                                                    <HashLink to={`/wein?cat=${catObj.name}&id=${catObj._id}`} onClick={() => setMobileMenuOpen(false)} className="px-4 text-gray-400 hover:text-white">{catObj.name}</HashLink>
                                                </li>
                                            ))
                                            )}
                                        </ul>
                                    </li>
                                    <li className="group">
                                        <RouterLink to="/hofladen" onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Hofladen
                                        </RouterLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to="/events" onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Events
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <HashLink to={`/camping`} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Camping
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink to={`/alpaka`} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Alpakas
                                        </HashLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to={`/orders`} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">Bestellungen</RouterLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to={`/bookings`} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">Buchungen</RouterLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to={`/gutscheine`} onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">Gutscheine</RouterLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to="/cart" onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Cart
                                        </RouterLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to="/booking" onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Bestellung
                                        </RouterLink>
                                    </li>
                                    <li className="group">
                                        <RouterLink to="/supportFAQ" onClick={() => setMobileMenuOpen(false)} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                            Support & FAQ
                                        </RouterLink>
                                    </li>
                                    {userInfo && (
                                        <li className="group">
                                            <RouterLink onClick={logoutHandler} className="flex items-center py-4 px-6 text-gray-200 hover:text-white">
                                                Logout
                                            </RouterLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </Flex>
    )
}