import { IoIosCall } from 'react-icons/io';
import { MdLocationOn } from 'react-icons/md';
import { GrMail } from 'react-icons/gr';
import { Flex } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { listSupportDetails } from '../actions/supportFaqActions';
import CookieBanner from './CookieBanner';

export default function Footer() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const location = useLocation();
    const [display, setDisplay] = useState('flex');
    const [showBanner, setShowBanner] = useState(false);

    const supportDetails = useSelector((state) => state.supportDetails);
    const { loading: loadingSupport, error: errorSupport, support } = supportDetails;

    useEffect(() => {
        dispatch(listSupportDetails());
    }, [dispatch]);

    useEffect(() => {
        location.pathname.split('/')[1] === `dashboard` ? setDisplay('none') : setDisplay('flex');
    }, [location])

    return (
        <Flex as='footer' className="relative bg-gradient-to-r from-gray-900 via-gray-700 to-[#ed6d15] dark:bg-gray-900" display={display}>
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="flex justify-between w-full" style={{ backgroundImage: 'nav-diamonds.JPG' }}>
                        <div className="flex w-1/2 md:w-1/3 flex-col justify-between">
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">SERVICES</h2>
                                <ul className="text-white text-xs md:text-lg dark:text-gray-400 font-medium">
                                    <li key='1' className="mb-4">
                                        <a href="https://weinhaus-schowalter.de/services/" className="hover:underline">IMPRESSUM</a>
                                    </li>
                                    <li key='2' className="mb-4">
                                        <a href="https://weinhaus-schowalter.de/datenschutzerklaerung/" className="hover:underline">DATENSCHUTZERKLÄRUNG</a>
                                    </li>
                                    <li key='3' className="mb-4">
                                        <a onClick={() => setShowBanner(true)} className="hover:underline">COOKIE-RICHTLINIE (EU)</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#" className="flex flex-col items-start gap-2">
                                <img src="../logo_WH_Schowalter_orange_main.png" className="h-16 md:h-24 mr-3" alt="Logo" />
                                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
                                {/* <h1 className="font-bold mt-1 text-gray-600">A VENTURE OF SORADIS GROUP UNDER SORADIS JEWELLERS PRIVATE LIMITED</h1> */}
                            </a>
                        </div>
                        <div className="w-1/2 md:w-1/3">
                            <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Kontaktieren Sie uns</h2>
                            {loadingSupport ? (
                                <div>
                                    Loading
                                </div>
                            ) : errorSupport ? (
                                <div>
                                    Error
                                </div>
                            ) : (
                                <ul className="text-white text-xs md:text-lg dark:text-gray-400 font-medium">
                                    <li key='1' className="flex mb-4">
                                        <MdLocationOn className='mt-1 md:mt-[6px] mr-2' /><a className="">{support.address}</a>
                                    </li>
                                    <li key='3' className="flex mb-4">
                                        <GrMail className='mt-1 md:mt-[6px] mr-2' /><a className="" href={`mailto:${support.mail}`}>{support.mail}</a>
                                    </li>
                                    <li key='4' className="flex mb-4">
                                        <IoIosCall className='mt-1 md:mt-[6px] mr-2' /><a className="" href={`tel:+${support.call}`}>+{support.call}</a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <h1 className="font-bold mt-1 text-[8px] md:text-xl text-gray-600"></h1>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="flex flex-col md:flex-row items-center justify-between">
                    {/* <div className="flex flex-col gap-2">
                        <h1 className="font-bold self-center text-xl text-blue-800"></h1>
                        <div className="flex self-center gap-6">
                            <img src="../amazon-png-logo-vector-6706.png" className="h-12" alt="Logo" />
                            <img src="../ebay-logo-34477.png" className="h-20 -mt-4" alt="Logo" />
                            <img src="../logomyntra.png" className="h-20 -mt-4" alt="Logo" />
                        </div>
                    </div> */}
                    <span className="hidden md:flex w-1/3 text-sm text-white text-center dark:text-gray-400">
                        ©2024 Weinhaus Schowalter | Alle Rechte vorbehalten
                    </span>
                    <span className="hidden md:flex w-1/3 text-sm text-white dark:text-gray-400">Designed & Developed by<a href="https://www.soradisdigital.com" target='_blank' className="ml-1 text-blue-500 hover:underline">Soradis Digital™</a>
                    </span>
                    <div className="flex space-x-6 sm:justify-center sm:mt-0">
                        <a href="https://www.facebook.com/WeingutSchowalter" target='_blank' className="text-white hover:text-blue-900 dark:hover:text-white">
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                            <span className="sr-only">Facebook page</span>
                        </a>
                        <a href="https://www.instagram.com/weingutschowalter/?igshid=YmMyMTA2M2Y%3D" target='_blank' className="text-white hover:text-pink-700 dark:hover:text-white">
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" /></svg>
                            <span className="sr-only">Instagram page</span>
                        </a>
                    </div>
                    {/* <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="#" className="hover:underline">Sapphire365™</a>. All Rights Reserved.
                    </span> */}
                    <span className="flex md:hidden mb-2 text-sm text-white sm:text-center dark:text-gray-400">©2024 Weinhaus Schowalter | Alle Rechte vorbehalten
                    </span>
                    <span className="flex md:hidden text-sm text-white sm:text-center dark:text-gray-400">Designed & Developed by<a href="https://www.soradisdigital.com" target='_blank' className="ml-1 text-blue-500 hover:underline">Soradis Digital™</a>
                    </span>
                </div>
            </div>
            <CookieBanner showBannerOnClick={showBanner} setShowBannerOnClick={setShowBanner} />
        </Flex>
    )
}