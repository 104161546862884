import axios from "axios";

import {
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_LIST_FAIL,
  SUB_CATEGORY_LIST_REQUEST,
  SUB_CATEGORY_LIST_SUCCESS,
  MENU_LINK_UPDATE_REQUEST,
  MENU_LINK_UPDATE_SUCCESS,
  MENU_LINK_UPDATE_FAIL,
} from "../constants/categoryConstants";
import { serverIp } from "../config/conf";

export const listCategory = (type) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_LIST_REQUEST });

    const { data } = await axios.get(`${serverIp}/api/category?type=${type}`);

    dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listSubCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUB_CATEGORY_LIST_REQUEST });

    const { data } = await axios.get(`${serverIp}/api/category/${id}`);

    dispatch({ type: SUB_CATEGORY_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUB_CATEGORY_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/category/${id}`, config);

    dispatch({ type: CATEGORY_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createCategory =
  (name, parentCategory, type) => async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/category`,
        { name, parentCategory, type },
        config
      );

      dispatch({ type: CATEGORY_CREATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: CATEGORY_CREATE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const updateMenuLink = (menuLinkData) => async (dispatch, getState) => {
  try {
    dispatch({ type: MENU_LINK_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${serverIp}/api/category/${menuLinkData._id}`,
      menuLinkData,
      config
    );

    dispatch({
      type: MENU_LINK_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MENU_LINK_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
