import {
	Button,
	Icon,
	Menu as ChakraMenu,
	MenuButton,
	Portal,
	MenuList,
	MenuItem,
	Select
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoAdd, IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CiMenuKebab } from 'react-icons/ci';

import {
	createProduct,
	deleteProduct,
	listProducts,
	copyProduct
} from '../actions/productActions';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';
import './sidenav.css'
import { IoMdCopy } from 'react-icons/io';
import { listCategory, listSubCategory } from '../actions/categoryActions';

const ProductListScreen = (props) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const routePath = useLocation();
	const queryParams = new URLSearchParams(routePath.search);
	const categoryQry = queryParams.get('category');

	const [category, setCategory] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('');
	const [taste, setTaste] = useState('');
	const [subCategory, setSubCategory] = useState('');

	const productList = useSelector((state) => state.productList);
	const { loading, error, products } = productList;

	const categorytList = useSelector((state) => state.categorytList);
	const { loading: loadingCategory, error: errorCategory, category: categoryList } = categorytList;

	const subCategoryList = useSelector((state) => state.subCategoryList);
	const { loading: loadingSubCategory, error: errorSubCategory, subCategory: subCategoryLists } = subCategoryList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const productDelete = useSelector((state) => state.productDelete);
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = productDelete;

	const productCreate = useSelector((state) => state.productCreate);
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		product: createdProduct,
	} = productCreate;

	useEffect(() => {
		dispatch({ type: PRODUCT_CREATE_RESET });

		if (!userInfo.isAdmin) {
			navigate('/login');
		} else {
			dispatch(listCategory('all'));
		}

		if (successCreate) {
			navigate(`/dashboard/product/${createdProduct._id}/edit?category=${categoryQry}`);
		} else {
			dispatch(listProducts(category, subCategory, taste, '1', 'All'));
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		successDelete,
		successCreate,
		createdProduct,
		props.list,
		category,
		subCategory,
		taste
	]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteProduct(id));
		}
	};

	const createProductHandler = () => {
		dispatch(createProduct(props.list));
	};

	const copyHandler = (id) => {
		dispatch(copyProduct(id));
	}

	// const handleSelectionChange =  (e) => {
	// 	console.log(e)
	//     const myObject = JSON.parse(e);
	//     setCategory(myObject.name);
	//     setSelectedCategory(myObject._id);
	//     dispatch(listSubCategory(myObject._id));
	// }

	const categories = categoryList?.filter((cat) => cat.type === 'category');
	const colors = categoryList?.filter((cat) => cat.type === 'color');
	const tastes = categoryList?.filter((cat) => cat.type === 'taste');

	return (
		<div>
			<Sidebar menu='mein Geschäft' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>

					<div className="table-data drop-shadow-md">
						<div class="order">
							<div class="head">
								<h3>Wein</h3>
								{/* <i class='bx bx-search' ></i>
								<i class='bx bx-filter' ></i> */}

								<div className="flex gap-6 p-16 w-full">
									<Select width='30%' value={category} onChange={(e) => setCategory(e.target.value)}>
										<option key='1' value='all'>Category</option>
										{loadingCategory ? (
											<>Loading</>
										) : errorCategory ? (
											<>error</>
										) : (categories.map((catObj, idx) => (
											<option key={catObj._id} value={catObj.name}>{catObj.name}</option>
										)))}
									</Select>
									<Select width='30%' value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
										<option key='1' value='all'>Color</option>
										{loadingSubCategory ? (
											<>Loading</>
										) : errorSubCategory ? (
											<>error</>
										) : (colors.map((subCatObj, idx) => (
											<option className="text-black" key={idx} value={subCatObj.name}>{subCatObj.name}</option>
										)))}
									</Select>
									<Select width='30%' value={taste} onChange={(e) => setTaste(e.target.value)}>
										<option key='1' value='all'>Taste</option>
										{loadingSubCategory ? (
											<>Loading</>
										) : errorSubCategory ? (
											<>error</>
										) : (tastes.map((subCatObj, idx) => (
											<option className="text-black" key={idx} value={subCatObj.name}>{subCatObj.name}</option>
										)))}
									</Select>
								</div>
								<Button onClick={createProductHandler} colorScheme='blue'>
									<Icon as={IoAdd} mr='2' fontSize='xl' fontWeight='bold' />Add Product
								</Button>
							</div>
							{loadingDelete && <Loader />}
							{errorDelete && <Message type='error'>{errorDelete}</Message>}
							{loadingCreate && <Loader />}
							{errorCreate && <Message type='error'>{errorCreate}</Message>}

							{loading ? (
								<Loader />
							) : error ? (
								<Message type='error'>{error}</Message>
							) : (
								<table>
									<thead>
										<tr>
											<th>Image</th>
											<th className='w-80'>Name</th>
											<th>Year</th>
											<th>Category</th>
											<th>Color</th>
											<th>Taste</th>
											<th>Price</th>
											<th>Stock</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{products.map((product, index) => (
											<tr key={index}>
												<td>
													{/* <Icon as={RxAvatar} boxSize='10' /> */}
													<img src={product.image[0]} alt={product.name} />
												</td>
												<td>
													<p className='w-80'>{product.name}</p>
												</td>
												<td>
													{product.year}
												</td>
												<td>
													{product.category}
												</td>
												<td>
													{product.subCategory}
												</td>
												<td>
													{product.taste}
												</td>
												<td>
													<p>{(product.discountedPrice)?.toFixed(2).replace('.', ',')} €</p>
												</td>
												<td>
													<p>{product.countInStock}</p>
												</td>
												<td>
													<div>
														<ChakraMenu>
															<MenuButton className="text-sm font-medium leading-6 text-gray-900"><CiMenuKebab className='h-7 w-7 rotate-90' /></MenuButton>
															<Portal>
																<MenuList className="text-sm font-semibold uppercase leading-6 text-gray-900" zIndex='99'>
																	<MenuItem onClick={() => copyHandler(product._id)} ><IoMdCopy className='h-5 w-5 mr-4' /> Copy</MenuItem>
																	<MenuItem as={RouterLink} to={`/dashboard/product/${product._id}/edit?category=${categoryQry}`} ><IoPencilSharp className='h-5 w-5 mr-4' /> Edit</MenuItem>
																	<MenuItem color='red.400' onClick={() => deleteHandler(product._id)}><IoTrashBinSharp className='h-5 w-5 mr-4' /> Delete</MenuItem>
																</MenuList>
															</Portal>
														</ChakraMenu>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</main>
			</section>
		</div>
	)
};

export default ProductListScreen;
