import React, { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	Heading,
	Icon,
	Image,
	Link,
	Select,
	Text,
} from '@chakra-ui/react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './sidenav.css'

import Sidebar from "../components/Sidebar";
import { getBookingById, updateBookingMenuItems, updateBookingToCompleted, updateBookingToPaid } from '../actions/bookingActions';
import { UPDATE_BOOKING_TO_COMPLETED_RESET, UPDATE_BOOKING_TO_PAID_RESET, UPDATE_MENU_ITEMS_RESET } from '../constants/bookingConstants';
import { listMenuItems } from '../actions/menuItemActions';
import { BiTrash } from 'react-icons/bi';
import { getPricingEntries } from '../actions/bookingItemActions';
import { ImCheckboxChecked } from 'react-icons/im';
import { FcCancel } from 'react-icons/fc';
import { CiDeliveryTruck } from 'react-icons/ci';

const BookingScreenAdmin = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: bookingId } = useParams();

	const menuItemList = useSelector((state) => state.menuItemList);
	const { loading: loadingMenuList, menuItems, error: errorMenuList } = menuItemList;

	const bookingDetails = useSelector((state) => state.bookingDetails);
	const { booking, loading, error } = bookingDetails;

	const bookingPay = useSelector((state) => state.bookingPay);
	const { loading: loadingPay, success: successPay } = bookingPay;

	const bookingCompleted = useSelector((state) => state.bookingCompleted);
	const { loading: loadingCompleted, success: successCompleted } = bookingCompleted;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const updateMenuItems = useSelector((state) => state.updateMenuItems);
	const { booking: updatedMenuItemBooking, loading: loadingUpdatedMenuItem, error: errorUpdatedMenuItem, success: successUpdatedMenuItem } = updateMenuItems;

	const pricing = useSelector((state) => state.pricing);
	const { pricingEntries } = pricing;

	const [selectedMenuItem, setSelectedMenuItem] = useState('');

	const successPaymentHandler = (paymentResult) => {
		dispatch(updateBookingToPaid(bookingId, paymentResult));
	};

	useEffect(() => {
		dispatch(listMenuItems())
		dispatch(getPricingEntries());
	}, [dispatch])

	useEffect(() => {
		if (successUpdatedMenuItem) {
			dispatch(getBookingById(bookingId));
			dispatch({ type: UPDATE_MENU_ITEMS_RESET });
		}
	}, [dispatch, successUpdatedMenuItem])

	useEffect(() => {
		dispatch({ type: UPDATE_BOOKING_TO_PAID_RESET });
		dispatch({ type: UPDATE_BOOKING_TO_COMPLETED_RESET });

		if (!booking || successPay) {
			dispatch({ type: UPDATE_BOOKING_TO_PAID_RESET });
			dispatch({ type: UPDATE_BOOKING_TO_COMPLETED_RESET });
			dispatch(getBookingById(bookingId));
		}
	}, [dispatch, bookingId, successPay, booking, successCompleted]);

	const deliverHandler = (id) => {
		dispatch(updateBookingToCompleted(id))
		navigate(`/dashboard/bookings`)
	};

	const [editableMenuItems, setEditableMenuItems] = useState([]);

	useEffect(() => {
		if (booking && booking.menuItems) {
			setEditableMenuItems(booking.menuItems);
		}
	}, [booking?.menuItems]);

	// Handle the selection of a menu item from the dropdown
	const handleMenuItemSelect = (event) => {
		const menuItem = menuItems?.find((item) => item.name === event.target.value);
		console.log(menuItem);
		setSelectedMenuItem(menuItem);
	};

	// Handle the addition of the selected menu item to the editable list
	const handleAddMenuItem = () => {
		if (selectedMenuItem) {
			// Check if the selected menu item already exists in editableMenuItems
			const isDuplicate = editableMenuItems.some((item) => item.product === selectedMenuItem._id || item._id === selectedMenuItem._id);
			console.log(selectedMenuItem._id)
			console.log(editableMenuItems);
			console.log(isDuplicate);
			if (!isDuplicate) {
				setEditableMenuItems([...editableMenuItems, selectedMenuItem]);
			}
		}
	};

	// Handle the removal of a menu item from the editable list
	const handleRemoveMenuItem = (index) => {
		const updatedMenuItems = [...editableMenuItems];
		updatedMenuItems.splice(index, 1);
		setEditableMenuItems(updatedMenuItems);
	};

	const handleMenuItemsChanges = () => {
		dispatch(updateBookingMenuItems(bookingId, editableMenuItems))
	}

	return loading ? (
		<div>Loading</div>
		// <Loader />
	) : error ? (
		<div type='error'>{error}</div>
	) : (
		<div>
			<Sidebar menu='Buchungen' />
			{/* <!-- CONTENT --> */}
			<section id="content">
				{/* <!-- MAIN --> */}
				<main>
					<Flex>
						{/* <Header /> */}
						<Flex w='full' py='5' direction='column'>
							<ToastContainer position="top-right" autoClose={3000} hideProgressBar />
							<Grid templateColumns={{ sm: '3fr fr', md: '3fr 2fr' }} gap='5'>
								{/* Column 1 */}
								<Flex direction='column' mx='4' shadow='md' p='8' bgColor='white' rounded='lg'>
									{/* Shipping */}
									<Box borderBottom='1px' borderColor='gray.300'>
										<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
											Buchung
										</Heading>
										<Text>
											Name: <strong>{booking.userName}</strong>
										</Text>
										<Text>
											Email:{' '}
											<strong>
												<a href={`mailto:${booking.userEmail}`}>{booking.userEmail}</a>
											</strong>
										</Text>
										<Text>
											Number: {' '}
											<strong>{booking.userContact}</strong>
										</Text>
										<Text mt='4'>
											{booking.isCompleted === 1 ? (
												<div className="flex gap-2">
													<div >
														<Icon as={ImCheckboxChecked} color='green.400' className="rounded-full" /> Abgeschlossen,
													</div>
													<div className="font-semibold">{new Date(booking.completedAt).toDateString()}</div>
												</div>
											) : booking.isCompleted === 2 ? (
												<div className="flex gap-2">
													<div >
														<Icon as={FcCancel} color='green.400' className="rounded-full" /> Abgesagt
													</div>
												</div>
											) : (
												<div>
													<Icon as={CiDeliveryTruck} color='red.400' boxSize='5' /> wird bald abgeschlossen sein
												</div>
											)}
										</Text>
									</Box>

									{/* Payment Method */}
									<Box borderBottom='1px' py='6' borderColor='gray.300'>
										<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
											Zahlungsabwicklung
										</Heading>
										<Text>
											<strong>Ihre Zahlungsoptionen: </strong>
											{booking.paymentMethod.toUpperCase()}
										</Text>
										<Text mt='4'>
											{booking.isPaid ? (
												<>
													Bezahlt {booking.paidAt.substring(0, 10)}
												</>
											) : (
												<>Zu zahlen</>
											)}
										</Text>
									</Box>

									{/* Order Items */}
									<Box py='6' borderColor='gray.300'>
										<Heading as='h2' mb='3' fontSize='2xl' fontWeight='semibold'>
											Wunschliste (möchte ich auf der Website haben)
										</Heading>
										<Box>
											{editableMenuItems?.length === 0 ? (
												<div>Ihr Warenkorb ist leer</div>
											) : (
												<Box py='2'>
													{editableMenuItems?.map((item, idx) => (
														<Flex key={idx} alignItems='center' justifyContent='space-between' gap='4'>
															<Flex py='2' alignItems='center'>
																<Image src={item.image} alt={item.name} w='12' h='12' objectFit='cover' mr='6' />
																<Text fontWeight='semibold' fontSize='lg'>
																	{item.name}
																</Text>
															</Flex>
															<Flex gap='2' alignItems='center'>
																<Text fontSize='lg' fontWeight='semibold'>
																	{((+booking.adults * item.priceAdult) + (+booking.children * item.priceChild) + ((1) * item.priceGroup))?.toFixed(2).replace('.', ',')}€
																</Text>
																<BiTrash className='text-red-500 text-xl' onClick={() => handleRemoveMenuItem(idx)} />
															</Flex>
														</Flex>
													))}
												</Box>
											)}
										</Box>
										<Box py='2'>
											<Flex alignItems='center'>

												{loadingMenuList ? (
													<>Loading</>
												) : errorMenuList ? (
													<>Error</>
												) : (
													<Select value={selectedMenuItem?.name} onChange={handleMenuItemSelect}>
														{menuItems?.filter((menu) => menu.category === "Camping Services").map((item) => (
															<option key={item._id} value={item.name}>
																{item.name}
															</option>
														))}
													</Select>
												)}
												<Button colorScheme='teal' ml='2' onClick={handleAddMenuItem} disabled={!selectedMenuItem}>
													Add
												</Button>
											</Flex>
										</Box>
										{booking?.totalMenuPrice && (
											<Flex py='2' borderColor='gray.200' alignitems='center' justifyContent='space-between'>
												<Flex direction='column'>
													<Text fontWeight='bold' fontSize='xl'>
														Gesamtpreis Ihrer Wunschliste
													</Text>
													{/* <Text fontSize='sm'>(* These are not confirmed, you pay for this only on-site after confirmation)</Text>
													<Text fontSize='sm'>(this will not be included in your booking price)</Text> */}
												</Flex>
												<Text fontWeight='bold' fontSize='xl'>
													{editableMenuItems.reduce((total, item) => total + ((+booking.adults * item.priceAdult) + (+booking.children * item.priceChild) + ((1) * item.priceGroup)), 0)?.toFixed(2).replace('.', ',')}€
												</Text>
											</Flex>
										)}
										<Flex justifyContent='center'>
											<Button colorScheme='teal' onClick={handleMenuItemsChanges}>Save</Button>
										</Flex>
									</Box>
								</Flex>

								{/* Column 2 */}
								<Flex
									mx='4'
									direction='column'
									bgColor='white'
									justifyContent='space-between'
									py='8'
									px='8'
									shadow='md'
									rounded='lg'
									borderColor='gray.300'>
									<Box>
										<Heading mb='6' as='h2' fontSize='3xl' fontWeight='bold'>
											Ihre Stellplatzbuchung
										</Heading>

										<div className="flex justify-between">
											<p className="text-gray-700">Ihre Reisedaten</p>
											<p className="text-gray-700">Von {new Date(booking.startDate).toISOString().split('T')[0]} Bis {new Date(booking.endDate).toISOString().split('T')[0]}</p>
										</div>

										<div className="flex justify-between">
											<p className="text-gray-700">Erwachsene</p>
											<p className="text-gray-700">{booking.adults}</p>
										</div>

										<div className="flex justify-between">
											<p className="text-gray-700">Kinder</p>
											<p className="text-gray-700">{booking.children}</p>
										</div>

										<div className="flex justify-between">
											<p className="text-gray-700">Stellplatz Übernachtung x ({Math.floor((new Date(booking.endDate) - new Date(booking.startDate)) / (1000 * 60 * 60 * 24))})</p>
											<p className="text-gray-700">{(booking.totalBookingPrice)?.toFixed(2).replace('.', ',')}€</p>
										</div>

										{booking?.discount && (
											<div className="flex justify-between">
												<p className="text-gray-700">Rabatt(%)</p>
												<p className="text-gray-700">{booking.discount}%</p>
											</div>
										)}

										{booking?.couponCode && (
											<div className="flex justify-between">
												<p className="text-gray-700">Gutschein Code</p>
												<p className="text-gray-700">{booking.couponCode}</p>
											</div>
										)}

										{/* Total Price */}
										<Flex
											borderBottom='1px'
											py='2'
											borderColor='gray.200'
											alignitems='center'
											justifyContent='space-between'>
											<Text fontSize='xl'>Gesamter Betrag</Text>
											<Text fontWeight='bold' fontSize='xl'>
												{(booking.discountedPrice)?.toFixed(2).replace('.', ',')}€
											</Text>
										</Flex>
										<div className="flex justify-between py-2">
											<p className="text-gray-700">Inklusive MwSt</p>
											<p className="text-gray-700">{((booking.discountedPrice) * (pricingEntries[0]?.campingTax / 100))?.toFixed(2).replace('.', ',')}€</p>
										</div>
									</Box>

									{loadingCompleted && <>Loading</>}

									{(userInfo &&
										userInfo.isAdmin &&
										!booking.isPaid) && (
											<Button type="button" colorScheme="teal" onClick={() => successPaymentHandler({ id: 'advance', status: 'COMPLETED', update_time: Date.now(), email_address: booking.userName })}>
												Als bezahlt markieren
											</Button>
										)}

									{userInfo &&
										userInfo.isAdmin &&
										booking.isPaid &&
										!booking.isCompleted && (
											<Button
												type='button'
												colorScheme='teal'
												onClick={() => deliverHandler(booking._id)}>
												Als abgeschlossen markieren
											</Button>
										)}
								</Flex>
							</Grid>
						</Flex>
					</Flex>
				</main>
			</section>
		</div>
	);
};

export default BookingScreenAdmin;
